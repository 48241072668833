import React, { useCallback, useContext } from 'react';
import {
    ViewModelForm
} from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import _ from 'lodash';
import { wizardProps } from 'gw-portals-wizard-react';
import metadata from './ValuableSummaryItem.metadata.json5';
import styles from './ValuableSummaryItem.module.scss';
import useCurrency from '../../../../modules-react/nfum-portals-utils-react/hooks/useCurrency';

function ValuableSummaryItem(props) {
    const {
        scheduleItems
    } = props;
    const currencyFormatter = useCurrency();
    const translator = useContext(TranslatorContext);

    const articleSubType = _.isEmpty(scheduleItems.itemData.ArticleSubType) ? '' : scheduleItems.itemData.ArticleSubType.typeCodeValue;
    const articleMakeType = _.isEmpty(scheduleItems.itemData.ArticleMakeType) ? '' : scheduleItems.itemData.ArticleMakeType.typeCodeValue;

    const getArticleValuableType = useCallback((SubType) => {
        return articleSubType !== undefined ? translator({
            id: `typekey.SpecifiedValuableType_NFUM.${SubType}`,
            defaultMessage: SubType
        }) : '';
    }, [articleSubType,
        translator]);

    const getArticleMakeType = useCallback((SubType) => {
        return articleSubType !== undefined ? translator({
            id: `typekey.SpecfdValuableMake_NFUM.${SubType}`,
            defaultMessage: SubType
        }) : '';
    }, [translator,
        articleSubType]);

    const overrideProps = {
        articleReplacementCost: {
            value: currencyFormatter
                .formatCurrency(scheduleItems.itemData.ArticleReplacementCost.bigDecimal,
                    true)
        },
        articleValuableType: {
            content: `${getArticleValuableType(articleSubType)}, `,
            visible: !_.isEmpty(scheduleItems.itemData.ArticleSubType)
                && !_.isEmpty(scheduleItems.itemData.ArticleType)
                && scheduleItems.itemData.ArticleType.typeCodeValue === 'JewelleryAndGemstones'
        },
        articleWatchMake: {
            content: `${getArticleMakeType(articleMakeType)}, `,
            visible: !_.isEmpty(scheduleItems.itemData.ArticleMakeType)
                && !_.isEmpty(scheduleItems.itemData.ArticleType)
                && scheduleItems.itemData.ArticleType.typeCodeValue === 'Watches'
        },
        articleGunMake: {
            content: `${getArticleMakeType(articleMakeType)}, `,
            visible: !_.isEmpty(scheduleItems.itemData.ArticleMakeType)
                && !_.isEmpty(scheduleItems.itemData.ArticleType)
                && scheduleItems.itemData.ArticleType.typeCodeValue === 'Guns'
        },
        articleDescription: {
            content: !scheduleItems.itemData.ArticleDescription ? '' : scheduleItems.itemData.ArticleDescription.stringValue,
            visible: !_.isEmpty(scheduleItems.itemData.ArticleDescription)
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            valuableSummaryItem: ValuableSummaryItem
        }
    };

    return (
        <ViewModelForm
            model={scheduleItems}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

ValuableSummaryItem.propTypes = wizardProps;
export default ValuableSummaryItem;
