import { setComponentMapOverrides } from '@jutro/uiconfig';
import NGHBespokePolicyChangeWizardPageTemplate from './NGHBespokePolicyChangeWizardPageTemplate';
import NGHBespokeCoveragesPolicyChangeWizardPageTemplate from './NGHBespokeCoveragesPolicyChangeWizardPageTemplate';
import BespokePolicyChangeDetailsPage from './pages/BespokePolicyChangeDetails/BespokePolicyChangeDetailsPage';
import BespokePolicyChangeSummaryPage from './pages/BespokePolicyChangeSummary/BespokePolicyChangeSummaryPage';
import BespokePolicyChangeSuccessPage from './pages/BespokePolicyChangeSuccess/BespokePolicyChangeSuccessPage';

setComponentMapOverrides(
    {
        NGHBespokePolicyChangeWizardPageTemplate: { component: 'NGHBespokePolicyChangeWizardPageTemplate' },
        NGHBespokeCoveragesPolicyChangeWizardPageTemplate: { component: 'NGHBespokeCoveragesPolicyChangeWizardPageTemplate' },
        BespokePolicyChangeDetailsPage: { component: 'BespokePolicyChangeDetailsPage' },
        BespokePolicyChangeSummaryPage: { component: 'BespokePolicyChangeSummaryPage' },
        BespokePolicyChangeSuccessPage: { component: 'BespokePolicyChangeSuccessPage' },
    },
    {
        NGHBespokePolicyChangeWizardPageTemplate,
        NGHBespokeCoveragesPolicyChangeWizardPageTemplate,
        BespokePolicyChangeDetailsPage,
        BespokePolicyChangeSummaryPage,
        BespokePolicyChangeSuccessPage,
    }
);

export { default as BespokePolicyChangeDetailsPage } from './pages/BespokePolicyChangeDetails/BespokePolicyChangeDetailsPage';
export { default as BespokePolicyChangeSummaryPage } from './pages/BespokePolicyChangeSummary/BespokePolicyChangeSummaryPage';
export { default as BespokePolicyChangeSuccessPage } from './pages/BespokePolicyChangeSuccess/BespokePolicyChangeSuccessPage';
export { default as NGHBespokePolicyChangeWizard } from './NGHBespokePolicyChangeWizard';
export { default as NGHBespokeCoveragesPolicyChangeWizard } from './NGHBespokeCoveragesPolicyChangeWizard';
