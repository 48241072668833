import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from 'nfum-portals-utils-react';
import { NfumLoader } from 'nfum-components-platform-react';
import {
    BridgeImageTextHyperlink,
    BridgeHeaderText,
    cmsComponents
} from 'nfum-cms-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import styles from './MarketingSidebar.module.scss';

const MarketingSidebar = (props) => {
    const { pageId } = props;
    const { env } = appConfig;
    const { marketingInfo } = useContext(AppContext);
    const [isLoaded, setIsLoaded] = useState(false);
    const MARKETING_INFO_TIMEOUT = 15000;

    const headerTexts = marketingInfo && marketingInfo[pageId]?.rightBlocks
        ?.filter((block) => block.contentType.includes(cmsComponents.HEADER_TEXT));

    const imageTextHyperlinks = marketingInfo && marketingInfo[pageId]?.rightBlocks
        ?.filter((block) => block.contentType.includes(cmsComponents.IMAGE_TEXT_HYPERLINK));

    useEffect(() => {
        setTimeout(() => {
            setIsLoaded(true);
        }, MARKETING_INFO_TIMEOUT);
    }, []);

    return (
        <div>
            {marketingInfo && marketingInfo[pageId] && (
                <aside className={styles.marketing}>
                    { headerTexts && headerTexts.map((imageLink, index) => {
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <div className={styles.marketingInfoBox} key={index}>
                                <BridgeHeaderText
                                    headerText={imageLink.headerText}
                                    text={imageLink.text}
                                    imageUrl={imageLink.headerImage?.url ? `${env.NFUM_CMS_URL}${imageLink.headerImage?.url}` : ''}
                                    imageAlt={imageLink.headerImageAlt}
                                />
                            </div>
                        );
                    })}
                    { imageTextHyperlinks && imageTextHyperlinks.map((imageLink, index) => {
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <div className={styles.marketingInfoBox} key={index}>
                                <BridgeImageTextHyperlink
                                    imageUrl={imageLink.image?.url ? `${env.NFUM_CMS_URL}${imageLink.image?.url}` : ''}
                                    linkUrl={imageLink.link}
                                    text={imageLink.text}
                                    imageAlt={imageLink.imageAlt}
                                    linkText={imageLink.linkText}
                                />
                            </div>
                        );
                    })}
                </aside>
            )}
            { (!marketingInfo || !marketingInfo[pageId]) && !isLoaded &&
                <div>
                    <NfumLoader />
                </div>
            }
        </div>
    );
};

export default MarketingSidebar;
