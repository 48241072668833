import React, {
    Fragment, useCallback, useState, useRef
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslator } from '@jutro/locale';
import { MetadataContent } from '@jutro/uiconfig';
import { useAuthentication, RedirectAfterLogin, AUTH_ERRORS } from 'gw-digital-auth-react';
import { NfumLoader } from 'nfum-components-platform-react';
import { isEmpty } from '../LoginUtil';

import messages from './LoginComponent.messages';
import metadata from './LoginComponent.metadata.json5';
import styles from '../CommonLogin.module.scss';

function LoginComponent(props) {
    const {
        onForgotPassword, successPath, loginPath, pathAfterSignIn
    } = props;
    const translator = useTranslator();
    const [formData, updateFormData] = useState({});
    const [loginError, updateLoginError] = useState('');
    const [isSignInButtonClicked, setSignInButtonClicked] = useState(false);
    const { login: authLogin, isAuthInProgress } = useAuthentication();
    const signInRef = useRef({});

    const login = useCallback(() => {
        const username = _.get(formData, 'username');
        const password = _.get(formData, 'password');

        if (isEmpty(username) || isEmpty(password)) {
            updateLoginError('fieldEmpty');
            return Promise.resolve();
        }

        updateLoginError(''); // reset errors
        setSignInButtonClicked(true);

        return authLogin({
            username: username,
            password: password
        })
            .then(() => {
                // reset errors
                updateLoginError('');
            })
            .catch((err) => {
                switch (_.get(err, 'error')) {
                    case AUTH_ERRORS.login_failure: // credentials are wrong
                    case AUTH_ERRORS.notLoggedIn: // tokens check problem
                        updateLoginError('fieldsIncorrect');
                        break;
                    case AUTH_ERRORS.account_locked: {
                        // account is locked
                        updateLoginError('accountLocked');
                        break;
                    }
                    default:
                        updateLoginError('invalidAttempt');
                        break;
                }
            });
    }, [authLogin, formData]);

    const readValue = useCallback(
        (id, path) => {
            return _.get(formData, path);
        },
        [formData]
    );

    const writeValue = useCallback(
        (value, path) => {
            const nextFormData = _.cloneDeep(formData);
            _.set(nextFormData, path, value);
            updateFormData(nextFormData);
        },
        [formData]
    );

    const handleEnterKey = () => {
        signInRef.current.click();
    };
    if (isAuthInProgress) {
        return <NfumLoader loaded={!isAuthInProgress} />; /* NOSONAR: pure declarative usage */
    }

    const overrideProps = {
        '@field': {
            onValueChange: writeValue,
            onEnter: handleEnterKey
        },
        signIn: {
            onTrigger: login,
            innerRef: signInRef
        },
        linkToForgotPassword: {
            visible: !_.isUndefined(onForgotPassword)
        },
        actionsWrapperSingleButton: {
            className: classNames(styles.actionsWrapperSingleButton, {
                [styles.coupleButtonStyle]: !_.isUndefined(onForgotPassword),
                [styles.singleButtonStyle]: _.isUndefined(onForgotPassword)
            })
        },
        errorMsg: {
            content: translator(messages[loginError]),
            visible: !!loginError
        }
    };

    const resolvers = {
        resolveValue: readValue,
        resolveCallbackMap: {
            login: login,
            forgotPassword: onForgotPassword || _.noop
        },
        resolveClassNameMap: styles
    };

    const successRedirectPath = isSignInButtonClicked ? pathAfterSignIn : successPath;

    return (
        <Fragment>
            <RedirectAfterLogin successPath={successRedirectPath} loginPath={loginPath} />
            <MetadataContent
                uiProps={metadata.componentContent}
                overrideProps={overrideProps}
                {...resolvers}
            />
        </Fragment>
    );
}

LoginComponent.propTypes = {
    onForgotPassword: PropTypes.func,
    successPath: PropTypes.string,
    loginPath: PropTypes.string,
    pathAfterSignIn: PropTypes.string
};

LoginComponent.defaultProps = {
    onForgotPassword: undefined,
    successPath: undefined,
    loginPath: undefined,
    pathAfterSignIn: undefined
};

export default LoginComponent;
