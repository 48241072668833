import { defineMessages } from 'react-intl';

export default defineMessages({
    renewalProcessed: {
        id: 'renewal.ngh.views.renewal-summary.renewalProcessed',
        defaultMessage: 'Your renewal has been processed'
    },
    thankYouText: {
        id: 'renewal.ngh.views.renewal-summary.thankYouText',
        defaultMessage: 'Thank you for choosing NFU Mutual for your Insurance policy.'
    },
    yourPolicyNumber: {
        id: 'renewal.ngh.views.renewal-summary.yourPolicyNumber',
        defaultMessage: 'Your policy number:'
    },
    policyEffectiveDate: {
        id: 'renewal.ngh.views.renewal-summary.policyEffectiveDate',
        defaultMessage: 'Policy effective date:'
    },
    cancellationRights: {
        id: 'renewal.ngh.views.renewal-payment.cancellationRights',
        defaultMessage: 'Your rights and conditions'
    },
    cancellationRightsHeading1: {
        id: 'renewal.ngh.views.renewal-payment.cancellationRightsHeading1',
        defaultMessage: 'Cooling off period'
    },
    cancellationRightsText1: {
        id: 'renewal.ngh.views.renewal-payment.cancellationRightsText1',
        defaultMessage: 'If you choose not to go ahead with this policy, you may cancel by contacting us within 14 days of receiving the policy. We’ll charge you a proportion of the premium for the period during which the policy was in force and we’ll refund the remaining premium as long as no claims have been made against the policy.'
    },
    cancellationRightsHeading2: {
        id: 'renewal.ngh.views.renewal-payment.cancellationRightsHeading2',
        defaultMessage: 'Your cancellation rights'
    },
    cancellationRightsText2: {
        id: 'renewal.ngh.views.renewal-payment.cancellationRightsText2',
        defaultMessage: 'You can cancel your policy at any other time by calling or writing to us, and we won’t charge a cancellation fee.  We’ll refund the premium for the period after the cancellation date.  We won’t refund any premium if you’ve made a claim.'
    },
    cancellationRightsHeading3: {
        id: 'renewal.ngh.views.renewal-payment.cancellationRightsHeading3',
        defaultMessage: 'Let us know if anything changes'
    },
    cancellationRightsText3: {
        id: 'renewal.ngh.views.renewal-payment.cancellationRightsText3',
        defaultMessage: 'You must tell us in the future of any changes to the information you’ve provided, or it may affect your insurance.'
    },
});
