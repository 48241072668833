import React, { useCallback, useMemo, useContext } from 'react';
import {
    ViewModelForm
} from 'gw-portals-viewmodel-react';
import _ from 'lodash';
import { wizardProps } from 'gw-portals-wizard-react';
import { appendMetadataWithIndex } from 'gw-jutro-adapters-react';
import { TranslatorContext } from '@jutro/locale';
import FineArtsSummaryItem from '../FineArtsSummaryItem/FineArtsSummaryItem';
import messages from './FineArtsSummaryRemovedItems.messages';
import metadata from './FineArtsSummaryRemovedItems.metadata.json5';
import styles from './FineArtsSummaryRemovedItems.module.scss';

function FineArtsSummaryRemovedItems(props) {
    const {
        removedItemsModel,
        index
    } = props;
    const translator = useContext(TranslatorContext);
    const category = Object.freeze({
        FINE_ART: 'FineArt',
        WINES: 'WineCollections',
        STAMPS_MEDALS_COINS: 'StampsMedalsCoins',
        OTHER_COLLECTIONS: 'OtherCollections'
    });

    const formattedMetadata = useMemo(() => {
        return appendMetadataWithIndex(metadata.componentContent, index);
    }, [index]);

    const getHeaderRemovedItemDescription = useCallback((fineArt) => {
        const itemType = _.get(fineArt, 'itemType');
        if (itemType === category.FINE_ART) {
            return `${fineArt.fineArtType}, ${fineArt.description}`;
        }
        if (itemType === category.WINES) {
            return translator(messages.wineCollection);
        }
        return `${fineArt.collectionType}`;
    }, [category, translator]);

    const generateOverrides = useCallback(() => {
        if (removedItemsModel.removed.length === 0) return Object.assign({});

        const fineArtsAddedItemsOverrides = removedItemsModel.removed.map((item, ind) => {
            return {
                [`headerRemovedItemDescription${ind}`]: {
                    content: getHeaderRemovedItemDescription(item)
                }
            };
        });

        return Object.assign({},
            ...fineArtsAddedItemsOverrides);
    }, [
        removedItemsModel,
        getHeaderRemovedItemDescription
    ]);

    const overrideProps = {
        [`category${index}`]: {
            content: removedItemsModel.name
        },
        [`removedItems${index}`]: {
            data: removedItemsModel.removed
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            fineArtsSummaryItem: FineArtsSummaryItem
        }
    };

    return (
        <ViewModelForm
            model={removedItemsModel}
            uiProps={formattedMetadata}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

FineArtsSummaryRemovedItems.propTypes = wizardProps;
export default FineArtsSummaryRemovedItems;
