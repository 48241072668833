import React from 'react';
import PropTypes from 'prop-types';
import { useDependencies } from 'gw-portals-dependency-react';
import { useAuthentication } from 'gw-digital-auth-react';
import DocumentLink from '../DocumentLink/DocumentLink';
import styles from './DocumentsLinksComponent.module.scss';

function DocumentsLinksComponent(props) {
    const { documents, pushFileDownloadInfo } = props;
    const { PolicyService } = useDependencies('PolicyService');
    const { authHeader } = useAuthentication();

    const onDocumentClicked = async (docId, docSessionId, linkText) => {
        const docUrl = await PolicyService.getDocumentUrl([docId, docSessionId], authHeader);
        pushFileDownloadInfo(linkText, docUrl);
        window.open(docUrl, '_blank');
    };

    return (
        <div>
            { documents?.map((doc) => {
                return (
                    <div
                        className={styles.link}
                        key={doc.publicID}
                    >
                        <DocumentLink
                            name={doc.name}
                            docId={doc.publicID}
                            docSessionId={doc.sessionID}
                            docMimeType={doc.mimeType}
                            onDocumentClicked={onDocumentClicked}
                        />
                    </div>
                );
            }) }
        </div>
    );
}

DocumentsLinksComponent.defaultProps = {
    documents: []
};

DocumentsLinksComponent.propTypes = {
    documents: PropTypes.array
};

export default DocumentsLinksComponent;
