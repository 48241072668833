import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'endorsement.ngh.views.valuables.protectingYourValuables.title',
        defaultMessage: 'Protecting your valuables'
    },
    showMinimumSecurity: {
        id: 'endorsement.ngh.views.valuables.protectingYourValuables.showMinimumSecurity',
        defaultMessage: 'Show our minumum security conditions'
    },
    weWillAllowFrom: {
        id: 'endorsement.ngh.views.valuables.protectingYourValuables.weWillAllowFrom',
        defaultMessage: 'We will allow for up to'
    },
    xDays: {
        id: 'endorsement.ngh.views.valuables.protectingYourValuables.xDays',
        defaultMessage: '{days} days'
    },
    fromTheDateThat: {
        id: 'endorsement.ngh.views.valuables.protectingYourValuables.fromTheDateThat',
        defaultMessage: 'from the date that the condition applies for the locks to be fitted. If the locks are not installed within this time, we won’t cover loss or damage caused by theft or attempted theft from the home'
    },
    fromTheDateThatAlarm: {
        id: 'endorsement.ngh.views.valuables.protectingYourValuables.fromTheDateThatAlarm',
        defaultMessage: 'from the date that the condition applies for the alarm to be installed. If the alarm are not installed within this time, we won’t cover loss or damage caused by theft or attempted theft from the home.'
    },
    forLossOrDamage: {
        id: 'endorsement.ngh.views.valuables.protectingYourValuables.forLossOrDamage',
        defaultMessage: 'For loss or damage to jewellery caused by theft or attempted theft, we won’t pay more than £{amount}, unless the jewellery is either:'
    },
    beingWornBy: {
        id: 'endorsement.ngh.views.valuables.protectingYourValuables.beingWornBy',
        defaultMessage: '1. being worn by you; or'
    },
    depositedInBank: {
        id: 'endorsement.ngh.views.valuables.protectingYourValuables.depositedInBank',
        defaultMessage: '2. deoposited in a bank or locked safe with an approved cash rating and the key removed'
    },
    minSecurityQuestion: {
        id: 'endorsement.ngh.views.valuables.protectingYourValuables.minSecurityQuestion',
        defaultMessage: 'Do all the locks on your external doors comply with our security conditions?'
    },
    alarmSecurityQuestion: {
        id: 'endorsement.ngh.views.valuables.protectingYourValuables.alarmSecurityQuestion',
        defaultMessage: 'Do you have an intruder alarm that complies with our security conditions?'
    },
    safeConditionQuestion: {
        id: 'endorsement.ngh.views.valuables.protectingYourValuables.safeConditionQuestion',
        defaultMessage: 'Do you have a safe fitted?'
    },
    minSecurityTitle1: {
        id: 'endorsement.ngh.views.valuables.protectingYourValuables.minSecurityTitle1',
        defaultMessage: 'For single doors and the second closing leaf of double doors:'
    },
    minSecurityContent1: {
        id: 'endorsement.ngh.views.valuables.protectingYourValuables.minSecurityContent1',
        defaultMessage: 'a. 5 lever (or more) mortice deadlock to a minimum of BS3621;'
    },
    minSecurityContent2: {
        id: 'endorsement.ngh.views.valuables.protectingYourValuables.minSecurityContent2',
        defaultMessage: 'b. if aluminium, a cylinder-operated mortice deadlock compliant with EN1303 grade 4 or above;'
    },
    minSecurityContent3: {
        id: 'endorsement.ngh.views.valuables.protectingYourValuables.minSecurityContent3',
        defaultMessage: 'c. if UPVC, a proprietary cylinder action multi-point locking device with removable key and central top and bottom mounted deadlocking bolt throws; or​​'
    },
    minSecurityContent4: {
        id: 'endorsement.ngh.views.valuables.protectingYourValuables.minSecurityContent4',
        defaultMessage: 'd. if not the main or final exit door, either the above or a removable key-operated lock and additional mounted top and bottom security mortice rack bolts or lockable surface mounted equivalent or standard agreed by us;​'
    },
    minSecurityTitle2: {
        id: 'endorsement.ngh.views.valuables.protectingYourValuables.minSecurityTitle2',
        defaultMessage: 'For the first closing leaf of double doors:'
    },
    minSecurityContent5: {
        id: 'endorsement.ngh.views.valuables.protectingYourValuables.minSecurityContent5',
        defaultMessage: 'a. key operated locks or bolts with detachable keys fitted inside top and bottom; or​​'
    },
    minSecurityContent6: {
        id: 'endorsement.ngh.views.valuables.protectingYourValuables.minSecurityContent6',
        defaultMessage: 'b. flush bolts fitted top and bottom to the inside door edge at the first closing leaf, and locking into the top frame and sill;​​​'
    },
    minSecurityTitle3: {
        id: 'endorsement.ngh.views.valuables.protectingYourValuables.minSecurityTitle3',
        defaultMessage: 'For patio and sliding doors, key operated hook lock or bolt with detachable keys:​​'
    },
    minSecurityContent7: {
        id: 'endorsement.ngh.views.valuables.protectingYourValuables.minSecurityContent7',
        defaultMessage: 'a. which prevents horizontal and vertical movement; or​​​'
    },
    minSecurityContent8: {
        id: 'endorsement.ngh.views.valuables.protectingYourValuables.minSecurityContent8',
        defaultMessage: 'b. with additional key operated surface mounted anti-theft patio locking devices fitted to both sides of the frame to prevent vertical movement; or​'
    },
    minSecurityTitle4: {
        id: 'endorsement.ngh.views.valuables.protectingYourValuables.minSecurityTitle4',
        defaultMessage: 'For other doors:​​'
    },
    minSecurityContent9: {
        id: 'endorsement.ngh.views.valuables.protectingYourValuables.minSecurityContent9',
        defaultMessage: 'a. any measures agreed with us.'
    },
    alarmSecurityTitle1: {
        id: 'endorsement.ngh.views.valuables.protectingYourValuables.alarmSecurityTitle1',
        defaultMessage: 'The alarm must be:'
    },
    alarmSecurityContent1: {
        id: 'endorsement.ngh.views.valuables.protectingYourValuables.alarmSecurityContent1',
        defaultMessage: 'a. turned on and working if the home is unattended;​​​'
    },
    alarmSecurityContent2: {
        id: 'endorsement.ngh.views.valuables.protectingYourValuables.alarmSecurityContent2',
        defaultMessage: 'b. maintained and serviced with an annual maintenance schedule with the installer or someone we agree to;​​​'
    },
    alarmSecurityContent3: {
        id: 'endorsement.ngh.views.valuables.protectingYourValuables.alarmSecurityContent3',
        defaultMessage: 'c. if the alarm isn’t working you must contact the alarm company as soon as reasonably possible;​​​'
    },
});
