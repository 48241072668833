import { setComponentMapOverrides } from '@jutro/uiconfig';
import 'gw-capability-policychange-common-react';
import RenewalSummaryPage from './pages/RenewalSummary/RenewalSummaryPage';
import RenewalPaymentPage from './pages/RenewalPayment/RenewalPaymentPage';
import RenewalSuccessPage from './pages/RenewalSuccess/RenewalSuccessPage';
import NGHRenewalWizardPageTemplate from './NGHRenewalWizardPageTemplate';
import './NGHRenewal.messages';

setComponentMapOverrides(
    {
        RenewalSummaryPage: { component: 'RenewalSummaryPage' },
        RenewalPaymentPage: { component: 'RenewalPaymentPage' },
        RenewalSuccessPage: { component: 'RenewalSuccessPage' },
        NGHRenewalWizardPageTemplate: { component: 'NGHRenewalWizardPageTemplate' }
    },
    {
        RenewalSummaryPage,
        RenewalPaymentPage,
        RenewalSuccessPage,
        NGHRenewalWizardPageTemplate
    }
);

export { default as RenewalSummaryPage } from './pages/RenewalSummary/RenewalSummaryPage';
export { default as RenewalPaymentPage } from './pages/RenewalPayment/RenewalPaymentPage';
export { default as RenewalSuccessPage } from './pages/RenewalSuccess/RenewalSuccessPage';
export { default as NGHRenewalWizard } from './NGHRenewalWizard';
