import { defineMessages } from 'react-intl';

export default defineMessages({
    paymentSource: {
        id: 'amp.bc3.directives.templates.payment-instrument-bc3.Source',
        defaultMessage: 'Source'
    },
    bankAccountSource: {
        id: 'amp.bc3.directives.templates.payment-instrument-bc3.Bank Account',
        defaultMessage: 'Bank Account'
    },
    paymentTypeChecking: {
        id: 'quoteandbind.directives.templates.bank-account-details-quote.Checking',
        defaultMessage: 'Checking'
    },
    paymentTypeSavings: {
        id: 'quoteandbind.directives.templates.bank-account-details-quote.Savings',
        defaultMessage: 'Savings'
    },
    paymentComponentBuyNow: {
        id: 'integration.quoteandbind.directives.custom-quote.BOP.BuyNow',
        defaultMessage: 'BuyNow'
    },
    cancel: {
        id: 'platform.paymentdetails.Cancel',
        defaultMessage: 'Cancel',
    },
    cardIssuer: {
        id: 'platform.paymentdetails.credit-card-auto-details.Card Issuer',
        defaultMessage: 'Card Issuer',
    },
    selectIssuer: {
        id: 'platform.paymentdetails.credit-card-auto-details.-- Please Select --',
        defaultMessage: '-- Please Select --'
    },
    paymentMethod: {
        id: 'quoteandbind.views.payment-details.Payment Method',
        defaultMessage: 'Payment Method',
    },
    bankAccount: {
        id: 'quoteandbind.views.payment-details.Bank Account',
        defaultMessage: 'Bank Account',
    },
    creditCard: {
        id: 'quoteandbind.views.payment-details.Credit Card',
        defaultMessage: 'Credit Card',
    },
    accountType: {
        id: 'quoteandbind.directives.templates.bank-account-details-quote.Account Type',
        defaultMessage: 'Account Type',
    },
    howToPay: {
        id: 'quoteandbind.directives.templates.bank-account-details-quote.How do you want to pay?',
        defaultMessage: 'How do you want to pay?',
    },
    accountNumber: {
        id: 'quoteandbind.directives.templates.bank-account-details-quote.Account Number',
        defaultMessage: 'Account Number',
    },
    routingNumber: {
        id: 'quoteandbind.directives.templates.bank-account-details-quote.Routing (ABA) Number',
        defaultMessage: 'Routing (ABA) Number',
    },
    bankName: {
        id: 'quoteandbind.directives.templates.bank-account-details-quote.Bank Name',
        defaultMessage: 'Bank Name',
    }
});
