import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { getConfigValue } from '@jutro/config';
import { logDeprecationMessage } from '@jutro/platform';
import AuthContextProvider from './AuthContextProvider';
import { AuthStateProvider } from './AuthStateProvider';
import LoginCallback from './LoginCallback';

export const OPENID_SCOPE = 'openid';
export const DEFAULT_EXPIRE_EARLY_SECONDS = 30;
export const AUTO_RENEW = true;
export const DEFAULT_STORAGE = 'localStorage';

const getTokenManagerConfig = () => {
    const expireEarlySeconds = getConfigValue('JUTRO_AUTH_EXPIRE_EARLY_SECONDS', DEFAULT_EXPIRE_EARLY_SECONDS);
    const autoRenew = getConfigValue('JUTRO_AUTH_AUTO_RENEW', AUTO_RENEW);
    const storage = getConfigValue('JUTRO_AUTH_STORAGE', DEFAULT_STORAGE);

    if (storage === 'cookie') {
        logDeprecationMessage(
            'JUTRO_AUTH_STORAGE=cookie',
            `JUTRO_AUTH_STORAGE=${DEFAULT_STORAGE}`,
            '7.0.0'
        );
    }
    return {
        expireEarlySeconds,
        storage,
        autoRenew,
    };
};

const getAuthConfig = () => {
    return {
        isEnabled: getConfigValue('OKTA_AUTH_ENABLED', false),
        issuer: getConfigValue('JUTRO_AUTH_ISSUER', ''),
        clientId: getConfigValue('JUTRO_AUTH_CLIENT_ID', ''),
        redirectBase: getConfigValue('JUTRO_AUTH_REDIRECT_BASE', window.location.origin),
        redirectPath: getConfigValue('JUTRO_AUTH_REDIRECT_PATH', ''),
        scope: getConfigValue('JUTRO_AUTH_SCOPE', `${OPENID_SCOPE},profile,email`),
        pkce: getConfigValue('JUTRO_AUTH_PKCE_ENABLED', false),
        tokenManager: getTokenManagerConfig()
    };
};

export const AuthProvider = ({
    children,
    authErrorComponent
}) => {
    const authConfig = getAuthConfig();

    if (authConfig.isEnabled) {
        const {
            issuer,
            clientId,
            redirectBase,
            redirectPath,
            scope,
            pkce,
            tokenManager,
        } = authConfig;
        const redirectUri = `${redirectBase}${redirectPath}`;
        const scopes = scope.split(',');

        if (scopes.indexOf(OPENID_SCOPE) === -1) {
            scopes.push(OPENID_SCOPE);
        }

        return (
            <AuthStateProvider
                issuer={issuer}
                clientId={clientId}
                redirectUri={redirectUri}
                scopes={scopes}
                pkce={pkce}
                tokenManager={tokenManager}
            >
                <AuthContextProvider>
                    <Switch>
                        <Route
                            path={redirectPath}
                            render={() => (
                                <LoginCallback
                                    errorComponent={authErrorComponent}
                                />
                            )}
                        />
                        {children}
                    </Switch>
                </AuthContextProvider>
            </AuthStateProvider>
        );
    }
    return <React.Fragment>{children}</React.Fragment>;
};
