import { defineMessages } from 'react-intl';

export default defineMessages({
    transactionTitle: {
        id: 'endorsement.ngh.views.policychange-success.transactionTitle',
        defaultMessage: 'Your mid-term adjustment is being processed'
    },
    thankYouWithPaperDocuments: {
        id: 'endorsement.ngh.views.policychange-success.thankYouWithPaperDocuments',
        defaultMessage: 'Thank you for purchasing your home insurance policy from NFU Mutual. A paper copy of your policy documents will be sent you in the post shortly. Digital versions of your new personal policy documents will be available in your online account shortly.'
    },
    thankYouWithoutPaperDocuments: {
        id: 'endorsement.ngh.views.policychange-success.thankYouWithoutPaperDocuments',
        defaultMessage: 'Thank you for purchasing your home insurance policy from NFU Mutual. Your policy documents are available in your online account.'
    },
    transactionPolicyNumberTitle: {
        id: 'endorsement.ngh.views.policychange-success.transactionPolicyNumberTitle',
        defaultMessage: 'Your policy number:'
    },
    transactionPolicyStartDateTitle: {
        id: 'endorsement.ngh.views.policychange-success.transactionPolicyStartDateTitle',
        defaultMessage: 'Change effective date:'
    },
    continue: {
        id: 'endorsement.ngh.views.policychange-success.continue',
        defaultMessage: 'Return to dashboard'
    },
    nfumMutualInsuranceSuccess: {
        id: 'endorsement.ngh.views.policychange-success.nfumMutualInsurance',
        defaultMessage: 'NFU Mutual Home Insurance underwritten by NFU Mutual'
    }
});
