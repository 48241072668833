export const covIds = {
    CONTENTS_AWAY_COV: 'HOMContentsAwayCov',
    CONTENTS_COV: 'HOMContentsCov',
    BUILDINGS_COV: 'HOMBuildingsCov',
    HOM_HOME_EMERGENCY_COV: 'HOMHomeEmergencyCov',
    HOM_BUILDINGS_ACCIDENTAL_DAMAGE: 'HOMBuildingsCovAccidentalDamageRequired',
    HOM_CONTENTS_ACCIDENTAL_DAMAGE: 'HOMContentsCovAccidentalDamageCover',
    LEX_PERSONAL_LEGAL_EXPENSES_COV: 'LEXPersonalLegalExpensesCov',
    HOM_CONTENTS_AWAY_BICYCLE_EXTENSION: 'HOMContentsAwayCovPedalCycleExtension',
    HOM_CONTENTS_BICYCLE_EXTENSION: 'HOMContentsCovPedalCycleExtension'
};

export const covKeys = {
    YES: 'Yes',
    LEVEL_OF_COVER: 'Level of Cover',
    STANDARD: 'Standard',
    EXTRA: 'Extra'
};
