import { defineMessages } from 'react-intl';

export default defineMessages({
    bespokeValuablesContainer: {
        id: 'endorsement.ngh.views.bespokeValuables.bespokeValuablesContainer',
        defaultMessage: 'Specified valuables'
    },
    bespokeValuablesDescription1: {
        id: 'endorsement.ngh.views.bespokeValuables.bespokeValuablesDescription1',
        defaultMessage: 'To add valuable items, specifically Jewellery and Gemstones, Watches, Furs and Guns, select one from below.'
    },
    bespokeValuablesDescription2: {
        id: 'endorsement.ngh.views.bespokeValuables.bespokeValuablesDescription2',
        defaultMessage: 'To add specific Collections and Fine Art, please close this box and navigate to the Fine Art and Collections section.'
    },
    category: {
        id: 'endorsement.ngh.views.bespokeValuables.category',
        defaultMessage: 'Category'
    },
    jewellery: {
        id: 'endorsement.ngh.views.bespokeValuables.jewellery',
        defaultMessage: 'Specified Jewellery and Gemstones'
    },
    watches: {
        id: 'endorsement.ngh.views.bespokeValuables.watches',
        defaultMessage: 'Specified Watches'
    },
    furs: {
        id: 'endorsement.ngh.views.bespokeValuables.furs',
        defaultMessage: 'Specified Furs'
    },
    guns: {
        id: 'endorsement.ngh.views.bespokeValuables.guns',
        defaultMessage: 'Guns'
    },
    jewelleryAndGemstones: {
        id: 'endorsement.ngh.views.bespokeValuables.jewelleryAndGemstones',
        defaultMessage: 'Jewellery and Gemstones'
    },
    type: {
        id: 'endorsement.ngh.views.bespokeValuables.type',
        defaultMessage: 'Type'
    },
    description: {
        id: 'endorsement.ngh.views.bespokeValuables.description',
        defaultMessage: 'Description'
    },
    professionalValuation: {
        id: 'endorsement.ngh.views.bespokeValuables.professionalValuation',
        defaultMessage: 'Have you had a professional valuation?'
    },
    valuationDone: {
        id: 'endorsement.ngh.views.bespokeValuables.valuationDone',
        defaultMessage: 'When was the valuation done?'
    },
    alwaysWorn: {
        id: 'endorsement.ngh.views.bespokeValuables.alwaysWorn',
        defaultMessage: 'Is this item always worn?'
    },
    notBeingWorn: {
        id: 'endorsement.ngh.views.bespokeValuables.notBeingWorn',
        defaultMessage: 'Where is the item usually kept when not being worn?'
    },
    otherKeptPlace: {
        id: 'endorsement.ngh.views.bespokeValuables.otherKeptPlace',
        defaultMessage: 'Other place kept'
    },
    usuallyKept: {
        id: 'endorsement.ngh.views.bespokeValuables.usuallyKept',
        defaultMessage: 'Please describe'
    },
    replacementCost: {
        id: 'endorsement.ngh.views.bespokeValuables.replacementCost',
        defaultMessage: 'Replacement cost'
    },
    addItem: {
        id: 'endorsement.ngh.views.bespokeValuables.addItem',
        defaultMessage: 'Add item'
    },
    saveItem: {
        id: 'endorsement.ngh.views.bespokeValuables.saveItem',
        defaultMessage: 'Save'
    },
    addAndSaveAnother: {
        id: 'endorsement.ngh.views.bespokeValuables.addAndSaveAnother',
        defaultMessage: 'Save and add another'
    },
    cancel: {
        id: 'endorsement.ngh.views.bespokeValuables.cancel',
        defaultMessage: 'Cancel'
    },
    agreedValue: {
        id: 'endorsement.ngh.views.bespokeValuables.agreedValue',
        defaultMessage: 'If you have had a professional valuation in the last three years do you require Agreed value cover?'
    },
    make: {
        id: 'endorsement.ngh.views.bespokeValuables.make',
        defaultMessage: 'Make'
    },
    serialNumber: {
        id: 'endorsement.ngh.views.bespokeValuables.serialNumber',
        defaultMessage: 'Serial Number'
    },
    gunPair: {
        id: 'endorsement.ngh.views.bespokeValuables.gunPair',
        defaultMessage: 'Is the gun part of a pair?'
    },
    gunDisplay: {
        id: 'endorsement.ngh.views.bespokeValuables.gunDisplay',
        defaultMessage: 'Is the gun on display?'
    },
    maxLengthError: {
        id: 'endorsement.ngh.views.valuablesModal.maxLengthError',
        defaultMessage: 'You have reached maximum allowed field length'
    },
    formatNotValidError: {
        id: 'endorsement.ngh.views.valuablesModal.formatNotValidError',
        defaultMessage: 'Please enter only numerical values'
    },
});
