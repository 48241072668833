import React, { useContext, useCallback } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { useCurrency } from 'nfum-portals-utils-react';
import messages from './BicyclesSummary.messages';
import metadata from './BicyclesSummary.metadata.json5';
import styles from './BicyclesSummary.module.scss';

function BicyclesSummary(props) {
    const {
        data: valuablesModel
    } = props;
    const currencyFormatter = useCurrency();
    const translator = useContext(TranslatorContext);

    const getItemCategory = useCallback((type) => {
        return translator({
            id: `typekey.SpecifiedValuableType_NFUM.${type}`,
            defaultMessage: type
        });
    }, [translator]);

    const getItemReplacementCost = useCallback((replacementCost) => {
        return currencyFormatter.formatCurrency(replacementCost);
    }, [currencyFormatter]);

    const getItemCoverType = useCallback((geoLimit) => {
        return geoLimit === 'AtHomeOnly'
            ? translator(messages.insideHomeOnly)
            : translator(messages.insideOutsideHome);
    }, [translator]);

    const getItemKeptInBank = useCallback((keptInBank) => {
        return keptInBank
            ? translator(messages.yes)
            : translator(messages.no);
    }, [translator]);

    const transformChangedValuableItem = useCallback((item) => {
        return [
            {
                oldValue: getItemReplacementCost(item.prev.replacementCost),
                newValue: getItemReplacementCost(item.new.replacementCost)
            }
        ];
    }, [getItemReplacementCost]);

    const generateOverrides = useCallback(() => {
        if (!valuablesModel) return Object.assign({});
        const addedValuablesOverrides = valuablesModel.added.map((item, index) => {
            return {
                [`addedValuableName${index}`]: {
                    content: item.description
                },
                [`addedValuableCategory${index}`]: {
                    value: getItemCategory(item.type)
                },
                [`addedValuableReplacementCost${index}`]: {
                    value: getItemReplacementCost(item.replacementCost)
                },
                [`addedValuableCoverType${index}`]: {
                    value: getItemCoverType(item.geoLimit)
                },
                [`addedValuableKeptInBank${index}`]: {
                    value: getItemKeptInBank(item.keptInBank)
                },
            };
        });
        const changedValuablesOverrides = valuablesModel.edited.map((item, index) => {
            return {
                [`changedValuablesTable${index}`]: {
                    data: transformChangedValuableItem(item),
                    title: item.new.description
                }
            };
        });
        const removedValuablesOverrides = valuablesModel.removed.map((item, index) => {
            return {
                [`removedValuablesTable${index}`]: {
                    data: [item],
                    title: item.description
                }
            };
        });
        return Object.assign({},
            ...addedValuablesOverrides,
            ...changedValuablesOverrides,
            ...removedValuablesOverrides);
    }, [
        valuablesModel,
        transformChangedValuableItem,
        getItemCategory,
        getItemKeptInBank,
        getItemCoverType,
        getItemReplacementCost
    ]);

    const renderValue = (item, index, metadataProps) => {
        const { path } = metadataProps;
        return item[path];
    };

    const overrideProps = {
        addedValuables: {
            data: valuablesModel.added
        },
        changedValuables: {
            data: valuablesModel.edited
        },
        removedValuables: {
            data: valuablesModel.removed
        },
        valuablesAddedContainer: {
            visible: valuablesModel.added.length > 0
        },
        valuablesChangedContainer: {
            visible: valuablesModel.edited.length > 0
        },
        valuablesRemovedContainer: {
            visible: valuablesModel.removed.length > 0
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            renderValue
        }
    };

    return (
        <ViewModelForm
            model={valuablesModel}
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            overrideProps={overrideProps}
        />
    );
}

export default BicyclesSummary;
