import React, { useContext, useCallback } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import _ from 'lodash';
import usePropertyTitle from '../../../hooks/usePropertyTitle';
import messages from './AdditionalCoversSummary.messages';
import metadata from './AdditionalCoversSummary.metadata.json5';
import styles from './AdditionalCoversSummary.module.scss';

function AdditionalCoversSummary(props) { /* NOSONAR: pure declarative usage  */
    const {
        homePropertiesData,
        lexLineCoverages, homeLineCoverages, submissionVM
    } = props;
    const translator = useContext(TranslatorContext);
    const { getPropertyTitle } = usePropertyTitle();
    const coversIds = Object.freeze({
        CONTENTS_AWAY_COV: 'HOMContentsAwayCov',
        CONTENTS_COV: 'HOMContentsCov',
        HOM_HOME_EMERGENCY_COV: 'HOMHomeEmergencyCov',
        HOM_CONTENTS_AWAY_BICYCLE_EXTENSION: 'HOMContentsAwayCovPedalCycleExtension',
        HOM_CONTENTS_BICYCLE_EXTENSION: 'HOMContentsCovPedalCycleExtension',
        LEX_PERSONAL_LEGAL_EXPENSES_COV: 'LEXPersonalLegalExpensesCov',
        HOM_PERSONAL_CYBER_COV: 'HOMPersonalCyberCov'
    });
    const LEVEL_OF_COVER_NAME = 'Level of Cover';
    const LEGAL_EXPENSES_EXTRA_KEY = 'Extra';
    const YES_CODE = 'Yes';

    const legalExpensesCover = lexLineCoverages
        ?.find((cov) => cov.publicID === coversIds.LEX_PERSONAL_LEGAL_EXPENSES_COV);
    const isLegalExpensesCover = legalExpensesCover?.selected;
    const legalExpensesLevelOfCover = legalExpensesCover?.terms
        ?.find((term) => term.name === LEVEL_OF_COVER_NAME)?.chosenTermValue;
    const legalExpensesLevelOfCoverText = legalExpensesLevelOfCover === LEGAL_EXPENSES_EXTRA_KEY
        ? translator(messages.personalLegalExpensesExtra)
        : translator(messages.personalLegalExpenses);

    const personalCyberCov = homeLineCoverages
        ?.find((cov) => cov.publicID === coversIds.HOM_PERSONAL_CYBER_COV);
    const isPersonalCyberCovSelected = personalCyberCov?.selected;

    const generateHomePropertiesData = useCallback(() => {
        if (!homePropertiesData) {
            return {};
        }
        const homePropertiesOverrides = homePropertiesData.map((homeProperty, index) => {
            const homeEmergencyCov = homeProperty.coverages.coverages
                .find(((cov) => cov.publicID === coversIds.HOM_HOME_EMERGENCY_COV));
            const isHomeEmergencyCov = homeEmergencyCov?.selected;
            const isHomeEmergencyCovForBespoke = isHomeEmergencyCov ? translator(messages.included) : '';
            const isHomeEmergencyCovForNGH = isHomeEmergencyCov
                ? translator(messages.added) : translator(messages.notSelected);
            const contentsAwayCov = homeProperty.coverages.coverages
                .find(((cov) => cov.publicID === coversIds.CONTENTS_AWAY_COV));
            const isBicycleExtensionCoverTerm = contentsAwayCov?.terms
                ?.find((term) => term.publicID === coversIds.HOM_CONTENTS_AWAY_BICYCLE_EXTENSION)
                ?.chosenTermValue === YES_CODE;
            const contentsCov = homeProperty.coverages.coverages
                .find(((cov) => cov.publicID === coversIds.CONTENTS_COV));
            const isCyclingProtectionCovTerm = contentsCov?.terms
                ?.find((term) => term.publicID === coversIds.HOM_CONTENTS_BICYCLE_EXTENSION)
                ?.chosenTermValue === YES_CODE;
            return {
                [`additionalCoversHomePropertyTitle${index}`]: {
                    content: getPropertyTitle(homeProperty, false)
                },
                [`bicycleExtensionCover${index}`]: {
                    visible: !!contentsAwayCov,
                    value: isBicycleExtensionCoverTerm
                        ? translator(messages.added)
                        : translator(messages.notSelected)
                },
                [`homeEmergencyCover${index}`]: {
                    value: (_.get(submissionVM.value, 'isBespokeHomeInsurance_NFUM'))
                        ? isHomeEmergencyCovForBespoke : isHomeEmergencyCovForNGH
                },
                [`cyclingProtectionCover${index}`]: {
                    value: isCyclingProtectionCovTerm
                        ? translator(messages.added)
                        : translator(messages.notSelected)
                }
            };
        });
        return Object.assign({}, ...homePropertiesOverrides);
    }, [homePropertiesData,
        coversIds,
        translator,
        getPropertyTitle,
        submissionVM
    ]);

    const isCyclingProtectionAdded = () => {
        const bicycleExtensions = homePropertiesData.filter((homeProperty) => {
            const contentsAwayCov = homeProperty.coverages.coverages
                .find(((cov) => cov.publicID === coversIds.CONTENTS_AWAY_COV));
            return contentsAwayCov?.terms
                ?.find((term) => term.publicID === coversIds.HOM_CONTENTS_AWAY_BICYCLE_EXTENSION)
                ?.chosenTermValue === YES_CODE;
        });
        return bicycleExtensions?.length > 0;
    };

    const isCyclingProtectionContentsCovAdded = () => {
        const bicycleExtensions = homePropertiesData.filter((homeProperty) => {
            const contentsCov = homeProperty.coverages.coverages
                .find(((cov) => cov.publicID === coversIds.CONTENTS_COV));
            return contentsCov?.terms
                ?.find((term) => term.publicID === coversIds.HOM_CONTENTS_BICYCLE_EXTENSION)
                ?.chosenTermValue === YES_CODE;
        });
        return bicycleExtensions?.length > 0;
    };

    const showCyclingProtectionCoverForNGH = isCyclingProtectionAdded()
        ? translator(messages.added) : translator(messages.notSelected);

    const showCyclingProtectionCoverForBespoke = isCyclingProtectionContentsCovAdded()
        ? translator(messages.included) : translator(messages.notSelected);

    const overrideProps = {
        '@field': {
            readOnly: true,
            labelPosition: 'left',
            className: 'riskObjectProperty',
            labelClassName: 'riskObjectPropertyLabel'
        },
        additionalCoversHomeProperties: {
            data: homePropertiesData
        },
        lineCoveragesTitle: {
            content: translator(messages.nonPropertySpecificCovers)
        },
        legalExpensesCover: {
            value: isLegalExpensesCover
                ? legalExpensesLevelOfCoverText
                : translator(messages.notSelected)
        },
        bicycleExtensionCover: {
            value: (_.get(submissionVM.value, 'isBespokeHomeInsurance_NFUM'))
                ? showCyclingProtectionCoverForBespoke
                : showCyclingProtectionCoverForNGH
        },
        personalCyberCover: {
            visible: (_.get(submissionVM.value, 'isBespokeHomeInsurance_NFUM')),
            value: isPersonalCyberCovSelected
                ? translator(messages.included)
                : ''
        },
        ...generateHomePropertiesData()
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
        />
    );
}

export default AdditionalCoversSummary;
