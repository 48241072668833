import {
    useEffect,
    useState,
    useMemo,
    useCallback
} from 'react';
import _ from 'lodash';
import {
    useHistory
} from 'react-router-dom';

const useLobs = (jobVM) => {
    const history = useHistory();
    const [isHomeLine, setIsHomeLine] = useState(false);
    const [isLifestyleJourney, setIsLifestyleJourney] = useState(false);
    const [isCaravanLine, setIsCaravanLine] = useState(false);
    const [isTravelLine, setIsTravelLine] = useState(false);
    const [isAnimalLine, setIsAnimalLine] = useState(false);
    const [isPersonalAccidentLine, setIsPersonalAccidentLine] = useState(false);
    const [isBuildingsCov, setIsBuildingsCov] = useState(false);
    const [isContentsCov, setIsContentsCov] = useState(false);
    const [isFineArtAndCollectionsCov, setIsFineArtAndCollectionsCov] = useState(false);
    const [isValuablesCov, setIsValuablesCov] = useState(false);
    const [isBespokeJourney, setIsBespokeJourney] = useState(false);
    const lobs = useMemo(() => {
        return {
            home: 'homeLine',
            caravan: 'caravanLine',
            travel: 'travelLine',
            animal: 'animalLine',
            personalAccident: 'personalAccidentLine'
        };
    }, []);

    const setCoversInfo = useCallback(() => {
        // get home line coverages and all home properties coverages
        let coverages = _.get(jobVM, 'lobData.value.homeLine.lineCoverages.coverages');
        const homeProperties = _.get(jobVM, 'lobData.value.homeLine.coverables.homeProperties');
        if (homeProperties?.length > 0) {
            homeProperties.forEach((home) => {
                coverages = coverages.concat(_.get(home, 'coverages.coverages') || []);
            });
        }

        // verify if coverage exists and selected on line or on any home property
        const buildingsCovsSelected = coverages?.filter((cov) => cov.publicID === 'HOMBuildingsCov' && cov.selected);
        setIsBuildingsCov(buildingsCovsSelected?.length > 0);
        const contentsCovsSelected = coverages?.filter((cov) => cov.publicID === 'HOMContentsCov' && cov.selected);
        setIsContentsCov(contentsCovsSelected?.length > 0);
        const fineArtsCovsSelected = coverages?.filter((cov) => cov.publicID === 'HOMFineArtCollectionsCov' && cov.selected);
        setIsFineArtAndCollectionsCov(fineArtsCovsSelected?.length > 0);
        const valuablesCovsSelected = coverages?.filter((cov) => cov.publicID === 'HOMValuablesCov' && cov.selected);
        setIsValuablesCov(valuablesCovsSelected?.length > 0);
    }, [
        jobVM,
        setIsBuildingsCov,
        setIsContentsCov,
        setIsFineArtAndCollectionsCov,
        setIsValuablesCov
    ]);

    useEffect(() => {
        setIsBespokeJourney(jobVM.value.isBespokeHomeInsurance_NFUM);
        const availableLobs = Object.getOwnPropertyNames(jobVM.value.lobData);
        _.each(availableLobs, (code) => {
            if (code === lobs.home) {
                setIsHomeLine(true);
                setCoversInfo();
            }
            if (code === lobs.caravan) {
                setIsCaravanLine(true);
            }
            if (code === lobs.travel) {
                setIsTravelLine(true);
            }
            if (code === lobs.animal) {
                setIsAnimalLine(true);
            }
            if (code === lobs.personalAccident) {
                setIsPersonalAccidentLine(true);
            }
            if ([lobs.caravan, lobs.travel, lobs.animal, lobs.personalAccident].includes(code)) {
                setIsLifestyleJourney(true);
            }
        });
    }, [jobVM, lobs, setCoversInfo]);

    const isQuotedWizard = useMemo(() => {
        const availableLobs = Object.getOwnPropertyNames(jobVM.value.lobData);
        let lifestyleLineExists = false;
        _.each(availableLobs, (code) => {
            if ([lobs.caravan, lobs.travel, lobs.animal, lobs.personalAccident].includes(code)) {
                lifestyleLineExists = true;
            }
        });
        if (jobVM.value.baseData.periodStatus === 'Quoted') {
            return true;
        }
        if (jobVM.value.baseData.periodStatus === 'Draft' && (lifestyleLineExists || jobVM.value.isBespokeHomeInsurance_NFUM)) {
            history.push('/contact-us');
        }
        return false;
    }, [jobVM, lobs, history]);

    return {
        isHomeLine,
        isLifestyleJourney,
        isCaravanLine,
        isTravelLine,
        isAnimalLine,
        isPersonalAccidentLine,
        isQuotedWizard,
        isBuildingsCov,
        isContentsCov,
        isFineArtAndCollectionsCov,
        isValuablesCov,
        isBespokeJourney
    };
};

export default useLobs;
