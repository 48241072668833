import { defineMessages } from 'react-intl';

export default defineMessages({
    EffectiveDate: {
        id: 'endorsement.bespoke.views.bespoke-policychange-details.EffectiveDate',
        defaultMessage: 'Change date'
    },
    PolicyChangeEffectiveDate: {
        id: 'endorsement.bespoke.views.bespoke-policychange-details.policyChangeEffectiveDate',
        defaultMessage: 'When would you like these policy changes to take effect? '
    },
    EffectiveDateTooltip: {
        id: 'endorsement.bespoke.views.bespoke-policychange-details.EffectiveDateTooltip',
        defaultMessage: 'This change will take effect from 00:01 on the date you select. If you select today\'s date, your change will come into effect immediately.'
    },
});
