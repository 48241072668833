import React, { useEffect, useCallback } from 'react';
import { PolicyService } from 'gw-capability-policy';
import { withRouter } from 'react-router-dom';
import { useAuthentication, withAuthenticationContext } from 'gw-digital-auth-react';

function PolicyChangeRetrieve(props) {
    const {
        history,
        match: {
            params: { policyNumber }
        }
    } = props;
    const { authHeader } = useAuthentication();

    const getPolicyDetailsAndRedirect = useCallback(async () => {
        const policyDetails = await PolicyService.getAccountPolicyDetails(policyNumber, authHeader);
        const params = { policyNumber };
        if (policyDetails.currentPeriod.isBespokeHomeInsurance_NFUM === true) {
            history.push('/endorsement-bespoke-ngh', params);
        } else {
            history.push('/endorsement-ngh', params);
        }
    }, [policyNumber, authHeader, history]);

    useEffect(() => {
        getPolicyDetailsAndRedirect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div />
    );
}
export default withRouter(withAuthenticationContext(PolicyChangeRetrieve));
