import {
    TagManagerBase,
    FORM_REQUEST_TYPE
} from 'nfum-portals-utils-react';

function useTagManager() {
    const isExistingCustomer = true;

    const pushFormStepInfo = (jobVM, step) => {
        TagManagerBase.pushFormStepInfo(
            jobVM,
            isExistingCustomer,
            FORM_REQUEST_TYPE.policyChange,
            step
        );
    };

    const pushFormConfirmationInfo = (jobVM, step) => {
        TagManagerBase.pushFormConfirmationInfo(
            jobVM,
            isExistingCustomer,
            FORM_REQUEST_TYPE.policyChange,
            step
        );
    };

    const pushFormStepErrorInfo = (jobVM, step, error, errorInfo = null) => {
        TagManagerBase.pushFormStepErrorInfo(
            jobVM,
            isExistingCustomer,
            FORM_REQUEST_TYPE.policyChange,
            step,
            error,
            errorInfo
        );
    };

    return {
        pushFormStepInfo,
        pushFormConfirmationInfo,
        pushFormStepErrorInfo,
        pushAccordionOpenInfo: TagManagerBase.pushAccordionOpenInfo,
        pushLinkClickInfo: TagManagerBase.pushLinkClickInfo,
        pushRelativeLinkClickInfo: TagManagerBase.pushRelativeLinkClickInfo,
        pushFileDownloadInfo: TagManagerBase.pushFileDownloadInfo
    };
}

export default useTagManager;
