import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { formatAddress } from '../../ScheduleItemsUtil';
import metadata from './LocationScheduleItem.metadata.json5';
import './LocationScheduleItem.messages';

function handleExistingAddressSelection(value, path, onValueChange, existingLocations) {
    const selectedLocation = existingLocations[value];
    onValueChange(selectedLocation, path);
}
let locationData = {};

function handleExistingLocationToggle(value, path, onValueChange, modalData) {
    const oldLocationToggleValue = _.get(modalData, path, true);
    const locationPath = 'itemData.Location.locationValue';
    if (oldLocationToggleValue !== value && value === false) {
        locationData = _.get(modalData, locationPath);
        const clonedLocationData = _.cloneDeep(locationData);
        clonedLocationData.address = {};
        clonedLocationData.isExistingLocation = value;
        onValueChange(clonedLocationData, locationPath);
    } else {
        locationData.isExistingLocation = value;
        onValueChange(locationData, locationPath);
    }
}

function generateOverrides( // NOSONAR: GW OOTB internal mechanism
    hasExistingLocations,
    shouldShowExistingLocation,
    scheduleItem,
    onValueChange,
    modalData,
    pathToValue,
    parentOverrides,
    isNew
) {
    const { existingLocations, blockedFromAddingNewLocations } = scheduleItem;
    const shouldBeAllowedToAddNewLocation = _.isUndefined(blockedFromAddingNewLocations)
        || !blockedFromAddingNewLocations;
    return {
        ...parentOverrides,
        existingLocationToggle: {
            visible: isNew && hasExistingLocations && shouldBeAllowedToAddNewLocation,
            onValueChange:
            (value, path) => handleExistingLocationToggle(value, path, onValueChange, modalData)
        },
        existingLocationContainer: {
            visible: isNew && hasExistingLocations && shouldShowExistingLocation
        },
        existingLocationSelect: {
            availableValues: existingLocations.map(({ address }, index) => ({
                name: formatAddress(address),
                code: _.toString(index)
            })),
            onValueChange:
            (value, path) => handleExistingAddressSelection(
                value,
                path,
                onValueChange,
                existingLocations
            ),
            value: existingLocations.findIndex((location) => _.isEqual(location.address, _.get(modalData, `${pathToValue}.address`)))
        },
        formLocationContainer: {
            visible: !isNew || !hasExistingLocations || !shouldShowExistingLocation
        },
        addressLine2: {
            visible: !_.isUndefined(_.get(modalData, `${pathToValue}.address.addressLine2`))
        },
        addressLine3: {
            visible: !_.isUndefined(_.get(modalData, `${pathToValue}.address.addressLine3`))
        }
    };
}

const setDefaultLocationValues = (location, defaultCountryCode) => {
    const newLocation = location;

    newLocation.address = newLocation.address || { country: defaultCountryCode };
    newLocation.address.country = newLocation.address.country || defaultCountryCode;
    newLocation.isNonSpecificLocation = newLocation.isNonSpecificLocation || false;

    return newLocation;
};

function createLocationVM(modalData, info, path, defaultCountryCode, viewModelService) {
    const { valueDeserializationClass } = info;
    const clonedModalData = _.cloneDeep(modalData);
    const modalLocationObj = _.get(modalData, path, {});
    const location = setDefaultLocationValues(modalLocationObj, defaultCountryCode);
    const locationVM = viewModelService.create(location, 'pc', valueDeserializationClass);
    _.set(clonedModalData, path, locationVM);
    return clonedModalData;
}

export default function LocationScheduleItem({
    info,
    modalData,
    viewModelService,
    defaultCountryCode,
    scheduleItem,
    onValueChange,
    onValidate,
    parentOverrides,
    isNew
}) {
    const { id } = info;
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [onValidate, id, isComponentValid]);

    const path = 'itemData.Location.locationValue';
    const isExistingLocationPath = `${path}.isExistingLocation`;
    const hasExistingLocations = !_.isEmpty(scheduleItem.existingLocations);
    const shouldShowExistingLocation = _.get(modalData, isExistingLocationPath);

    const locationVM = createLocationVM(
        modalData,
        info,
        path,
        defaultCountryCode,
        viewModelService
    );

    const countryPath = `${path}.address.country`;
    const oldCountry = _.get(modalData, countryPath);
    const newCountry = _.get(locationVM, `${path}.address.country.value.code`);
    if (oldCountry !== newCountry) {
        // If no country code is provided we use the default country code
        onValueChange(newCountry, countryPath);
    }
    if (isNew && hasExistingLocations && _.isUndefined(shouldShowExistingLocation)) {
        onValueChange(true, isExistingLocationPath);
        handleExistingAddressSelection(0, path, onValueChange, scheduleItem.existingLocations);
    }

    const overrideProps = useMemo(
        () => generateOverrides(
            hasExistingLocations,
            shouldShowExistingLocation,
            scheduleItem,
            onValueChange,
            modalData,
            path,
            parentOverrides,
            isNew
        ),
        [
            hasExistingLocations,
            shouldShowExistingLocation,
            scheduleItem,
            onValueChange,
            modalData,
            path,
            parentOverrides,
            isNew
        ]
    );

    const readValue = useCallback(
        (elementId, elementPath) => {
            return readViewModelValue(
                metadata.componentContent,
                modalData,
                elementId,
                elementPath,
                overrideProps
            );
        },
        [modalData, overrideProps]
    );

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={locationVM}
            overrideProps={overrideProps}
            onValueChange={onValueChange}
            onValidationChange={setComponentValidation}
            resolveValue={readValue}
        />
    );
}

LocationScheduleItem.propTypes = {
    info: PropTypes.shape({
        id: PropTypes.string
    }).isRequired,
    modalData: PropTypes.shape({}).isRequired,
    viewModelService: PropTypes.shape({}).isRequired,
    defaultCountryCode: PropTypes.string.isRequired,
    scheduleItem: PropTypes.shape({
        existingLocations: PropTypes.arrayOf(PropTypes.shape({}))
    }).isRequired,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    parentOverrides: PropTypes.shape({}).isRequired,
    isNew: PropTypes.bool.isRequired
};
