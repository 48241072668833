import React from 'react';
import PropTypes from 'prop-types';

import ImageComponent from '../ImageComponent/ImageComponent';
import styles from './EntryPageTemplate.module.scss';

function EntryPageTemplate(props) {
    const { applicationName, applicationLogo, contentToRender } = props;

    return (
        <div id="entryPageTemplate" className={styles.entryPageTemplate}>
            <div id="entryPageTemplateHeader" className={styles.entryPageTemplateHeader}>
                <div id="entryPageTemplateLogo" className={styles.entryPageTemplateLogo}>
                    <ImageComponent src={applicationLogo} title={applicationName} />
                </div>
                <h2
                    id="entryPageTemplateApplicationName"
                    className={styles.entryPageTemplateApplicationName}
                >
                    {applicationName}
                </h2>
            </div>
            <div id="entryPageTemplateContent" className={styles.entryPageTemplateContent}>
                <div
                    id="entryPageContentContainer"
                    className={styles.entryPageContentContainer}
                >
                    {contentToRender}
                </div>
            </div>
        </div>
    );
}

EntryPageTemplate.propTypes = {
    applicationName: PropTypes.string.isRequired,
    applicationLogo: PropTypes.string.isRequired,
    contentToRender: PropTypes.shape({}).isRequired
};

export default EntryPageTemplate;
