/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

export default defineMessages({
    open: {
        id: 'nfum.platform.label.accordion.Open',
        defaultMessage: 'Open'
    },
    close: {
        id: 'nfum.platform.label.accordion.Close',
        defaultMessage: 'Close'
    }
});
