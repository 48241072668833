import { defineMessages } from 'react-intl';

export default defineMessages({
    EffectiveDate: {
        id: 'endorsement.ngh.views.policychange-details.EffectiveDate',
        defaultMessage: 'Effective date'
    },
    PolicyChangeEffectiveDate: {
        id: 'endorsement.ngh.views.policychange-details.policyChangeEffectiveDate',
        defaultMessage: 'When would you like these policy changes to take effect? '
    },
    EffectiveDateTooltip: {
        id: 'endorsement.ngh.views.policychange-details.EffectiveDateTooltip',
        defaultMessage: 'This change will take effect from 00:01 on the date you select. If you select today\'s date, your change will come into effect immediately.'
    },
    AccidentalDamageCover: {
        id: 'endorsement.ngh.views.policychange-details.AccidentalDamageCover',
        defaultMessage: 'Accidental Damage'
    },
    ContentsAccidentalCover: {
        id: 'endorsement.ngh.views.policychange-details.ContentsAccidentalCover',
        defaultMessage: 'Contents accidental damage'
    },
    ContentsAccidentalCoverText: {
        id: 'endorsement.ngh.views.policychange-details.ContentsAccidentalCoverText',
        defaultMessage: 'Cover for damage to your contents that occurs suddenly as the result of an accident, such as spilling wine on your sofa. '
    },
    BuildingsAccidentalCover: {
        id: 'endorsement.ngh.views.policychange-details.BuildingsAccidentalCover',
        defaultMessage: 'Buildings accidental damage'
    },
    BuildingsAccidentalCoverText: {
        id: 'endorsement.ngh.views.policychange-details.BuildingsAccidentalCoverText',
        defaultMessage: 'Cover for damage to your buildings that occur suddenly as a the result of an accident, such as hitting a pipe when hanging a picture frame. '
    },
    LegalExpensesCover: {
        id: 'endorsement.ngh.views.policychange-details.LegalExpensesCover',
        defaultMessage: 'Personal Legal Expenses'
    },
    StandardLegalExpensesCover: {
        id: 'endorsement.ngh.views.policychange-details.StandardLegalExpensesCover',
        defaultMessage: 'Personal Legal Expenses'
    },
    StandardLegalExpensesCoverText: {
        id: 'endorsement.ngh.views.policychange-details.StandardLegalExpensesCoverText',
        defaultMessage: 'Cover for your legal proceedings that relate to your home, employment death or personal injury up to £100,000. '
    },
    EnhancedLegalExpensesCover: {
        id: 'endorsement.ngh.views.policychange-details.EnhancedLegalExpensesCover',
        defaultMessage: 'Personal Legal Expenses Extra'
    },
    EnhancedLegalExpensesCoverText: {
        id: 'endorsement.ngh.views.policychange-details.EnhancedLegalExpensesCoverText',
        defaultMessage: 'Cover for an even wider range of personal and family related legal disputes up to £100,000. '
    },
    learnMore: {
        id: 'endorsement.ngh.views.policychange-details.learnMore',
        defaultMessage: 'Learn More.'
    },
    BicycleExtensionCover: {
        id: 'endorsement.ngh.views.policychange-details.BicycleExtensionCover',
        defaultMessage: 'Cycling Protection'
    },
    BicycleExtensionCoverText: {
        id: 'endorsement.ngh.views.policychange-details.BicycleExtensionCoverText',
        defaultMessage: 'Cover for the cost of calling out a tradesperson due to sudden incident, such as your boiler breaking down or your pipes bursting. Our Home Emergency Cover includes protection for a wide range of home emergencies. '
    },
    HomeEmergencyCover: {
        id: 'endorsement.ngh.views.policychange-details.HomeEmergencyCover',
        defaultMessage: 'Home Emergency'
    },
    HomeEmergencyCoverText: {
        id: 'endorsement.ngh.views.policychange-details.HomeEmergencyCoverText',
        defaultMessage: 'Cover for the cost of calling out a tradesperson due to sudden incident, such as your boiler breaking down or your pipes bursting. Our Home Emergency Cover includes protection for a wide range of home emergencies. '
    },
    LegalExpensesCoverText: {
        id: 'endorsement.ngh.views.policychange-details.LegalExpensesCoverText',
        defaultMessage: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sed leo viverra erat accumsan mattis sed at leo. Phasellus non mauris ut nisi venenatis consequat '
    },
    GetInTouch: {
        id: 'endorsement.ngh.views.policychange-details.GetInTouch',
        defaultMessage: 'We need you to get in touch'
    },
    WithinRenewalCycle: {
        id: 'endorsement.ngh.views.policychange-details.WithinRenewalCycle',
        defaultMessage: 'You can not select date which falls within renewal period, please contact us using one of the following options and we can support you with your Home Insurance amendment '
    },
    SavedProgress: {
        id: 'endorsement.ngh.views.policychange-details.SavedProgress',
        defaultMessage: 'We have saved your progress and will be able to pick up with you where you left off. Alternatively you can find your local agency or send us an email.'
    },
    ContentsLimitExceeded: {
        id: 'endorsement.ngh.views.policychange-details.ContentsLimitExceeded',
        defaultMessage: 'It looks like there has been a change in your circumstances that we need to know about. Please get in touch with us either by calling us or arranging a call back. '
    },
    Contents: {
        id: 'endorsement.ngh.views.policychange-details.Contents',
        defaultMessage: 'Contents'
    },
    costOfReplacingContents: {
        id: 'endorsement.ngh.views.policychange-details.costOfReplacingContents',
        defaultMessage: 'The cost of replacing all your contents is currently '
    },
    CostOfReplacingContentsDesc2: {
        id: 'endorsement.ngh.views.policychange-details.CostOfReplacingContentsDesc2',
        defaultMessage: 'If you have made changes to your valuables, consider updating your total contents sum insured as this value must include all contents and valuables added together.'
    },
    contentDesc: {
        id: 'endorsement.ngh.views.policychange-details.contentDesc',
        defaultMessage: 'If you reduce the contents sum insured  and it doesn\'t fully reflect all the items in your home it may reduce our ability to pay a claim in full. You can read our '
    },
    helpfulguidelink: {
        id: 'endorsement.ngh.views.policychange-details.helpfulguidelink',
        defaultMessage: 'helpful guide'
    },
    CostOfReplacingContentsDesc3a: {
        id: 'endorsement.ngh.views.policychange-details.CostOfReplacingContentsDesc3a',
        defaultMessage: ' to calculating your contents.'
    },
    replacingContentsCostOverride: {
        id: 'endorsement.ngh.views.policychange-details.replacingContentsCostOverride',
        defaultMessage: 'How much would it cost to replace your contents?'
    },
    replacingContentsAccurate: {
        id: 'endorsement.ngh.views.policychange-details.replacingContentsAccurate',
        defaultMessage: '. Is this still accurate? '
    },
    TechnicalProblems: {
        id: 'quoteandbind.ngh.views.policychange-details.technicalProblems',
        defaultMessage: 'Sorry, we are experiencing some technical problems at the moment.'
    },
    midTermErrorOnline: {
        id: 'quoteandbind.ngh.views.policychange-details.midTermErrorOnline',
        defaultMessage: 'Sorry, you can not complete this mid-term change online'
    },
    ProgressSaved: {
        id: 'endorsement.ngh.views.policychange-details.ProgressSaved',
        defaultMessage: 'We have saved your progress and will be able to pick up with you where you left off. Alternatively you can '
    },
    homeEmergencyCoverTextClaimPeriod: {
        id: 'endorsement.ngh.views.policychange-details.homeEmergencyCoverTextClaimPeriod',
        defaultMessage: 'We will not pay for any claims under Home Emergency which occur within the first 14 days of the start of the period of cover, as shown on your schedule.'
    },
    limitsValidationErrorText: {
        id: 'endorsement.ngh.views.policychange-details.limitsValidationErrorText',
        defaultMessage: 'Looks like you’ve added an item of higher value than we would want to quote online or your total valuables exceeds our maximum limit.'
    },
    limitsValidationErrorDesc: {
        id: 'endorsement.ngh.views.policychange-details.limitsValidationErrorDesc',
        defaultMessage: 'Don’t worry, we have several products that may be suitable for you and we’d like to speak to you about them. Please contact us using the option below:'
    },
    ratingServiceTimedOut: {
        id: 'endorsement.ngh.views.policychange-Details.ratingServiceTimedOut',
        defaultMessage: 'Rating service has timed out'
    },
    contentReplacementTooltipText1: {
        id: 'endorsement.ngh.views.policychange-Details.contentReplacementTooltipText1',
        defaultMessage: 'Remember to include all contents inside your home and garden, not forgetting things like carpets and curtains.'
    },
    contentReplacementTooltipText2: {
        id: 'endorsement.ngh.views.policychange-Details.contentReplacementTooltipText2',
        defaultMessage: 'The sum insured for interior fittings is not included in the cost of replacing your contents.'
    },
    contentReplacementTooltipText3: {
        id: 'endorsement.ngh.views.policychange-Details.contentReplacementTooltipText3',
        defaultMessage: 'If you need to amend your Tenants Interior Decorations cover, please contact us.'
    },
    replContentsCostValidMsg1: {
        id: 'endorsement.ngh.views.policychange-details.replContentsCostValidMsg1',
        defaultMessage: 'This is a required field'
    },
    replContentsCostValidMsg2: {
        id: 'endorsement.ngh.views.policychange-details.replContentsCostValidMsg2',
        defaultMessage: 'You have reached maximum allowed field length'
    },
    premiumAmtChangeWarning: {
        id: 'endorsement.ngh.views.policychange-Details.premiumAmtChangeWarning',
        defaultMessage: 'We regularly check our prices and they are subject to change. To get a full quotation from us, please click continue and your information will be saved.'
    }
});
