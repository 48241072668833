import _ from 'lodash';
import React, { useCallback, useContext } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import messages from './TravelSummary.messages';
import metadata from './TravelSummary.metadata.json5';
import styles from './TravelSummary.module.scss';

function TravelSummary(props) {
    const { value: travelData } = props;
    const translator = useContext(TranslatorContext);
    const travelIds = Object.freeze({
        CANCELLATION_COV: 'TRVCancellationCov',
        CANCELLATION_SUM_INSURED: 'TRVCancellationCovSumInsured',
        MEDICAL_COV: 'TRVMedicalCov',
        MEDICAL_SUM_INSURED: 'TRVMedicalCovMedicalExpSI',
        BAGGAGE_COV: 'TRVBaggageCov',
        BAGGAGE_SUM_INSURED: 'TRVBaggageCovCoverLimit',
        WINTER_SPORTS_COV: 'TRVWinterSportsCov',
        WINTER_SPORTS_DAYS: 'TRVWinterSportsCovWinterSportsDays'
    });
    const insuredTypeIds = Object.freeze({
        INSURED_ONLY: 'insured_only',
        COUPLE: 'couple',
        INSURED_FAMILY: 'insured_family',
        SINGLE_PARENT: 'single_parent_family'
    });

    const getTravellersNames = useCallback((traveller) => {
        return _.map(traveller.exposures.trvtravellerDetailss.filter((trv) => trv.additionalFamilyMember !== true), 'insuredName').join(', ');
    }, []);

    const getAdditionalTravellersNames = useCallback((traveller) => {
        return _.map(traveller.exposures.trvtravellerDetailss.filter((trv) => trv.additionalFamilyMember === true), 'insuredName').join(', ');
    }, []);

    const travelCoverageLimitValue = useCallback((travellers, covPublicId, termPublicID) => {
        const travellersCoverages = travellers?.coverages?.coverages
            ?.find((cov) => cov?.publicID === covPublicId);
        if (!travellersCoverages?.selected) {
            return translator(messages.notSelected);
        }
        const chosenTermValueObj = travellersCoverages?.terms
            ?.find((term) => term?.publicID === termPublicID);
        let chosenTermValue = chosenTermValueObj?.chosenTermValue;
        if (termPublicID !== travelIds.WINTER_SPORTS_DAYS) {
            chosenTermValue = `£${chosenTermValue}`;
        }
        return chosenTermValue;
    }, [translator, travelIds]);

    const getPeopleInsuredTypeName = useCallback((peopleInsuredType) => {
        switch (peopleInsuredType) {
            case insuredTypeIds.INSURED_ONLY:
                return translator(messages.insuredOnly);
            case insuredTypeIds.COUPLE:
                return translator(messages.couple);
            case insuredTypeIds.INSURED_FAMILY:
                return translator(messages.insuredFamily);
            case insuredTypeIds.SINGLE_PARENT:
                return translator(messages.singleFarentFamily);
            default:
                return '';
        }
    }, [translator, insuredTypeIds]);

    const getAreaVisitedName = useCallback((areaCode) => {
        switch (areaCode) {
            case 'europe':
                return translator(messages.europe);
            case 'worldwide_exc_usa_canada_caribbean':
                return translator(messages.worldwideExcUsaCanadaCaribbean);
            case 'worldwide':
                return translator(messages.worldwide);
            default:
                return '';
        }
    }, [translator]);

    const getAnnualDaysName = useCallback((days) => {
        switch (days) {
            case '30_days':
                return translator(messages.days30);
            case '60_days':
                return translator(messages.days60);
            case '90_days':
                return translator(messages.days90);
            case '120_days':
                return translator(messages.days120);
            default:
                return '';
        }
    }, [translator]);

    const generateTravelData = useCallback(() => {
        if (!travelData) {
            return {};
        }
        const travelOverrides = travelData.map((traveller, index) => {
            const travellersNames = getTravellersNames(traveller);
            const additionalTravellersNames = getAdditionalTravellersNames(traveller);
            return {
                [`travelTitle${index}`]: {
                    content: getPeopleInsuredTypeName(traveller.peopleInsuredType)
                },
                [`travellers${index}`]: {
                    value: travellersNames.length > 0
                        && !(traveller.isMigrated === true
                            && traveller.isThereAdditionalFamily === false)
                        ? getTravellersNames(traveller)
                        : getPeopleInsuredTypeName(traveller.peopleInsuredType)
                },
                [`travellersTooltipContainer${index}`]: {
                    visible: travellersNames.length === 0
                        || (traveller.isMigrated === true
                        && traveller.isThereAdditionalFamily === false)
                },
                [`insuredOnlyTooltip${index}`]: {
                    visible: traveller.peopleInsuredType === insuredTypeIds.INSURED_ONLY
                },
                [`coupleTooltip${index}`]: {
                    visible: traveller.peopleInsuredType === insuredTypeIds.COUPLE
                },
                [`singleParentTooltip${index}`]: {
                    visible: traveller.peopleInsuredType === insuredTypeIds.SINGLE_PARENT
                },
                [`insuredAndFamilyTooltip${index}`]: {
                    visible: traveller.peopleInsuredType === insuredTypeIds.INSURED_FAMILY
                },
                [`additionalTravellers${index}`]: {
                    visible: additionalTravellersNames.length > 0,
                    value: additionalTravellersNames
                },
                [`areaToBeVisited${index}`]: {
                    value: getAreaVisitedName(traveller.areaToBeVisited)
                },
                [`annualDaysOfCover${index}`]: {
                    value: getAnnualDaysName(traveller.maxHolDays)
                },
                [`annualCancellationLimit${index}`]: {
                    value: travelCoverageLimitValue(traveller,
                        travelIds.CANCELLATION_COV,
                        travelIds.CANCELLATION_SUM_INSURED)
                },
                [`medicalExpensesSumInsured${index}`]: {
                    value: travelCoverageLimitValue(traveller,
                        travelIds.MEDICAL_COV,
                        travelIds.MEDICAL_SUM_INSURED)
                },
                [`baggageSumInsured${index}`]: {
                    value: travelCoverageLimitValue(traveller,
                        travelIds.BAGGAGE_COV,
                        travelIds.BAGGAGE_SUM_INSURED)
                },
                [`winterSports${index}`]: {
                    value: traveller?.coverages?.coverages
                        ?.find((cov) => cov?.publicID === travelIds.WINTER_SPORTS_COV)?.selected
                        ? travelCoverageLimitValue(traveller,
                            travelIds.WINTER_SPORTS_COV,
                            travelIds.WINTER_SPORTS_DAYS)
                        : translator(messages.notSelected)
                }
            };
        });
        return Object.assign({}, ...travelOverrides);
    }, [travelData,
        translator,
        getTravellersNames,
        getAdditionalTravellersNames,
        travelCoverageLimitValue,
        getPeopleInsuredTypeName,
        getAreaVisitedName,
        getAnnualDaysName,
        travelIds,
        insuredTypeIds]);

    const overrideProps = {
        '@field': {
            readOnly: true,
            labelPosition: 'left',
            className: 'riskObjectProperty',
            labelClassName: 'riskObjectPropertyLabel'
        },
        travellers: {
            data: travelData
        },
        ...generateTravelData()
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
        />
    );
}

export default TravelSummary;
