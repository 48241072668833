import { defineMessages } from 'react-intl';

export default defineMessages({
    problemGeneratingQuote: {
        id: 'endorsement.directives.specificError.problemGeneratingQuote',
        defaultMessage: 'We are experiencing a problem generating your mid-term quote at the moment.'
    },
    tryAgainLater: {
        id: 'endorsement.directives.specificError.Please try again a little later',
        defaultMessage: 'We are aware of the issue and are working to solve it urgently. Please try again a little later.'
    },
    savedQuote: {
        id: 'endorsement.directives.specificError.savedQuote',
        defaultMessage: 'Don\'t worry, your progress has been saved. You can resume your progress by calling our UK-based team, or arrange a call back for a time that suits you.'
    },   
    savedProgress: {
        id: 'endorsement.directives.specificError.savedProgress',
        defaultMessage: 'We have saved your progress and will be able to pick up with you where you left off. Alternatively you can find your local agency or send us an email.'
    },
    contactUsTextWithAmendments: {
        id: 'endorsement.directives.specificError.contactUsTextWithAmendments',
        defaultMessage: 'Please contact us using one of the following options and we can support you with your Home and Lifestyle amendment'
    },
    
});
