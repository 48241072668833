import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import { useAuthState } from './AuthStateProvider';

const LoginCallback = ({
    errorComponent: ErrorComponent,
}) => {
    const [loginError, setLoginError] = useState();
    const { error, isPending } = useAuth();
    /** Modified for okta state*/
    const { authState, oktaAuth } = useAuthState();

    useEffect(() => {
        let hasUnmounted = false;
        const handleLoginRedirect = async () => {
            try {
                await oktaAuth.handleLoginRedirect();
            } catch (e) {
                // See https://github.com/okta/okta-auth-js/issues/663
                if (!hasUnmounted) {
                    setLoginError(e);
                }
            }
        };
        if (authState.accessToken === null) {
            // Resetting logoutAM flag to false on login
            window.localStorage.setItem('logoutAM', false);
            handleLoginRedirect();
        }

        return () => {
            hasUnmounted = true;
        };
    }, [oktaAuth, authState]);

    const finalError = loginError || (!isPending && error);

    if (finalError) {
        return <ErrorComponent error={finalError} />;
    }

    return null;
};

export default LoginCallback;
