import { defineMessages } from 'react-intl';

export default defineMessages({
    yes: {
        id: 'renewal.ngh.views.renewal-summary.travel.yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'renewal.ngh.views.renewal-summary.travel.no',
        defaultMessage: 'No'
    },
    notSelected: {
        id: 'renewal.ngh.views.renewal-summary.travel.notSelected',
        defaultMessage: 'Not Selected'
    },
    additionalTravellers: {
        id: 'renewal.ngh.views.renewal-summary.travel.additionalTravellers',
        defaultMessage: 'Additional travellers'
    },
    travellers: {
        id: 'renewal.ngh.views.renewal-summary.travel.travellers',
        defaultMessage: 'Travellers'
    },
    areaOfCover: {
        id: 'renewal.ngh.views.renewal-summary.travel.areaOfCover',
        defaultMessage: 'Area of cover'
    },
    maxDaysOfCover: {
        id: 'renewal.ngh.views.renewal-summary.travel.maxDaysOfCover',
        defaultMessage: 'Maxiumum days of cover per trip'
    },
    annualCancellationLimit: {
        id: 'renewal.ngh.views.renewal-summary.travel.cancellationExpensesLimit',
        defaultMessage: 'Cancellation expenses limit'
    },
    medicalExpensesSumInsured: {
        id: 'renewal.ngh.views.renewal-summary.travel.medicalExpensesSumInsured',
        defaultMessage: 'Medical expenses sum insured'
    },
    baggageSumInsured: {
        id: 'renewal.ngh.views.renewal-summary.travel.baggageCover',
        defaultMessage: 'Baggage cover'
    },
    winterSports: {
        id: 'renewal.ngh.views.renewal-summary.travel.winterSportsDaysOfCover',
        defaultMessage: 'Winter sports days of cover'
    },
    insuredOnly: {
        id: 'renewal.ngh.views.renewal-summary.travel.insuredOnly',
        defaultMessage: 'Insured only'
    },
    couple: {
        id: 'renewal.ngh.views.renewal-summary.travel.couple',
        defaultMessage: 'Couple'
    },
    insuredFamily: {
        id: 'renewal.ngh.views.renewal-summary.travel.insuredFamily',
        defaultMessage: 'Insured and Family'
    },
    singleFarentFamily: {
        id: 'renewal.ngh.views.renewal-summary.travel.singleFarentFamily',
        defaultMessage: 'Single parent and Family'
    },
    europe: {
        id: 'renewal.ngh.views.renewal-summary.travel.europe',
        defaultMessage: 'Europe'
    },
    worldwideExcUsaCanadaCaribbean: {
        id: 'renewal.ngh.views.renewal-summary.travel.worldwideExcUsaCanadaCaribbean',
        defaultMessage: 'Worldwide exc. USA/Canada/Caribbean'
    },
    worldwide: {
        id: 'renewal.ngh.views.renewal-summary.travel.worldwide',
        defaultMessage: 'Worldwide'
    },
    days30: {
        id: 'renewal.ngh.views.renewal-summary.travel.days30',
        defaultMessage: '30 days'
    },
    days60: {
        id: 'renewal.ngh.views.renewal-summary.travel.days60',
        defaultMessage: '60 days'
    },
    days90: {
        id: 'renewal.ngh.views.renewal-summary.travel.days90',
        defaultMessage: '90 days'
    },
    days120: {
        id: 'renewal.ngh.views.renewal-summary.travel.days120',
        defaultMessage: '120 days'
    },
    insuredOnlyTooltipContent1: {
        id: 'renewal.ngh.views.renewal-summary.travel.insuredOnlyTooltipContent1',
        defaultMessage: 'The person shown on the schedule only'
    },
    coupleTooltipContent1: {
        id: 'renewal.ngh.views.renewal-summary.travel.coupleTooltipContent1',
        defaultMessage: 'The person shown on the schedule only plus their: spouse, civil or domestic partner'
    },
    singleParentTooltipContent1: {
        id: 'renewal.ngh.views.renewal-summary.travel.singleParentTooltipContent1',
        defaultMessage: 'Cover is provided for the policyholder plus their:'
    },
    singleParentTooltipContentLi1: {
        id: 'renewal.ngh.views.renewal-summary.travel.singleParentTooltipContentLi1',
        defaultMessage: 'children, stepchildren and foster children who normally live at the home and are under 18;'
    },
    singleParentTooltipContentLi2: {
        id: 'renewal.ngh.views.renewal-summary.travel.singleParentTooltipContentLi2',
        defaultMessage: 'children who live with their other parent or are in foster care, and are under 18; and'
    },
    singleParentTooltipContentLi3: {
        id: 'renewal.ngh.views.renewal-summary.travel.singleParentTooltipContentLi3',
        defaultMessage: 'children and stepchildren between 18 and 23 who normally live at the home and are in full time education.'
    },
    insuredAndFamilyTooltipContent1: {
        id: 'renewal.ngh.views.renewal-summary.travel.insuredAndFamilyTooltipContent1',
        defaultMessage: 'The person shown on the schedule only plus their:'
    },
    insuredAndFamilyTooltipContentLiText1: {
        id: 'renewal.ngh.views.renewal-summary.travel.insuredAndFamilyTooltipContentLiText1',
        defaultMessage: 'spouse, civil or domestic partner'
    },
    insuredAndFamilyTooltipContentLiText2: {
        id: 'renewal.ngh.views.renewal-summary.travel.insuredAndFamilyTooltipContentLiText2',
        defaultMessage: 'children, stepchildren and foster children who normally live at the home and are under 18;'
    },
    insuredAndFamilyTooltipContentLiText3: {
        id: 'renewal.ngh.views.renewal-summary.travel.insuredAndFamilyTooltipContentLiText3',
        defaultMessage: 'children who live with their other parent or are in foster care, and are under 18; and'
    },
    insuredAndFamilyTooltipContentLiText4: {
        id: 'renewal.ngh.views.renewal-summary.travel.insuredAndFamilyTooltipContentLiText4',
        defaultMessage: 'children and stepchildren between 18 and 23 who normally live at the home and are in full time education.'
    },
    insuredAndFamilyTooltipContent2: {
        id: 'renewal.ngh.views.renewal-summary.travel.insuredAndFamilyTooltipContent2',
        defaultMessage: 'We’ll still cover children in full time education who reach 24 during the policy period, if they normally live at the home'
    },
});
