/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DateField, Icon } from '@jutro/components';
import { NfumFieldLabel } from 'nfum-components-platform-react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import styles from './NfumDateField.module.scss';

function NfumDateField(props) {
    const {
        value,
        success,
        error,
        onValidationChange,
        className,
        labelClassName,
        onBlur,
        isFullSize
    } = props;
    const [currentValidation, updateCurrentValidation] = useState(true);
    const [showState, updateShowState] = useState(false);

    const showError = error || !currentValidation;
    let icon;
    
    if (showError) {
        icon = "gw-close";
    } else if (success || !isEmpty(value)) {
        icon = "gw-check";
    } else {
        icon = undefined;
    }
    const iconClass = classNames(styles.nfumInputIcon, {
        [styles.errorIcon]: showError
    });

    const iconComponent = <Icon icon={icon} className={iconClass}/>;
    const inputClass = classNames(styles.nfumInputField, className, {
        [styles.fullSize]: isFullSize
    });

    const onValidate = (isValid, path) => {
        updateCurrentValidation(isValid);
        onValidationChange(isValid, path);
    };

    const handleBlur = (event, data) => {
        onBlur && onBlur(data.value, data.model);
        updateShowState(true);
    };

    return (
        <div className={styles.nfumInputContainer}>
            <NfumFieldLabel
                {...props}
                className={labelClassName}
            />
            <div className={styles.nfumInput}>
                <DateField
                    {...props}
                    icon="gw-calendar-today"
                    iconPosition="left"
                    className={inputClass}
                    displayFormat="medium"
                    onValidationChange={onValidate}
                    onBlur={handleBlur}
                    hideLabel="true"
                />
                {(showState && !isFullSize) && iconComponent}
            </div>
        </div>
    );
}

NfumDateField.propTypes = {
    error: PropTypes.bool,
    success: PropTypes.bool,
    showEdit: PropTypes.bool,
};

NfumDateField.defaultProps = {
    error: false,
    success: false,
    showEdit: false
};

export default NfumDateField;
