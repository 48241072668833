import {
    useCallback
} from 'react';
import {
    useHistory
} from 'react-router-dom';

const useJobRetrieval = () => {
    const POLICY_CHANGE_RETRIEVE_KEY = 'policyChangeRetrieveKey';
    const RENEWAL_RETRIEVE_KEY = 'renewalRetrieveKey';
    const POLICY_NUMBER_KEY = 'policynumber';
    const EMPTY_KEY = 'empty';
    const history = useHistory();

    const storePolicyNumberToRetrieveRenewal = useCallback((pathname) => {
        if (pathname.includes('/renewal')) {
            let policyNumber = null;
            const pathParts = pathname?.split('/');
            if (pathParts) {
                const renewalIndex = pathParts.indexOf('renewal');
                // eslint-disable-next-line prefer-destructuring
                policyNumber = pathParts[renewalIndex + 1];
            }
            if (policyNumber) {
                localStorage.setItem(RENEWAL_RETRIEVE_KEY, policyNumber);
            }
        }
    }, []);

    const storePolicyNumberToRetrievePolicyChange = useCallback((pathname) => {
        if (pathname.includes('/retrieve-policy-change')) {
            const searchParams = new URLSearchParams(window.location.search);
            const policyNumber = searchParams.get(POLICY_NUMBER_KEY);
            if (policyNumber) {
                localStorage.setItem(POLICY_CHANGE_RETRIEVE_KEY, policyNumber);
            }
        }
    }, []);

    const jumpToRetrievedPolicyChange = useCallback(() => {
        const policyChangeRetrieveKey = localStorage.getItem(POLICY_CHANGE_RETRIEVE_KEY);
        if (policyChangeRetrieveKey && policyChangeRetrieveKey !== EMPTY_KEY) {
            localStorage.setItem(POLICY_CHANGE_RETRIEVE_KEY, EMPTY_KEY);
            history.push(`/retrieve/${policyChangeRetrieveKey}`);
        }
    }, [history]);

    const clearRenewalRetrieveKey = useCallback(() => {
        localStorage.setItem(RENEWAL_RETRIEVE_KEY, EMPTY_KEY);
    }, []);

    const jumpToRetrievedRenewal = useCallback(() => {
        const renewalRetrieveKey = localStorage.getItem(RENEWAL_RETRIEVE_KEY);
        if (renewalRetrieveKey && renewalRetrieveKey !== EMPTY_KEY) {
            clearRenewalRetrieveKey();
            history.push(`/renewal/${renewalRetrieveKey}`);
        }
    }, [clearRenewalRetrieveKey, history]);

    return {
        storePolicyNumberToRetrieveRenewal,
        storePolicyNumberToRetrievePolicyChange,
        jumpToRetrievedPolicyChange,
        jumpToRetrievedRenewal,
        clearRenewalRetrieveKey
    };
};

export default useJobRetrieval;
