import React, {
    useContext, useCallback, useMemo
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Tooltip } from '@jutro/components';
import { NfumLoaderButton } from 'nfum-components-platform-react';
import { TranslatorContext } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import {
    WizardContext,
    pageTemplateProps
} from 'gw-portals-wizard-react';
import MarketingSidebar from '../components/MarketingSidebar/MarketingSidebar';
import styles from './NfumDigitalWizardPageTemplate.module.scss';
import messages from './NfumDigitalWizardPageTemplate.messages';

function DigitalWizardPageTemplate(props) { /* NOSONAR: pure declarative usage */
    const translator = useContext(TranslatorContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const wizardContext = useContext(WizardContext);
    const isPhone = breakpoint === 'phone';
    const { currentStep } = wizardContext;
    const {
        children: content,
        onNext,
        isLoadingNext,
        disableNext,
        showNext,
        nextLabel,
        nextButtonTooltip,
        onPrevious,
        isLoadingPrevious,
        disablePrevious,
        showPrevious,
        previousLabel,
        onCancel,
        isLoadingCancel,
        disableCancel,
        showCancel,
        cancelLabel,
        renderContextComponent,
        renderWidgets,
        wizardData,
        wizardSnapshot,
        showContextComponent,
        showWizardHeader,
        hideSidebar,
        showJumpBack,
        onJumpBack,
        disableJumpBack,
        jumpBackLabel,
        showSaveForLater,
        disableSaveForLater,
        onSaveForLater,
        saveForLaterLabel
    } = props;

    const contextComponent = useCallback(() => {
        return <div className={styles.wizardContext}>{renderContextComponent(wizardSnapshot)}</div>;
    }, [renderContextComponent, wizardSnapshot]);

    const shouldShowButtons = useMemo(() => !(
        showNext === false
        && showPrevious === false
        && showCancel === false
    ), [showCancel, showNext, showPrevious]);

    const isPerformingTransiton = useMemo(() => (
        isLoadingCancel || isLoadingPrevious || isLoadingNext
    ), [isLoadingCancel, isLoadingNext, isLoadingPrevious]);

    const handleOnNext = useCallback(() => {
        return onNext();
    }, [onNext]);

    const nextButton = useMemo(() => (
        <NfumLoaderButton
            id="gw-wizard-Next"
            className={styles.nextButton}
            disabled={disableNext || isPerformingTransiton}
            onClick={handleOnNext}
            icon={isLoadingNext ? '' : 'gw-keyboard-arrow-right'}
            iconPosition="right"
            isLoading={isLoadingNext}
        >
            {translator(nextLabel)}
        </NfumLoaderButton>
    ), [disableNext, isLoadingNext, isPerformingTransiton, nextLabel, handleOnNext, translator]);

    const nextButtonWithTooltip = useMemo(() => (
        <Tooltip placement="bottom" content={nextButtonTooltip}>
            <span>{nextButton}</span>
        </Tooltip>
    ), [nextButton, nextButtonTooltip]);

    const saveForLaterButton = useMemo(() => (
        <Button
            id="gw-wizard-SaveForLater"
            className={styles.saveForLaterButton}
            onClick={onSaveForLater}
            disabled={disableSaveForLater || isPerformingTransiton}
            type="text"
            icon="gw-bookmark-border"
            iconPosition="right"
        >
            {translator(saveForLaterLabel)}
        </Button>
    ), [disableSaveForLater, saveForLaterLabel, onSaveForLater, translator, isPerformingTransiton]);

    const renderNextButton = nextButtonTooltip ? nextButtonWithTooltip : nextButton;
    const buttonContainerClassName = classNames(styles.navTransitionButtons, {
        [styles.navTransitionButtonOnlyNext]: !showPrevious && isPhone
    });

    return (
        <div className={styles.digitalWizard}>
            {showContextComponent && contextComponent()}
            <div className={styles.wizardMain}>
                <div className={styles.digitalContentContainer}>
                    <div className={styles.wizardContent}>
                        {showWizardHeader && contextComponent()}
                        {content}
                        <div className={classNames({ [styles.navButtons]: shouldShowButtons })}>
                            {showCancel && (
                                <NfumLoaderButton
                                    id="gw-wizard-cancel"
                                    className={styles.cancelButton}
                                    disabled={disableCancel || isPerformingTransiton}
                                    type="text"
                                    icon="gw-close"
                                    iconPosition="left"
                                    onClick={onCancel}
                                    isLoading={isLoadingCancel}
                                >
                                    {translator(cancelLabel)}
                                </NfumLoaderButton>
                            )}
                            {showJumpBack && (
                                <Button
                                    id="gw-wizard-jump-back"
                                    className={styles.jumpBackButton}
                                    disabled={disableJumpBack || isPerformingTransiton}
                                    type="text"
                                    iconPosition="left"
                                    icon="gw-keyboard-arrow-left"
                                    onClick={onJumpBack}
                                >
                                    { translator(jumpBackLabel)
                                        || translator(messages.returnToDashboard) }
                                </Button>
                            )}
                            {showSaveForLater && saveForLaterButton}
                            <div className={buttonContainerClassName}>
                                {showPrevious && (
                                    <NfumLoaderButton
                                        id="gw-wizard-previous"
                                        className={styles.previousButton}
                                        disabled={disablePrevious || isPerformingTransiton}
                                        type="outlined"
                                        icon={isLoadingPrevious ? '' : 'gw-keyboard-arrow-left'}
                                        iconPosition="left"
                                        onClick={onPrevious}
                                        isLoading={isLoadingPrevious}
                                    >
                                        {translator(previousLabel)}
                                    </NfumLoaderButton>
                                )}
                                {showNext && renderNextButton}
                            </div>
                        </div>
                    </div>
                    {!hideSidebar ? (
                        <div className={styles.wizardNavigation}>
                            <MarketingSidebar
                                pageId={currentStep.path.replace('/', '')}
                            />
                            <div className={styles.wizardWidgets}>
                                {renderWidgets(wizardData)}
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

DigitalWizardPageTemplate.propTypes = {
    ...pageTemplateProps,
    /** a function that accepts wizardData to render a React component */
    renderContextComponent: PropTypes.func,
    /** a function that accepts wizardData to render a React component */
    renderWidgets: PropTypes.func,
    showContextComponent: PropTypes.bool,
    showWizardHeader: PropTypes.bool,
    nextButtonTooltip: PropTypes.string,
    showJumpBack: PropTypes.bool,
    disableJumpBack: PropTypes.bool,
    onJumpBack: PropTypes.func,
    jumpBackLabel: PropTypes.string,
    showSaveForLater: PropTypes.bool,
    disableSaveForLater: PropTypes.bool,
    saveForLaterLabel: PropTypes.oneOfType([PropTypes.shape({
        id: PropTypes.string
    }), PropTypes.string]),
    onSaveForLater: PropTypes.func,
};

DigitalWizardPageTemplate.defaultProps = {
    renderContextComponent: () => null,
    renderWidgets: () => null,
    showContextComponent: false,
    showWizardHeader: false,
    nextButtonTooltip: '',
    showJumpBack: false,
    disableJumpBack: false,
    onJumpBack: () => null,
    jumpBackLabel: '',
    showSaveForLater: false,
    disableSaveForLater: false,
    saveForLaterLabel: '',
    onSaveForLater: () => null,
};

export default DigitalWizardPageTemplate;
