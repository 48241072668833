import { defineMessages } from 'react-intl';

export default defineMessages({
    buildingsInsurance: {
        id: 'renewal.ngh.views.renewal-summary.buildingsSummary.buildingsInsurance',
        defaultMessage: 'Buildings insurance'
    },
    noOfBedrooms: {
        id: 'renewal.ngh.views.renewal-summary.buildingsSummary.noOfBedrooms',
        defaultMessage: 'Number of bedrooms'
    },
    buildingsSumInsured: {
        id: 'renewal.ngh.views.renewal-summary.buildingsSummary.buildingsSumInsured',
        defaultMessage: 'Building sum insured'
    },
    firstLossSumInsured: {
        id: 'renewal.ngh.views.renewal-summary.buildingsSummary.firstLossSumInsured',
        defaultMessage: 'First loss sum insured'
    },
    buildingsLevelLimit: {
        id: 'renewal.ngh.views.renewal-summary.buildingsSummary.buildingsLevelLimit',
        defaultMessage: 'Buildings level limit'
    },
    buildingsAccidentalDamage: {
        id: 'renewal.ngh.views.renewal-summary.buildingsSummary.buildingsAccidentalDamage',
        defaultMessage: 'Buildings accidental damage'
    },
    buildingsAccidentalDamageInd: {
        id: 'renewal.ngh.views.renewal-summary.buildingsSummary.buildingsAccidentalDamageInd',
        defaultMessage: 'Buildings accidental damage'
    },
    confirmationText1: {
        id: 'renewal.ngh.views.renewal-summary.buildingsSummary.confirmationText1',
        defaultMessage: 'You have confirmed that:'
    },
    confirmationText2: {
        id: 'renewal.ngh.views.renewal-summary.buildingsSummary.confirmationText2',
        defaultMessage: 'The buildings you want to insure have never sustained loss of damage by flood, subsidence, heave or landslip'
    },
    confirmationText4: {
        id: 'renewal.ngh.views.renewal-summary.buildingsSummary.confirmationText4',
        defaultMessage: 'The buildings you want to insure are not at immediate risk of flooding'
    },
    confirmationText6: {
        id: 'renewal.ngh.views.renewal-summary.buildingsSummary.confirmationText6',
        defaultMessage: 'You don\'t use your home for business purposes other than as a home office'
    },
    confirmationText7: {
        id: 'renewal.ngh.views.renewal-summary.buildingsSummary.confirmationText7',
        defaultMessage: 'No customers or clients visit your home for business purposes'
    },
    confirmationText3: {
        id: 'renewal.ngh.views.renewal-summary.buildingsSummary.confirmationText3',
        defaultMessage: 'The buildings you want to insure have sustained loss of damage by flood, subsidence, heave or landslip'
    },
    confirmationTex5: {
        id: 'renewal.ngh.views.renewal-summary.buildingsSummary.confirmationTex5',
        defaultMessage: 'The buildings you want to insure are at immediate risk of flooding'
    },
    excess: {
        id: 'renewal.ngh.views.renewal-summary.buildingsSummary.excesses',
        defaultMessage: 'Excesses'
    },
    leakingAndFrozenWater: {
        id: 'renewal.ngh.views.renewal-summary.buildingsSummary.leakingAndFrozenWater',
        defaultMessage: 'Leaking and Frozen Water'
    },
    subsidence: {
        id: 'renewal.ngh.views.renewal-summary.buildingsSummary.subsidence',
        defaultMessage: 'Subsidence'
    },
    flood: {
        id: 'renewal.ngh.views.renewal-summary.buildingsSummary.flood',
        defaultMessage: 'Flood'
    },
    voluntary: {
        id: 'renewal.ngh.views.renewal-summary.buildingsSummary.voluntary',
        defaultMessage: 'Voluntary'
    },
    compulsaryAllClaims: {
        id: 'renewal.ngh.views.renewal-summary.buildingsSummary.compulsaryAllClaims',
        defaultMessage: 'Compulsory (All claims)'
    },
    seeDocuments: {
        id: 'renewal.ngh.views.renewal-summary.buildingsSummary.seeDocuments',
        defaultMessage: 'See documents'
    },
    notSelected: {
        id: 'renewal.ngh.views.renewal-summary.buildingsSummary.notSelected',
        defaultMessage: 'Not selected'
    },
    added: {
        id: 'renewal.ngh.views.renewal-summary.buildingsSummary.added',
        defaultMessage: 'Added'
    },
    included: {
        id: 'renewal.ngh.views.renewal-summary.buildingsSummary.included',
        defaultMessage: 'Included'
    },
    tenantsFixtures: {
        id: 'renewal.ngh.views.renewal-summary.buildingsSummary.tenantsFixtures',
        defaultMessage: 'Tenants Fixtures'
    }
});
