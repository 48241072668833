import { defineMessages } from 'react-intl';

export default defineMessages({
    bespokeValuablesDesc1: {
        id: 'endorsement.ngh.views.bespokeValuables.bespokeValuablesDesc1',
        defaultMessage: 'You can request cover for individual specified valuables such as Jewellery, Gemstones, Watches, Furs and Guns worth more than '
    },
    valuables: {
        id: 'endorsement.ngh.views.bespokeValuables.valuables',
        defaultMessage: 'Valuables'
    },
    bespokeValuablesDesc2: {
        id: 'endorsement.ngh.views.bespokeValuables.bespokeValuablesDesc2',
        defaultMessage: 'We use the term valuables to cover watches, jewellery, medals, coins and works of art as well as high value laptops and portable electronics. Take a look at the full list of what we consider a valuable.'
    },
    valuablesCtaDescription2: {
        id: 'endorsement.ngh.views.bespokeValuables.valuablesCtaDescription2',
        defaultMessage: 'Up to five new items may be added.'
    },
    valuablesCtaDescription3: {
        id: 'endorsement.ngh.views.bespokeValuables.valuablesCtaDescription3',
        defaultMessage: 'You can view specified valuables you currently have insured, amend the replacement cost(s) of your valuables or, if required, remove valuables.'
    },
    valuablesCtaDescription4: {
        id: 'endorsement.ngh.views.bespokeValuables.valuablesCtaDescription4',
        defaultMessage: 'You can also request cover for individual specified valuable items, such as Jewellery, Gemstones, Watches, Furs and Guns worth more than '
    },
    valuablesCtaDescription5: {
        id: 'endorsement.ngh.views.bespokeValuables.valuablesCtaDescription5',
        defaultMessage: 'Full details of your specified valuables are included within your policy documentation.'
    },
    valuablesCtaDescription6: {
        id: 'endorsement.ngh.views.bespokeValuables.valuablesCtaDescription6',
        defaultMessage: 'If you would like to discuss changes to your valuables please contact your agent.'
    },
    bespokeValuablesDesc3: {
        id: 'endorsement.ngh.views.bespokeValuables.bespokeValuablesDesc3',
        defaultMessage: 'The maximum cover we will provide for all specified and unspecified valuables combined is £17,500 If you have more than this amount please contact us.'
    },
    addItemButton: {
        id: 'quoteandbind.ngh.views.valuablesCard.addItemButton',
        defaultMessage: 'ADD ITEM'
    },
    make: {
        id: 'endorsement.ngh.views.bespokeValuables.makeType',
        defaultMessage: 'Make'
    },
    itemDescription: {
        id: 'endorsement.ngh.views.bespokeValuables.itemDescription',
        defaultMessage: 'Description'
    },
    newlyAddedValuables: {
        id: 'endorsement.ngh.views.policychange-summary.newlyAddedValuables',
        defaultMessage: 'Newly added valuable items'
    },
    existingValuables: {
        id: 'endorsement.ngh.views.policychange-summary.existingValuables',
        defaultMessage: 'Valuables already on your policy'
    },
    category: {
        id: 'renewal.bespoke.views.renewal-summary.valuables.category',
        defaultMessage: 'Category'
    },
    item: {
        id: 'endorsement.bespoke.views.bespokeValuables.item',
        defaultMessage: 'Item description here'
    },
    edit: {
        id: 'endorsement.bespoke.views.bespokeValuables.edit',
        defaultMessage: 'Edit'
    },
    delete: {
        id: 'endorsement.bespoke.views.bespokeValuables.delete',
        defaultMessage: 'Delete'
    },
    yes: {
        id: 'endorsement.bespoke.views.valuablesCard.yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'endorsement.bespoke.views.valuablesCard.no',
        defaultMessage: 'No'
    },
    valuablesWarningMsg1: {
        id: 'endorsement.ngh.views.bespokeValuables.valuablesWarningMsg1',
        defaultMessage: 'You have reached the limit of the number of items you are able to add online.  If you wish to add more, please contact your agent.'
    }
});
