import { defineMessages } from 'react-intl';

export default defineMessages({
    onlineSupportModalTitle: {
        id: 'accountmanagement.ngh.component.onlineSupport.onlineSupportModalTitle',
        defaultMessage: 'How can we help?'
    },
    WhatDoesBuildingInsuranceCover: {
        id: 'accountmanagement.ngh.component.onlineSupport.What does building insurance cover',
        defaultMessage: 'What does building insurance cover?'
    },
    WhatDoesContentsInsuranceCover: {
        id: 'accountmanagement.ngh.component.onlineSupport.What does contents insurance cover',
        defaultMessage: 'What does contents insurance cover?'
    },
    AreMyValuablesCoverOutsideOfmyHome: {
        id: 'accountmanagement.ngh.component.onlineSupport.Are my valuables cover outside of my home',
        defaultMessage: 'Are my valuables covered outside of my home?'
    },
    moreFaqs: {
        id: 'accountmanagement.ngh.component.onlineSupport.moreFaqs',
        defaultMessage: 'More FAQs'
    },
    Callus: {
        id: 'accountmanagement.ngh.component.onlineSupport.Call us',
        defaultMessage: 'Call us'
    },
    onlineSupportModalCallNumber: {
        id: 'accountmanagement.ngh.component.onlineSupport.onlineSupportModalCallNumber',
        defaultMessage: '0808 159 7883'
    },
    CallTimings: {
        id: 'accountmanagement.ngh.component.onlineSupport.CallTimings',
        defaultMessage: 'Mon to Fri: 8am to 8pm'
    },
    SaturdayCallTimings: {
        id: 'accountmanagement.ngh.component.onlineSupport.SaturdayCallTimings',
        defaultMessage: 'Saturday: 9am to 12:30pm'
    },
    SundayCallTimings: {
        id: 'accountmanagement.ngh.component.onlineSupport.SundayCallTimings',
        defaultMessage: 'Sunday: Closed'
    },
    ArrangeaCallBack: {
        id: 'accountmanagement.ngh.component.onlineSupport.Arrange a call back',
        defaultMessage: 'Arrange a call back'
    },
    convinentForYou: {
        id: 'accountmanagement.ngh.component.onlineSupport.convinent for you',
        defaultMessage: "We can call you at a time that's convenient for you "
    },
    ArrangeaCall: {
        id: 'accountmanagement.ngh.component.onlineSupport.Arrange a call',
        defaultMessage: 'Arrange a call'
    },
    EmailUs: {
        id: 'accountmanagement.ngh.component.onlineSupport.Send us an enquiry',
        defaultMessage: 'Send us an enquiry'
    },
    EmailQuery: {
        id: 'accountmanagement.ngh.component.onlineSupport.onlineSupportModalEmailUsTerms',
        defaultMessage: 'You can send us a message with your query'
    },
    SendEmail: {
        id: 'accountmanagement.ngh.component.onlineSupport.Send an enquiry',
        defaultMessage: 'Send an enquiry'
    },
    RequestCallBack: {
        id: 'accountmanagement.ngh.component.onlineSupport.requestCallBack',
        defaultMessage: 'Request a call back'
    },
    RequestCall: {
        id: 'accountmanagement.ngh.component.onlineSupport.requestCall',
        defaultMessage: 'Request a call'
    },
    likeToCallUs: {
        id: 'accountmanagement.ngh.component.onlineSupport.likeToCallUs',
        defaultMessage: 'Would you like us to call you?'
    }    
});
