import React, { useCallback, useMemo } from 'react';
import {
    ViewModelForm
} from 'gw-portals-viewmodel-react';
import { appendMetadataWithIndex } from 'gw-jutro-adapters-react';
import NfumCostItem from '../NfumCostItem/NfumCostItem';
import metadata from './NfumCostsPerLocation.metadata.json5';
import styles from './NfumCostsPerLocation.module.scss';
import './NfumCostsPerLocation.messages';

function NfumCostsPerLocation(props) {
    const {
        costInfosPerLocation,
        noHeader,
        index
    } = props;

    const formattedMetadata = useMemo(() => {
        return appendMetadataWithIndex(metadata.componentContent, index);
    }, [index]);

    const generateOverrides = useCallback(() => {
        if (costInfosPerLocation.items.length === 0) return Object.assign({});

        const fineArtsAddedItemsOverrides = costInfosPerLocation.items.map((item, ind) => {
            return {
                [`costItem${ind}`]: {
                    costInfo: item,
                    index: ind
                }
            };
        });

        return Object.assign({},
            ...fineArtsAddedItemsOverrides);
    }, [
        costInfosPerLocation
    ]);

    const overrideProps = {
        [`costInfoTitleText${index}`]: {
            content: costInfosPerLocation.name
        },
        [`costInfoTableHeader${index}`]: {
            visible: !noHeader
        },
        [`costsItems${index}`]: {
            data: costInfosPerLocation.items
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            nfumCostItem: NfumCostItem
        }
    };

    return (
        <ViewModelForm
            model={costInfosPerLocation}
            uiProps={formattedMetadata}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default NfumCostsPerLocation;
