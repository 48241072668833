import React, { useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { withAuthenticationContext } from 'gw-digital-auth-react';

function PolicyChangeRequestRetrieve(props) {
    const {
        history,
        match: {
            params: { policyNumber }
        }
    } = props;

    const redirect = useCallback(() => {
        history.push('/endorsement-coverages-bespoke-ngh', { policyNumber });
    }, [policyNumber, history]);

    useEffect(() => {
        redirect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div />
    );
}
export default withRouter(withAuthenticationContext(PolicyChangeRequestRetrieve));
