import React, {
    useEffect,
    useState,
    useContext,
    useCallback
} from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { NfumLoader } from 'nfum-components-platform-react';
import { TranslatorContext } from '@jutro/locale';
import { Wizard } from 'gw-portals-wizard-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { ErrorBoundary } from 'gw-portals-error-react';
import { messages as platformMessages } from 'gw-platform-translations';
import { ViewModelServiceContext, withViewModelService } from 'gw-portals-viewmodel-react';
import { useErrorHandler, AppContext } from 'nfum-portals-utils-react';
import { useJobRetrieval } from 'nfum-portals-wizard-react';
import { useRenewalCms } from 'nfum-cms-react';
import NGHContext from './NGHContext';
import wizardConfig from './config/ngh-wizard-config.json5';
import './NGHRenewal.messages';

function NGHRenewalWizard(props) {
    const { steps, title } = wizardConfig;
    const translator = useContext(TranslatorContext);
    const [initialSubmission, setInitialSubmission] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { RenewalService } = useDependencies('RenewalService');
    const { location, history, authHeader } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const { handleError } = useErrorHandler();
    const { clearRenewalRetrieveKey } = useJobRetrieval();
    const { marketingInfo, setMarketingInfo } = useContext(AppContext);
    const { getCmsContentForRenewal } = useRenewalCms(marketingInfo, setMarketingInfo);
    const productCodes = Object.freeze({
        BESPOKE: 'bespoke',
        NGH: 'ngh',
        COMMON: 'common'
    });

    const getAndSaveCmsData = useCallback((modelData) => {
        const isBespokeJourney = modelData
            .isBespokeHomeInsurance_NFUM;
        getCmsContentForRenewal(isBespokeJourney
            ? productCodes.BESPOKE
            : productCodes.NGH);
    }, [getCmsContentForRenewal, productCodes]);

    useEffect(
        () => {
            let policyOrJobNumber = null;
            const { pathname } = location;
            const pathParts = pathname?.split('/');
            if (pathParts) {
                const renewalIndex = pathParts.indexOf('renewal');
                // eslint-disable-next-line prefer-destructuring
                policyOrJobNumber = pathParts[renewalIndex + 1];
                clearRenewalRetrieveKey();
            }
            if (location?.state?.policyNumber) {
                policyOrJobNumber = location.state.policyNumber;
            }
            if (!policyOrJobNumber) {
                history.push('/');
                return;
            }
            RenewalService.retrieveByPolicyOrJobNumber(policyOrJobNumber, authHeader)
                .then((responseData) => {
                    getAndSaveCmsData(responseData);
                    const renewalVM = viewModelService.create(
                        responseData,
                        'pc',
                        'nfum.edge.capabilities.renewal.dto.RenewalDataDTO_NFUM'
                    );
                    setInitialSubmission(renewalVM);
                    setIsLoading(false);
                }).catch((error) => {
                    handleError(error, policyOrJobNumber);
                });
        },
        // Disabled so we don't rerun this function on every rerender
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    if (isLoading) {
        return (
            <NfumLoader loaded={!isLoading} /> /* NOSONAR: pure declarative usage */
        );
    }

    if (!initialSubmission) {
        return null;
    }

    return (
        <NGHContext.Provider>
            <ErrorBoundary onError={handleError}>
                <Wizard
                    initialSteps={steps}
                    wizardTitle={title}
                    initialData={initialSubmission}
                    onPreviousModalProps={{
                        title: platformMessages.wantToJump,
                        message: platformMessages.wantToJumpMessage,
                        status: 'warning',
                        icon: 'gw-error-outline',
                        confirmButtonText: translator(platformMessages.yes),
                        cancelButtonText: translator(platformMessages.no)
                    }}
                />
            </ErrorBoundary>
        </NGHContext.Provider>
    );
}

NGHRenewalWizard.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            policyNumber: PropTypes.string
        }),
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }).isRequired,
};

export default withRouter(withAuthenticationContext(withViewModelService(NGHRenewalWizard)));
