import { defineMessages } from 'react-intl';

export default defineMessages({
    loading: {
        id: 'quoteandbind.ngh.components.DocumentsLinks.loading',
        defaultMessage: 'Documents are being generated...'
    },
    error: {
        id: 'quoteandbind.ngh.components.DocumentsLinks.error',
        defaultMessage: 'Documents cannot be generated right now. Please try again later.'
    },
});
