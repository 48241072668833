import React, { useCallback } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import BicyclesPerLocation from './BicyclesPerLocation/BicyclesPerLocation';
import metadata from './BicyclesSummary.metadata.json5';
import styles from './BicyclesSummary.module.scss';

function BicyclesSummary(props) {
    const { homePropertiesData } = props;

    const generateHomePropertiesData = useCallback(() => {
        if (!homePropertiesData) {
            return {};
        }
        const homePropertiesOverrides = homePropertiesData.map((homeProperty, index) => {
            return {
                [`bicyclesPerLocation${index}`]: {
                    scheduleItems: homeProperty.coverages.schedules[0].scheduleItems
                        .filter((item) => item
                            .itemData?.ArticleGeographicLimit?.typeCodeValue === undefined)
                }
            };
        });
        return Object.assign({}, ...homePropertiesOverrides);
    }, [
        homePropertiesData
    ]);

    const overrideProps = {
        bicyclesHomeProperties: {
            data: homePropertiesData
        },
        ...generateHomePropertiesData()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentsMap: {
            bicyclesPerLocation: BicyclesPerLocation
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentsMap}
        />
    );
}

export default BicyclesSummary;
