import React, { useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Button } from '@jutro/components';
import styles from './DocumentLink.module.scss';

function DocumentLink(props) {
    const {
        name,
        docId,
        docSessionId,
        onDocumentClicked,
        docMimeType
    } = props;

    const getNameWithFormat = useCallback(() => {
        let docFormat = null;
        const mimeTypeParts = docMimeType?.split('/');
        if (mimeTypeParts) {
            docFormat = _.last(mimeTypeParts)?.toUpperCase();
        }
        return docFormat ? `${name} (${docFormat})` : name;
    }, [name, docMimeType]);

    const onLinkClicked = useCallback(() => {
        onDocumentClicked(docId, docSessionId, getNameWithFormat());
    }, [onDocumentClicked, docId, docSessionId, getNameWithFormat]);

    return (
        <div className={styles.nfumDocumentLinkContainer}>
            <Button
                id="doc-link"
                type="text"
                icon="gw-cloud-download"
                onClick={onLinkClicked}
                iconPosition="left"
            >
                { getNameWithFormat() }
            </Button>
        </div>
    );
}

DocumentLink.propTypes = {
    name: PropTypes.string.isRequired,
    docId: PropTypes.string.isRequired,
    onDocumentClicked: PropTypes.func.isRequired
};

export default DocumentLink;
