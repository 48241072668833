import React from 'react';
import { Button, InlineLoader } from '@jutro/components';

function NfumLoaderButton(props) {
    const {
        children,
        isLoading,
        ...otherProps
    } = props;

    const renderInlineLoader = () => {
        return isLoading ? <InlineLoader loading={isLoading} className="gw-inline-loader" /> : null;
    };

    return (
        <Button
            {...otherProps}
        >
            {children}
            {renderInlineLoader(isLoading)}
        </Button>
    );
}

export default NfumLoaderButton;
