import React, { useContext } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { useLobs } from 'nfum-portals-utils-react';
import messages from './PolicyDetailsSummary.messages';
import metadata from './PolicyDetailsSummary.metadata.json5';
import styles from './PolicyDetailsSummary.module.scss';

function PolicyDetailsSummary(props) { /* NOSONAR: pure declarative usage  */
    const { submissionVM } = props;
    const translator = useContext(TranslatorContext);
    const {
        isCaravanLine,
        isTravelLine,
        isAnimalLine,
        isPersonalAccidentLine,
        isBuildingsCov,
        isContentsCov,
        isFineArtAndCollectionsCov,
        isValuablesCov
    } = useLobs(submissionVM);

    const getCoverType = () => {
        const coverTypes = [];
        if (isBuildingsCov) {
            coverTypes.push(translator(messages.buildings));
        }
        if (isContentsCov) {
            coverTypes.push(translator(messages.contents));
        }
        if (isCaravanLine) {
            coverTypes.push(translator(messages.caravan));
        }
        if (isAnimalLine) {
            coverTypes.push(translator(messages.catdog));
        }
        if (isTravelLine) {
            coverTypes.push(translator(messages.travel));
        }
        if (isPersonalAccidentLine) {
            coverTypes.push(translator(messages.personalAccident));
        }
        if ((_.get(submissionVM.value, 'isBespokeHomeInsurance_NFUM'))) {
            if (isFineArtAndCollectionsCov) {
                coverTypes.push(translator(messages.fineArtAndCollection));
            }
            coverTypes.push(translator(messages.personalLegalExpenses));
            if (isValuablesCov) {
                coverTypes.push(translator(messages.valuables));
            }
        }
        return [...coverTypes].sort().join(', ');
    };

    const getAddress = () => {
        const address = [];
        const addressParts = ['addressLine1', 'addressLine2', 'addressLine3', 'city', 'county', 'postalCode'];
        addressParts.forEach((part) => {
            const partValue = _.get(submissionVM, `baseData.value.policyAddress.${part}`);
            if (partValue) {
                address.push(partValue);
            }
        });
        return address.join(', ');
    };

    const getPolicyDatesRange = () => {
        const { year, month, day } = _.get(submissionVM, 'baseData.value.periodStartDate');
        const startDate = moment(new Date(year, month, day)).format('DD MMMM YYYY');
        const { year: endYear, month: endMonth, day: endDay } = _.get(submissionVM, 'baseData.value.periodEndDate');
        const endDate = moment(new Date(endYear, endMonth, endDay)).format('DD MMMM YYYY');
        return `${startDate} to ${endDate}`;
    };

    const overrideProps = {
        '@field': {
            readOnly: true,
            className: 'propertyValue',
            labelClassName: 'propertyLabel'
        },
        policyType: {

            value: (_.get(submissionVM.value, 'isBespokeHomeInsurance_NFUM'))
                ? translator(messages.bespokeInsurance)
                : translator(messages.homeAndLifestyleInsurance)
        },
        coverType: {
            value: getCoverType()
        },
        address: {
            value: getAddress()
        },
        policyPeriodDateRange: {
            value: getPolicyDatesRange()
        },
        paymentType: {
            value: _.get(submissionVM, 'baseData.value.paymentMethod_NFUM') === 'cash'
                ? translator(messages.creditOrDebitCard)
                : translator(messages.directDebit)
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            model={submissionVM}
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
        />
    );
}

export default PolicyDetailsSummary;
