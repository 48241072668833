import { defineMessages } from 'react-intl';

export default defineMessages({
    fineArtsContainer: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsContainer',
        defaultMessage: 'Fine Art & Collections'
    },
    onlyMaximumCanBeAdded1: {
        id: 'endorsement.ngh.views.fineArtsCard.onlyMaximumCanBeAdded1',
        defaultMessage: 'Only a maximum of 5 new items can be added in this change, please '
    },
    contactYourAgent: {
        id: 'endorsement.ngh.views.fineArtsCard.contactYourAgent',
        defaultMessage: 'contact your agent'
    },
    onlyMaximumCanBeAdded2: {
        id: 'endorsement.ngh.views.fineArtsCard.onlyMaximumCanBeAdded2',
        defaultMessage: ' if you wish to add more.'
    },
    fineArtsLimitShort: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsLimitShort',
        defaultMessage: 'You can request cover for Fine Art & Collections worth more than { limit }.'
    },
    fineArtsDescriptionLong: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsDescriptionLong',
        defaultMessage: 'You can view Fine Art & Collections you currently have insured, amend the replacement cost(s) of your Fine Art & Collections or, if required, remove items.'
    },
    fineArtsLimitLong: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsLimitLong',
        defaultMessage: 'You can also request cover for other items such as Fine Art, Stamps, Medal and Coin Collections worth more than { limit }.'
    },
    upToFiveItems: {
        id: 'endorsement.ngh.views.fineArtsCard.upToFiveItems',
        defaultMessage: 'Up to five new items may be added.'
    },
    addItem: {
        id: 'endorsement.ngh.views.fineArtsCard.addItem',
        defaultMessage: 'ADD ITEM'
    },
    contactAgentInfoTitle: {
        id: 'endorsement.ngh.views.fineArtsCard.contactAgentInfoTitle',
        defaultMessage: 'About your Fine Art and Collections'
    },
    contactAgentInfoMainText: {
        id: 'endorsement.ngh.views.fineArtsCard.contactAgentInfoMainText',
        defaultMessage: 'Full details of your Fine Art and Collection(s) items are included within your policy documentation.'
    },
    contactAgentInfoExtraText: {
        id: 'endorsement.ngh.views.fineArtsCard.contactAgentInfoExtraText',
        defaultMessage: 'If you would like to discuss changes to your Fine Art and Collection please contact your agent.'
    },
    newlyAddedFineArts: {
        id: 'endorsement.ngh.views.fineArtsCard.newlyAddedFineArts',
        defaultMessage: 'Newly added fine art and collections'
    },
    existingFineArts: {
        id: 'endorsement.ngh.views.fineArtsCard.existingFineArts',
        defaultMessage: 'Fine art and collections already on your policy'
    },
    fineArts: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArts',
        defaultMessage: 'Fine Arts'
    },
    wineCollection: {
        id: 'endorsement.ngh.views.fineArtsCard.wineCollection',
        defaultMessage: 'Wine collection'
    },
    stampsMedalsAndCoins: {
        id: 'endorsement.ngh.views.fineArtsCard.stampsMedalsAndCoins',
        defaultMessage: 'Stamps, medals and coins collections'
    },
    otherCollections: {
        id: 'endorsement.ngh.views.fineArtsCard.otherCollections',
        defaultMessage: 'Other collections'
    }
});
