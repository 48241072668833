import React, { useCallback, useState, useContext } from 'react';
import {
    ViewModelForm
} from 'gw-portals-viewmodel-react';
import _ from 'lodash';
import moment from 'moment';
import { useValidation } from 'gw-portals-validation-react';
import { TranslatorContext } from '@jutro/locale';
import { wizardProps } from 'gw-portals-wizard-react';
import { ModalNext } from '@jutro/components';
import { useCurrency } from 'nfum-portals-utils-react';
import messages from './FineArtsModal.messages';
import metadata from './FineArtsModal.metadata.json5';
import styles from './FineArtsModal.module.scss';

function FineArtsModal(props) { /* NOSONAR: pure declarative usage  */
    const {
        isOpen,
        onResolve,
        fineArtVM,
        isEditMode = false,
        newFineArts = [],
        itemsToAdd = [],
        isExisting,
        viewModelService
    } = props;
    const translator = useContext(TranslatorContext);
    const { isComponentValid, onValidate, disregardFieldValidation } = useValidation('FineArtsModal');
    const category = Object.freeze({
        FINE_ART: 'FineArt',
        WINES: 'WineCollections',
        STAMPS_MEDALS_COINS: 'StampsMedalsCoins',
        OTHER_COLLECTIONS: 'OtherCollections'
    });
    const OTHER_TYPE = 'Other';
    const NUMERIC_FIELD_MAX_LENGTH = 16;
    const DIGITS_REGEXP = '^[0-9,.]+$|^$';
    const currencyFormatter = useCurrency();
    const TEXT_FIELD_MAX_LENGTH = 255;
    const [isReplacementCostMaxLength, setIsReplacementCostMaxLength] = useState(false);
    const [isReplacementCostFormatValid, setIsReplacementCostFormatValid] = useState(true);
    const [isDescriptionMaxLength, setIsDescriptionMaxLength] = useState(false);
    const [formVM, updateFormVM] = useState(fineArtVM);
    const maxDate = moment();

    const writeValue = useCallback(
        (value, path) => {
            const newFormVM = viewModelService.clone(formVM);
            _.set(newFormVM.value, path, value);
            updateFormVM(newFormVM);
        },
        [updateFormVM, viewModelService, formVM]
    );
    const onOtherCollectionsTypeChange = useCallback((value, path) => {
        _.set(formVM.value, 'collectionDescription', undefined);
        setIsDescriptionMaxLength(false);
        setIsReplacementCostMaxLength(false);
        setIsReplacementCostFormatValid(true);
        writeValue(value, path);
    }, [formVM, writeValue]);

    const handleClose = (data) => () => {
        onResolve(data);
    };
    const getTypes = useCallback((typecode) => ({
        code: typecode.code,
        name: translator({
            id: typecode.name,
            defaultMessage: typecode.code
        })
    }), [translator]);

    const getAvailableCategoryTypes = useCallback(() => {
        const specifiedFineArtsTypelist = viewModelService.getTypelistNFUM('pc', 'typekey.FineArtsSpecItem_NFUM');
        return specifiedFineArtsTypelist.codes.map(getTypes)
            .sort((a, b) => a.name.localeCompare(b.name));
    }, [viewModelService, getTypes]);

    const getAvailableCollectionTypes = useCallback((filterName) => {
        const availableCollectionTypes = viewModelService.getTypelistNFUM('pc', 'typekey.TypeOfCollection_NFUM');
        return availableCollectionTypes.filters
            .find((x) => x.name === filterName).codes.map(getTypes);
    }, [viewModelService, getTypes]);

    const isDigitsOnly = useCallback((value) => {
        const regex = new RegExp(DIGITS_REGEXP);
        return regex.test(value);
    }, []);

    const validateReplacementCostField = useCallback((value, path) => {
        if (!isDigitsOnly(value)) return;
        writeValue(value, path);
        if (value.length >= NUMERIC_FIELD_MAX_LENGTH) {
            setIsReplacementCostMaxLength(true);
        } else {
            setIsReplacementCostMaxLength(false);
        }
    }, [setIsReplacementCostMaxLength, writeValue, isDigitsOnly]);

    const validateFormat = useCallback((value) => {
        if (!currencyFormatter.isValidFormat(value)) {
            setIsReplacementCostFormatValid(false);
        } else {
            setIsReplacementCostFormatValid(true);
        }
    }, [currencyFormatter]);

    const validateDescriptionFieldLength = useCallback((value, path) => {
        writeValue(value, path);
        if (value.length >= TEXT_FIELD_MAX_LENGTH) {
            setIsDescriptionMaxLength(true);
        } else {
            setIsDescriptionMaxLength(false);
        }
    }, [setIsDescriptionMaxLength, writeValue]);

    const getValuablesReplCostImmediateInfoMessages = () => {
        let validationMessages = [];
        if (isReplacementCostMaxLength) {
            validationMessages = [
                ...validationMessages,
                translator(messages.maxAllowedFieldLengthReached)
            ];
        }
        return validationMessages;
    };

    const getDescriptionValidationMessages = () => {
        if (isDescriptionMaxLength) {
            return [
                translator(messages.maxAllowedFieldLengthReached)
            ];
        }
        return [];
    };

    const getValuablesReplCostValidationMessages = () => {
        let validationMessages = [];
        if (!isReplacementCostFormatValid) {
            validationMessages = [
                ...validationMessages,
                translator(messages.enterOnlyNumericalValues)
            ];
        }
        return validationMessages;
    };

    const getMidnightFromMomentDate = useCallback((date) => {
        return date.toDate().setHours(0, 0, 0, 0);
    }, []);

    const onCategoryChange = useCallback((value, path) => {
        setIsDescriptionMaxLength(false);
        setIsReplacementCostMaxLength(false);
        setIsReplacementCostFormatValid(true);
        const newFormVM = viewModelService.clone(formVM);
        const formPaths = ['fineArtType', 'description', 'valuationDate', 'agreedValue', 'collectionType', 'sumInsured', 'collectionKept'];
        formPaths.forEach((formPath) => {
            disregardFieldValidation(formPath);
            _.set(newFormVM.value, formPath, undefined);
        });
        _.set(newFormVM.value, path, value);
        updateFormVM(newFormVM);
    }, [disregardFieldValidation, formVM, viewModelService]);

    const onValueChange = (value, path) => {
        switch (path) {
            case 'replacementCost':
                validateReplacementCostField(value, path);
                break;
            case 'collectionDescription':
                validateDescriptionFieldLength(value, path);
                break;
            case 'agreedValue':
                validateReplacementCostField(value, path);
                break;
            case 'description':
                validateDescriptionFieldLength(value, path);
                break;
            case 'sumInsured':
                validateReplacementCostField(value, path);
                break;
            case 'collectionType':
                onOtherCollectionsTypeChange(value, path);
                break;
            case 'itemType':
                onCategoryChange(value, path);
                break;
            default: writeValue(value, path);
        }
    };

    const overrideProps = {
        '@field': {
            showOptional: true
        },
        fineArtsDescriptionWrapper: {
            visible: !isEditMode
        },
        fineArtsAdditionalDescriptionWrapper: {
            visible: !isEditMode
        },
        cancelButton: {
            visible: _.get(formVM.value, 'itemType') !== undefined
        },
        saveItemButton: {
            visible: isEditMode && isComponentValid
        },
        saveAndAddButton: {
            visible: !isEditMode && isComponentValid && newFineArts.length + itemsToAdd.length <= 3
        },
        addItemButton: {
            visible: !isEditMode && isComponentValid && newFineArts.length + itemsToAdd.length <= 4
        },
        limitReachedInfoContainer: {
            visible: newFineArts.length + itemsToAdd.length > 4
        },
        replacementCost: {
            visible: isEditMode && isExisting,
            path: [category.FINE_ART].includes(_.get(formVM.value, 'itemType')) ? 'agreedValue' : 'sumInsured',
            onValueChange: onValueChange,
            onBlur: validateFormat,
            validationMessages: getValuablesReplCostValidationMessages(),
            immediateInfoMessages: getValuablesReplCostImmediateInfoMessages(),
            maxLength: NUMERIC_FIELD_MAX_LENGTH,
            value: [category.FINE_ART].includes(_.get(formVM.value, 'itemType')) ? _.get(formVM.value, 'agreedValue') : _.get(formVM.value, 'sumInsured')
        },
        category: {
            availableValues: getAvailableCategoryTypes(),
            visible: !isEditMode || (isEditMode && !isExisting),
            disabled: (isEditMode && !isExisting) || newFineArts.length + itemsToAdd.length > 4,
            onValueChange: onValueChange,
        },
        type: {
            visible: (!isEditMode || !isExisting) && [category.FINE_ART].includes(_.get(formVM.value, 'itemType')),
            onValueChange: onValueChange,
        },
        collectionType: {
            availableValues: getAvailableCollectionTypes([category.STAMPS_MEDALS_COINS].includes(_.get(formVM.value, 'itemType'))
                ? 'StampsCoinCollection' : 'OtherTypeofCollection'),
            visible: (!isEditMode || !isExisting) && [category.OTHER_COLLECTIONS, category.STAMPS_MEDALS_COINS].includes(_.get(formVM.value, 'itemType')),
            onValueChange: onValueChange,
        },
        sumInsured: {
            visible: (!isEditMode || !isExisting) && [category.OTHER_COLLECTIONS, category.WINES, category.STAMPS_MEDALS_COINS].includes(_.get(formVM.value, 'itemType')),
            onValueChange: onValueChange,
            onBlur: validateFormat,
            validationMessages: getValuablesReplCostValidationMessages(),
            immediateInfoMessages: getValuablesReplCostImmediateInfoMessages(),
            maxLength: NUMERIC_FIELD_MAX_LENGTH,
            value: _.get(formVM.value, 'sumInsured')
        },
        collectionKept: {
            visible: (!isEditMode || !isExisting) && [category.STAMPS_MEDALS_COINS].includes(_.get(formVM.value, 'itemType')),
            onValueChange: onValueChange,
        },
        description: {
            visible: (!isEditMode || !isExisting) && [category.FINE_ART].includes(_.get(formVM.value, 'itemType')),
            onValueChange: onValueChange,
            immediateInfoMessages: getDescriptionValidationMessages(),
            maxLength: TEXT_FIELD_MAX_LENGTH,
            value: _.get(formVM.value, 'description')
        },
        valuationDate: {
            visible: (!isEditMode || !isExisting) && [category.FINE_ART, category.WINES, category.STAMPS_MEDALS_COINS].includes(_.get(formVM.value, 'itemType')),
            maxDate: getMidnightFromMomentDate(maxDate),
            onValueChange: onValueChange,
            popperPlacement: 'top-start',
            format: 'long'
        },
        agreedValue: {
            visible: (!isEditMode || !isExisting) && [category.FINE_ART].includes(_.get(formVM.value, 'itemType')),
            onValueChange: onValueChange,
            onBlur: validateFormat,
            validationMessages: getValuablesReplCostValidationMessages(),
            immediateInfoMessages: getValuablesReplCostImmediateInfoMessages(),
            maxLength: NUMERIC_FIELD_MAX_LENGTH,
            value: _.get(formVM.value, 'agreedValue')
        },
        collectionDescription: {
            visible: (!isEditMode || !isExisting) && _.get(formVM.value, 'itemType') === category.OTHER_COLLECTIONS
                && _.get(formVM.value, 'collectionType') === OTHER_TYPE,
            onValueChange: onValueChange,
            immediateInfoMessages: getDescriptionValidationMessages(),
            maxLength: TEXT_FIELD_MAX_LENGTH
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onAddItem: handleClose({ type: 'ADD', data: formVM }),
            onSaveItem: handleClose({ type: 'SAVE', data: formVM }),
            onSaveAndAdd: handleClose({ type: 'REOPEN', data: formVM }),
            onCancel: handleClose({ type: 'CANCEL' }),
        }
    };
    return (
        <ModalNext
            isOpen={isOpen}
            onRequestClose={() => onResolve({ type: 'CANCEL' })}
        >
            <ViewModelForm
                model={formVM}
                uiProps={metadata.componentContent}
                onValidationChange={onValidate}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                className={styles.modalWrapper}
            />
        </ModalNext>
    );
}

FineArtsModal.propTypes = wizardProps;
export default FineArtsModal;
