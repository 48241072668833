import React, {
    useCallback, useContext, useEffect
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { getConfigValue } from '@jutro/config';
import {
    MTA_STEPS,
    BESPOKE_MTA_STEPS
} from 'nfum-portals-utils-react';
import { useTagManager } from 'nfum-capability-policychange-common-react';
import metadata from './SuccessPage.metadata.json5';
import styles from './SuccessPage.module.scss';
import messages from './SuccessPage.messages';

function SuccessPage(props) {
    const { wizardData: policySubmissionVM } = props;
    const translator = useContext(TranslatorContext);
    const {
        pushFormConfirmationInfo,
        pushLinkClickInfo
    } = useTagManager();

    // #region GTM EVENTS

    useEffect(() => {
        const isBespoke = _.get(policySubmissionVM.value, 'isBespokeHomeInsurance_NFUM');
        pushFormConfirmationInfo(policySubmissionVM, isBespoke
            ? BESPOKE_MTA_STEPS.SUCCESS
            : MTA_STEPS.SUCCESS);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // #endregion GTM EVENTS

    const prepareStartDate = useCallback(() => {
        const periodStartDate = _.get(policySubmissionVM, 'baseData.effectiveDate.value');
        if (periodStartDate) {
            const { year, month, day } = periodStartDate;
            const date = new Date(year, month, day);
            const dayName = date.toLocaleDateString('en-US', { weekday: 'long' });
            const monthName = date.toLocaleDateString('en-US', { month: 'long' });
            return `${dayName} ${monthName} ${day} ${year}`;
        }
        return '';
    }, [policySubmissionVM]);

    const preparePolicyNumber = useCallback(() => {
        const policyNumber = _.get(policySubmissionVM, 'policyNumber.value');
        return policyNumber || '';
    }, [policySubmissionVM]);

    const prepareThankYouText = useCallback(() => {
        const isPaperMethodSelectedNFUM = _.get(policySubmissionVM.value, 'baseData.isPaperMethodSelected_NFUM');
        if (isPaperMethodSelectedNFUM) {
            return translator(messages.thankYouWithPaperDocuments);
        }
        return translator(messages.thankYouWithoutPaperDocuments);
    }, [policySubmissionVM, translator]);

    const resolvers = {
        resolveClassNameMap: styles
    };

    const overrideProps = {
        PolicyChangeSuccessPageTransactionText: {
            content: prepareThankYouText()
        },
        PolicyChangeSuccessPageTransactionPolicyNumber: {
            content: preparePolicyNumber()
        },
        PolicyChangeSuccessPageTransactionPolicyStartDate: {
            content: prepareStartDate()
        }
    };

    const returnToDCSDashboard = useCallback(() => {
        const backToDashboardURL = getConfigValue('JUTRO_BACK_TO_DASHBOARD_PATH', '/');
        pushLinkClickInfo(translator(messages.continue), backToDashboardURL);
        window.location.replace(backToDashboardURL);
    }, [pushLinkClickInfo, translator]);

    const onNext = useCallback(async () => {
        returnToDCSDashboard();
    }, [returnToDCSDashboard]);

    return (
        <WizardPage
            onNext={onNext}
            showPrevious={false}
            showCancel={false}
            nextLabel={messages.continue}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policySubmissionVM}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

SuccessPage.propTypes = wizardProps;
export default withAuthenticationContext(SuccessPage);
