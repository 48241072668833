import { defineMessages } from 'react-intl';

export default defineMessages({
    specifiedValuables: {
        id: 'endorsement.ngh.views.valuablesCard.specifiedValuables',
        defaultMessage: 'Specified valuables'
    },
    valuablesCtaDescription: {
        id: 'endorsement.ngh.views.valuablesCard.valuablesCtaDescription',
        defaultMessage: 'Here you can add or change any specified valuables worth '
    },
    moreThan: {
        id: 'endorsement.ngh.views.valuablesCard.moreThan',
        defaultMessage: 'more than '
    },
    valuablesAdditionalDescription1: {
        id: 'endorsement.ngh.views.valuablesCard.valuablesAdditionalDescription1',
        defaultMessage: 'We use the term valuables to cover watches, jewellery, medals, coins and works of art as well as high value laptops and portable electronics. Take a look at the '
    },
    fullList: {
        id: 'endorsement.ngh.views.valuablesCard.fullList',
        defaultMessage: 'full list'
    },
    valuablesAdditionalDescription2: {
        id: 'endorsement.ngh.views.valuablesCard.valuablesAdditionalDescription3',
        defaultMessage: ' of what we consider a valuable.'
    },
    valuablesMaxCoverDescription1: {
        id: 'endorsement.ngh.views.valuablesCard.valuablesMaxCoverDescription1',
        defaultMessage: 'The maximum cover we will provide for all specified and unspecified valuables combined is '
    },
    valuablesMaxCoverDescription2: {
        id: 'endorsement.ngh.views.valuablesCard.valuablesMaxCoverDescription2',
        defaultMessage: '. If you have more than this amount please '
    },
    contactUs: {
        id: 'endorsement.ngh.views.valuablesCard.contactUs',
        defaultMessage: 'contact us.'
    },
    edit: {
        id: 'endorsement.ngh.views.valuablesCard.edit',
        defaultMessage: 'Edit'
    },
    delete: {
        id: 'endorsement.ngh.views.valuablesCard.delete',
        defaultMessage: 'Delete'
    },
    addItem: {
        id: 'endorsement.ngh.views.valuablesCard.addItem',
        defaultMessage: 'Add item'
    },
    valuablesCategoryColumn: {
        id: 'endorsement.ngh.views.valuablesCard.valuablesCategoryColumn',
        defaultMessage: 'Category'
    },
    valuablesDescriptionColumn: {
        id: 'endorsement.ngh.views.valuablesCard.valuablesDescriptionColumn',
        defaultMessage: 'Description'
    },
    valuablesValueColumn: {
        id: 'endorsement.ngh.views.valuablesCard.replacementCostColumn',
        defaultMessage: 'Replacement cost'
    },
    valuablesInsideOutsideColumn: {
        id: 'endorsement.ngh.views.valuablesCard.coverTypeColumn',
        defaultMessage: 'Cover Type'
    },
    keptInBankColumn: {
        id: 'endorsement.ngh.views.valuablesCard.keptInBankColumn',
        defaultMessage: 'Kept in bank?'
    },
    ExceededCoverLimit: {
        id: 'endorsement.ngh.views.valuablesCard.Exceeded1CoverLimit',
        defaultMessage: 'Your have exceeded your cover limit'
    },
    ExceededCoverLimitContent: {
        id: 'endorsement.ngh.views.valuablesCard.ExceededCover1LimitContent',
        defaultMessage: 'Sorry, you have exceeded the specified valuables limit for your cover. Please edit the items or get in touch with us below and we can discuss your options with you.'
    },
    ValuableAwayFromHomeMessageTitle: {
        id: 'endorsement.ngh.views.valuablesCard.valuableAwayFromHomeMessageTitle',
        defaultMessage: 'Your valuables away from the home cover is still added'
    },
    ValuableAwayFromHomeMessage: {
        id: 'endorsement.ngh.views.valuablesCard.valuableAwayFromHomeMessage',
        defaultMessage: 'Contents away from home cover is still included as part of your policy.  You are able to remove this cover if no longer required'
    },
    inside: {
        id: 'endorsement.ngh.views.valuablesCard.inside',
        defaultMessage: 'Inside'
    },
    both: {
        id: 'endorsement.ngh.views.valuablesCard.both',
        defaultMessage: 'Both'
    },
    yes: {
        id: 'endorsement.ngh.views.valuablesCard.yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'endorsement.ngh.views.valuablesCard.no',
        defaultMessage: 'No'
    },
    insideHome: {
        id: 'endorsement.ngh.views.valuablesCard.insideHome',
        defaultMessage: 'Inside the home'
    },
    insideOutsideHome: {
        id: 'rendorsement.ngh.views.valuablesCard.insideOutsideHome',
        defaultMessage: 'Both inside and outside the home (UK)'
    },
});
