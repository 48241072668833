import { useErrorHandler, useCms } from 'nfum-portals-utils-react';

const useRenewalCms = (marketingInfo, setMarketingInfo) => {
    const { handleNotBlockingError } = useErrorHandler();
    const RENEWAL_URL_PREFIX = '/bridge/account-management/renewal';
    const { createCmsSiteMapPromise, createCmsContentPromise } = useCms();

    const RENEWAL_CMS_CONFIG = [
        // NGH
        {
            productId: 'ngh',
            portalPageId: 'renewal-summary',
            cmsPageId: 'renewal-summary'
        },
        {
            productId: 'ngh',
            portalPageId: 'renewal-payment',
            cmsPageId: 'renewal-payment'
        },
        {
            productId: 'ngh',
            portalPageId: 'renewal-payment-confirmation',
            cmsPageId: 'renewal-payment-confirmation'
        },
        // Bespoke
        {
            productId: 'bespoke',
            portalPageId: 'renewal-summary',
            cmsPageId: 'renewal-summary'
        },
        {
            productId: 'bespoke',
            portalPageId: 'renewal-payment',
            cmsPageId: 'renewal-payment'
        },
        {
            productId: 'bespoke',
            portalPageId: 'renewal-payment-confirmation',
            cmsPageId: 'renewal-payment-confirmation'
        }
    ];

    const getCmsContentForRenewal = (productCode) => {
        createCmsSiteMapPromise().then((siteMapInfo) => {
            const allPagesPromises = [];
            RENEWAL_CMS_CONFIG
                .filter((configItem) => configItem.productId === productCode)
                .forEach((page) => {
                    const siteId = siteMapInfo?.find((site) => site.url === `${RENEWAL_URL_PREFIX}/${productCode}/${page.cmsPageId}/`)?.id;
                    if (siteId) {
                        allPagesPromises.push(createCmsContentPromise(siteId));
                    }
                });
            const newMarketingInfo = {};
            Promise.all(allPagesPromises).then((allPagesCmsContent) => {
                allPagesCmsContent.forEach((pageContent) => {
                    RENEWAL_CMS_CONFIG
                        ?.filter((page) => page.cmsPageId === pageContent.routeSegment
                            && page.productId === productCode).forEach((configItem) => {
                            newMarketingInfo[configItem.portalPageId] = pageContent;
                        });
                });
                setMarketingInfo({
                    ...marketingInfo,
                    ...newMarketingInfo
                });
            });
        }).catch((err) => {
            handleNotBlockingError(err);
        });
    };

    return {
        getCmsContentForRenewal
    };
};

export default useRenewalCms;
