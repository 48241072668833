/* eslint-disable */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { SwitchField } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import classNames from 'classnames';

import styles from './NfumSwitchField.module.scss';

function NfumSwitchField(props) {
    const translator = useContext(TranslatorContext);

    const {
        description,
        extraDescription,
        label,
        labelVisible
    } = props;

    return (
        <div className={styles.nfumSwitchContainer}>
            { description && <p className={styles.switchDescription}>{translator(description)}</p> }
            { extraDescription && <p className={styles.switchExtraDescription}>{translator(extraDescription)}</p> }
            <div className={styles.nfumSwitchInnerContainer}>
                <SwitchField
                    {...props}
                    hideLabel="true"
                />
                <p className={classNames({ [styles.labelHidden]: !labelVisible, [styles.label]: true })}>{translator(label)}</p>
            </div>
        </div>
    );
}

NfumSwitchField.propTypes = {
    description: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string
    }),
    label: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string
    }),
    labelVisible: PropTypes.bool
};

NfumSwitchField.defaultProps = {
    labelVisible: true
};

export default NfumSwitchField;
