import { defineMessages } from 'react-intl';

export default defineMessages({
    contentsInsurance: {
        id: 'renewal.ngh.views.renewal-summary.contentsSummary.contentsInsurance',
        defaultMessage: 'Contents insurance'
    },
    noOfBedrooms: {
        id: 'renewal.ngh.views.renewal-summary.contentsSummary.noOfBedrooms',
        defaultMessage: 'Number of bedrooms'
    },
    contentsLevelLimit: {
        id: 'renewal.ngh.views.renewal-summary.contentsSummary.contentsLevelLimit',
        defaultMessage: 'Contents level limit'
    },
    contentsSumInsured: {
        id: 'renewal.ngh.views.renewal-summary.contentsSummary.contentsSumInsured',
        defaultMessage: 'Contents sum insured'
    },
    contentsReplacementCost: {
        id: 'renewal.ngh.views.renewal-summary.contentsSummary.contentsReplacementCost',
        defaultMessage: 'Contents Replacement cost'
    },
    contentsAccidentalDamage: {
        id: 'renewal.ngh.views.renewal-summary.contentsSummary.contentsAccidentalDamage',
        defaultMessage: 'Contents accidental damage'
    },
    confirmationText1: {
        id: 'renewal.ngh.views.renewal-summary.contentsSummary.confirmationText1',
        defaultMessage: 'You have confirmed that:'
    },
    confirmationText2: {
        id: 'renewal.ngh.views.renewal-summary.contentsSummary.confirmationText2',
        defaultMessage: 'The buildings you want to insure have never sustained loss of damage by flood, subsidence, heave or landslip'
    },
    confirmationText4: {
        id: 'renewal.ngh.views.renewal-summary.contentsSummary.confirmationText4',
        defaultMessage: 'The buildings you want to insure are not at immediate risk of flooding'
    },
    confirmationText6: {
        id: 'renewal.ngh.views.renewal-summary.contentsSummary.confirmationText6',
        defaultMessage: 'You don\'t use your home for business purposes other than as a home office'
    },
    confirmationText7: {
        id: 'renewal.ngh.views.renewal-summary.contentsSummary.confirmationText7',
        defaultMessage: 'No customers or clients visit your home for business purposes'
    },
    confirmationText3: {
        id: 'renewal.ngh.views.renewal-summary.contentsSummary.confirmationText3',
        defaultMessage: 'The buildings you want to insure have sustained loss of damage by flood, subsidence, heave or landslip'
    },
    confirmationTex5: {
        id: 'renewal.ngh.views.renewal-summary.contentsSummary.confirmationTex5',
        defaultMessage: 'The buildings you want to insure are at immediate risk of flooding'
    },
    excess: {
        id: 'renewal.ngh.views.renewal-summary.contentsSummary.excesses',
        defaultMessage: 'Excesses'
    },
    leakingAndFrozenWater: {
        id: 'renewal.ngh.views.renewal-summary.contentsSummary.leakingAndFrozenWater',
        defaultMessage: 'Leaking and Frozen Water'
    },
    subsidence: {
        id: 'renewal.ngh.views.renewal-summary.contentsSummary.subsidence',
        defaultMessage: 'Subsidence'
    },
    flood: {
        id: 'renewal.ngh.views.renewal-summary.contentsSummary.flood',
        defaultMessage: 'Flood'
    },
    voluntary: {
        id: 'renewal.ngh.views.renewal-summary.contentsSummary.voluntary',
        defaultMessage: 'Voluntary'
    },
    compulsaryAllClaims: {
        id: 'renewal.ngh.views.renewal-summary.contentsSummary.compulsaryAllClaims',
        defaultMessage: 'Compulsory (All claims)'
    },
    seeDocuments: {
        id: 'renewal.ngh.views.renewal-summary.contentsSummary.seeDocuments',
        defaultMessage: 'See documents'
    },
    notSelected: {
        id: 'renewal.ngh.views.renewal-summary.contentsSummary.notSelected',
        defaultMessage: 'Not selected'
    },
    added: {
        id: 'renewal.ngh.views.renewal-summary.contentsSummary.added',
        defaultMessage: 'Added'
    },
    tenantsInteriorDecorationCov: {
        id: 'renewal.ngh.views.renewal-summary.contentsSummary.tenantsInteriorDecorationCov',
        defaultMessage: 'Tenants interior decorations'
    }
});
