import React, { useCallback } from 'react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useErrorHandler } from 'nfum-portals-utils-react';
import commonMessages from 'nfum-capability-policychange-common-react/NGHPolicyChange.messages';
import metadata from './PolicyChangesStartConfirmationPage.metadata.json5';
import styles from './PolicyChangesStartConfirmationPage.module.scss';
import './PolicyChangesStartConfirmationPage.messages';

function PolicyChangesStartConfirmationPage(props) {
    const { authHeader } = useAuthentication();
    const { EndorsementService } = useDependencies('EndorsementService');
    const { wizardData: policyChangeVM, updateWizardData } = props;
    const { handleError } = useErrorHandler();

    const onNext = useCallback(async () => {
        try {
            policyChangeVM.value = await EndorsementService.createOrSave(
                [policyChangeVM.value],
                authHeader
            );
        } catch (e) {
            handleError(e, policyChangeVM.value.policyNumber);
        }
        return policyChangeVM;
    }, [
        EndorsementService,
        authHeader,
        policyChangeVM,
        handleError
    ]);

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <WizardPage
            onNext={onNext}
            showPrevious={false}
            showCancel={false}
            nextLabel={commonMessages.start}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                classNameMap={resolvers.resolveClassNameMap}
                onModelChange={updateWizardData}
            />
        </WizardPage>
    );
}

PolicyChangesStartConfirmationPage.propTypes = wizardProps;

export default PolicyChangesStartConfirmationPage;
