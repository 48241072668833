import React, { useContext, useCallback } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { useCurrency } from 'nfum-portals-utils-react';
import BespokeAddedValuables from '../BespokeAddedValuables/BespokeAddedValuables';
import BespokeValuablesSummaryRemovedItems from '../BespokeSummaryRemovedItems/BespokeSummaryRemovedItems';
import metadata from './BespokeValuablesSummary.metadata.json5';
import styles from './BespokeValuablesSummary.module.scss';

function BespokeValuablesSummary(props) {
    const {
        data: valuablesModel
    } = props;
    const currencyFormatter = useCurrency();
    const translator = useContext(TranslatorContext);

    const getItemReplacementCost = useCallback((value) => {
        return currencyFormatter.formatCurrency(value.itemData.ArticleReplacementCost.bigDecimal);
    }, [currencyFormatter]);

    const truncateString = useCallback((str) => {
        if (str.length > 60) {
            return str.substring(0, 59);
        }
        return str;
    }, []);

    const getArticleValuableType = useCallback((articleSubType) => {
        return articleSubType !== undefined ? translator({
            id: `typekey.SpecifiedValuableType_NFUM.${articleSubType}`,
            defaultMessage: articleSubType
        }) : '';
    }, [translator]);

    const getArticleMakeType = useCallback((articleSubType) => {
        return articleSubType !== undefined ? translator({
            id: `typekey.SpecfdValuableMake_NFUM.${articleSubType}`,
            defaultMessage: articleSubType
        }) : '';
    }, [translator]);

    const getHeaderDescription = useCallback((value) => {
        const description = value.itemData.ArticleDescription.stringValue;
        if (value.itemData.ArticleType.typeCodeValue === 'Guns' || value.itemData.ArticleType.typeCodeValue === 'Watches') {
            return `${getArticleMakeType(value.itemData.ArticleMakeType.typeCodeValue)},${truncateString(description)}`;
        }
        if (value.itemData.ArticleType.typeCodeValue === 'JewelleryAndGemstones') {
            return `${getArticleValuableType(value.itemData.ArticleSubType.typeCodeValue)},${truncateString(description)}`;
        }
        return description;
    }, [getArticleMakeType,
        getArticleValuableType,
        truncateString
    ]);

    const generateOverrides = useCallback(() => {
        if (!valuablesModel) return Object.assign({});

        const jewelsOverrides = valuablesModel.jewels.edited
            .map((item, index) => {
                return {
                    [`changedJewelleryTable${index}`]: {
                        data: [
                            {
                                name: 'Replacement cost',
                                oldValue: getItemReplacementCost(item.prev),
                                newValue: getItemReplacementCost(item.new)
                            }
                        ],
                        title: getHeaderDescription(item.new)
                    }
                };
            });

        const watchOverrides = valuablesModel.watches.edited.map((item, index) => {
            return {
                [`changedWatchTable${index}`]: {
                    data: [
                        {
                            name: 'Replacement cost',
                            oldValue: getItemReplacementCost(item.prev),
                            newValue: getItemReplacementCost(item.new)
                        }
                    ],
                    title: getHeaderDescription(item.new)
                }
            };
        });

        const fursOverrides = valuablesModel.furs.edited.map((item, index) => {
            return {
                [`changedFurTable${index}`]: {
                    data: [
                        {
                            name: 'Replacement cost',
                            oldValue: getItemReplacementCost(item.prev),
                            newValue: getItemReplacementCost(item.new)
                        }
                    ],
                    title: getHeaderDescription(item.new)
                }
            };
        });

        const gunsOverrides = valuablesModel.guns.edited.map((item, index) => {
            return {
                [`changedGunTable${index}`]: {
                    data: [
                        {
                            name: 'Replacement cost',
                            oldValue: getItemReplacementCost(item.prev),
                            newValue: getItemReplacementCost(item.new)
                        }
                    ],
                    title: getHeaderDescription(item.new)
                }
            };
        });

        const changedValuableOverrides = valuablesModel.valuable.edited.map((item, index) => {
            return {
                [`changedValuableTable${index}`]: {
                    data: [
                        {
                            name: 'Replacement cost',
                            oldValue: getItemReplacementCost(item.prev),
                            newValue: getItemReplacementCost(item.new)
                        }
                    ],
                    title: getHeaderDescription(item.new)
                }
            };
        });

        const valuablesAddedOverrides = valuablesModel.collections
            .filter((x) => x.added.length > 0)
            .map((item, index) => {
                return {
                    [`valuablesAddedItems${index}`]: {
                        addedItemsModel: item,
                        index: index
                    }
                };
            });

        const valuablesRemovedOverrides = valuablesModel.collections
            .filter((x) => x.removed.length > 0)
            .map((item, index) => {
                return {
                    [`bespokeSummaryRemovedItems${index}`]: {
                        removedItemsModel: item,
                        index: index
                    }
                };
            });

        return Object.assign({},
            ...valuablesAddedOverrides,
            ...valuablesRemovedOverrides,
            ...changedValuableOverrides,
            ...jewelsOverrides,
            ...fursOverrides,
            ...watchOverrides,
            ...gunsOverrides);
    }, [
        valuablesModel,
        getItemReplacementCost,
        getHeaderDescription
    ]);

    const renderTitle = () => {
        return 'Replacement cost';
    };

    const renderValue = (item, index, metadataProps) => {
        const { path } = metadataProps;
        return item[path];
    };

    const overrideProps = {
        addedValuables: {
            data: valuablesModel.added
        },
        changedValuables: {
            data: valuablesModel.edited
        },
        removedValuables: {
            data: valuablesModel.removed
        },
        valuablesAddedContainer: {
            visible: !_.isEmpty(valuablesModel.collections.filter((x) => x.added.length > 0))
        },
        valuablesChangedContainer: {
            visible: !_.isEmpty(valuablesModel.collections.filter((x) => x.edited.length > 0))
        },
        changedJewelleryTables: {
            data: valuablesModel.jewels.edited
        },
        changedJewelleryCategory: {
            content: 'Jewellery and Gemstones'
        },
        changedJewelleryContainer: {
            visible: valuablesModel.jewels.edited.length > 0
        },
        changedWatchContainer: {
            visible: valuablesModel.watches.edited.length > 0
        },
        changedWatchCategory: {
            content: 'Watches'
        },
        changedWatchTables: {
            data: valuablesModel.watches.edited
        },
        changedGunContainer: {
            visible: valuablesModel.guns.edited.length > 0
        },
        changedGunCategory: {
            content: 'Guns'
        },
        changedGunTables: {
            data: valuablesModel.guns.edited
        },
        changedFurContainer: {
            visible: valuablesModel.furs.edited.length > 0
        },
        changedFurCategory: {
            content: 'Furs'
        },
        changedFurTables: {
            data: valuablesModel.furs.edited
        },
        valuablesRemoved: {
            data: valuablesModel.collections.filter((x) => x.removed.length > 0)
        },
        valuablesRemovedContainer: {
            visible: !_.isEmpty(valuablesModel.collections.filter((x) => x.removed.length > 0))
        },
        valuablesAdded: {
            data: valuablesModel.collections.filter((x) => x.added.length > 0)
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            renderValue,
            renderTitle
        },
        resolveComponentMap: {
            valuablesAddedItems: BespokeAddedValuables,
            bespokeSummaryRemovedItems: BespokeValuablesSummaryRemovedItems
        }
    };

    return (
        <ViewModelForm
            model={valuablesModel}
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            overrideProps={overrideProps}
        />
    );
}

export default BespokeValuablesSummary;
