function getDisplayableErrorMessage(error) {
    const GW_ERROR_MESSAGE_SUFFIX = 'ErrorMessage: ';
    const errorMsg = error.baseError;
    const errorMessageParts = errorMsg.split(GW_ERROR_MESSAGE_SUFFIX);
    return errorMessageParts[errorMessageParts.length - 1];
}

export default {
    getDisplayableErrorMessage
};
