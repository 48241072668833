import React, { useCallback, useContext } from 'react';
import { ViewModelForm, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import usePropertyTitle from '../../../hooks/usePropertyTitle';
import metadata from './BuildingsSummary.metadata.json5';
import styles from './BuildingsSummary.module.scss';
import messages from './BuildingsSummary.messages';

function BuildingsSummary(props) {
    const {
        homePropertiesData, homeLine,
        onClickSeeDocuments, submissionVM
    } = props;
    const translator = useContext(TranslatorContext);
    const { getPropertyTitle } = usePropertyTitle();
    const viewModelService = useContext(ViewModelServiceContext);
    const buildingsIds = Object.freeze({
        CONTENTS_ONLY: 'contentsOnly',
        HOM_BUILDINGS_COV: 'HOMBuildingsCov',
        HOM_BUILDINGS_COV_ACCIDENTAL_DAMAGE: 'HOMBuildingsCovAccidentalDamageRequired',
        HOM_BUILDINGS_COV_VOLUNTARY_EXCESS: 'HOMBuildingsCovVoluntaryExcess',
        HOM_BUILDINGS_COV_ALL_OTHER_CLAIMS_EXCESS: 'HOMBuildingsCovAllOtherClaimsExcess',
        HOM_BUILDINGS_COV_BUILDING_LEVEL_LIMIT: 'HOMBuildingsCovBuildingLevelLimit',
        HOM_BUILDINGS_COV_ESCAPE_OF_WATER_OR_OIL_TOTAL_EXCESS: 'HOMBuildingsCovEscapeOfWaterOrOilTotalExcess',
        HOM_BUILDINGS_COV_SUBSIDENCE_EXCESS: 'HOMBuildingsCovSubsidenceExcess',
        HOM_BUILDINGS_COV_FLOOD_TOTAL_EXCESS: 'HOMBuildingsCovFloodTotalExcess',
        HOM_HOME_EMERGENCY_COV: 'HOMHomeEmergencyCov',
        HOM_HOME_EMERGENCY_COV_LIMIT: 'HOMHomeEmergencyCovLimit',
        HOM_BUILDINGS_COV_FLOOD_EXCESS_ADJUSTMENT: 'HOMBuildingsCovFloodExcessAdjustment',
        HOM_BUILDINGS_COV_ESCAPE_OF_WATER_OR_OIL_EXCESS_ADJUST: 'HOMBuildingsCovEscapeOfWaterOrOilExcessAdjust',
        HOM_BUILDINGS_COV_SUBSIDENCE_HEAVE_EXCESS_ADJUST: 'HOMBuildingsCovSubsidenceHeaveExcessAdjust',
        HOM_BUILDINGS_COV_STORM_EXCESS_ADJUSTMENT: 'HOMBuildingsCovStormExcessAdjustment',
        HOM_BUILDINGS_COV_FIRE_EXCESS_ADJUSTMENT: 'HOMBuildingsCovFireExcessAdjustment',
        HOM_BUILDINGS_COV_THEFT_EXCESS_ADJUSTMENT: 'HOMBuildingsCovTheftExcessAdjustment',
        HOM_BUILDINGS_COV_LEAKING_OIL_EXCESS_ADJUSTMENT: 'HOMBuildingsCovLeakingOilExcessAdjustment',
        HOM_BUILDINGS_COV_FALLING_TREES_AND_BRANCHES_EXC_ADJ: 'HOMBuildingsCovFallingTreesAndBranchesExcAdj',
        HOM_BUILDINGS_COV_FALLING_AERIALS_TURBINES_EXC_ADJ: 'HOMBuildingsCovFallingAerialsTurbinesExcAdj',
        HOM_BUILDINGS_COV_COLLISION_VEH_ANIMAL_EXCESS_ADJUST: 'HOMBuildingsCovCollisionVehAnimalExcessAdjust',
        HOM_BUILDINGS_COV_MALICIOUS_PEOPLE_EXCESS_ADJUST: 'HOMBuildingsCovMaliciousPeopleExcessAdjust',
        HOM_BUILDINGS_COV_RIOT_EXCESS_ADJUSTMENT: 'HOMBuildingsCovRiotExcessAdjustment',
        HOM_BUILDINGS_COV_AIRCRAFT_EXCESS_ADJUSTMENT: 'HOMBuildingsCovAircraftExcessAdjustment',
        HOM_BUILDINGS_COV_BUILDINGS_SUM_INSURED: 'HOMBuildingsCovBuildingsSumInsured',
        HOM_BUILDINGS_COV_FIRST_LOSS_SUM_REQUIRED: 'HOMBuildingsCovFirstLossSumRequired',
        HOM_BUILDINGS_COV_BASIS_OF_SETTLEMENT: 'HOMBuildingsCovBasisOfSettlement',
        HOM_BUILDINGS_COV_OTHER_VOL_Xs: 'HOMBuildingsCovOtherVolXs',
        HOM_BUILDINGS_COV_RESTRICT_COVER_TO_FLEE: 'HOMBuildingsCovRestrictCoverToFLEE'
    });
    const YES_KEY = 'Yes';
    const UNOCCUPIED_KEY = 'Unoccupied';
    const bespokeSustainedDmg = _.get(submissionVM.lobData.homeLine.value, 'isBespokeSustainedDmg');
    const bespokeFloodRisk = _.get(submissionVM.lobData.homeLine.value, 'isBespokeFloodRisk');

    const getRebuildingCostByHighestPriority = useCallback(() => {
        const rebuildingCostByHighestPriority = homePropertiesData[0]
            ?.homhomePropertyConstruction.rebuildingCostByHighestPriority;
        return `£${rebuildingCostByHighestPriority}`;
    }, [homePropertiesData]);

    const getTermValue = useCallback((cov, termId) => {
        return cov?.terms
            ?.find((term) => term.publicID === termId)
            ?.chosenTermValue;
    }, []);

    const getTermLimitValue = useCallback((cov, termPublicID, currSymbol = true) => {
        const chosenTermValueObj = cov?.terms
            ?.find((term) => term?.publicID === termPublicID);
        const chosenTermValue = chosenTermValueObj?.chosenTermValue;
        return currSymbol ? `£${chosenTermValue}` : chosenTermValue;
    }, []);

    const isAnyAdjustmentsCovTermValue = useCallback((buildingsCov) => {
        const arr = [
            buildingsIds.HOM_BUILDINGS_COV_FLOOD_EXCESS_ADJUSTMENT,
            buildingsIds.HOM_BUILDINGS_COV_ESCAPE_OF_WATER_OR_OIL_EXCESS_ADJUST,
            buildingsIds.HOM_BUILDINGS_COV_SUBSIDENCE_HEAVE_EXCESS_ADJUST,
            buildingsIds.HOM_BUILDINGS_COV_STORM_EXCESS_ADJUSTMENT,
            buildingsIds.HOM_BUILDINGS_COV_FIRE_EXCESS_ADJUSTMENT,
            buildingsIds.HOM_BUILDINGS_COV_THEFT_EXCESS_ADJUSTMENT,
            buildingsIds.HOM_BUILDINGS_COV_LEAKING_OIL_EXCESS_ADJUSTMENT,
            buildingsIds.HOM_BUILDINGS_COV_FALLING_TREES_AND_BRANCHES_EXC_ADJ,
            buildingsIds.HOM_BUILDINGS_COV_FALLING_AERIALS_TURBINES_EXC_ADJ,
            buildingsIds.HOM_BUILDINGS_COV_COLLISION_VEH_ANIMAL_EXCESS_ADJUST,
            buildingsIds.HOM_BUILDINGS_COV_MALICIOUS_PEOPLE_EXCESS_ADJUST,
            buildingsIds.HOM_BUILDINGS_COV_RIOT_EXCESS_ADJUSTMENT,
            buildingsIds.HOM_BUILDINGS_COV_AIRCRAFT_EXCESS_ADJUSTMENT,
        ];

        return arr.reduce((acc, currentCovTerm) => {
            const result = getTermLimitValue(
                buildingsCov,
                currentCovTerm,
                false
            );
            return result && +result.replace(/,/g, '') > 0 ? true : acc;
        }, false);
    }, [buildingsIds, getTermLimitValue]);

    const isBespokeAndCondition = useCallback((condition = true) => {
        const isBespoke = _.get(submissionVM.value, 'isBespokeHomeInsurance_NFUM');
        return isBespoke && condition;
    }, [submissionVM]);

    // eslint-disable-next-line max-len
    const generateHomePropertiesData = useCallback(() => { /* NOSONAR: GW Jutro specific convention */
        if (!homePropertiesData) {
            return {};
        }
        const homePropertiesOverrides = homePropertiesData.map((homeProperty, index) => {
            const buildingsCov = homeProperty.coverages.coverages
                .find(((cov) => cov.publicID === buildingsIds.HOM_BUILDINGS_COV));
            const isBuildingsCov = buildingsCov.selected;
            const basisOfSettlementTypelist = viewModelService.getTypelistNFUM('pc', 'typekey.BasisOfSettlement_NFUM');
            const basisOfSettlementCovTerm = buildingsCov?.terms?.find((term) => term.publicID === 'HOMBuildingsCovBasisOfSettlement').chosenTerm;
            const firstLossCovTerm = basisOfSettlementTypelist.codes.find((typecode) => typecode.code === 'FirstLoss');
            const isFirstLoss = firstLossCovTerm
                ? firstLossCovTerm.code === basisOfSettlementCovTerm : false;
            const getVoluntaryExcessTermValue = buildingsCov?.terms?.find((term) => term.publicID === 'HOMBuildingsCovVoluntaryExcess').chosenTermValue;
            const getVoluntaryExcessOtherTermValue = (getVoluntaryExcessTermValue === 'Other')
                ? getTermLimitValue(buildingsCov,
                    buildingsIds.HOM_BUILDINGS_COV_OTHER_VOL_Xs)
                : getTermLimitValue(buildingsCov,
                    buildingsIds.HOM_BUILDINGS_COV_VOLUNTARY_EXCESS);
            const getPropertyOccupancy = homeProperty?.homhomePropertyDetails
                ?.whoOccupiesTheProperty === UNOCCUPIED_KEY;
            return {
                [`buildingsHomePropertyTitle${index}`]: {
                    content: getPropertyTitle(homeProperty)
                },
                [`buildingsNotSelected${index}`]: {
                    visible: !isBuildingsCov,
                    value: translator(messages.notSelected)
                },
                [`buildingsSelectedContainer${index}`]: {
                    visible: isBuildingsCov
                },
                [`noOfBedrooms${index}`]: {
                    value: homeProperty.homhomePropertyDetails.numberOfBedrooms
                },
                [`buildingsSumInsured${index}`]: {
                    visible: isBespokeAndCondition(!isFirstLoss
                        && !homeProperty.isTenantFixure),
                    value: getRebuildingCostByHighestPriority()
                },
                [`firstLossSumInsured${index}`]: {
                    visible: isBespokeAndCondition(isFirstLoss),
                    value: getTermLimitValue(buildingsCov,
                        buildingsIds.HOM_BUILDINGS_COV_FIRST_LOSS_SUM_REQUIRED)
                },
                [`buildingsLevelLimit${index}`]: {
                    visible: !isBespokeAndCondition(),
                    value: getTermLimitValue(
                        buildingsCov,
                        buildingsIds.HOM_BUILDINGS_COV_BUILDING_LEVEL_LIMIT
                    )
                },
                [`buildingsAccidentalDamage${index}`]: {
                    value: getTermValue(buildingsCov,
                        buildingsIds.HOM_BUILDINGS_COV_ACCIDENTAL_DAMAGE) === YES_KEY
                        ? translator(messages.added)
                        : translator(messages.notSelected),
                    visible: !isBespokeAndCondition()
                },
                [`buildingsAccidentalDamageInd${index}`]: {
                    value: ((getPropertyOccupancy && getPropertyOccupancy !== undefined)
                    || (getTermValue(buildingsCov,
                        buildingsIds.HOM_BUILDINGS_COV_RESTRICT_COVER_TO_FLEE) === YES_KEY))
                        ? translator(messages.notSelected)
                        : translator(messages.included),
                    visible: isBespokeAndCondition()
                },
                [`tenantsFixtures${index}`]: {
                    value: getRebuildingCostByHighestPriority(),
                    visible: homeProperty.isTenantFixure
                },
                [`confirmedContainer${index}`]: {
                    visible: homeLine?.isPropertyNotForBusinessUse
                        && homeLine?.isPropertyNotImpactedByFloodAndSubsidence
                },
                [`youConfirmed${index}`]: {
                    visible: true
                },
                [`confirmationText2${index}`]: {
                    visible: isBespokeAndCondition(!bespokeSustainedDmg)
                        || !isBespokeAndCondition()
                },
                [`confirmationText3${index}`]: {
                    visible: isBespokeAndCondition(bespokeSustainedDmg)
                },
                [`confirmationText4${index}`]: {
                    visible: isBespokeAndCondition(!bespokeFloodRisk)
                        || !isBespokeAndCondition()
                },
                [`confirmationText5${index}`]: {
                    visible: isBespokeAndCondition(bespokeFloodRisk)
                },
                [`confirmationText6${index}`]: {
                    visible: !isBespokeAndCondition()
                },
                [`confirmationText7${index}`]: {
                    visible: !isBespokeAndCondition()
                },
                [`leakingAndFrozenWater${index}`]: {
                    value: getTermLimitValue(
                        buildingsCov,
                        buildingsIds.HOM_BUILDINGS_COV_ESCAPE_OF_WATER_OR_OIL_TOTAL_EXCESS
                    )
                },
                [`subsidence${index}`]: {
                    value: getTermLimitValue(
                        buildingsCov,
                        buildingsIds.HOM_BUILDINGS_COV_SUBSIDENCE_EXCESS
                    )
                },
                [`flood${index}`]: {
                    value: getTermLimitValue(
                        buildingsCov,
                        buildingsIds.HOM_BUILDINGS_COV_FLOOD_TOTAL_EXCESS
                    )
                },
                [`voluntary${index}`]: {
                    value: isBespokeAndCondition()
                        ? getVoluntaryExcessOtherTermValue
                        : getTermLimitValue(
                            buildingsCov,
                            buildingsIds.HOM_BUILDINGS_COV_VOLUNTARY_EXCESS
                        )
                },
                [`compulsaryAllClaims${index}`]: {
                    value: getTermLimitValue(
                        buildingsCov,
                        buildingsIds.HOM_BUILDINGS_COV_ALL_OTHER_CLAIMS_EXCESS
                    ),
                    visible: !isAnyAdjustmentsCovTermValue(buildingsCov)
                },
                [`compulsaryAllClaimsWithSeeDocuments${index}`]: {
                    value: translator(messages.seeDocuments),
                    visible: isAnyAdjustmentsCovTermValue(buildingsCov)
                },
                [`seeDocuments${index}`]: {
                    onClick: onClickSeeDocuments
                }
            };
        });
        return Object.assign({}, ...homePropertiesOverrides);
    }, [homePropertiesData,
        buildingsIds,
        translator,
        getPropertyTitle,
        homeLine,
        onClickSeeDocuments,
        getTermValue,
        getTermLimitValue,
        isAnyAdjustmentsCovTermValue,
        bespokeSustainedDmg,
        bespokeFloodRisk,
        viewModelService,
        isBespokeAndCondition,
        getRebuildingCostByHighestPriority
    ]);

    const overrideProps = {
        '@field': {
            readOnly: true,
            labelPosition: 'left',
            className: 'riskObjectProperty',
            labelClassName: 'riskObjectPropertyLabel'
        },
        buildingsHomeProperties: {
            data: homePropertiesData
        },
        ...generateHomePropertiesData()
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
        />
    );
}

export default BuildingsSummary;
