import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import _ from 'lodash';

import { useAuth } from './AuthContext';

const secureRoutePropTypes = {
    // eslint-disable-next-line react/forbid-foreign-prop-types
    ...Route.propTypes,
    children: PropTypes.func,
    component: PropTypes.elementType,
    render: PropTypes.func,
};

const secureRouteDefaultProps = {
    children: undefined,
    component: undefined,
    render: _.noop,

};

const SecureRoute = ({
    children,
    component: Component,
    render,
    ...props
}) => {
    const auth = useAuth();
    if (auth && !auth.authenticated && !auth.isPending) {
        auth.login();
    }

    return (
        <Route {...props}>
            {(routeProps) => {
                if (children) {
                    return children({ ...routeProps, auth });
                }
                if (Component) {
                    if (auth) {
                        return (
                            _.get(auth, 'authenticated') && <Component {...routeProps} />
                        );
                    }

                    return <Component {...routeProps} />;
                }
                if (render) {
                    return render({ ...routeProps, auth });
                }
                return null;
            }}
        </Route>
    );
};

SecureRoute.propTypes = secureRoutePropTypes;
SecureRoute.defaultProps = secureRouteDefaultProps;

SecureRoute.displayName = 'SecureRoute';

export default SecureRoute;
