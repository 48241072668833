import React, { useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from 'gw-portals-viewmodel-react';

import metadata from './SpecificError.metadata.json5';
import styles from './SpecificError.module.scss';
import messages from './SpecificError.messages';

function SpecificError(props) {
    const {
        errorMessage,
        showWorkingOnQuoteText,
        showSavedQuoteText,
        showContactUsTextWithAmendments,
        workingOnQuoteText,
        producerDesc,
        producerTele,
        isBespoke,
        showAgencyDetails
    } = props;
    const translator = useContext(TranslatorContext);

    const overrideProps = {
        problemGeneratingQuoteText: {
            content: errorMessage,
        },
        workingOnQuoteText: {
            visible: showWorkingOnQuoteText
        },
        savedQuoteText: {
            visible: showSavedQuoteText,
            content: workingOnQuoteText || translator(messages.savedQuote)
        },
        contactUsTextWithAmendments: {
            visible: showContactUsTextWithAmendments
        },
        referralMessageContainer: {
            className: 'referralMessageContainer'
        },
        referralMessage: {
            producerDesc: producerDesc,
            producerTele: producerTele,
            isBespoke: isBespoke,
            showAgencyDetails: showAgencyDetails,
            showCallBack: true
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

export default SpecificError;
