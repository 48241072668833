import { defineMessages } from 'react-intl';

export default defineMessages({
    exitConfirmation: {
        id: 'returnToDashboardModal.exitConfirmation',
        defaultMessage: 'Return to dashboard?'
    },
    returnToDashboardInfo: {
        id: 'returnToDashboardModal.returnToDashboardModalInfo',
        defaultMessage: 'If you return to the dashboard any information you have entered so far will not be saved.'
    },
    continueSessionButton: {
        id: 'returnToDashboardModal.exitButton',
        defaultMessage: 'Yes, return to dashboard'
    },
    logoutButton: {
        id: 'returnToDashboardModal.closeButton',
        defaultMessage: 'Close'
    }
});
