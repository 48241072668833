import React, {
    useCallback, useEffect, useContext
} from 'react';
import { TranslatorContext } from '@jutro/locale';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { getConfigValue } from '@jutro/config';
import { get } from 'lodash';
import {
    BESPOKE_COVERAGES_MTA_STEPS
} from 'nfum-portals-utils-react';
import { useTagManager } from 'nfum-capability-policychange-common-react';
import metadata from './BespokePolicyChangeSuccessPage.metadata.json5';
import styles from './BespokePolicyChangeSuccessPage.module.scss';
import messages from './BespokePolicyChangeSuccessPage.messages';

function BespokePolicyChangeSuccessPage(props) {
    const { wizardData: policySubmissionVM } = props;
    const translator = useContext(TranslatorContext);
    const {
        pushFormConfirmationInfo,
        pushLinkClickInfo
    } = useTagManager();

    const producerDesc = get(policySubmissionVM.value, 'baseData.producerDetails_NFUM.producerCodeDescription', '');
    const producerTele = get(policySubmissionVM.value, 'baseData.producerDetails_NFUM.producerCodeTelephone', '');
    const isBespoke = get(policySubmissionVM.value, 'baseData.producerDetails_NFUM.isBespoke', '');
    const showAgencyDetails = get(policySubmissionVM.value, 'baseData.producerDetails_NFUM.showProducerDetails', '');

    useEffect(() => {
        pushFormConfirmationInfo(policySubmissionVM, BESPOKE_COVERAGES_MTA_STEPS.SUCCESS);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        }
    };

    const overrideProps = {
        bespokeChangeConfirmationContainer: {
            visible: get(policySubmissionVM.value, 'isBespokeHomeInsurance_NFUM')
        },
        callOrContactInfo: {
            producerDesc: producerDesc,
            producerTele: producerTele,
            isBespoke: isBespoke,
            showAgencyDetails: showAgencyDetails
        }
    };

    const returnToDCSDashboard = () => {
        const backToDashboardURL = getConfigValue('JUTRO_BACK_TO_DASHBOARD_PATH', '/');
        pushLinkClickInfo(translator(messages.continue), backToDashboardURL);
        window.location.replace(backToDashboardURL);
    };

    const onNext = useCallback(async () => {
        returnToDCSDashboard();
    }, []);

    return (
        <WizardPage
            onNext={onNext}
            showPrevious={false}
            showCancel={false}
            nextLabel={messages.continue}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policySubmissionVM}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

BespokePolicyChangeSuccessPage.propTypes = wizardProps;
export default withAuthenticationContext(BespokePolicyChangeSuccessPage);
