import moment from 'moment';
import React, { useCallback, useContext } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import messages from './PersonalAccidentSummary.messages';
import metadata from './PersonalAccidentSummary.metadata.json5';
import styles from './PersonalAccidentSummary.module.scss';

function PersonalAccidentSummary(props) {
    const { value: paData } = props;
    const translator = useContext(TranslatorContext);
    const paIds = Object.freeze({
        PAINJURY_COV: 'PPAInjuryCov',
        LOSS_OF_LIMBS_SUM: 'PPAInjuryCovLossOfLimbsSI',
        PA_ILLNESS_COV: 'PPAIllnessCov',
        PA_INJURY_COV_DEFERRED_BENEFIT: 'PPAInjuryCovInjuryDeferredBenefit',
        PA_INJURY_COV_BENEFIT_PAID_FOR: 'PPAInjuryCovInjuryBenefitPaidFor',
        PA_INJURY_COV_DEATH_SUM: 'PPAInjuryCovDeathSI',
        PA_INJURY_COV_PTD_SUM: 'PPAInjuryCovPTDSI',
        PA_INJURY_COV_TTD_SUM: 'PPAInjuryCovTTDInjurySI',
        PA_INJURY_COV_TPD_SUM: 'PPAInjuryCovTPDSI',
        PA_ILLNESS_COV_SUM: 'PPAIllnessCovTTDIllnessSI'
    });

    const paDOBFormat = useCallback((dob) => {
        const dobdt = new Date(dob.year, dob.month, dob.day);
        return moment(dobdt).format('Do MMMM YYYY');
    }, []);

    const paGetRelationshipName = useCallback((typecode) => {
        return translator({
            id: `typekey.InsuredRelationshipToPH_NFUM.${typecode}`,
            defaultMessage: typecode
        });
    }, [translator]);

    const resolvePAPPAInjuryCovValue = useCallback((PPAInjuryCov, termId) => {
        const injuryCovTermValue = (PPAInjuryCov.coverages.coverages)
            .find((cov) => cov.publicID === paIds.PAINJURY_COV)
            ?.terms?.find((term) => term.publicID === termId)?.chosenTermValue;
        if (!injuryCovTermValue || injuryCovTermValue === 'No') {
            return translator(messages.notSelected);
        }
        return injuryCovTermValue;
    }, [translator, paIds]);

    const resolvePAPPAInjuryCovSumValue = useCallback((PPAInjuryCov, termId) => {
        const injuryCovTermValue = (PPAInjuryCov.coverages.coverages)
            .find((cov) => cov.publicID === paIds.PAINJURY_COV)
            ?.terms?.find((term) => term.publicID === termId)?.chosenTermValue;
        if (!injuryCovTermValue || injuryCovTermValue === 'No') {
            return translator(messages.notSelected);
        }
        return `£${injuryCovTermValue}`;
    }, [translator, paIds]);

    const isDeathBenefitCoverSelected = useCallback((PPAInjuryCov, termId) => {
        const injuryCovTermValue = (PPAInjuryCov.coverages.coverages)
            .find((cov) => cov.publicID === paIds.PAINJURY_COV)
            ?.terms?.find((term) => term.publicID === termId)?.chosenTermValue;
        if (!injuryCovTermValue || injuryCovTermValue === 'No') {
            return false;
        }
        return true;
    }, [paIds]);

    const resolvePPAIllnessCatCovTermValue = useCallback((PPAIllnessCat, termId) => {
        const illnessCovTermValue = PPAIllnessCat.coverages.coverages
            .find((cov) => cov.publicID === paIds.PA_ILLNESS_COV)
            ?.terms?.find((term) => term.publicID === termId).chosenTermValue;
        return illnessCovTermValue ? `£${illnessCovTermValue}` : translator(messages.notSelected);
    }, [translator, paIds]);

    const resolvePADeathBenefitAssignedToExposures = useCallback((PPAIndividuals) => {
        let paDeathBenefitAssignedTo = '';
        if (PPAIndividuals.exposures.ppaisDeathBenefitAssigned) {
            PPAIndividuals.exposures.ppaisDeathBenefitAssigned.forEach((assigned) => {
                if (paDeathBenefitAssignedTo !== '') {
                    paDeathBenefitAssignedTo += ', ';
                }
                paDeathBenefitAssignedTo += `${assigned.assignTo} (${assigned.assignmentPercentage}%)`;
            });
        }
        return paDeathBenefitAssignedTo || translator(messages.notSelected);
    }, [translator]);

    const generatePersonalAccidentData = useCallback(() => {
        const paOverrides = paData ? paData.map((individual, index) => {
            const isPaDeathBenefit = isDeathBenefitCoverSelected(
                individual, paIds.PA_INJURY_COV_DEATH_SUM
            );
            return {
                [`paTitle${index}`]: {
                    content: `${individual.forename} ${individual.surname}`
                },
                [`paRelationship${index}`]: {
                    value: paGetRelationshipName(individual.insuredRelationshipToPH)
                },
                [`paDOB${index}`]: {
                    value: paDOBFormat(individual.doB)
                },
                [`paLossOfLimbs${index}`]: {
                    value: resolvePAPPAInjuryCovSumValue(individual, paIds.LOSS_OF_LIMBS_SUM)
                },
                [`paPermanentTotalDiability${index}`]: {
                    value: resolvePAPPAInjuryCovSumValue(individual, paIds.PA_INJURY_COV_PTD_SUM)
                },
                [`paTemporaryTotalDisability${index}`]: {
                    value: resolvePAPPAInjuryCovSumValue(individual, paIds.PA_INJURY_COV_TTD_SUM)
                },
                [`paTemporaryPartialDisability${index}`]: {
                    value: resolvePAPPAInjuryCovSumValue(individual, paIds.PA_INJURY_COV_TPD_SUM)
                },
                [`paTemporaryTotalDisabilityIllness${index}`]: {
                    value: resolvePPAIllnessCatCovTermValue(individual, paIds.PA_ILLNESS_COV_SUM)
                },
                [`paDeathCover${index}`]: {
                    value: resolvePAPPAInjuryCovSumValue(individual, paIds.PA_INJURY_COV_DEATH_SUM)
                },
                [`paDeathBenefit${index}`]: {
                    value: resolvePADeathBenefitAssignedToExposures(individual),
                    visible: isPaDeathBenefit
                },
                [`paBenefitRestricted${index}`]: {
                    value: individual.isOccupationOnlyBenefits === true
                        ? translator(messages.yes)
                        : translator(messages.notSelected)
                },
                [`paWeeklyBenefitPeriod${index}`]: {
                    value: resolvePAPPAInjuryCovValue(individual,
                        paIds.PA_INJURY_COV_BENEFIT_PAID_FOR)
                },
                [`paBenefitPeriodDeferred${index}`]: {
                    value: resolvePAPPAInjuryCovValue(individual,
                        paIds.PA_INJURY_COV_DEFERRED_BENEFIT)
                }
            };
        }) : [];
        return Object.assign({}, ...paOverrides);
    }, [
        paData,
        translator,
        resolvePAPPAInjuryCovValue,
        resolvePAPPAInjuryCovSumValue,
        resolvePPAIllnessCatCovTermValue,
        resolvePADeathBenefitAssignedToExposures,
        paIds,
        paDOBFormat,
        paGetRelationshipName,
        isDeathBenefitCoverSelected
    ]);

    const overrideProps = {
        '@field': {
            readOnly: true,
            labelPosition: 'left',
            className: 'riskObjectProperty',
            labelClassName: 'riskObjectPropertyLabel'
        },
        personalAccident: {
            data: paData
        },
        ...generatePersonalAccidentData()
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
        />
    );
}

export default PersonalAccidentSummary;
