import React, {
    useEffect,
    useState,
    useContext,
    useCallback
} from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Loader, useModal } from '@jutro/components';
import { Wizard } from 'gw-portals-wizard-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { useDependencies } from 'gw-portals-dependency-react';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { ErrorBoundary } from 'gw-portals-error-react';
import { messages as platformMessages } from 'gw-platform-translations';
import { ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { messages as commonMessages } from 'gw-capability-policychange-common-react';
import wizardConfig from './config/ho-wizard-config.json5';
import messages from './HOPolicyChange.messages';

const setPeriodStartDate = (date) => {
    const currentDate = new Date(date);
    const effectiveDate = {
        day: currentDate.getDate(),
        month: currentDate.getMonth(),
        year: currentDate.getFullYear()
    };
    return effectiveDate;
};

function HOPolicyChangeWizard(props) { /* NOSONAR: pure declarative usage */
    const {
        showConfirm,
        showAlert
    } = useModal();

    const { steps, title } = wizardConfig;
    const [initialSubmission, setInitialSubmission] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);
    const { EndorsementService } = useDependencies('EndorsementService');
    const { location, history, authHeader } = props;

    useEffect(
        () => {
            if (!location.state) {
                history.push('/');
                return;
            }
            const { state: { policyNumber, policyDetails, requestType } } = location;
            if (!policyNumber) {
                return;
            }
            const errorModalBox = (errorMessage) => {
                showConfirm(errorMessage).then((results) => {
                    if (results === 'cancel' || results === 'close') {
                        return _.noop();
                    }
                    setIsLoading(false);
                    return history.push(`/contactAgent/${policyNumber}`);
                }, _.noop);
            };
            EndorsementService.getAvailablePolicy(policyNumber, authHeader)
                .then((response) => {
                    if (appConfig.persona === 'policyholder') {
                        const effectivePolicyDate = (requestType === 'policyRenew') ? policyDetails.effective : new Date();
                        const date = setPeriodStartDate(effectivePolicyDate);
                        EndorsementService.loadEndorsementWithEffectiveDate(
                            [response.policyNumber, date],
                            authHeader
                        )
                            .then((responseData) => {
                                const submission = viewModelService.create(
                                    responseData,
                                    'pc',
                                    'edge.capabilities.policychange.dto.PolicyChangeDataDTO'
                                );
                                setInitialSubmission(submission);
                                setIsLoading(false);
                            });
                    } else {
                        EndorsementService.loadEndorsement(response.policyNumber, authHeader)
                            .then((responseData) => { /* NOSONAR: GW OOTB internal mechanism */
                                const submission = viewModelService.create(
                                    responseData,
                                    'pc',
                                    'edge.capabilities.policychange.dto.PolicyChangeDataDTO'
                                );
                                setInitialSubmission(submission);
                                setIsLoading(false);
                            }).catch(() => {
                                errorModalBox({
                                    title: commonMessages.unableToLoadDraftPolicy,
                                    message: commonMessages.somethingWentWrong,
                                });
                            }).catch(() => {
                                errorModalBox({
                                    title: commonMessages.unableToStartPolicy,
                                    message: commonMessages.anErrorOccurred,
                                    status: 'warning',
                                    icon: 'mi-error-outline'
                                });
                            });
                    }
                });
        },
        // Disabled so we don't rerun this function on every rerender
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleOnCancel = useCallback((cancelParams) => {
        const {
            wizardData: { submissionVM },
            wizardSnapshot,
            currentStepIndex,
            steps: wizardSteps,
            param: nextPath
        } = cancelParams;
        const confirmationPageIndex = wizardSteps.length - 1;
        if ((!_.isEmpty(nextPath) && nextPath.startsWith('/contactAgent'))
            || currentStepIndex === confirmationPageIndex || hasErrorOccurred
        ) {
            return true;
        }

        return showConfirm({
            title: platformMessages.wantToCancel,
            message: platformMessages.infoWillBeSavedAsDraft,
            status: 'warning',
            icon: 'mi-error-outline'
        }).then((results) => {
            if (results === 'cancel' || results === 'close') {
                return _.noop();
            }
            setIsLoading(true);
            const isValid = submissionVM.aspects.valid && submissionVM.aspects.subtreeValid;
            const payload = (isValid) ? _.get(submissionVM, 'value') : _.get(wizardSnapshot, 'submissionVM.value');
            const jobID = _.get(submissionVM.value, 'jobID');
            const quoteStatus = _.get(wizardSnapshot, 'baseData.periodStatus.value.code')
                || _.get(submissionVM.value, 'status');
            const isStatusQuoted = quoteStatus !== 'Quoted';
            const policyNumber = _.get(submissionVM.value, 'policyNumber');
            let exitPromise;
            let redirectLobPath = (!_.isEmpty(nextPath) && nextPath) || `/change/${jobID}/summary`;
            if (appConfig.persona === 'policyholder') {
                redirectLobPath = (!_.isEmpty(nextPath) && nextPath) || `/account-policy-details/${policyNumber}`;
            }
            if (appConfig.persona === 'policyholder') {
                if (quoteStatus !== 'Bound') {
                    exitPromise = EndorsementService.withdrawEndorsement([jobID], authHeader);
                } else {
                    return history.push(redirectLobPath);
                }
            } else if (isStatusQuoted) {
                exitPromise = EndorsementService.saveEndorsement([payload], authHeader);
            } else {
                return history.push(redirectLobPath);
            }
            exitPromise.then(() => {
                history.push(redirectLobPath);
            }).catch(() => {
                showAlert({
                    title: commonMessages.unableToDraftPolicy,
                    message: commonMessages.anErrorOccurred,
                    status: 'warning',
                    icon: 'mi-error-outline'
                });
            }).finally(() => {
                setIsLoading(false);
            });
            return true;
        }, _.noop);
    }, [hasErrorOccurred, showConfirm, EndorsementService, authHeader, history, showAlert]);

    const handleError = useCallback((error) => {
        const isQuotePage = _.includes(_.toLower(_.get(error, 'gwInfo.method')), 'quote');
        const jobIDFromWizard = _.get(error, 'gwInfo.params[0].jobID');
        const jobIDFromLocation = _.get(location, 'state.policyNumber');
        let redirectPath = `/contactAgent/${jobIDFromLocation}`;
        if (!_.isEmpty(jobIDFromWizard)) {
            redirectPath = `/change/${jobIDFromWizard}/summary`;
        }
        const state = {
            pathname: redirectPath,
            state: {
                redirectPath: '/home',
            }
        };

        return showAlert({
            title: isQuotePage ? messages.saveQuoteError : messages.anErrorOccurredTitle,
            message: isQuotePage ? messages.saveQuoteErrorMessage : messages.anErrorOccurred,
            status: 'error',
            icon: 'mi-error-outline',
            confirmButtonText: platformMessages.ok
        }).then(() => {
            setHasErrorOccurred(true);
            return <Redirect to={state} />;
        }, _.noop);
    }, [location, showAlert]);

    if (isLoading) {
        return (
            <Loader loaded={!isLoading} /> /* NOSONAR: GW OOTB internal mechanism */
        );
    }

    if (!initialSubmission) {
        return null;
    }

    return (
        <ErrorBoundary onError={handleError}>
            <Wizard
                initialSteps={steps}
                wizardTitle={title}
                initialData={{
                    submissionVM: initialSubmission,
                    selectedData: {
                        valuables: false,
                        mortgagee: false,
                        coverages: false
                    },
                    bindData: { paymentDetails: {} }
                }}
                onCancel={handleOnCancel}
                onPreviousModalProps={{
                    title: platformMessages.wantToJump,
                    message: platformMessages.wantToJumpMessage,
                    status: 'warning',
                    icon: 'mi-error-outline',
                    confirmButtonText: platformMessages.yes,
                    cancelButtonText: platformMessages.no
                }}
                extractVMObject={(data) => _.get(data, 'submissionVM')}
            />
        </ErrorBoundary>
    );
}

HOPolicyChangeWizard.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            address: PropTypes.shape({}),
            policyNumber: PropTypes.string,
            requestType: PropTypes.string,
            policyDetails: PropTypes.shape({
                effective: PropTypes.instanceOf(Date)
            })
        }),
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }).isRequired,
};

export default withRouter(withAuthenticationContext(HOPolicyChangeWizard));
