import React from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { ModalNext } from '@jutro/components';
import metadata from './ReturnToDashboardModal.metadata.json5';
import styles from './ReturnToDashboardModal.module.scss';
import './ReturnToDashboardModal.messages';

function ReturnToDashboardModal(props) {
    const {
        isOpen,
        onResolve
    } = props;

    const overrideProps = {
        closeButton: {
            onClick: () => onResolve({ type: 'CONTINUE' })
        },
        exitButton: {
            onClick: () => onResolve({ type: 'EXIT' })
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ModalNext
            isOpen={isOpen}
        >
            <ViewModelForm
                className={styles.modalWrapper}
                uiProps={metadata.componentContent}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </ModalNext>
    );
}

export default ReturnToDashboardModal;
