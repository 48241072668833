import React, { useContext, useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import messages from './ProtectingYourValuables.messages';
import metadata from './ProtectingYourValuables.metadata.json5';
import styles from './ProtectingYourValuables.scss';
import InfoPanel from '../../../components/InfoPanel/InfoPanel';
import minSecurityMetadata from './infoMetadata/minimumSecurity.metadata.json5';
import alarmSecurityMetadata from './infoMetadata/alarmSecurity.metadata.json5';

function ProtectingYourValuables(props) {
    const translator = useContext(TranslatorContext);
    const {
        value: securityQuestionsVM,
        onValueChange,
        path,
        isDisabled
    } = props;

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    const getMinSecurityExtendedData = () => {
        return (
            <p>
                { translator(messages.weWillAllowFrom) }
                <span className="xDays">{ translator(messages.xDays, { days: 28 }) }</span>
                { translator(messages.fromTheDateThat) }
            </p>
        );
    };

    const getSafeConditionExtendedData = () => {
        return (
            <div>
                <p>{ translator(messages.forLossOrDamage, { amount: _.get(securityQuestionsVM.value, 'safeConditionMaxAmount') }) }</p>
                <p>{ translator(messages.beingWornBy) }</p>
                <p>{ translator(messages.depositedInBank) }</p>
            </div>
        );
    };

    const getAlarmSecurityExtendedData = () => {
        return (
            <p>
                { translator(messages.weWillAllowFrom) }
                <span className="xDays">{ translator(messages.xDays, { days: 28 }) }</span>
                { translator(messages.fromTheDateThatAlarm) }
            </p>
        );
    };

    const overrideProps = {
        minSecuritySection: {
            visible: false // not visible for MTA
        },
        alarmSecuritySection: {
            visible: false // not visible for MTA
        },
        safeConditionSection: {
            visible: _.get(securityQuestionsVM, 'isSafeConditionExists.value') === true
        },
        minSecurityInfoPanel: {
            isCollapsible: true,
            collapsibleButtonText: translator(messages.showMinimumSecurity),
            contentMetadata: minSecurityMetadata
        },
        alarmSecurityInfoPanel: {
            isCollapsible: true,
            collapsibleButtonText: translator(messages.showMinimumSecurity),
            contentMetadata: alarmSecurityMetadata
        },
        minSecurityExtendedInfoPanel: {
            visible: _.get(securityQuestionsVM, 'isMinSecurity.value') === true,
            isCollapsible: false,
            contentJsx: getMinSecurityExtendedData(),
            type: 'extended'
        },
        alarmSecurityExtendedInfoPanel: {
            visible: _.get(securityQuestionsVM, 'isAlarmSecurity.value') === true,
            isCollapsible: false,
            contentJsx: getAlarmSecurityExtendedData(),
            type: 'extended'
        },
        safeConditionExtendedInfoPanel: {
            visible: _.get(securityQuestionsVM, 'isSafeCondition.value') === true,
            isCollapsible: false,
            contentJsx: getSafeConditionExtendedData(),
            type: 'extended'
        },
        minSecurityYesOrNo: {
            disabled: isDisabled
        },
        alarmSecurityYesOrNo: {
            disabled: isDisabled
        },
        safeConditionYesOrNo: {
            disabled: isDisabled
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            infoPanel: InfoPanel
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={securityQuestionsVM}
            onValueChange={handleValueChange}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

ProtectingYourValuables.propTypes = {
    value: PropTypes.shape({}).isRequired,
    onValueChange: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired
};

export default ProtectingYourValuables;
