import { defineMessages } from 'react-intl';

export default defineMessages({
    removeCover: {
        id: 'endorsement.Bespoke.ngh.policychange-coverages.removeCover',
        defaultMessage: 'Remove cover'
    },
    addCover: {
        id: 'endorsement.Bespoke.ngh.policychange-coverages.addCover',
        defaultMessage: 'Add cover'
    },
    seeAllCoverBenefits: {
        id: 'endorsement.common.components.cycling-protection.seeAllCoverBenefits',
        defaultMessage: 'See all cover benefits.'
    },
    perMonth: {
        id: 'endorsement.common.components.cycling-protection.perMonth',
        defaultMessage: 'Per Month'
    },
    perYear: {
        id: 'endorsement.common.components.cycling-protection.perYear',
        defaultMessage: 'Per Year'
    }
});
