import { defineMessages } from 'react-intl';

export default defineMessages({
    policyDetailsIntro: {
        id: 'endorsement.bespoke.views.bespoke-policychange-summary.policyDetailsIntro',
        defaultMessage: 'A summary of the changes you have requested are below, if you are happy with these changes select Confirm Changes. If you wish to amend your changes, select Back.'
    },
    policyDetails: {
        id: 'endorsement.bespoke.views.bespoke-policychange-summary.policyDetails',
        defaultMessage: 'Policy Details'
    },
    valuables: {
        id: 'endorsement.bespoke.views.bespoke-policychange-summary.valuables',
        defaultMessage: 'Changes to your Valuables'
    },
    fineArts: {
        id: 'endorsement.bespoke.views.bespoke-policychange-summary.fineArts',
        defaultMessage: 'Changes to your Fine Art & Collections'
    },
    editChanges: {
        id: 'endorsement.bespoke.views.bespoke-policychange-summary.editChanges',
        defaultMessage: 'Edit changes'
    },
    cancelChanges: {
        id: 'endorsement.bespoke.views.bespoke-policychange-summary.cancelChanges',
        defaultMessage: 'Cancel changes'
    },
    confirmChanges: {
        id: 'endorsement.bespoke.views.bespoke-policychange-summary.confirmChanges',
        defaultMessage: 'Confirm changes'
    },
    policyDetailsIntroTitle: {
        id: 'endorsement.bespoke.views.bespoke-policychange-summary.policyDetailsIntroTitle',
        defaultMessage: 'Review your requested changes'
    },
});
