/* eslint-disable */
import React, { useState } from 'react';
import { Icon } from '@jutro/components';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { NfumFieldLabel } from 'nfum-components-platform-react';
import classNames from 'classnames';
import styles from './NfumCurrencyField.module.scss';

function NfumCurrencyField(props) {
    const {
        value,
        success,
        error,
        onValidationChange,
        onBlur,
        className,
        labelClassName,
        isFullSize,
        immediateInfoMessages,
        iconPosition
    } = props;
    const [currentValidation, updateCurrentValidation] = useState(true);
    const [showState, updateShowState] = useState(false);

    const showError = value !== undefined && (error || !currentValidation);
    let icon;
    
    if (showError) {
        icon = "gw-close";
    } else if (success || value) {
        icon = "gw-check";
    } else {
        icon = undefined;
    }
    const iconClass = classNames(styles.nfumInputIcon, {
        [styles.errorIcon]: showError
    });
    const inputClass = classNames(styles.nfumInputField, className, {
        [styles.fullSize]: isFullSize
    });

    const iconComponent = <Icon icon={icon} className={iconClass}/>;

    const onValidate = (isValid, path) => {
        updateCurrentValidation(isValid);
        onValidationChange(isValid, path);
    };

    const handleBlur = (event, data) => {
        onBlur && onBlur(data.value, data.model);
        updateShowState(true);
    };

    return (
        <div className={styles.nfumInputContainer}>
            <NfumFieldLabel
                {...props}
                className={labelClassName}
            />
            <div className={styles.nfumInput}>
                <CurrencyField
                    {...props}
                    icon={'cust-currency-pound'}
                    iconPosition={iconPosition}
                    className={inputClass}
                    onValidationChange={onValidate}
                    onBlur={handleBlur}
                    hideLabel="true"
                />
                {(showState && !isFullSize) && iconComponent}
            </div>
            { immediateInfoMessages && (
                <div>
                    { immediateInfoMessages.map((info) => {
                        return (<div>{info}</div>)
                    }) }
                </div>)
            }
        </div>
    );
}

export default NfumCurrencyField;
