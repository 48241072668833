import React, {
    useState,
    useMemo,
    useEffect,
    useContext,
    createContext,
} from 'react';
import { isEqual } from 'lodash';
import { OktaAuth } from '@okta/okta-auth-js';
import { useHistory } from 'react-router-dom';
import joinUrl from 'proper-url-join';
import { getConfigValue } from '@jutro/config';
import fetchUserInfo from './fetchUserInfo';

export const AuthStateContext = createContext(
    // We do not expect the context to be used without provider
    // https://github.com/typescript-cheatsheets/react#extended-example
    undefined
);

export const useAuthState = () => useContext(AuthStateContext);

function convertAuthState(state) {
    const {
        isAuthenticated,
        accessToken,
        idToken,
        error,
        userInfo = null,
    } = state || {};
    const newState = {
        isAuthenticated: !!isAuthenticated,
        isPending: state === null,
        accessToken: accessToken ? accessToken.accessToken : null,
        idToken: idToken ? idToken.idToken : null,
        userInfo,
    };
    if (error) {
        newState.error = error;
    }
    return newState;
}
/**15/09/22 Commented getUser call as it is cancelling the logout call */
export const transformAuthState = async (
    oktaAuth,
    authState
) => {
    if (authState.accessToken && authState.idToken) {
        return {
            ...authState,
            // getUser requires both tokens to be valid
            // Note: even though idToken.claims contains a lot of user info
            // it still may missing some data - https://developer.okta.com/docs/reference/api/oidc/#response-example-success-5
            // @ts-ignore see https://github.com/okta/okta-auth-js/issues/667
            userInfo: {}, // await oktaAuth.getUser(),
        };
    }

    // cookie size limitation causes issue with storing IDToken
    // https://github.com/okta/okta-auth-js/issues/507
    // As workaround require only accessToken to be available to mark user as authenticated
    if (authState.accessToken && !authState.idToken) {
        return {
            ...authState,
            userInfo: await fetchUserInfo(authState.accessToken),
            isAuthenticated: true,
        };
    }

    return authState;
};

export const AuthStateProvider = ({
    children,
    oktaAuth: oktaAuthProp,
    ...props
}) => {
    const history = useHistory();
    const oktaAuth = useMemo(
        () => oktaAuthProp || new OktaAuth({
            transformAuthState,
            ...props,
        }),
        // Do not reinitiate OktaAuth if props changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [oktaAuthProp]
    );

    if (!oktaAuth.options.restoreOriginalUri) {
        // From okta-react implementation
        // By default okta uses window.location.replace - we want to use react-router-dom
        // https://github.com/okta/okta-auth-js#restoreoriginaluri
        oktaAuth.options.restoreOriginalUri = async (_, originalUri) => {
            history.replace(
                originalUri.replace(
                    joinUrl(window.location.origin, getConfigValue('JUTRO_ROUTER_BASENAME', '')),
                    ''
                )
            );
        };
    }

    const [authState, setAuthState] = useState(() => {
        return convertAuthState(oktaAuth.authStateManager.getAuthState());
    });

    useEffect(() => {
        const subscriptionHandler = () => {
            setAuthState((oldState) => {
                const newState = convertAuthState(
                    oktaAuth.authStateManager.getAuthState()
                );
                if (isEqual(oldState, newState)) {
                    // it won't update if state is identical
                    return oldState;
                }
                return newState;
            });
        };
        oktaAuth.authStateManager.subscribe(subscriptionHandler);

        // Trigger an initial change event to make sure authState is latest
        if (!oktaAuth.isLoginRedirect()) {
            oktaAuth.authStateManager.updateAuthState();
        }
        return () => {
            oktaAuth.authStateManager.unsubscribe(subscriptionHandler);
        };
    }, [oktaAuth]);

    const stateMemo = useMemo(() => ({ oktaAuth, authState }), [
        oktaAuth,
        authState,
    ]);

    return (
        <AuthStateContext.Provider value={stateMemo}>
            {children}
        </AuthStateContext.Provider>
    );
};
