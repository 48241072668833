import { defineMessages } from 'react-intl';

export default defineMessages({
    policyDetails: {
        id: 'endorsement.ngh.views.policychange-summary.policyDetails',
        defaultMessage: 'Policy Details'
    },
    valuables: {
        id: 'endorsement.ngh.views.policychange-summary.valuables',
        defaultMessage: 'Changes to Your Valuables'
    },
    contentsCard: {
        id: 'endorsement.ngh.views.policychange-summary.contentsCard',
        defaultMessage: 'Changes to Your Contents'
    },
    contentsAway: {
        id: 'endorsement.ngh.views.policychange-summary.contentsAway',
        defaultMessage: 'Contents Away from the Home'
    },
    bicycles: {
        id: 'endorsement.ngh.views.policychange-summary.bicycles',
        defaultMessage: 'Bicycles'
    },
    additionalInsurances: {
        id: 'endorsement.ngh.views.policychange-summary.additionalInsurances',
        defaultMessage: 'Additional Insurances'
    },
    editChanges: {
        id: 'endorsement.ngh.views.policychange-summary.editChanges',
        defaultMessage: 'Edit changes'
    },
    cancelChanges: {
        id: 'endorsement.ngh.views.policychange-summary.cancelChanges',
        defaultMessage: 'Cancel changes'
    },
    confirmChanges: {
        id: 'endorsement.ngh.views.policychange-summary.confirmChanges',
        defaultMessage: 'Confirm changes'
    },
    yourDocuments: {
        id: 'endorsement.ngh.views.policychange-summary.yourDocuments',
        defaultMessage: 'Your documents'
    },
    documentsInfo: {
        id: 'endorsement.ngh.views.policy-change-summary.documentsInfo',
        defaultMessage: 'Please take the time to read these important documents before you complete your transaction to ensure the cover is right for you. You \'ll also find details on how to cancel and how to make a claim'
    },
    specialConditionsTitle: {
        id: 'endorsement.ngh.views.policychange-summary.specialConditionsTitle',
        defaultMessage: 'Special conditions'
    },
    specialConditionsBodyText1: {
        id: 'endorsement.ngh.views.policychange-summary.specialConditionsBodyText1',
        defaultMessage: 'Please note specific conditions apply to your policy. Please check your '
    },
    policyScheduleLink: {
        id: 'endorsement.ngh.views.policychange-summary.policyScheduleLink',
        defaultMessage: 'schedule'
    },
    specialConditionsBodyText2: {
        id: 'endorsement.ngh.views.policychange-summary.specialConditionsBodyText2',
        defaultMessage: ' for full details.'
    },
    addValidationReferalMessage1: {
        id: 'quoteandbind.ngh.views.policy-change-summary.addValidationReferalMessage1',
        defaultMessage: 'We can only setup payments online that are both in your name and have the same billing address as the property you are looking to insure.'
    },
    addValidationReferalMessage2: {
        id: 'quoteandbind.ngh.views.policy-change-summary.addValidationReferalMessage2',
        defaultMessage: 'If you want to use a different method of payment that is in your name and matches the address you want to insure then click \'I confirm\' and proceed. Alternatively, you can get in touch using the options below'
    },
    billingInformation: {
        id: 'quoteandbind.ngh.views.policy-change-summary.insureBillingAddress',
        defaultMessage: 'The billing address of this account is the address you are looking to insure'
    },
    billingAddress: {
        id: 'quoteandbind.ngh.views.policy-change-summary.billingAddress',
        defineMessages: 'Billing address'
    },
    confirmMessage: {
        id: 'quoteandbind.ngh.views.policy-change-summary.confirmMessage',
        defaultMessage: 'Please confirm the following statements are correct:'
    },
    yourNameAddress: {
        id: 'quoteandbind.ngh.views.policy-change-summary.yourNameAddress',
        defaultMessage: 'The account you are paying from is in your name'
    },
    nfumMutualInsurance: {
        id: 'endorsement.ngh.views.policychange-summary.nfumMutualInsurance',
        defaultMessage: 'NFU Mutual Home Insurance underwritten by NFU Mutual'
    }

});
