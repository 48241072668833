import { defineMessages } from 'react-intl';

export default defineMessages({
    edit: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsItem.edit',
        defaultMessage: 'Edit'
    },
    delete: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsItem.delete',
        defaultMessage: 'delete'
    },
    description: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsItem.description',
        defaultMessage: 'description'
    },
    replacementCost: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsItem.replacementCost',
        defaultMessage: 'Replacement cost'
    },
    category: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsItem.category',
        defaultMessage: 'Category'
    },
    type: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsItem.type',
        defaultMessage: 'Type'
    },
    collectionType: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsItem.collectionType',
        defaultMessage: 'Collection type'
    },
    agreedValue: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsItem.agreedValue',
        defaultMessage: 'Agreed value'
    },
    valuationDate: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsItem.valuationDate',
        defaultMessage: 'When was the valuation done?'
    },
    collectionKept: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsItem.collectionKept',
        defaultMessage: 'Where is the collection kept?'
    },
    sumInsured: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsItem.sumInsured',
        defaultMessage: 'Sum insured'
    },
    fineArts: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsItem.fineArts',
        defaultMessage: 'Fine Arts'
    },
    wineCollection: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsItem.wineCollection',
        defaultMessage: 'Wine collection'
    },
    stampsMedalsAndCoins: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsItem.stampsMedalsAndCoins',
        defaultMessage: 'Stamps, medals and coins collections'
    },
    otherCollections: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsItem.otherCollections',
        defaultMessage: 'Other collections'
    }
});
