import { defineMessages } from 'react-intl';

export default defineMessages({
    logoutConfirmation: {
        id: 'logoutModal.logoutConfirmation',
        defaultMessage: 'Are you sure you want to sign out?'
    },
    continueSessionButton: {
        id: 'logoutModal.continueSessionButton',
        defaultMessage: 'Continue session'
    },
    logoutButton: {
        id: 'logoutModal.logoutButton',
        defaultMessage: 'Sign out'
    }
});
