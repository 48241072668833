import React, { useCallback, useEffect } from 'react';
import { isString, uniq } from 'lodash';
import {
    EVENT_ADDED,
    EVENT_EXPIRED,
    EVENT_RENEWED,
    EVENT_ERROR,
    EVENT_REMOVED,
} from '@okta/okta-auth-js';
import { error } from '@jutro/logger';
import { getConfigValue } from '@jutro/config';
import joinUrl from 'proper-url-join';
import { getTokenByKey, setToken, removeTokenByKey } from './AuthTokenStorage';
import { useAuthState } from './AuthStateProvider';
import { AuthContext } from './AuthContext';

const OKTA_NOT_FOUND = 'E0000007';

const AuthContextProvider = ({ children }) => {
    const { authState, oktaAuth } = useAuthState();
    const { tokenManager } = oktaAuth;
    const basenameUri = getConfigValue('JUTRO_ROUTER_BASENAME', '');
    const logout = useCallback(async (fromUri) => {
        // Filter anything but strings in fromUri to avoid passing events
        // when this function is used as an event handler
        const logoutUri = isString(fromUri)
            ? fromUri
            : (getConfigValue('JUTRO_AUTH_LOGOUT_REDIRECT_PATH', '/logout'));
        await oktaAuth
            .signOut({
                postLogoutRedirectUri: logoutUri
            })
            .catch((err) => {
                const { errorCode } = err;
                const errorMsg = `Jutro Auth logout failure: ${errorCode}`;
                error(errorMsg);

                /**
                 * Swallow not found errors due to 3rd party cookie issues in FF & Safari
                 * See: https://github.com/okta/okta-oidc-js/issues/418
                 */
                if (errorCode !== OKTA_NOT_FOUND) {
                    throw new Error(errorMsg);
                }
            });
    }, [oktaAuth]);

    useEffect(() => { /* NOSONAR: GW PATCH - high complexity */
        const autoRenew = getConfigValue('JUTRO_AUTH_AUTO_RENEW', true);
        const expiredHandler = () => {
            if (!autoRenew || window.localStorage.getItem('logoutAM') === 'true') {
                logout();
            }
        };
        const addHandler = (key, token) => {
            if (key === 'accessToken' && !token.pendingRemove) {
                setToken(key, token.accessToken);
            }
            if (key === 'idToken' && !token.pendingRemove) {
                setToken(key, token.idToken);
            }
        };
        const errorHandler = () => {
            error('Jutro Auth: failed to renew the token. Logging out...');
            // eslint-disable-next-line max-len
            // logout(); //Commented this as it was getting called in loop for any okta failure /* NOSONAR: GW PATCH - high complexity */
        };
        const removeHandler = (key) => {
            removeTokenByKey(key);
        };

        tokenManager.on(EVENT_ADDED, addHandler);
        tokenManager.on(EVENT_RENEWED, addHandler);
        tokenManager.on(EVENT_REMOVED, removeHandler);
        tokenManager.on(EVENT_EXPIRED, expiredHandler);
        tokenManager.on(EVENT_ERROR, errorHandler);

        tokenManager.getTokens().then(({ idToken, accessToken }) => {
            if (window.localStorage.getItem('logoutAM') === 'true') {
                tokenManager.clear();
            } else {
                if (idToken && !tokenManager.hasExpired(idToken)) {
                    setToken('idToken', idToken.idToken);
                }
                if (accessToken && !tokenManager.hasExpired(accessToken)) {
                    setToken('accessToken', accessToken.accessToken);
                }
            }
        });

        return () => {
            tokenManager.off(EVENT_ADDED, addHandler);
            tokenManager.off(EVENT_RENEWED, addHandler);
            tokenManager.off(EVENT_REMOVED, removeHandler);
            tokenManager.off(EVENT_EXPIRED, expiredHandler);
            tokenManager.off(EVENT_ERROR, errorHandler);
        };
    }, [logout, tokenManager]);

    const allocateToken = async (
        scopes,
        newTokenKey
    ) => {
        if (!scopes || scopes.length === 0) {
            const errorMsg = 'scopes are not provided';
            error(errorMsg);
            return undefined;
        }
        if (!newTokenKey) {
            const errorMsg = 'newTokenKey is not provided';
            error(errorMsg);
            return undefined;
        }
        const { token } = oktaAuth;
        const defaultScopes = oktaAuth.options.scopes || [];
        const tokenScopes = uniq(defaultScopes.concat(scopes));
        const newTokensResponse = await token.getWithoutPrompt({
            scopes: tokenScopes,
        });

        const { accessToken } = newTokensResponse.tokens;
        if (accessToken) {
            setToken(newTokenKey, accessToken.accessToken);
            tokenManager.add(newTokenKey, accessToken);
        }
        return getTokenByKey(newTokenKey);
    };

    const login = async (
        fromUri,
        additionalParams = {}
    ) => {
        const loginRedirectFallBack = window.location.href.replace(
            joinUrl(window.location.origin, basenameUri),
            ''
        );
        oktaAuth.tokenManager.start();
        // Filter anything but strings in fromUri to avoid passing events
        // when this function is used as an event handler
        let loginUri = isString(fromUri)
            ? fromUri
            : (getConfigValue('JUTRO_AUTH_LOGIN_REDIRECT_PATH', loginRedirectFallBack));

        if (loginUri.includes('/logout')) {
            loginUri = '/';
        }

        const idp = getConfigValue('JUTRO_AUTH_IDP');
        const params = {
            ...(idp && { idp }),
            ...additionalParams,
        };

        oktaAuth.setOriginalUri(
            joinUrl(window.location.origin, basenameUri, loginUri, {
                trailingSlash: true,
            })
        );
        if (!authState.isAuthenticated && !authState.accessToken) {
            await oktaAuth.signInWithRedirect(params);
        }
    };

    const getAccessToken = () => oktaAuth.getAccessToken();

    const getIdToken = () => oktaAuth.getIdToken();

    const decodeToken = (token) => oktaAuth.token.decode(token);

    const getDecodedIdToken = () => {
        const token = getIdToken();
        if (!token) {
            return undefined;
        }
        return decodeToken(token);
    };

    const getDecodedAccessToken = () => {
        const token = getAccessToken();
        if (!token) {
            return undefined;
        }
        return decodeToken(token);
    };

    const getAuth = () => ({
        ...authState,
        authenticated: authState.isAuthenticated,
        tokenManager,
        allocateToken,
        decodeToken,
        getAccessToken,
        getDecodedIdToken,
        getDecodedAccessToken,
        getIdToken,
        login,
        logout,
    });

    return (
        <AuthContext.Provider value={getAuth()}>
            {children}
        </AuthContext.Provider>
    );
};

AuthContextProvider.displayName = 'AuthContextProvider';

export default AuthContextProvider;
