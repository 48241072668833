import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDependencies } from 'gw-portals-dependency-react';
import { TranslatorContext } from '@jutro/locale';
import { useAuthentication } from 'gw-digital-auth-react';
import { useCallRetry } from 'nfum-portals-utils-react';
import NfumLoader from '../NfumLoader/NfumLoader';
import DocumentsLinksComponent from './DocumentsLinksComponent/DocumentsLinksComponent';
import styles from './DocumentsLinks.module.scss';
import messages from './DocumentsLinks.messages';

function DocumentsLinks(props) {
    const { authHeader } = useAuthentication();
    const translator = useContext(TranslatorContext);
    const {
        jobId,
        description
    } = props;
    const { PolicyService } = useDependencies('PolicyService');

    const fetchDocuments = useCallback(async () => {
        const docs = await PolicyService.getDocuments(
            [jobId], authHeader
        );
        return docs;
    }, [PolicyService, jobId, authHeader]);

    const {
        loading: isLoading,
        error,
        data: documents
    } = useCallRetry(
        fetchDocuments,
        {
            retryTimes: 3,
            retryDelayFactor: 2,
            retryDelayInSeconds: 5,
            initialIsLoading: true
        },
        []
    );

    return (
        <div>
            { isLoading && (
                <NfumLoader loaded={!isLoading} text={translator(messages.loading)} />
            )}
            { error && (
                <p>{translator(messages.error)}</p>
            )}
            { !!documents && (
                <p className={styles.description}>{ description }</p>
            )}
            { documents && (<DocumentsLinksComponent documents={documents} />)}
        </div>
    );
}

DocumentsLinks.propTypes = {
    jobId: PropTypes.string.isRequired
};

export default DocumentsLinks;
