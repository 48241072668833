import { defineMessages } from 'react-intl';

export default defineMessages({
    pageTitle: {
        id: 'policy.view.ngh.policy-details.Policy Details',
        defaultMessage: 'Policy Details: {policyNumber}'
    },
    currentTab: {
        id: 'policy.view.ngh.policy-details.tab.Current',
        defaultMessage: 'Current'
    },
    changePolicy: {
        id: 'policy.view.ngh.policy-details.Change Policy',
        defaultMessage: 'Change Policy'
    },
    renewalTab: {
        id: 'policy.view.ngh.policy-details.tab.Renewing',
        defaultMessage: 'Renewing'
    },
    DownloadIDCard: {
        id: 'policy.view.ngh.policy-details.Download ID Card',
        defaultMessage: 'Download ID Card'
    },
    viewPolicy: {
        id: 'policy.view.ngh.policy-details.View Renewal Policy',
        defaultMessage: 'View Renewal Policy'
    }
});
