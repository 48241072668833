import React, { useContext, useCallback } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { useCurrency } from 'nfum-portals-utils-react';
import messages from './ContentsMTASummary.messages';
import metadata from './ContentsMTASummary.metadata.json5';
import styles from './ContentsMTASummary.module.scss';

function ContentsMTASummary(props) {
    const {
        data: contentsOverrideCostModel
    } = props;
    const currencyFormatter = useCurrency();
    const translator = useContext(TranslatorContext);
    const replFixedInt = 0.5;

    const getContentsOverrideCost = useCallback((cost) => {
        if (!cost) return null;
        return currencyFormatter.formatCurrency(cost);
    }, [currencyFormatter]);

    const renderCoverageValue = (item, index, metadataProps) => {
        const { path } = metadataProps;
        return item[path];
    };

    const overrideProps = {
        contentsTable: {
            title: translator(messages.contents),
            data: [
                {
                    oldValue: getContentsOverrideCost(contentsOverrideCostModel.oldValue),
                    newValue: getContentsOverrideCost(contentsOverrideCostModel.newValue)
                }
            ]
        },
        valuablesLimitTable: {
            title: translator(messages.valuablesReplLimit),
            data: [
                {
                    oldValue: getContentsOverrideCost((contentsOverrideCostModel.oldValue)
                 * replFixedInt),
                    newValue: getContentsOverrideCost((contentsOverrideCostModel.newValue)
                    * replFixedInt)
                }
            ]
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            renderCoverageValue: renderCoverageValue
        }
    };

    return (
        <ViewModelForm
            model={contentsOverrideCostModel}
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            overrideProps={overrideProps}
        />
    );
}

export default ContentsMTASummary;
