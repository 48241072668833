import { defineMessages } from 'react-intl';

export default defineMessages({
    continue: {
        id: 'endorsement.bespoke.views.bespoke-policychange-success.continue',
        defaultMessage: 'Return to dashboard'
    },
    nfumMutualInsuranceSuccess: {
        id: 'endorsement.bespoke.views.bespoke-policychange-success.nfumMutualInsurance',
        defaultMessage: 'NFU Mutual Home Insurance underwritten by NFU Mutual'
    },
    bespokeChangeConfirmationInfo1: {
        id: 'endorsement.bespoke.views.bespoke-policychange-success.bespokeChangeConfirmation',
        defaultMessage: 'The information you’ve provided has been forwarded to your agency. Your agency will call you as soon as possible to discuss your requirements.'
    },
    bespokeChangeWhatIsNext: {
        id: 'endorsement.bespoke.views.bespoke-policychange-success.WhatIsNext',
        defaultMessage: 'If you need to speak to someone urgently in the meantime, you can contact your agency using the telephone number below.'
    },
    policyDetailsIntroTitle: {
        id: 'endorsement.bespoke.views.bespoke-policychange-success.policyDetailsIntroTitle',
        defaultMessage: 'What happens next?'
    },
    policyDetailsIntro1: {
        id: 'endorsement.bespoke.views.bespoke-policychange-success.policyDetailsIntro1',
        defaultMessage: 'Important to know'
    },
    bespokeChangeConfirmationInfo2: {
        id: 'endorsement.bespoke.views.bespoke-policychange-success.bespokeChangeConfirmationInfo2',
        defaultMessage: 'No changes will be made to your policy until you\'ve agreed and accepted them with your agency.'
    }
});
