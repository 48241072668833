import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 *
 * @param {any} method the method to invoke on the backend endpoint
 * @param {any} data the data to be passed as part of the invokation on the backend
 * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} the promise from the backend call
 */
function processRenewal(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('renewal'), method, data, additionalHeaders);
}

export default class RenewalService {
    static retrieveByPolicyOrJobNumber(data, additionalHeaders = {}) {
        return processRenewal('retrieveByPolicyOrJobNumber', [data], additionalHeaders);
    }

    static getRedirectURL(data, additionalHeaders = {}) {
        return processRenewal('getRedirectUrl', data, additionalHeaders);
    }

    /**
     * Defect 714776 Check Payment Status during Purchase and update Billing Summary in PC
     * for NFUM.     *
     * @param {string} jobID
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise}
     * @memberof LoadSaveService
     */
    static checkPaymentStatus_NFUM(jobID, additionalHeaders = {}) {
        return processRenewal('checkPaymentStatus_NFUM', [jobID], additionalHeaders);
    }
}
