export default [
    {
        stepId: 0,
        title: {
            id: 'portal.wizardStep.bespokePolicyChange.Make changes to your bespoke insurance policy',
            defaultMessage: 'Request a change',
        },
        isTransactionNumberHidden: true
    },
    {
        stepId: 1,
        title: {
            id: 'portal.wizardStep.bespokePolicyChange.reviewYourChanges',
            defaultMessage: 'Review your changes',
        },
        isTransactionNumberHidden: true
    },
    {
        stepId: 2,
        title: {
            id: 'portal.wizardStep.bespokePolicyChange.Your changes have been submitted',
            defaultMessage: 'Thanks, your request has been submitted',
        },
        isTransactionNumberHidden: true,
        isPolicyNumberHidden: true
    }
];
