/* eslint-disable */
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { StepperField, Icon } from '@jutro/components';
import { NfumFieldLabel } from 'nfum-components-platform-react';
import classNames from 'classnames';

import styles from './NfumStepperField.module.scss';

function NfumStepperField(props) {
    const {
        value,
        success,
        error,
        onValidationChange,
        onValueChange,
        shouldShowState
    } = props;
    const [currentValidation, updateCurrentValidation] = useState(true);
    const [showState, updateShowState] = useState(false);

    const showError = value !== undefined && (error || !currentValidation);
    let icon;
    if (showError) {
    icon = "gw-close";
    } else if (success || value) {
    icon = "gw-check";
    } else {
    icon = undefined;
    }
    const iconClass = classNames(styles.nfumInputIcon, {
        [styles.errorIcon]: showError
    });

    const iconComponent = <Icon icon={icon} className={iconClass}/>;

    const onValidate = (isValid, path) => {
        updateCurrentValidation(isValid);
        onValidationChange(isValid, path);
    };

    const handleValueChange = useCallback(
        (data, changedPath) => {
            updateShowState(true);
            if (onValueChange) {
                onValueChange(data, changedPath);
            }
        },
        [onValueChange]
    );

    return (
        <div className={styles.nfumInputContainer}>
            <NfumFieldLabel
                {...props}
            />
            <div className={styles.nfumInput}>
                <StepperField
                    {...props}
                    className={styles.nfumInputField}
                    onValidationChange={onValidate}
                    onValueChange={handleValueChange}
                    hideLabel="true"
                />
                {(shouldShowState && showState) && iconComponent}
            </div>
        </div>
    );
}

NfumStepperField.propTypes = {
    error: PropTypes.bool,
    success: PropTypes.bool,
    showEdit: PropTypes.bool,
    shouldShowState: PropTypes.bool
};

NfumStepperField.defaultProps = {
    error: false,
    success: false,
    showEdit: false,
    shouldShowState: true
};

export default NfumStepperField;
