import { defineMessages } from 'react-intl';

export default defineMessages({
    unspecifiedItemsAway: {
        id: 'endorsement.ngh.views.policychange-summary.contentsAway.unspecifiedItemsAway',
        defaultMessage: 'Unspecified items away from your home'
    },
    itemsAdded: {
        id: 'endorsement.ngh.views.policychange-summary.contentsAway.itemsAdded',
        defaultMessage: 'Items added'
    },
    itemsChanged: {
        id: 'endorsement.ngh.views.policychange-summary.contentsAway.itemsChanged',
        defaultMessage: 'Items changed'
    },
    itemsRemoved: {
        id: 'endorsement.ngh.views.policychange-summary.contentsAway.itemsRemoved',
        defaultMessage: 'Items removed'
    },
    category: {
        id: 'endorsement.ngh.views.policychange-summary.contentsAway.category',
        defaultMessage: 'Category'
    },
    replacementCost: {
        id: 'endorsement.ngh.views.policychange-summary.contentsAway.replacementCost',
        defaultMessage: 'Replacement cost'
    },
    coverType: {
        id: 'endorsement.ngh.views.policychange-summary.contentsAway.coverType',
        defaultMessage: 'Cover type'
    },
    keptInBank: {
        id: 'endorsement.ngh.views.policychange-summary.contentsAway.keptInBank',
        defaultMessage: 'Kept in bank?'
    },
    yes: {
        id: 'endorsement.ngh.views.policychange-summary.contentsAway.yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'endorsement.ngh.views.policychange-summary.contentsAway.no',
        defaultMessage: 'No'
    },
    insideHomeOnly: {
        id: 'endorsement.ngh.views.policychange-summary.contentsAway.insideHomeOnly',
        defaultMessage: 'Inside the home only'
    },
    insideOutsideHome: {
        id: 'endorsement.ngh.views.policychange-summary.contentsAway.insideOutsideHome',
        defaultMessage: 'Both inside and outside the home (UK)'
    },
    originalAmount: {
        id: 'endorsement.ngh.views.policychange-summary.contentsAway.originalAmount',
        defaultMessage: 'Original amount'
    },
    newAmount: {
        id: 'endorsement.ngh.views.policychange-summary.contentsAway.newAmount',
        defaultMessage: 'New amount'
    },
    notSelected: {
        id: 'endorsement.ngh.views.policychange-summary.contentsAway.notSelected',
        defaultMessage: 'Not selected'
    },
    removed: {
        id: 'endorsement.ngh.views.policychange-summary.contentsAway.removed',
        defaultMessage: 'Removed'
    },
});
