import { defineMessages } from 'react-intl';

export default defineMessages({
    homeAndLifestyleInsurance: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.homeAndLifestyleInsurance',
        defaultMessage: 'Home & Lifestyle insurance'
    },
    bespokeInsurance: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.bespokeInsurance',
        defaultMessage: 'Bespoke insurance'
    },
    buildings: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.buildings',
        defaultMessage: 'Buildings'
    },
    contents: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.contents',
        defaultMessage: 'Contents'
    },
    buildingsAndContents: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.buildingsAndContents',
        defaultMessage: 'Buildings & contents'
    },
    catdog: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.catdog',
        defaultMessage: 'Cat/Dog'
    },
    personalAccident: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.personalAccident',
        defaultMessage: 'Personal Accident'
    },
    caravan: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.caravan',
        defaultMessage: 'Caravan'
    },
    travel: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.travel',
        defaultMessage: 'Travel'
    },
    personalLegalExpenses: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.personalLegalExpenses',
        defaultMessage: 'Personal Legal Expenses'
    },
    fineArtAndCollection: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.fineArtAndCollection',
        defaultMessage: 'Fine Art & Collections'
    },
    valuables: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.policyDetails.valuables',
        defaultMessage: 'Valuables'
    },
    policyNumber: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.policyNumber',
        defaultMessage: 'Policy number'
    },
    policyType: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.policyType',
        defaultMessage: 'Policy type'
    },
    coverType: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.coverType',
        defaultMessage: 'Cover type'
    },
    policyHolder: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.policyHolder',
        defaultMessage: 'Policyholder'
    },
    address: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.address',
        defaultMessage: 'Address'
    },
    policyStartAndEndDate: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.policyStartAndEndDate',
        defaultMessage: 'Policy start and end date'
    },
    paymentType: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.paymentType',
        defaultMessage: 'Payment type'
    },
    creditOrDebitCard: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.creditOrDebitCard',
        defaultMessage: 'Credit/Debit card'
    },
    directDebit: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.directDebit',
        defaultMessage: 'Direct Debit'
    },
});
