import { defineMessages } from 'react-intl';

export default defineMessages({
    increaseDirectDebit: {
        id: 'endorsement.ngh.views.payment.Increase to your Direct-Debit',
        defaultMessage: 'Increase to your Direct Debit'
    },
    decreaseDirectDebit: {
        id: 'endorsement.ngh.views.payment.Decrease to your Direct-Debit',
        defaultMessage: 'Decrease to your Direct Debit'
    },
    perMonth: {
        id: 'endorsement.ngh.views.payment.perMonth',
        defaultMessage: '/per month*'
    },
    perYear: {
        id: 'endorsement.ngh.views.payment.perYear',
        defaultMessage: '/per year*'
    },
    nothingToDo: {
        id: 'endorsement.ngh.views.payment.nothingToDo',
        defaultMessage: 'There’s nothing for you to do'
    },
    noPremiumChangesInfo: {
        id: 'endorsement.ngh.views.payment.noPremiumChangesInfo',
        defaultMessage: 'The changes you have made have not affected your premium. You can now exit this screen or return to your NFU Mutual dashboard.'
    },
    returnToDashboard: {
        id: 'endorsement.ngh.views.payment.returnToDashboard',
        defaultMessage: 'Return to dashboard'
    },
    editChanges: {
        id: 'endorsement.ngh.views.payment.editChanges',
        defaultMessage: 'Edit changes'
    },
    cancelChanges: {
        id: 'endorsement.ngh.views.payment.cancelChanges',
        defaultMessage: 'Cancel changes'
    },
    insurancePremiumTax: {
        id: 'endorsement.ngh.views.payment.insurancePremiumTax',
        defaultMessage: '*Includes Insurance Premium Tax (IPT) where appropriate'
    },
    totalToPay: {
        id: 'endorsement.ngh.views.payment.totalToPay',
        defaultMessage: 'Total to pay now:'
    },
    extraPremium: {
        id: 'endorsement.ngh.views.payment.extraPremium',
        defaultMessage: 'The extra premium of £XXXX.XX will be added to your renewal premium and collected as part of your first direct debit payment.'
    },
    yourNextPayment: {
        id: 'endorsement.ngh.views.payment.yourNextPayment',
        defaultMessage: 'Your next payment is {nextPaymentAmount}, followed by {numberOfPayments} payments of {followingPaymentsAmount}.'
    },
    additionalPremium: {
        id: 'endorsement.ngh.views.payment.additionalPremium',
        defaultMessage: 'There are additional premiums owing'
    },
    afterPaymentAction: {
        id: 'endorsement.ngh.views.payment.afterPaymentAction',
        defaultMessage: 'After Payment Action'
    },
    completeChanges: {
        id: 'endorsement.ngh.views.payment.completeChanges',
        defaultMessage: 'Continue'
    },
    extraRefundInfoContent: {
        id: 'endorsement.ngh.views.payment.extraRefundInfoContent',
        defaultMessage: 'Your policy change means you are due a refund, we will attempt a refund with the card you paid for the policy with, within 2-3 working days. If we are unable to do this we will be in contact.'
    },
    extraRefundInfoTitle: {
        id: 'endorsement.ngh.views.payment.extraRefundInfoTitle',
        defaultMessage: 'You are due a refund of {amount}'
    },
    cardLimit: {
        id: 'endorsement.ngh.views.payment.cardLimit',
        defaultMessage: 'Online Card payment limit is £25,000'
    },
    savedProgress: {
        id: 'endorsement.ngh.views.payment.savedProgress',
        defaultMessage: 'We have saved your progress and will be able to pick up with you where you left off. Alternatively you can'
    },
    cardLimitMessage: {
        id: 'endorsement.ngh.views.payment.cardLimitMessage',
        defaultMessage: 'Please contact us using one of the following options and we can support you with your Home Insurance amendment'
    },
    newPremiumTitle: {
        id: 'endorsement.ngh.views.payment.newPremiumTitle',
        defaultMessage: 'Your new home insurance premium'
    },
    decreaseOf: {
        id: 'endorsement.ngh.views.payment.decreaseOf',
        defaultMessage: 'This is a decrease of {amount}'
    },
    paymentUnavailable: {
        id: 'endorsement.ngh.views.payment.paymentUnavailable',
        defaultMessage: 'Payment is currently unavailable'
    },
    paymentUnavailableMessageText: {
        id: 'endorsement.ngh.views.payment.paymentUnavailableMessageText',
        defaultMessage: 'Payment service not available, please try again after some time or please contact us using one of the following options and we can support you with your Home Insurance amendment.​'
    },
    extraRefundPremium: {
        id: 'endorsement.ngh.views.payment.extraRefundPremium',
        defaultMessage: 'NFUM will reduce the renewal premium if the policy is renewed or be returned to the Policy Holder if the renewal is not taken.'
    },
    revisedPaymentSchedule: {
        id: 'endorsement.ngh.views.payment.revisedPaymentSchedule',
        defaultMessage: 'Revised payment schedule'
    },
    paymentDate: {
        id: 'endorsement.ngh.views.payment.paymentDate',
        defaultMessage: 'Payment date'
    },
    premium: {
        id: 'endorsement.ngh.views.payment.premium',
        defaultMessage: 'Premium'
    },
    paymentProcessing: {
        id: 'endorsement.ngh.views.payment.paymentProcessing',
        defaultMessage: 'Payment processing...'
    },
    cancelTransaction: {
        id: 'endorsement.ngh.views.payment.cancelTransaction',
        defaultMessage: 'Cancel transaction'
    },
    updatePaymentInformation: {
        id: 'endorsement.ngh.views.payment.updatePaymentInformation',
        defaultMessage: 'Update payment information'
    }
});
