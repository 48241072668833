import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'endorsement.ngh.views.paymentUnsuccessful.title',
        defaultMessage: 'We couldn\'t process your payment'
    },
    description1: {
        id: 'endorsement.ngh.views.paymentUnsuccessful.description1',
        defaultMessage: 'Unfortunately, we couldn\'t collect payment on your purchase. Please take a moment to review your billing information and try again.'
    },
    description2: {
        id: 'endorsement.ngh.views.paymentUnsuccessful.description2',
        defaultMessage: 'If you have any questions about your payment please contact us.'
    },
});
