import React, {
    useCallback, useContext, useMemo
} from 'react';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import {
    useCurrency
} from 'nfum-portals-utils-react';
import useTagManager from '../../hooks/useTagManager';
import metadata from './CyclingProtectionCoverage.metadata.json5';
import styles from './CyclingProtectionCoverage.module.scss';
import messages from './CyclingProtectionCoverage.messages';

function CyclingProtectionCoverage(props) {
    const {
        wizardData: policyChangeVM, updateWizardData, paths
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useContext(TranslatorContext);
    const {
        pushLinkClickInfo
    } = useTagManager();
    const currencyFormatter = useCurrency();
    const YES_KEY = 'Yes';
    const dynamicPricing = useMemo(
        () => _.get(policyChangeVM.value, 'dynamicPricing_NFUM'),
        [policyChangeVM.value]
    );
    const { bicycleExtensionPath } = paths;
    const bicycleExtensionValue = _.get(policyChangeVM, `${bicycleExtensionPath[1]}.chosenTermValue`);

    const getAddRemoveCoverToggleButtonLabel = (conditionForRemoveCoverLabel) => {
        return conditionForRemoveCoverLabel
            ? translator(messages.removeCover)
            : translator(messages.addCover);
    };

    const handleEnableBicycleExtensionCover = useCallback(
        (value) => {
            const newSubmissionVM = viewModelService.clone(policyChangeVM);
            _.set(newSubmissionVM, `${bicycleExtensionPath[1]}.chosenTermValue`, value ? 'Yes' : 'No');
            _.set(newSubmissionVM, `${bicycleExtensionPath[1]}.updated`, true);
            _.set(newSubmissionVM, `${bicycleExtensionPath[0]}.updated`, true);
            _.set(newSubmissionVM, `${bicycleExtensionPath[1]}.directBooleanValue`, value);
            updateWizardData(newSubmissionVM);
        },
        [bicycleExtensionPath, policyChangeVM, updateWizardData, viewModelService]
    );

    const getExtraDescription = useCallback(() => {
        const paymentMethod = _.get(policyChangeVM.value, 'baseData.paymentMethod_NFUM');
        if (paymentMethod === 'directdebit') {
            return messages.perMonth;
        }
        return messages.perYear;
    }, [policyChangeVM]);

    const getDynamicPrice = useCallback((amount) => {
        const paymentMethod = _.get(policyChangeVM.value, 'baseData.paymentMethod_NFUM');
        if (paymentMethod === 'directdebit') {
            return currencyFormatter.formatCurrency(amount / 12, true);
        }
        return currencyFormatter.formatCurrency(amount, true);
    }, [policyChangeVM, currencyFormatter]);

    const overrideProps = {
        enableBicycleExtensionCoverSwitch: {
            value: bicycleExtensionValue === YES_KEY,
            path: bicycleExtensionPath,
            label: getAddRemoveCoverToggleButtonLabel(bicycleExtensionValue === YES_KEY),
            description: getDynamicPrice(dynamicPricing.cycleProtectionPremium?.amount),
            extraDescription: getExtraDescription()
        },
    };
    const writeValue = useCallback(
        (value, path) => {
            _.set(policyChangeVM, path, value);
            updateWizardData(policyChangeVM);
        },
        [policyChangeVM, updateWizardData]
    );

    const seeAllCoverBenefits = useCallback(() => {
        const url = 'https://www.nfumutual.co.uk/home-insurance/cycling-protection/';
        pushLinkClickInfo(translator(messages.seeAllCoverBenefits), url);
        window.open(url, '_blank');
    }, [pushLinkClickInfo, translator]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallBackMap: {
            onEnableBicycleExtensionCover: handleEnableBicycleExtensionCover,
            onSeeAllCoverBenefitsClick: seeAllCoverBenefits
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policyChangeVM}
            onModelChange={updateWizardData}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallBackMap}
        />
    );
}

export default CyclingProtectionCoverage;
