import React, { useCallback, useMemo } from 'react';
import {
    ViewModelForm
} from 'gw-portals-viewmodel-react';
import { wizardProps } from 'gw-portals-wizard-react';
import { appendMetadataWithIndex } from 'gw-jutro-adapters-react';
import FineArtsItem from '../FineArtsItem/FineArtsItem';
import metadata from './FineArtsAddedItems.metadata.json5';
import styles from './FineArtsAddedItems.module.scss';

function FineArtsAddedItems(props) {
    const {
        itemsModel,
        index,
        editItem,
        removeItem,
        isExistingItems
    } = props;

    const formattedMetadata = useMemo(() => {
        return appendMetadataWithIndex(metadata.componentContent, index);
    }, [index]);

    const generateOverrides = useCallback(() => {
        if (itemsModel.items.length === 0) return Object.assign({});

        const fineArtsAddedItemsOverrides = itemsModel.items.map((item, ind) => {
            return {
                [`fineArtsItem${ind}`]: {
                    fineArtVM: item,
                    index: ind,
                    onRemoveItem: removeItem,
                    onEditItem: (fineArtVM) => editItem(fineArtVM, isExistingItems),
                    isExisting: isExistingItems
                }
            };
        });

        return Object.assign({},
            ...fineArtsAddedItemsOverrides);
    }, [
        itemsModel,
        removeItem,
        editItem,
        isExistingItems
    ]);

    const overrideProps = {
        [`category${index}`]: {
            content: itemsModel.name
        },
        [`addedItems${index}`]: {
            data: itemsModel.items
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            fineArtsItem: FineArtsItem
        }
    };

    return (
        <ViewModelForm
            model={itemsModel}
            uiProps={formattedMetadata}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

FineArtsAddedItems.propTypes = wizardProps;
export default FineArtsAddedItems;
