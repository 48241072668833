import { defineMessages } from 'react-intl';

export default defineMessages({
    yourCurrentPremium: {
        id: 'renewal.ngh.views.renewal-summary.costBreakdownBox.yourCurrentPremium',
        defaultMessage: 'Your current premium is {currentPremium} {perTime}, this takes in to account any changes you may have made to your policy. A full breakdown of your premium is included in your policy documents.'
    },
    yourNewPremium: {
        id: 'renewal.ngh.views.renewal-summary.costBreakdownBox.yourNewPremium',
        defaultMessage: 'From your renewal date of {renewalDate}, your premium will be:'
    },
    perYear: {
        id: 'renewal.ngh.views.renewal-summary.costBreakdownBox.perYear',
        defaultMessage: 'per year'
    },
    perMonth: {
        id: 'renewal.ngh.views.renewal-summary.costBreakdownBox.perMonth',
        defaultMessage: 'per month'
    },
});
