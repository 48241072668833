import React, { useContext, useCallback } from 'react';
import _ from 'lodash';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { useCurrency } from 'nfum-portals-utils-react';
import FineArtsSummaryItem from '../../../components/FineArtsSummaryItem/FineArtsSummaryItem';
import FineArtsSummaryAddedItems from '../../../components/FineArtsSummaryAddedItems/FineArtsSummaryAddedItems';
import FineArtsSummaryRemovedItems from '../../../components/FineArtsSummaryRemovedItems/FineArtsSummaryRemovedItems';
import messages from './FineArtsSummary.messages';
import metadata from './FineArtsSummary.metadata.json5';
import styles from './FineArtsSummary.module.scss';

function ValuablesSummary(props) {
    const {
        data: fineArtsDiff
    } = props;
    const currencyFormatter = useCurrency();
    const translator = useContext(TranslatorContext);

    const category = Object.freeze({
        FINE_ART: 'FineArt',
        WINES: 'WineCollections',
        STAMPS_MEDALS_COINS: 'StampsMedalsCoins',
        OTHER_COLLECTIONS: 'OtherCollections'
    });

    const getItemReplacementCost = useCallback((item) => {
        return currencyFormatter.formatCurrency(item.agreedValue || item.sumInsured);
    }, [currencyFormatter]);

    const getHeaderDescription = useCallback((fineArt) => {
        let description = '';
        const itemType = _.get(fineArt, 'itemType');
        if (itemType === category.FINE_ART) {
            description = `${fineArt.fineArtType}, ${fineArt.description}`;
        } else if (itemType === category.WINES) {
            description = translator(messages.wineCollection);
        } else {
            description = `${fineArt?.collectionType}`;
        }

        return `${description}`;
    }, [category, translator]);

    const generateOverrides = useCallback(() => {
        if (!fineArtsDiff) return Object.assign({});

        const changedStampsMedalsCoinsOverrides = fineArtsDiff.stampsMedalsCoins.edited
            .map((item, index) => {
                return {
                    [`changedStampsMedalsCoinsTable${index}`]: {
                        data: [
                            {
                                name: translator(messages.replacementCost),
                                oldValue: getItemReplacementCost(item.prev),
                                newValue: getItemReplacementCost(item.new)
                            }
                        ],
                        title: getHeaderDescription(item.new)
                    }
                };
            });

        const changedOtherCollectionsOverrides = fineArtsDiff.other.edited.map((item, index) => {
            return {
                [`changedOtherCollectionsTable${index}`]: {
                    data: [
                        {
                            name: translator(messages.replacementCost),
                            oldValue: getItemReplacementCost(item.prev),
                            newValue: getItemReplacementCost(item.new)
                        }
                    ],
                    title: getHeaderDescription(item.new)
                }
            };
        });

        const changedWinesOverrides = fineArtsDiff.wines.edited.map((item, index) => {
            return {
                [`changedWinesTable${index}`]: {
                    data: [
                        {
                            name: translator(messages.replacementCost),
                            oldValue: getItemReplacementCost(item.prev),
                            newValue: getItemReplacementCost(item.new)
                        }
                    ],
                    title: getHeaderDescription(item.new)
                }
            };
        });

        const changedFineArtsOverrides = fineArtsDiff.fineArts.edited.map((item, index) => {
            return {
                [`changedFineArtsTable${index}`]: {
                    data: [
                        {
                            name: translator(messages.replacementCost),
                            oldValue: getItemReplacementCost(item.prev),
                            newValue: getItemReplacementCost(item.new)
                        }
                    ],
                    title: getHeaderDescription(item.new)
                }
            };
        });

        const fineArtsAddedOverrides = fineArtsDiff.collections
            .filter((x) => x.added.length > 0)
            .map((item, index) => {
                return {
                    [`fineArtsSummaryAddedItems${index}`]: {
                        addedItemsModel: item,
                        index: index
                    }
                };
            });

        const fineArtsRemovedOverrides = fineArtsDiff.collections
            .filter((x) => x.removed.length > 0)
            .map((item, index) => {
                return {
                    [`fineArtsSummaryRemovedItems${index}`]: {
                        removedItemsModel: item,
                        index: index
                    }
                };
            });

        return Object.assign({},
            ...fineArtsAddedOverrides,
            ...fineArtsRemovedOverrides,
            ...changedFineArtsOverrides,
            ...changedStampsMedalsCoinsOverrides,
            ...changedWinesOverrides,
            ...changedOtherCollectionsOverrides);
    }, [
        fineArtsDiff,
        getItemReplacementCost,
        getHeaderDescription,
        translator
    ]);

    const renderValue = (item, index, metadataProps) => {
        const { path } = metadataProps;
        return item[path];
    };

    const renderTitle = () => {
        return translator(messages.replacementCost);
    };

    const overrideProps = {
        valuablesAddedContainer: {
            visible: fineArtsDiff.added.length > 0
        },
        valuablesChangedContainer: {
            visible: fineArtsDiff.edited.length > 0
        },
        valuablesRemovedContainer: {
            visible: fineArtsDiff.removed.length > 0
        },
        fineArtsAdded: {
            data: fineArtsDiff.collections.filter((x) => x.added.length > 0)
        },
        fineArtsRemoved: {
            data: fineArtsDiff.collections.filter((x) => x.removed.length > 0)
        },
        changedWinesCategory: {
            content: translator(messages.wine)
        },
        changedWinesTables: {
            data: fineArtsDiff.wines.edited
        },
        changedWinesContainer: {
            visible: fineArtsDiff.wines.edited.length > 0
        },
        changedOtherCollectionsCategory: {
            content: translator(messages.otherCollections)
        },
        changedOtherCollectionsTables: {
            data: fineArtsDiff.other.edited
        },
        changedOtherCollectionsContainer: {
            visible: fineArtsDiff.other.edited.length > 0
        },
        changedFineArtsCategory: {
            content: translator(messages.fineArts)
        },
        changedFineArtsTables: {
            data: fineArtsDiff.fineArts.edited
        },
        changedFineArtsContainer: {
            visible: fineArtsDiff.fineArts.edited.length > 0
        },
        changedStampsMedalsCoinsCategory: {
            content: translator(messages.stampsMedalsAndCoins)
        },
        changedStampsMedalsCoinsTables: {
            data: fineArtsDiff.stampsMedalsCoins.edited
        },
        changedStampsMedalsCoinsContainer: {
            visible: fineArtsDiff.stampsMedalsCoins.edited.length > 0
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            renderValue,
            renderTitle
        },
        resolveComponentMap: {
            fineArtsSummaryItem: FineArtsSummaryItem,
            fineArtsSummaryAddedItems: FineArtsSummaryAddedItems,
            fineArtsSummaryRemovedItems: FineArtsSummaryRemovedItems
        }
    };

    return (
        <ViewModelForm
            model={fineArtsDiff}
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            overrideProps={overrideProps}
        />
    );
}

export default ValuablesSummary;
