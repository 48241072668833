import { getProxiedUrl } from 'gw-portals-url-js';

const fetchContent = (url, headers = {}) => {
    return new Promise((resolve, reject) => {
        fetch(url, { headers }).then((resp) => {
            if (!resp.ok) {
                reject(new Error(`${resp.status} ${resp.url}`));
            }
            return resp.json();
        }).then((jsonRes) => {
            resolve(jsonRes);
        }).catch((err) => {
            reject(err);
        });
    });
};

const useCms = () => {
    const createCmsSiteMapPromise = () => {
        const siteMapUrl = getProxiedUrl('api/menu/sitemap');
        const siteMapUrlUrl = `${siteMapUrl}?siteName=Bridge`;
        return fetchContent(siteMapUrlUrl);
    };

    const createCmsContentPromise = function getCmsContent(contentId) {
        if (!contentId) throw new Error('Content id from siteMap is null');
        const url = getProxiedUrl('api/episerver/v3.0/content');
        const cmsUrl = `${url}/${contentId}?expand=%2A`;
        return fetchContent(cmsUrl, { 'Accept-Language': 'en' });
    };

    return {
        createCmsSiteMapPromise,
        createCmsContentPromise
    };
};

export default useCms;
