import React, {
    useCallback,
    useEffect,
    useRef,
    useContext
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { NfumAccordionHeader } from 'nfum-components-platform-react';
import { BESPOKE_COVERAGES_MTA_STEPS } from 'nfum-portals-utils-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { useTagManager, usePeriodsDiff } from 'nfum-capability-policychange-common-react';
import commonMessages from 'nfum-capability-policychange-common-react/NGHPolicyChange.messages';
import PolicyDetailsSummary from 'nfum-capability-policychange-ngh-react/pages/PolicyChangeSummary/PolicyDetailsSummary/PolicyDetailsSummary';
import BespokeValuablesSummary from '../../components/BespokeValuablesSummary/BespokeValuablesSummary';
import FineArtsSummary from './FineArtsSummary/FineArtsSummary';
import metadata from './BespokePolicyChangeSummaryPage.metadata.json5';
import styles from './BespokePolicyChangeSummaryPage.module.scss';
import messages from './BespokePolicyChangeSummaryPage.messages';

function BespokePolicyChangeSummaryPage(props) {
    const translator = useContext(TranslatorContext);
    const {
        wizardData: policySubmissionVM, updateWizardData, steps, jumpTo, authHeader
    } = props;
    const scrollToPolicyDetailsSection = useRef();
    const scrollToValuablesMTACardSection = useRef();
    const scrollToFineArtsMTACardSection = useRef();
    const policyDetailsOffset = -90;
    const { EndorsementService } = useDependencies('EndorsementService');
    const cardsIds = Object.freeze({
        POLICY_DETAILS_CARD: 'policyDetailsCard',
        VALUABLES_CARD: 'valuablesCard',
        FINEARTS_CARD: 'fineArtsCard'
    });

    const {
        pushFormStepInfo,
        pushAccordionOpenInfo
    } = useTagManager();

    const {
        getValuables,
        getFineArtsAndCollections
    } = usePeriodsDiff(policySubmissionVM);

    const specificValuables = getValuables();
    const fineArtCollections = getFineArtsAndCollections();

    // #region GTM EVENTS

    useEffect(() => {
        pushFormStepInfo(policySubmissionVM, BESPOKE_COVERAGES_MTA_STEPS.MTA_SUMMARY);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFineArtsAccordion = () => {
        if (scrollToValuablesMTACardSection?.current) {
            scrollToValuablesMTACardSection?.current?.scrollIntoView();
        } else {
            scrollToPolicyDetailsSection?.current?.scrollIntoView();
        }
    };

    const onUpdateOpenAccordion = useCallback((accordionCardsIds) => {
        const openCardId = accordionCardsIds && accordionCardsIds.length > 0
            ? accordionCardsIds[0] : null;
        switch (openCardId) {
            case cardsIds.POLICY_DETAILS_CARD:
                pushAccordionOpenInfo(translator(messages.policyDetails));
                scrollToPolicyDetailsSection?.current?.scrollIntoView();
                window.scrollBy(0, policyDetailsOffset);
                break;
            case cardsIds.VALUABLES_CARD:
                pushAccordionOpenInfo(translator(messages.valuables));
                scrollToPolicyDetailsSection?.current?.scrollIntoView();
                break;
            case cardsIds.FINEARTS_CARD:
                pushAccordionOpenInfo(translator(messages.fineArts));
                handleFineArtsAccordion();
                break;
            default:
                // do nothing
        }
    }, [cardsIds, pushAccordionOpenInfo, translator, policyDetailsOffset]);

    // #endregion GTM EVENTS

    const handlePageNavigation = useCallback((typeOfLink) => {
        const indexOfPolicyDetails = _.findIndex(
            steps,
            ({ path }) => path === typeOfLink
        );
        jumpTo(indexOfPolicyDetails);
    }, [jumpTo, steps]);

    const renderAccordionHeader = (isOpen, title) => {
        return (
            <NfumAccordionHeader
                isOpen={isOpen}
                title={title}
            />
        );
    };

    const handleNext = useCallback(async() => {
        try {
            await EndorsementService.createBespokeActivity(
                policySubmissionVM.value.jobID,
                authHeader
            );
        } catch {
            const policyNumber = _.get(policySubmissionVM, 'policyNumber.value');
            history.push(`/contact-us?policyNumber=${policyNumber}`);
        }
        return policySubmissionVM;
    }, [policySubmissionVM, history, authHeader, EndorsementService]);

    const overrideProps = {
        policyDetailsList: {
            onUpdateAccordionStates: onUpdateOpenAccordion
        },
        policyDetailsCard: {
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen, messages.policyDetails,
                );
            }
        },
        valuablesCard: {
            visible: !_.isEmpty(specificValuables.collections.filter((x) => x.removed.length > 0))
                || !_.isEmpty(specificValuables.collections.filter((x) => x.edited.length > 0))
                || !_.isEmpty(specificValuables.collections.filter((x) => x.added.length > 0)),
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen, messages.valuables,
                );
            }
        },
        fineArtsCard: {
            visible: fineArtCollections.added.length > 0
                || fineArtCollections.edited.length > 0
                || fineArtCollections.removed.length > 0,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen, messages.fineArts,
                );
            }
        },
        policyDetailsCardContainer: {
            ref: scrollToPolicyDetailsSection
        },
        valuablesCardContainer: {
            visible: !_.isEmpty(specificValuables.collections.filter((x) => x.removed.length > 0))
            || !_.isEmpty(specificValuables.collections.filter((x) => x.edited.length > 0))
            || !_.isEmpty(specificValuables.collections.filter((x) => x.added.length > 0)),
            ref: scrollToValuablesMTACardSection
        },
        fineArtsCardContainer: {
            visible: fineArtCollections.added.length > 0
                || fineArtCollections.edited.length > 0
                || fineArtCollections.removed.length > 0,
            ref: scrollToFineArtsMTACardSection
        },
        policyDetailsSummary: {
            submissionVM: policySubmissionVM
        },
        bespokeValuablesSummary: {
            data: specificValuables
        },
        fineArtsSummary: {
            data: fineArtCollections
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            policyDetailsSummary: PolicyDetailsSummary,
            bespokeValuablesSummary: BespokeValuablesSummary,
            fineArtsSummary: FineArtsSummary
        }
    };

    return (
        <WizardPage
            onNext={handleNext}
            showPrevious
            onPrevious={() => { handlePageNavigation('/policychange-details'); }}
            showCancel={false}
            showNext
            nextLabel={commonMessages.confirmChanges}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policySubmissionVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

BespokePolicyChangeSummaryPage.propTypes = wizardProps;
export default withAuthenticationContext(BespokePolicyChangeSummaryPage);
