import React, { useCallback, useState, useContext } from 'react';
import { useCurrency } from 'nfum-portals-utils-react';
import {
    ViewModelForm,
} from 'gw-portals-viewmodel-react';
import _ from 'lodash';
import moment from 'moment';
import { useValidation } from 'gw-portals-validation-react';
import { TranslatorContext } from '@jutro/locale';
import { wizardProps } from 'gw-portals-wizard-react';
import { ModalNext } from '@jutro/components';
import metadata from './BespokeValuablesModel.metadata.json5';
import styles from './BespokeValuablesModel.module.scss';
import messages from './BespokeValuablesModel.messages';

function BespokeValuablesModel(props) { /* NOSONAR: pure declarative usage  */
    const {
        isOpen,
        onResolve,
        initialFormData = {},
        viewModelService,
        itemCount,
        newlyAddedItem
    } = props;
    const [form, setForm] = useState(initialFormData);
    const translator = useContext(TranslatorContext);
    const NUMERIC_FIELD_MAX_LENGTH = 16;
    const DIGITS_REGEXP = '^[0-9,.]+$|^$';
    const currencyFormatter = useCurrency();
    const TEXT_FIELD_MAX_LENGTH = 255;
    const { isComponentValid, onValidate } = useValidation('BespokeValuablesModel');
    const [isReplacementCostMaxLength, setIsReplacementCostMaxLength] = useState(false);
    const [isReplacementCostFormatValid, setIsReplacementCostFormatValid] = useState(true);
    const [isDescriptionMaxLength, setIsDescriptionMaxLength] = useState(false);
    const [isOtherKeptPlaceMaxLength, setIsOtherKeptPlaceMaxLength] = useState(false);
    const [category, updateCategory] = useState(false);
    const MAX_PERIOD_START_DATE_DAYS_SHIFT = 1;
    const maxDate = moment().add(MAX_PERIOD_START_DATE_DAYS_SHIFT, 'days');

    const getTypes = useCallback((typecode) => ({
        code: typecode.code,
        name: translator({
            id: typecode.name,
            defaultMessage: typecode.code
        })
    }), [translator]);

    const getAvailableValuableTypes = useCallback(() => {
        const specifiedValuableTypelist = viewModelService.getTypelistNFUM('pc', 'typekey.SpecifiedValuableType_NFUM');
        if (_.isEmpty(specifiedValuableTypelist)) { return []; }
        return specifiedValuableTypelist.filters.find((x) => x.name === 'Valuables_JeweleryAndGemstones').codes.map(getTypes);
    }, [viewModelService, getTypes]);

    const getAvailableWatchMakeTypes = useCallback((type) => {
        const filterName = type === 'Watches' ? 'SpecifiedWatchesMake' : 'SpecifiedGunsMake';
        const specifiedValuableTypelist = viewModelService.getTypelistNFUM('pc', 'typekey.SpecfdValuableMake_NFUM');
        if (_.isEmpty(specifiedValuableTypelist)) { return []; }
        return specifiedValuableTypelist.filters.find((x) => x.name === filterName)
            .codes.map(getTypes);
    }, [viewModelService, getTypes]);

    const getAvailableItemNotWornTypes = useCallback(() => {
        const specifiedValuableTypelist = viewModelService.getTypelistNFUM('pc', 'typekey.ItemKeptWhenNotWorn_NFUM');
        if (_.isEmpty(specifiedValuableTypelist)) { return []; }
        return specifiedValuableTypelist.codes.map(getTypes);
    }, [viewModelService, getTypes]);

    const getAvailableCategoryTypes = useCallback(() => {
        const specifiedValuableTypelist = viewModelService.getTypelistNFUM('pc', 'typekey.ValuablesSpecItem_NFUM');
        if (_.isEmpty(specifiedValuableTypelist)) { return []; }
        return specifiedValuableTypelist.codes.map(getTypes);
    }, [viewModelService, getTypes]);

    const handleCategoryVal = useCallback((value, path) => {
        updateCategory(true);
        setForm({ form, [path]: value });
    }, [form]);

    const writeValue = useCallback((value, path) => {
        setForm({ ...form, [path]: value });
    }, [form]);

    const handleClose = (data) => () => {
        onResolve(data);
    };

    const isDigitsOnly = useCallback((value) => {
        const regex = new RegExp(DIGITS_REGEXP);
        return regex.test(value);
    }, []);

    const validateReplacementCostField = useCallback((value, path) => {
        if (!isDigitsOnly(value)) return;
        writeValue(value, path);
        if (value.length >= NUMERIC_FIELD_MAX_LENGTH) {
            setIsReplacementCostMaxLength(true);
        } else {
            setIsReplacementCostMaxLength(false);
        }
    }, [setIsReplacementCostMaxLength, writeValue, isDigitsOnly]);

    const validateFormat = useCallback((value) => {
        if (!currencyFormatter.isValidFormat(value)) {
            setIsReplacementCostFormatValid(false);
        } else {
            setIsReplacementCostFormatValid(true);
        }
    }, [currencyFormatter]);

    const getValuablesReplCostValidationMessages = () => {
        let validationMessages = [];
        if (!isReplacementCostFormatValid) {
            validationMessages = [
                ...validationMessages,
                translator(messages.formatNotValidError)
            ];
        }
        return validationMessages;
    };

    const getMidnightFromMomentDate = useCallback((date) => {
        return date.toDate().setHours(0, 0, 0, 0);
    }, []);

    const getValuablesReplCostImmediateInfoMessages = () => {
        let validationMessages = [];
        if (isReplacementCostMaxLength) {
            validationMessages = [
                ...validationMessages,
                translator(messages.maxLengthError)
            ];
        }
        return validationMessages;
    };

    const validateDescriptionFieldLength = useCallback((value, path) => {
        writeValue(value, path);
        if (value.length >= TEXT_FIELD_MAX_LENGTH) {
            if (path === 'description') {
                setIsDescriptionMaxLength(true);
            } else if (path === 'otherKeptPlace') {
                setIsOtherKeptPlaceMaxLength(true);
            }
        } else {
            setIsDescriptionMaxLength(false);
            setIsOtherKeptPlaceMaxLength(false);
        }
    }, [setIsDescriptionMaxLength, writeValue]);

    const getDescriptionValidationMessages = () => {
        if (isDescriptionMaxLength) {
            return [
                translator(messages.maxLengthError)
            ];
        }
        return [];
    };

    const getOtherPlaceKeptValidationMessages = () => {
        if (isOtherKeptPlaceMaxLength) {
            return [
                translator(messages.maxLengthError)
            ];
        }
        return [];
    };

    const overrideProps = {
        category: {
            availableValues: getAvailableCategoryTypes(),
            value: form.category,
            visible: !_.isEmpty(form.publicId) || newlyAddedItem,
            disabled: !_.isEmpty(initialFormData),
            onValueChange: handleCategoryVal
        },
        valuablemodel: {
            visible: (form.category !== undefined && !_.isEmpty(form.publicId))
            || newlyAddedItem,
        },
        type: {
            visible: form.category === 'JewelleryAndGemstones',
            availableValues: getAvailableValuableTypes(),
            value: !form.type && category ? '' : form.type
        },
        gunMake: {
            visible: form.category === 'Guns',
            availableValues: getAvailableWatchMakeTypes(form.category),
            value: !form.gunMake && category ? '' : form.gunMake
        },
        watchMake: {
            visible: form.category === 'Watches',
            availableValues: getAvailableWatchMakeTypes(form.category),
            value: !form.watchMake && category ? '' : form.watchMake
        },
        serialNumber: {
            visible: form.category === 'Guns',
            value: form.serialNumber && category === undefined ? '' : form.serialNumber
        },
        professionalValuation: {
            visible: (form.category === 'JewelleryAndGemstones' || form.category === 'Watches') && form.category !== '',
            value: form.professionalValuation === undefined && category ? '' : form.professionalValuation
        },
        valuationDone: {
            visible: form.category !== '' && (form.professionalValuation === true),
            value: form.valuationDone === undefined && category ? '' : form.valuationDone,
            maxDate: getMidnightFromMomentDate(maxDate),
            popperPlacement: 'top-start',
            format: 'long'
        },
        alwaysWorn: {
            visible: form.category === 'JewelleryAndGemstones' || form.category === 'Watches',
            value: form.alwaysWorn === undefined && category ? '' : form.alwaysWorn
        },
        notBeingWorn: {
            visible: (form.category === 'JewelleryAndGemstones' || form.category === 'Watches') && (form.alwaysWorn === false),
            value: form.notBeingWorn === undefined && category ? '' : form.notBeingWorn,
            availableValues: getAvailableItemNotWornTypes(),
        },
        otherKeptPlace: {
            visible: (form.category === 'JewelleryAndGemstones' || form.category === 'Watches') && (form.notBeingWorn === 'Other' && form.alwaysWorn === false),
            value: form.otherKeptPlace === undefined && category ? '' : form.otherKeptPlace,
            onValueChange: validateDescriptionFieldLength,
            immediateInfoMessages: getOtherPlaceKeptValidationMessages(),
            maxLength: TEXT_FIELD_MAX_LENGTH,
        },
        agreedValue: {
            visible: form.category === 'Guns' || form.category === 'Furs',
            value: form.professionalValuation === undefined && category ? '' : form.professionalValuation
        },
        gunPair: {
            visible: form.category === 'Guns',
            value: form.gunPair === undefined && category ? '' : form.gunPair
        },
        gunDisplay: {
            visible: form.category === 'Guns',
            value: form.gunDisplay === undefined && category ? '' : form.gunDisplay
        },
        replacementCost: {
            onValueChange: validateReplacementCostField,
            onBlur: validateFormat,
            validationMessages: getValuablesReplCostValidationMessages(),
            immediateInfoMessages: getValuablesReplCostImmediateInfoMessages(),
            maxLength: NUMERIC_FIELD_MAX_LENGTH,
            value: form.replacementCost === undefined && category ? '' : form.replacementCost,
            visible: form.category !== undefined
        },
        description: {
            onValueChange: validateDescriptionFieldLength,
            immediateInfoMessages: getDescriptionValidationMessages(),
            maxLength: TEXT_FIELD_MAX_LENGTH,
            value: form.description === undefined && category ? '' : form.description,
            visible: form.category !== undefined
        },
        cancelButton: {
            visible: form.category !== undefined
        },
        saveAndAddButton: {
            visible: form.publicId === undefined
                && isReplacementCostFormatValid
                && isComponentValid
                && itemCount < 4
        },
        addItemButton: {
            visible: form.publicId === undefined
                && isReplacementCostFormatValid
                && isComponentValid
                && itemCount < 6
        },
        saveButton: {
            disabled: !isReplacementCostFormatValid
                || !isComponentValid,
            visible: form.publicId !== undefined
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onAddItem: handleClose({ type: 'ADD', data: form }),
            onSaveAndAdd: handleClose({ type: 'REOPEN', data: form }),
            onCancel: handleClose({ type: 'CANCEL' }),
        }
    };

    return (
        <ModalNext
            isOpen={isOpen}
            onRequestClose={() => onResolve({ type: 'CANCEL' })}
        >
            <ViewModelForm
                model={form}
                uiProps={metadata.componentContent}
                onValueChange={writeValue}
                onValidationChange={onValidate}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                className={styles.modalWrapper}
            />
        </ModalNext>
    );
}

BespokeValuablesModel.propTypes = wizardProps;
export default BespokeValuablesModel;
