/** nfum custom (okta-patch)
import React from 'react';
import PropTypes from 'prop-types';

import { AuthProvider, SecureRoute, AuthContext } from '@jutro/auth';
import { Loader } from '@jutro/components';
import { getGrantedAuthorities } from 'gw-portals-auth-js/utils/GrantedAuthoritiesUtil';
import { AuthContext as DigitalAuthContext } from './AuthenticationContext';

import contextTransformer from './contextTransformer';

class DigitalAuthContextWrapper extends React.Component {
    state = {
        digitalAuthContext: {}
    };

    // eslint-disable-next-line react/no-arrow-function-lifecycle
    componentWillReceiveProps = (nextProps) => {
        const { jutroAuthContext = {} } = nextProps;
        const {
            jutroAuthContext: prevJutroAuthContext = {},
            onAuthDataCreation,
            shouldCheckGrantedAuthorities
        } = this.props;
        if (jutroAuthContext.authenticated
            && prevJutroAuthContext.userInfo !== jutroAuthContext.userInfo) {
            contextTransformer.getDigitalAuthContext(jutroAuthContext)
                .then(async (digitalAuthContext) => {
                    const authUserData = await onAuthDataCreation(
                        digitalAuthContext,
                        digitalAuthContext.authHeader
                    );
                    let combinedAuthContext = {};
                    if (shouldCheckGrantedAuthorities) {
                        const grantedAuthorities = Promise.resolve(getGrantedAuthorities(
                            digitalAuthContext
                        ));
                        combinedAuthContext = Object.assign(
                            { authUserData, grantedAuthorities },
                            digitalAuthContext
                        );
                    } else {
                        combinedAuthContext = Object.assign(
                            { authUserData },
                            digitalAuthContext
                        );
                    }
                    this.setState({ digitalAuthContext: combinedAuthContext });
                }).catch((e) => {
                    console.error('AccessTokenError', e);
                });
        }
    };

    render() {
        const { digitalAuthContext = {} } = this.state;
        const { children } = this.props;
        if (digitalAuthContext && digitalAuthContext.isLoggedIn) {
            return (
                <DigitalAuthContext.Provider value={digitalAuthContext}>
                    {children}
                </DigitalAuthContext.Provider>
            );
        }

        return <Loader />;
    }
}

DigitalAuthContextWrapper.propTypes = {
    jutroAuthContext: PropTypes.shape({
        authenticated: PropTypes.bool.isRequired,
        userInfo: PropTypes.shape({
            sub: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
            email_verified: PropTypes.bool.isRequired,
            updated_at: PropTypes.number.isRequired
        }),
        getAccessToken: PropTypes.func.isRequired,
        logout: PropTypes.func.isRequired
    }).isRequired,
    onAuthDataCreation: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    shouldCheckGrantedAuthorities: PropTypes.bool.isRequired,
};

function CloudAuthContextProvider({ children, onAuthDataCreation, shouldCheckGrantedAuthorities }) {
    return (
        <AuthProvider>
            <SecureRoute
                path="/"
                render={() => (
                    <AuthContext.Consumer>
                        {(jutroAuthContext) => {
                            return (
                                <DigitalAuthContextWrapper
                                    jutroAuthContext={jutroAuthContext}
                                    onAuthDataCreation={onAuthDataCreation}
                                    shouldCheckGrantedAuthorities={shouldCheckGrantedAuthorities}
                                >
                                    {children}
                                </DigitalAuthContextWrapper>
                            );
                        }}
                    </AuthContext.Consumer>
                )}
            />
        </AuthProvider>
    );
}

CloudAuthContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
    onAuthDataCreation: PropTypes.func.isRequired,
    shouldCheckGrantedAuthorities: PropTypes.bool.isRequired,
};

export default CloudAuthContextProvider;
*/

import React, {
    useCallback,
    useEffect,
    useState,
    useContext,
    useMemo
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { AuthProvider, SecureRoute, AuthContext as JutroAuthContext } from 'gw-digital-auth-okta-react';
import { ModalNextProvider } from '@jutro/components';
import { getConfigValue } from '@jutro/config';
import { getGrantedAuthorities } from 'gw-portals-auth-js/utils/GrantedAuthoritiesUtil';
import InactiveModal from 'nfum-digital-auth-react/NfumSessionModal/InactiveModal/InactiveNFUMModal';
import { AuthContext as DigitalAuthContext } from './AuthenticationContext';
import { NfumLoader } from 'nfum-components-platform-react';
import contextTransformer from './contextTransformer';

const MODAL_CONFIRMATION_INTERVAL_MS = getConfigValue('logoutConfirmationIntervalMins', 1);
/** Added for post logout redirection url  */
const LOGOUT_URI = getConfigValue('JUTRO_AUTH_LOGOUT_REDIRECT_PATH', 'https://www.nfumutual.co.uk/insurance/home-insurance');

const MONITORED_EVENTS = ['click', 'mousemove', 'scroll', 'keydown'];

const CONTINUE = 'CONTINUE';

async function transformJutroContextToDigital({
    jutroAuthContext,
    onAuthDataCreation,
    shouldCheckGrantedAuthorities
}) {
    try {
        if (!jutroAuthContext.userInfo) {
            return {};
        }
        const digitalAuthContext = await contextTransformer.getDigitalAuthContext(jutroAuthContext);
        const { authHeader, accessToken } = digitalAuthContext;

        if (!accessToken) {
            // if we have no authHeader we'll have to rely on information
            // we already have about the token
            return digitalAuthContext;
        }
        const authUserData = await onAuthDataCreation(digitalAuthContext, authHeader);
        const newDigitalContextValue = {
            authUserData,
            ...digitalAuthContext
        };

        if (shouldCheckGrantedAuthorities) {
            const grantedAuthorities = await Promise.resolve(
                getGrantedAuthorities(digitalAuthContext)
            );
            newDigitalContextValue.grantedAuthorities = grantedAuthorities;
        }

        return newDigitalContextValue;
    } catch (e) {
        // Expected message
        // eslint-disable-next-line no-console
        console.error('AccessTokenError', e);
    }

    return {};
}

function useTracking({ events, trackingFunction }) {
    const eventsArr = _.castArray(events);

    useEffect(() => {
        eventsArr.forEach((event) => {
            document.addEventListener(event, trackingFunction);
        });
        return () => {
            eventsArr.forEach((event) => {
                document.removeEventListener(event, trackingFunction);
            });
        };
    }, [trackingFunction, eventsArr]);
}

function useTokenRenewal({ tokenManager, onTokenRenewed }) {
    const handleRenewal = useCallback((_tokenIdentifier, token) => {
        onTokenRenewed(token);
    }, [onTokenRenewed]);

    useEffect(() => {
        if (tokenManager) {
            tokenManager.on('renewed', handleRenewal);
        }
        return () => {
            if (tokenManager) {
                tokenManager.off('renewed', handleRenewal);
            }
        };
    });
}

function useTokenExpiration({ tokenManager, onAccessTokenExpired }) {
    const onTokenExpired = useCallback((tokenType) => {
        if (tokenType === 'accessToken') {
            onAccessTokenExpired();
        }
    }, [onAccessTokenExpired]);
    useEffect(() => {
        if (tokenManager) {
            tokenManager.on('expired', onTokenExpired);
        }
        return () => {
            if (tokenManager) {
                tokenManager.off('expired', onTokenExpired);
            }
        };
    }, [onTokenExpired, tokenManager]);
}

function InactivityTracker({
    onActivityChange,
    inactivityAllowedSecs,
    onTokenExpiring,
    jutroAuthContext
}) {
    const [lastInteraction, setLastInteraction] = useState(Date.now());
    const [isActive, setIsActive] = useState(true);
    const [isTokenExp] = useState(false);

    /**
     * The below code is added to track if user is active in the app
     * Also to check if user had logged out from any other tab then
     * Reload the current tab to stop movement in logged in tab and re-enter
     * the credentian back again before continueing in the transaction
     */
    const trackInteraction = useCallback(_.throttle(() => {
        if (jutroAuthContext.isAuthenticated && window.localStorage.logoutAM === 'true') {
            window.location.replace(LOGOUT_URI);
        } else {
            setLastInteraction(Date.now());
        }
    }, 500), [jutroAuthContext]);

    useTracking({
        events: MONITORED_EVENTS,
        trackingFunction: trackInteraction,
    });

    useEffect(() => {
        onActivityChange({ isActive });
    }, [isActive, onActivityChange]);

    useEffect(() => {
        onTokenExpiring({ isTokenExp });
    }, [isTokenExp, onTokenExpiring]);

    const setUserInactive = useCallback(() => {
        setIsActive(false);
    }, []);

    useEffect(() => {
        setIsActive(true);
        // every time we have an interaction we register the user is active
    }, [lastInteraction]);

    useEffect(() => {
        const userActivityTimeout = setTimeout(setUserInactive, inactivityAllowedSecs * 1000);
        return () => {
            clearTimeout(userActivityTimeout);
        };
    }, [
        inactivityAllowedSecs,
        // every time we have an interaction we register the user is active
        lastInteraction,
        setUserInactive
    ]);

    return <React.Fragment />;
}

InactivityTracker.propTypes = {
    onActivityChange: PropTypes.func.isRequired,
    onTokenExpiring: PropTypes.func.isRequired,
    inactivityAllowedSecs: PropTypes.number,
    jutroAuthContext: PropTypes.func.isRequired,
};

InactivityTracker.defaultProps = {
    inactivityAllowedSecs: 60 * 60 // 1 hour
};

function useTokenRefresher() {
    // This is used to track token updates
    const [tokenLastUpdated, setTokenLastUpdated] = useState(Date.now());

    const forceTokenUpdate = useCallback(() => {
        setTokenLastUpdated(Date.now());
    }, []);

    return {
        forceTokenUpdate,
        tokenLastUpdated
    };
}

// eslint-disable-next-line max-len
function ContentWrapper({ children, onAuthDataCreation, shouldCheckGrantedAuthorities }) { /* NOSONAR: pure declarative usage */
    const jutroAuthContext = useContext(JutroAuthContext);
    const [digitalContextValue, setDigitalContextValue] = useState();
    const [isUserActive, setIsUserActive] = useState(true);
    const [isTokenExpiring, setTokenExpiry] = useState(false);

    const { forceTokenUpdate, tokenLastUpdated } = useTokenRefresher();
    useEffect(() => {
        transformJutroContextToDigital({
            jutroAuthContext,
            onAuthDataCreation,
            shouldCheckGrantedAuthorities
        }).then(setDigitalContextValue);
    }, [
        jutroAuthContext,
        // We want to recompute every time the local token value gets updated
        tokenLastUpdated,
        jutroAuthContext.userInfo,
        onAuthDataCreation,
        shouldCheckGrantedAuthorities
    ]);

    const { tokenManager, decodeToken } = jutroAuthContext;

    const logout = useCallback(() => {
        /** Added logoutAM flag to track if user has looged out from one tab
         * then force logout the user from rest of the tabs
        */
        window.localStorage.setItem('logoutAM', true);
        jutroAuthContext.logout();
    }, [jutroAuthContext]);

    const onTokenRenewed = useCallback((/* newToken */) => {
        // Force reloading the context with the new token value
        forceTokenUpdate(Date.now());
    }, [forceTokenUpdate]);

    const onAccessTokenExpired = useCallback(async () => {
        await tokenManager.renew('accessToken');
    }, [tokenManager]);

    // Customized session modal pop window specific to NFUM

    const showModal = useCallback(() => {
        ModalNextProvider.showModal(
            <InactiveModal
                isOpen
                logoutConfirmationInterval={MODAL_CONFIRMATION_INTERVAL_MS}
            />
        ).then((choice) => {
            if (choice.type === CONTINUE) {
                if (window.localStorage.getItem('logoutAM') !== 'true') {
                    onTokenRenewed();
                } else {
                    window.location.reload();
                }
            } else {
                logout();
            }
        }, _.noop);
    }, [logout, onTokenRenewed]);

    useTokenExpiration({ tokenManager, onAccessTokenExpired });

    const onActivityChange = useCallback(({ isActive }) => {
        setIsUserActive(isActive);
    }, []);

    const onTokenExpiring = useCallback(({ isTokenExp }) => {
        setTokenExpiry(isTokenExp);
    }, []);

    /**
     * Added below useEffect to display mpdal popup window when user is idle in the the tab
        and redirects to NFUM home Insurance if user has already logged out from other tab
    */
    useEffect(() => {
        if (jutroAuthContext.isAuthenticated && window.localStorage.logoutAM === 'true') {
            window.location.replace(LOGOUT_URI);
        } else if (!isUserActive && window.localStorage.logoutAM !== 'true') {
            showModal();
        }
    }, [isUserActive, showModal, jutroAuthContext]);

    useEffect(() => {
        if (isTokenExpiring && isUserActive) {
            onTokenRenewed();
            setTokenExpiry(false);
        }
    }, [isTokenExpiring, isUserActive, onTokenRenewed]);

    useTokenRenewal({ tokenManager, onTokenRenewed });

    const accessToken = _.get(digitalContextValue, 'accessToken');

    const tokenDuration = useMemo(() => {
        if (!accessToken) {
            return null;
        }
        const { exp: expiration, iat: issued } = decodeToken(accessToken).payload;
        // Added logic to display the session pop-up to dispay one minute prior to its expiry.
        return (expiration - issued) - 60;
    }, [accessToken, decodeToken]);

    const isLoggedIn = _.get(digitalContextValue, 'isLoggedIn', false);

    if (!isLoggedIn) {
        return <NfumLoader />;
    }

    return (
        <DigitalAuthContext.Provider value={digitalContextValue}>
            <InactivityTracker
                onActivityChange={onActivityChange}
                onTokenExpiring={onTokenExpiring}
                inactivityAllowedSecs={tokenDuration}
                jutroAuthContext={jutroAuthContext}
            />
            { children }
        </DigitalAuthContext.Provider>
    );
}

ContentWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    onAuthDataCreation: PropTypes.func,
    shouldCheckGrantedAuthorities: PropTypes.bool
};

ContentWrapper.defaultProps = {
    onAuthDataCreation: _.noop,
    shouldCheckGrantedAuthorities: false
};

function CloudAuthContextProvider({ children, onAuthDataCreation, shouldCheckGrantedAuthorities }) {
    return (
        <AuthProvider>
            <SecureRoute
                path="/"
                render={() => (
                    <ContentWrapper
                        onAuthDataCreation={onAuthDataCreation}
                        shouldCheckGrantedAuthorities={shouldCheckGrantedAuthorities}
                    >
                        {children}
                    </ContentWrapper>
                )}
            />
        </AuthProvider>
    );
}

CloudAuthContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
    onAuthDataCreation: PropTypes.func.isRequired,
    shouldCheckGrantedAuthorities: PropTypes.bool.isRequired,
};

export default CloudAuthContextProvider;
