import { defineMessages } from 'react-intl';

export default defineMessages({
    coverName: {
        id: 'nfum.platform.nfumCostsPerLocation.coverName',
        defaultMessage: 'Cover name'
    },
    amount: {
        id: 'nfum.platform.nfumCostsPerLocation.amount',
        defaultMessage: 'Amount'
    },
});
