import React, { useCallback, useContext } from 'react';
import {
    ViewModelForm
} from 'gw-portals-viewmodel-react';
import moment from 'moment';
import _ from 'lodash';
import { wizardProps } from 'gw-portals-wizard-react';
import { useCurrency } from 'nfum-portals-utils-react';
import { TranslatorContext } from '@jutro/locale';
import metadata from './BespokeValuableSummaryItem.metadata.json5';
import styles from './BespokeValuableSummaryItem.module.scss';
import messages from './BespokeValuableSummaryItem.messages';

function BespokeValuableSummaryItem(props) { /* NOSONAR: pure declarative usage  */
    const {
        addedValuable
    } = props;
    const translator = useContext(TranslatorContext);
    const currencyFormatter = useCurrency();
    const safePlaceKept = _.isEmpty(addedValuable.itemData.ArticleSafePlaceKept) ? '' : addedValuable.itemData.ArticleSafePlaceKept.typeCodeValue;
    const category = _.isEmpty(addedValuable.itemData.ArticleType) ? '' : addedValuable.itemData.ArticleType.typeCodeValue;
    const articleMakeType = _.isEmpty(addedValuable.itemData.ArticleMakeType) ? '' : addedValuable.itemData.ArticleMakeType.typeCodeValue;
    const articleSubType = _.isEmpty(addedValuable.itemData.ArticleSubType) ? '' : addedValuable.itemData.ArticleSubType.typeCodeValue;

    const getArticleValuableType = useCallback(() => {
        return articleSubType !== undefined ? translator({
            id: `typekey.SpecifiedValuableType_NFUM.${articleSubType}`,
            defaultMessage: articleSubType
        }) : '';
    }, [translator,
        articleSubType]);

    const getBooleanValue = useCallback((val) => {
        if (!_.isEmpty(val)) {
            if (val.booleanValue) {
                return translator(messages.yes);
            }
            return translator(messages.no);
        }
        return '';
    }, [translator]);

    const getArticleMakeType = useCallback(() => {
        return articleSubType !== undefined ? translator({
            id: `typekey.SpecfdValuableMake_NFUM.${articleMakeType}`,
            defaultMessage: articleMakeType
        }) : '';
    }, [translator,
        articleSubType,
        articleMakeType]);

    const getDateDisplay = useCallback((effectiveDate) => {
        if (effectiveDate) {
            const { year, month, day } = effectiveDate;
            return moment(new Date(year, month, day))
                .format('DD MMMM YYYY');
        }
        return '';
    }, []);

    const overrideProps = {
        articleCategory1: {
            value: translator({
                id: `typekey.ValuablesSpecItem_NFUM.${category}`,
                defaultMessage: category
            }),
            visible: addedValuable.itemData.ArticleType !== undefined
        },
        articleDescription1: {
            content: !addedValuable.itemData.ArticleDescription ? '' : addedValuable.itemData.ArticleDescription.stringValue,
            visible: !_.isEmpty(addedValuable.itemData.ArticleDescription)
        },
        articleWatchMake1: {
            value: `${getArticleMakeType(articleMakeType)}`,
            visible: !_.isEmpty(addedValuable.itemData.ArticleMakeType)
                && !_.isEmpty(addedValuable.itemData.ArticleType)
                && addedValuable.itemData.ArticleType.typeCodeValue === 'Watches'
        },
        articleGunMake1: {
            value: `${getArticleMakeType(articleMakeType)}`,
            visible: !_.isEmpty(addedValuable.itemData.ArticleMakeType)
                && !_.isEmpty(addedValuable.itemData.ArticleType)
                && addedValuable.itemData.ArticleType.typeCodeValue === 'Guns'
        },
        articleProfessionalValuation1: {
            value: getBooleanValue(addedValuable.itemData.ArticleValuationMethod),
            visible: !_.isEmpty(addedValuable.itemData.ArticleValuationMethod) && (addedValuable.itemData.ArticleType.typeCodeValue === 'JewelleryAndGemstones' || addedValuable.itemData.ArticleType.typeCodeValue === 'Watches')
        },
        articleValuationDone1: {
            value: (!addedValuable.itemData.ArticleValuationDate || !addedValuable.itemData.ArticleValuationMethod.booleanValue) ? '' : getDateDisplay(addedValuable.itemData.ArticleValuationDate.dateValue),
            visible: !_.isEmpty(addedValuable.itemData.ArticleValuationDate)
                && !_.isEmpty(addedValuable.itemData.ArticleValuationMethod)
                && addedValuable.itemData.ArticleValuationMethod.booleanValue
        },
        articleNotBeingWorn1: {
            value: translator({
                id: `typekey.ItemKeptWhenNotWorn_NFUM.${safePlaceKept}`,
                defaultMessage: safePlaceKept
            }),
            visible: !_.isEmpty(safePlaceKept)
                && !_.isEmpty(addedValuable.itemData.ArticleAlwaysWorn)
                && !addedValuable.itemData.ArticleAlwaysWorn.booleanValue
        },
        articleOtherKeptPlace1: {
            value: !addedValuable.itemData.ArticleOtherPlaceKept ? '' : addedValuable.itemData.ArticleOtherPlaceKept.stringValue,
            visible: !_.isEmpty(addedValuable.itemData.ArticleOtherPlaceKept)
                && !_.isEmpty(addedValuable.itemData.ArticleAlwaysWorn)
                && !addedValuable.itemData.ArticleAlwaysWorn.booleanValue
                && safePlaceKept === 'Other'
        },
        articleAgreedValue1: {
            value: getBooleanValue(addedValuable.itemData.ArticleValuationMethod),
            visible: !_.isEmpty(addedValuable.itemData.ArticleValuationMethod) && (addedValuable.itemData.ArticleType.typeCodeValue === 'Guns' || addedValuable.itemData.ArticleType.typeCodeValue === 'Furs')
        },
        articleMake1: {
            value: !addedValuable.itemData.ArticleSafePlaceKept ? '' : addedValuable.itemData.ArticleSafePlaceKept.typeCodeValue,
            visible: false
        },
        articleSerialNumber1: {
            value: !addedValuable.itemData.ArticleSerialNumber ? '' : addedValuable.itemData.ArticleSerialNumber.stringValue,
            visible: !_.isEmpty(addedValuable.itemData.ArticleSerialNumber) && addedValuable.itemData.ArticleType.typeCodeValue === 'Guns'
        },
        articleGunPair1: {
            value: getBooleanValue(addedValuable.itemData.ArticlePartPair),
            visible: !_.isEmpty(addedValuable.itemData.ArticlePartPair) && addedValuable.itemData.ArticleType.typeCodeValue === 'Guns'
        },
        articleGunDisplay1: {
            value: getBooleanValue(addedValuable.itemData.ArticleGunOnDisplay),
            visible: !_.isEmpty(addedValuable.itemData.ArticleGunOnDisplay) && addedValuable.itemData.ArticleType.typeCodeValue === 'Guns'
        },
        articleValuableType1: {
            value: `${getArticleValuableType(articleSubType)}`,
            visible: !_.isEmpty(addedValuable.itemData.ArticleSubType) && addedValuable.itemData.ArticleType.typeCodeValue === 'JewelleryAndGemstones'
        },
        articleReplacementCost1: {
            value: currencyFormatter
                .formatCurrency(addedValuable.itemData.ArticleReplacementCost.bigDecimal,
                    true),
            visible: !_.isEmpty(addedValuable.itemData.ArticleReplacementCost)
        },
        articleAlwaysWorn1: {
            value: addedValuable?.itemData?.ArticleAlwaysWorn?.booleanValue
                ? translator(messages.yes)
                : translator(messages.no),
            visible: !_.isEmpty(addedValuable.itemData.ArticleAlwaysWorn) && (addedValuable.itemData.ArticleType.typeCodeValue === 'JewelleryAndGemstones' || addedValuable.itemData.ArticleType.typeCodeValue === 'Watches')
        },
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            model={addedValuable}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

BespokeValuableSummaryItem.propTypes = wizardProps;
export default BespokeValuableSummaryItem;
