import { defineMessages } from 'react-intl';

export default defineMessages({
    ContactNumber: {
        id: 'nfum.platform.callOrContactInfo.ContactNumber',
        defaultMessage: 'Call us 0800 316 4661'
    },
    callAgencyNumber: {
        id: 'nfum.platform.callOrContactInfo.callAgencyNumber',
        defaultMessage: 'Call us'
    },
    CallTimings: {
        id: 'nfum.platform.callOrContactInfo.CallTimings',
        defaultMessage: 'Mon to Fri: 8am-8pm'
    },
    SaturdayCallTimings: {
        id: 'nfum.platform.callOrContactInfo.SaturdayCallTimings',
        defaultMessage: 'Saturday: 9am-12:30pm'
    },
    SundayCallTimings: {
        id: 'nfum.platform.callOrContactInfo.SundayCallTimings',
        defaultMessage: 'Sunday: Closed'
    },
    contactYourAgency: {
        id: 'nfum.platform.callOrContactInfo.contactYourAgency',
        defaultMessage: 'Contact your local Agency'
    },
    contactYourAgencyText: {
        id: 'nfum.platform.callOrContactInfo.contactYourAgencyText',
        defaultMessage: 'If you would like to speak to your local Agency directly'
    },
    contactAgencyLink: {
        id: 'nfum.platform.callOrContactInfo.contactAgencyLink',
        defaultMessage: 'Contact your Agency'
    },
    alterantivelyText: {
        id: 'nfum.platform.callOrContactInfo.alterantivelyText',
        defaultMessage: 'Alternatively, you can '
    },
    requestForCallbackText: {
        id: 'nfum.platform.callOrContactInfo.requestForCallbackText',
        defaultMessage: 'request a callback'
    },
    callMeBack: {
        id: 'nfum.platform.callOrContactInfo.callMeBack',
        defaultMessage: 'Call me back'
    },
    speakToCallCenter: {
        id: 'nfum.platform.callOrContactInfo.speakToCallCenter',
        defaultMessage: 'One of our UK based call centres will call you back'
    },
    callCenterText: {
        id: 'nfum.platform.callOrContactInfo.callCenterText',
        defaultMessage: ' from one of our UK-based call centres'
    },
    moreInfoButton: {
        id: 'nfum.platform.callOrContactInfo.moreIfoButtonText',
        defaultMessage: 'More information'
    },
});
