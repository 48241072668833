import { start } from '@jutro/app';
import { initDefaultGA, initDefaultMixpanel } from '@jutro/events';
import { LocaleService } from 'gw-portals-i18n-react';
import { BreakpointTracker } from '@jutro/layout'; // nfum custom
// eslint-disable-next-line import/no-unresolved, camelcase
import backendTranslations_en_US from 'app-translation/en_US.json'; // nfum custom
// eslint-disable-next-line import/no-unresolved, camelcase
import backendTranslations_en_GB from 'app-translation/en_GB.json'; // nfum custom
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import consumerThemeConfig from './themes/nfumMainTheme/index'; // nfum custom
import 'regenerator-runtime';

import App from './app/App';

// nfum custom
BreakpointTracker.prototype.getBreakpoint = function getWidthBreakpoint() {
    const width = Math.max(
        document.documentElement.clientWidth,
        window.innerWidth
    );
    if (width > 1500) {
        return 'desktop';
    }
    if (width > 1024) {
        return 'tablet';
    }
    if (width > 768) {
        return 'phoneWide';
    }
    return 'phone';
};

// nfum custom
const { trackingConfig = {}, authentication = {} } = appConfig; // okta-patch

const gaTrackingId = trackingConfig.GA_TRACKING_ID;
const mixpanelTrackingId = trackingConfig.MIXPANEL_TRACKING_ID;

if (window.self === window.top) {
    const theBody = document.getElementsByTagName('body')[0];
    theBody.style.display = 'block';
} else {
    // eslint-disable-next-line max-len
    window.top.location = window.self.location; /* NOSONAR: GW OOTB internal mechanism */
}

if (gaTrackingId) {
    initDefaultGA(trackingConfig);
}
if (mixpanelTrackingId) {
    initDefaultMixpanel(trackingConfig);
}

// nfum custom
// OKTA Upgrade Patch change Start // okta-patch
const cloudAuthConfig = {
    ...appConfig.env
};
if (authentication.authServer === 'okta') {
    /* NOSONAR: OKTA/UAA Config  // cloudAuthConfig = authentication.servers.okta; */
}
// OKTA upgrade Patch change End

/* nfum translations patch for upgrade 11.5.2
#### backend translations are not working.
---
LocaleService didn't load backend translations
from i18n directory before messageLoader is used;
we import them before message loader is used
so they are loaded properly */
// eslint-disable-next-line no-unused-vars
const definedBackendTranslations = { // nfum custom
    // eslint-disable-next-line camelcase
    en_GB: backendTranslations_en_GB,
    // eslint-disable-next-line camelcase
    en_US: backendTranslations_en_US
};
/* end: nfum translations patch for upgrade 11.5.2 */

start(App, {
    rootId: 'root',
    messageLoader: LocaleService.loadMessages,
    coreMessageLoader: LocaleService.loadCoreMessages,
    config: [cloudAuthConfig], // nfum custom // okta-patch
    refreshOnLanguageOrLocaleChange: true,
    routerBasename: '/account-management',
    onInit: () => {
        LocaleService.register();
    },
    appName: {
        id: 'digital.appName',
        defaultMessage: 'CustomerEngage Account Management'
    },
    appDescription: 'CustomerEngage Account Management',
    themeConfig: consumerThemeConfig,
    modalProvider: 'default', /* NOSONAR: TODO  */
});
