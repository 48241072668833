import _ from 'lodash';
import {
    GTM_PARAMS,
    FORM_CUSTOMER_TYPES,
    FORM_INSURANCE_LINE,
    FORM_PRODUCT_LINE,
    ERROR_STEPS,
    GTM_EVENTS
} from '../constants/TagManagerConstants';
import {
    UW_ERROR_CODE,
    FUNCTIONAL_ERROR_CODE
} from '../constants/ErrorCodesConstants';

const logData = (data) => {
    console.debug(`GTM data push - ${new Date()}`);
    console.debug(data);
};

const pushToDataLayer = (data) => {
    logData(data);
    window.dataLayer.push(data);
};

const resolveCustomerType = (jobId, isExistingCustomer) => {
    if (isExistingCustomer === true) {
        return FORM_CUSTOMER_TYPES.existing;
    }
    if (isExistingCustomer === false || jobId) {
        return FORM_CUSTOMER_TYPES.new;
    }
    return FORM_CUSTOMER_TYPES.undefined;
};

const createGtmFormStepEventData = (
    jobVM,
    isExistingCustomer,
    requestType,
    step,
    event = GTM_EVENTS.formStep
) => {
    const jobId = _.get(jobVM, 'quoteID.value') || _.get(jobVM, 'jobID.value');
    const data = {
        [GTM_PARAMS.event]: event,
        [GTM_PARAMS.formCustomerType]: resolveCustomerType(jobId, isExistingCustomer),
        [GTM_PARAMS.formInsuranceLine]: FORM_INSURANCE_LINE.personalLines,
        [GTM_PARAMS.formProductLine]: FORM_PRODUCT_LINE.home,
        [GTM_PARAMS.formRequestType]: requestType,
        [GTM_PARAMS.formStepDescription]: step.description,
        [GTM_PARAMS.formStepNumber]: step.number
    };
    if (jobId) {
        data[GTM_PARAMS.formReference] = jobId;
    }
    return data;
};

const pushFormConfirmationInfo = (jobVM, isExistingCustomer, requestType, step) => {
    const data = createGtmFormStepEventData(
        jobVM,
        isExistingCustomer,
        requestType,
        step,
        GTM_EVENTS.formConfirmation
    );
    pushToDataLayer(data);
};

const pushFormStepInfo = (jobVM, isExistingCustomer, requestType, step) => {
    const data = createGtmFormStepEventData(jobVM, isExistingCustomer, requestType, step);
    pushToDataLayer(data);
};

const resolveErrorDescription = (error, errorInfo) => {
    let errorDescription = ERROR_STEPS.TECHNICAL_ERROR.description;
    if (errorInfo) {
        errorDescription = errorInfo.description;
    } else if (error?.appErrorCode && error?.appErrorCode === UW_ERROR_CODE) {
        errorDescription = ERROR_STEPS.UW_ERROR.description;
    } else if (error?.appErrorCode && error?.appErrorCode === FUNCTIONAL_ERROR_CODE) {
        errorDescription = ERROR_STEPS.CONTACT_US_ERROR.description;
    }
    return errorDescription;
};

const pushFormStepErrorInfo = (jobVM,
    isExistingCustomer,
    requestType,
    step,
    error,
    errorInfo = null) => {
    const data = createGtmFormStepEventData(jobVM, isExistingCustomer, requestType, step);
    data[GTM_PARAMS.formStepDescription] = resolveErrorDescription(error, errorInfo);
    pushToDataLayer(data);
};

const pushAccordionOpenInfo = (accordionStepName) => {
    const data = {
        [GTM_PARAMS.event]: GTM_EVENTS.accordionOpen,
        [GTM_PARAMS.linkText]: accordionStepName
    };
    pushToDataLayer(data);
};

const pushLinkClickInfo = (linkText, linkUrl = null) => {
    const data = {
        [GTM_PARAMS.event]: GTM_EVENTS.linkClick,
        [GTM_PARAMS.linkText]: linkText
    };
    if (linkUrl) {
        data[GTM_PARAMS.linkUrl] = linkUrl;
    }
    pushToDataLayer(data);
};

const pushRelativeLinkClickInfo = (linkText, linkUrl = null) => {
    const baseUrl = window.location.href.split('/').slice(0, -1).join('/');
    const absoluteUrl = `${baseUrl}${linkUrl}`;
    pushLinkClickInfo(linkText, absoluteUrl);
};

const pushFileDownloadInfo = (linkText, linkUrl, fileName = null) => {
    const data = {
        [GTM_PARAMS.event]: GTM_EVENTS.fileDownload,
        [GTM_PARAMS.linkText]: linkText,
        [GTM_PARAMS.linkUrl]: linkUrl
    };
    if (fileName) {
        data[GTM_PARAMS.fileName] = fileName;
    }
    pushToDataLayer(data);
};

export default {
    pushFormStepInfo,
    pushFormConfirmationInfo,
    pushFormStepErrorInfo,
    pushAccordionOpenInfo,
    pushLinkClickInfo,
    pushRelativeLinkClickInfo,
    pushFileDownloadInfo
};
