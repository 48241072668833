import {
    useCallback
} from 'react';
import { covIds, covKeys } from 'nfum-portals-utils-react';
import _ from 'lodash';

function usePeriodsDiff(jobVM) {
    const mapItemData = useCallback((itemData) => ({
        type: itemData?.ArticleValuationMethod?.typeCodeValue,
        description: itemData?.ArticleType?.typeCodeValue,
        replacementCost: itemData?.ArticleLimit?.bigDecimal,
        geoLimit: itemData?.ArticleGeographicLimit?.typeCodeValue,
        keptInBank: itemData?.ArticleKeptinBank?.booleanValue,
        fixedID: itemData?.FixedID?.typeCodeValue
    }), []);

    const getAddedItems = useCallback((currentItems, previousItems) => {
        const addedValuables = [];
        currentItems.forEach((item) => {
            if (!previousItems.find((prevItem) => prevItem.fixedID === item.fixedID)) {
                addedValuables.push(item);
            }
        });
        return addedValuables;
    }, []);

    const getAddedValuable = useCallback((currentItems, previousItems) => {
        const addedValuables = [];
        currentItems.forEach((item) => {
            if (!previousItems.find((prevItem) => prevItem.itemData?.FixedID?.stringValue
                === item.itemData?.FixedID?.stringValue)) {
                addedValuables.push(item);
            }
        });
        return addedValuables;
    }, []);

    const getRemovedValuables = useCallback((currentItems, previousItems) => {
        const removedValuables = [];
        previousItems.forEach((prevItem) => {
            if (!currentItems.find((item) => item.itemData?.FixedID?.stringValue
            === prevItem.itemData?.FixedID?.stringValue)) {
                removedValuables.push(prevItem);
            }
        });
        return removedValuables;
    }, []);

    const getRemovedItems = useCallback((currentItems, previousItems) => {
        const removedValuables = [];
        previousItems.forEach((prevItem) => {
            if (!currentItems.find((item) => item.fixedID === prevItem.fixedID)) {
                removedValuables.push(prevItem);
            }
        });
        return removedValuables;
    }, []);

    const isValuableEdited = (item1, item2) => {
        return item1.fixedID === item2.fixedID
            && (
                item1.description !== item2.description
                || item1.geoLimit !== item2.geoLimit
                || item1.replacementCost !== item2.replacementCost
                || item1.keptInBank !== item2.keptInBank
            );
    };

    const isFineArtEdited = (item1, item2) => {
        return item1.fixedID === item2.fixedID
            && (
                item1.sumInsured !== item2.sumInsured
                || item1.agreedValue !== item2.agreedValue
            );
    };

    const isValuablesEdited = (item1, item2) => {
        return item1.itemData?.FixedID?.stringValue === item2.itemData?.FixedID?.stringValue
            && (
                item1.itemData.ArticleReplacementCost.bigDecimal
                    !== item2.itemData.ArticleReplacementCost.bigDecimal
            );
    };

    const getEditedItems = useCallback((currentItems, previousItems, isEdited) => {
        const editedValuables = [];
        currentItems.forEach((item) => {
            previousItems.forEach((prevItem) => {
                if (isEdited(prevItem, item)) {
                    editedValuables.push({
                        new: item,
                        prev: prevItem
                    });
                }
            });
        });
        return editedValuables;
    }, []);

    const getBuildingsAccidentalDamage = useCallback((homeLineCoverages) => {
        const buildingsCov = homeLineCoverages
            ?.find(((cov) => cov.publicID === covIds.BUILDINGS_COV));
        return buildingsCov?.terms
            ?.find((term) => term.publicID === covIds.HOM_BUILDINGS_ACCIDENTAL_DAMAGE)
            ?.chosenTermValue === covKeys.YES;
    }, []);

    const getContentsAccidentalDamage = useCallback((homeLineCoverages) => {
        const contentsCov = homeLineCoverages
            ?.find(((cov) => cov.publicID === covIds.CONTENTS_COV));
        return contentsCov?.terms
            ?.find((term) => term.publicID === covIds.HOM_CONTENTS_ACCIDENTAL_DAMAGE)
            ?.chosenTermValue === covKeys.YES;
    }, []);

    const getBicycleExtension = useCallback((homeLineCoverages) => {
        const contentsAwayCov = homeLineCoverages
            ?.find(((cov) => cov.publicID === covIds.CONTENTS_AWAY_COV));
        return contentsAwayCov?.terms
            ?.find((term) => term.publicID === covIds.HOM_CONTENTS_AWAY_BICYCLE_EXTENSION)
            ?.chosenTermValue === covKeys.YES;
    }, []);

    const getBicycleExtensionForBespoke = useCallback((homeLineCoverages) => {
        const contentsCov = homeLineCoverages
            ?.find(((cov) => cov.publicID === covIds.CONTENTS_COV));
        return contentsCov?.terms
            ?.find((term) => term.publicID === covIds.HOM_CONTENTS_BICYCLE_EXTENSION)
            ?.chosenTermValue === covKeys.YES;
    }, []);

    const getHomeEmergencyCov = useCallback((homeLineCoverages) => {
        return homeLineCoverages
            ?.find(((cov) => cov.publicID === covIds.HOM_HOME_EMERGENCY_COV))
            ?.selected || false;
    }, []);

    const getLegalExpensesCov = useCallback((lexLineCoverages) => {
        const legalExpensesCover = lexLineCoverages
            ?.find((cov) => cov.publicID === covIds.LEX_PERSONAL_LEGAL_EXPENSES_COV);
        const isLegalExpensesCover = legalExpensesCover?.selected;
        const legalExpensesLevelOfCover = legalExpensesCover?.terms
            ?.find((term) => term.name === covKeys.LEVEL_OF_COVER)?.chosenTermValue;
        const lexCoverValue = isLegalExpensesCover
            ? legalExpensesLevelOfCover
            : false;
        return lexCoverValue;
    }, []);

    const getAdditionalCovers = useCallback(() => {
        const currentHomeLineCoverages = _.get(jobVM, 'lobData.value.homeLine.lineCoverages.coverages');
        const currentLexLineCoverages = _.get(jobVM, 'lobData.value.lexLine.lineCoverages.coverages');
        const previousHomeLineCoverages = _.get(jobVM, 'previousLobData.value.homeLine.lineCoverages.coverages');
        const previousLexLineCoverages = _.get(jobVM, 'previousLobData.value.lexLine.lineCoverages.coverages');
        return [
            {
                coverageId: covIds.HOM_HOME_EMERGENCY_COV,
                newValue: getHomeEmergencyCov(currentHomeLineCoverages),
                oldValue: getHomeEmergencyCov(previousHomeLineCoverages)
            },
            {
                coverageId: covIds.HOM_CONTENTS_ACCIDENTAL_DAMAGE,
                newValue: getContentsAccidentalDamage(currentHomeLineCoverages),
                oldValue: getContentsAccidentalDamage(previousHomeLineCoverages)
            },
            {
                coverageId: covIds.HOM_BUILDINGS_ACCIDENTAL_DAMAGE,
                newValue: getBuildingsAccidentalDamage(currentHomeLineCoverages),
                oldValue: getBuildingsAccidentalDamage(previousHomeLineCoverages)
            },
            {
                coverageId: covIds.LEX_PERSONAL_LEGAL_EXPENSES_COV,
                newValue: getLegalExpensesCov(currentLexLineCoverages),
                oldValue: getLegalExpensesCov(previousLexLineCoverages)
            },
            {
                coverageId: covIds.HOM_CONTENTS_AWAY_BICYCLE_EXTENSION,
                newValue: getBicycleExtension(currentHomeLineCoverages),
                oldValue: getBicycleExtension(previousHomeLineCoverages)
            },
            {
                coverageId: covIds.HOM_CONTENTS_BICYCLE_EXTENSION,
                newValue: getBicycleExtensionForBespoke(currentHomeLineCoverages),
                oldValue: getBicycleExtensionForBespoke(previousHomeLineCoverages)
            },
        ];
    }, [
        jobVM,
        getHomeEmergencyCov,
        getBuildingsAccidentalDamage,
        getContentsAccidentalDamage,
        getLegalExpensesCov,
        getBicycleExtension,
        getBicycleExtensionForBespoke
    ]);

    const getSpecificValuables = useCallback(() => {
        const currentValuables = _.get(jobVM, 'lobData.value.homeLine.lineCoverages.schedules[0].scheduleItems')
            ?.filter((item) => !_.isEmpty(item.itemData.ArticleGeographicLimit))
            ?.map((item) => mapItemData(item.itemData));
        const previousValuables = _.get(jobVM, 'previousLobData.value.homeLine.lineCoverages.schedules[0].scheduleItems')
            ?.filter((item) => !_.isEmpty(item.itemData.ArticleGeographicLimit))
            ?.map((item) => mapItemData(item.itemData));
        return {
            added: getAddedItems(currentValuables, previousValuables),
            removed: getRemovedItems(currentValuables, previousValuables),
            edited: getEditedItems(currentValuables, previousValuables, isValuableEdited)
        };
    }, [
        jobVM,
        getAddedItems,
        getRemovedItems,
        getEditedItems,
        mapItemData
    ]);

    const getValuables = useCallback(() => {
        const currentValuables = _.get(jobVM, 'lobData.value.homeLine.lineCoverages.schedules[0].scheduleItems');
        const previousValuables = _.get(jobVM, 'previousLobData.value.homeLine.lineCoverages.schedules[0].scheduleItems');

        const currentValuable = _.get(jobVM, 'lobData.value.homeLine.lineCoverages.schedules[0].scheduleItems')
            .filter((item) => item.itemData.ArticleType.typeCodeValue === 'valuable');
        const previousValuable = _.get(jobVM, 'previousLobData.value.homeLine.lineCoverages.schedules[0].scheduleItems')
            .filter((item) => item.itemData.ArticleType.typeCodeValue === 'valuable');

        const currentJewels = _.get(jobVM, 'lobData.value.homeLine.lineCoverages.schedules[0].scheduleItems')
            .filter((item) => item.itemData.ArticleType.typeCodeValue === 'JewelleryAndGemstones');
        const previousJewels = _.get(jobVM, 'previousLobData.value.homeLine.lineCoverages.schedules[0].scheduleItems')
            .filter((item) => item.itemData.ArticleType.typeCodeValue === 'JewelleryAndGemstones');

        const currentGuns = _.get(jobVM, 'lobData.value.homeLine.lineCoverages.schedules[0].scheduleItems')
            .filter((item) => item.itemData.ArticleType.typeCodeValue === 'Guns');
        const previousGuns = _.get(jobVM, 'previousLobData.value.homeLine.lineCoverages.schedules[0].scheduleItems')
            .filter((item) => item.itemData.ArticleType.typeCodeValue === 'Guns');

        const currentFurs = _.get(jobVM, 'lobData.value.homeLine.lineCoverages.schedules[0].scheduleItems')
            .filter((item) => item.itemData.ArticleType.typeCodeValue === 'Furs');
        const previousFurs = _.get(jobVM, 'previousLobData.value.homeLine.lineCoverages.schedules[0].scheduleItems')
            .filter((item) => item.itemData.ArticleType.typeCodeValue === 'Furs');

        const currentWatches = _.get(jobVM, 'lobData.value.homeLine.lineCoverages.schedules[0].scheduleItems')
            .filter((item) => item.itemData.ArticleType.typeCodeValue === 'Watches');
        const previousWatches = _.get(jobVM, 'previousLobData.value.homeLine.lineCoverages.schedules[0].scheduleItems')
            .filter((item) => item.itemData.ArticleType.typeCodeValue === 'Watches');
        return {
            collections: [
                {
                    name: 'Valuables',
                    added: getAddedValuable(currentValuable, previousValuable),
                    removed: getRemovedValuables(currentValuable, previousValuable),
                    edited: getEditedItems(currentValuable, previousValuable, isValuablesEdited)
                },
                {
                    name: 'Jewellery and Gemstones',
                    added: getAddedValuable(currentJewels, previousJewels),
                    removed: getRemovedValuables(currentJewels, previousJewels),
                    edited: getEditedItems(currentJewels, previousJewels, isValuablesEdited)
                },
                {
                    name: 'Watches',
                    added: getAddedValuable(currentWatches, previousWatches),
                    removed: getRemovedValuables(currentWatches, previousWatches),
                    edited: getEditedItems(currentWatches,
                        previousWatches, isValuablesEdited)
                },
                {
                    name: 'Guns',
                    added: getAddedValuable(currentGuns, previousGuns),
                    removed: getRemovedValuables(currentGuns, previousGuns),
                    edited: getEditedItems(currentGuns, previousGuns, isValuablesEdited)
                },
                {
                    name: 'Furs',
                    added: getAddedValuable(currentFurs, previousFurs),
                    removed: getRemovedValuables(currentFurs, previousFurs),
                    edited: getEditedItems(currentFurs, previousFurs, isValuablesEdited)
                }
            ],
            valuable: {
                added: getAddedValuable(currentValuable, previousValuable),
                removed: getRemovedValuables(currentValuable, previousValuable),
                edited: getEditedItems(currentValuable, previousValuable, isValuablesEdited)
            },
            jewels: {
                added: getAddedValuable(currentJewels, previousJewels),
                removed: getRemovedValuables(currentJewels, previousJewels),
                edited: getEditedItems(currentJewels, previousJewels, isValuablesEdited)
            },
            watches: {
                added: getAddedValuable(currentWatches, previousWatches),
                removed: getRemovedValuables(currentWatches, previousWatches),
                edited: getEditedItems(currentWatches,
                    previousWatches, isValuablesEdited)
            },
            guns: {
                added: getAddedValuable(currentGuns, previousGuns),
                removed: getRemovedValuables(currentGuns, previousGuns),
                edited: getEditedItems(currentGuns, previousGuns, isValuablesEdited)
            },
            furs: {
                added: getAddedValuable(currentFurs, previousFurs),
                removed: getRemovedValuables(currentFurs, previousFurs),
                edited: getEditedItems(currentFurs, previousFurs, isValuablesEdited)
            },
            added: getAddedValuable(currentValuables, previousValuables),
            removed: getRemovedValuables(currentValuables, previousValuables),
            edited: getEditedItems(currentValuables, previousValuables, isValuablesEdited)
        };
    }, [
        jobVM,
        getAddedValuable,
        getRemovedValuables,
        getEditedItems
    ]);

    const getBicycles = useCallback(() => {
        const currentValuables = _.get(jobVM, 'lobData.value.homeLine.lineCoverages.schedules[0].scheduleItems')
            ?.filter((item) => _.isEmpty(item.itemData.ArticleGeographicLimit))
            ?.map((item) => mapItemData(item.itemData));
        const previousValuables = _.get(jobVM, 'previousLobData.value.homeLine.lineCoverages.schedules[0].scheduleItems')
            ?.filter((item) => _.isEmpty(item.itemData.ArticleGeographicLimit))
            ?.map((item) => mapItemData(item.itemData));
        return {
            added: getAddedItems(currentValuables, previousValuables),
            removed: getRemovedItems(currentValuables, previousValuables),
            edited: getEditedItems(currentValuables, previousValuables, isValuableEdited)
        };
    }, [
        jobVM,
        getAddedItems,
        getRemovedItems,
        getEditedItems,
        mapItemData
    ]);

    const getUnspecifiedBelongingsReplCostValue = useCallback(() => {
        const currentIsContentsAway = _.get(jobVM, 'lobData.value.homeLine.lineCoverages.isUnspecifiedContentsAwaySelected');
        const previousIsContentsAway = _.get(jobVM, 'previousLobData.value.homeLine.lineCoverages.isUnspecifiedContentsAwaySelected');
        return {
            newValue: currentIsContentsAway ? _.get(jobVM, 'lobData.value.homeLine.lineCoverages.unspecifiedBelongingsReplCost').replace('gbp', '') : null,
            oldValue: previousIsContentsAway ? _.get(jobVM, 'previousLobData.value.homeLine.lineCoverages.unspecifiedBelongingsReplCost').replace('gbp', '') : null,
        };
    }, [jobVM]);

    const getContentsOverrideCostValue = useCallback(() => {
        const currentContentsOverrideVal = _.get(jobVM, 'lobData.value.homeLine.coverables.homhomeProperty.homhomePropertyConstruction.contentsReplacementCostOverrid');
        const previousContentsOverrideVal = _.get(jobVM, 'previousLobData.value.homeLine.coverables.homhomeProperty.homhomePropertyConstruction.contentsReplacementCostOverrid');
        return {
            newValue: currentContentsOverrideVal ? _.get(jobVM, 'lobData.value.homeLine.coverables.homhomeProperty.homhomePropertyConstruction.contentsReplacementCostOverrid') : null,
            oldValue: previousContentsOverrideVal ? _.get(jobVM, 'previousLobData.value.homeLine.coverables.homhomeProperty.homhomePropertyConstruction.contentsReplacementCostOverrid') : null,
        };
    }, [jobVM]);

    const getFineArtsAndCollections = useCallback(() => {
        const currentFineArtsItems = _.get(jobVM, 'lobData.value.homeLine.lineCoverages.fineArts') || [];
        const previousFineArtsItems = _.get(jobVM, 'previousLobData.value.homeLine.lineCoverages.fineArts') || [];

        const currentFineArts = currentFineArtsItems
            .filter((item) => item.itemType === 'FineArt');
        const previousFineArts = previousFineArtsItems
            .filter((item) => item.itemType === 'FineArt');

        const currentWines = currentFineArtsItems
            .filter((item) => item.itemType === 'WineCollections');
        const previousWines = previousFineArtsItems
            .filter((item) => item.itemType === 'WineCollections');

        const currentStampsMedalsCoins = currentFineArtsItems
            .filter((item) => item.itemType === 'StampsMedalsCoins');
        const previousStampsMedalsCoins = previousFineArtsItems
            .filter((item) => item.itemType === 'StampsMedalsCoins');

        const currentOther = currentFineArtsItems
            .filter((item) => item.itemType === 'OtherCollections');
        const previousOther = previousFineArtsItems
            .filter((item) => item.itemType === 'OtherCollections');

        return {
            collections: [
                {
                    name: 'Fine Arts',
                    added: getAddedItems(currentFineArts, previousFineArts),
                    removed: getRemovedItems(currentFineArts, previousFineArts),
                    edited: getEditedItems(currentFineArts, previousFineArts, isFineArtEdited)
                },
                {
                    name: 'Wine',
                    added: getAddedItems(currentWines, previousWines),
                    removed: getRemovedItems(currentWines, previousWines),
                    edited: getEditedItems(currentWines, previousWines, isFineArtEdited)
                },
                {
                    name: 'Stamps, medals and coins',
                    added: getAddedItems(currentStampsMedalsCoins, previousStampsMedalsCoins),
                    removed: getRemovedItems(currentStampsMedalsCoins, previousStampsMedalsCoins),
                    edited: getEditedItems(currentStampsMedalsCoins,
                        previousStampsMedalsCoins, isFineArtEdited)
                },
                {
                    name: 'Other collections',
                    added: getAddedItems(currentOther, previousOther),
                    removed: getRemovedItems(currentOther, previousOther),
                    edited: getEditedItems(currentOther, previousOther, isFineArtEdited)
                }
            ],
            fineArts: {
                added: getAddedItems(currentFineArts, previousFineArts),
                removed: getRemovedItems(currentFineArts, previousFineArts),
                edited: getEditedItems(currentFineArts, previousFineArts, isFineArtEdited)
            },
            wines: {
                added: getAddedItems(currentWines, previousWines),
                removed: getRemovedItems(currentWines, previousWines),
                edited: getEditedItems(currentWines, previousWines, isFineArtEdited)
            },
            stampsMedalsCoins: {
                added: getAddedItems(currentStampsMedalsCoins, previousStampsMedalsCoins),
                removed: getRemovedItems(currentStampsMedalsCoins, previousStampsMedalsCoins),
                edited: getEditedItems(currentStampsMedalsCoins,
                    previousStampsMedalsCoins, isFineArtEdited)
            },
            other: {
                added: getAddedItems(currentOther, previousOther),
                removed: getRemovedItems(currentOther, previousOther),
                edited: getEditedItems(currentOther, previousOther, isFineArtEdited)
            },
            added: getAddedItems(currentFineArtsItems, previousFineArtsItems),
            removed: getRemovedItems(currentFineArtsItems, previousFineArtsItems),
            edited: getEditedItems(currentFineArtsItems, previousFineArtsItems, isFineArtEdited)
        };
    }, [
        jobVM,
        getAddedItems,
        getRemovedItems,
        getEditedItems
    ]);

    return {
        getBicycles,
        getSpecificValuables,
        getAdditionalCovers,
        getUnspecifiedBelongingsReplCostValue,
        getContentsOverrideCostValue,
        getFineArtsAndCollections,
        getValuables
    };
}

export default usePeriodsDiff;
