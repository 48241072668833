import { defineMessages } from 'react-intl';

export default defineMessages({
    renewPolicy: {
        id: 'ngh.policy-renewal.renewPolicy',
        defaultMessage: 'Renew my policy'
    },
    returnToDashboard: {
        id: 'ngh.policy-renewal.returnToDashboard',
        defaultMessage: 'Return to dashboard'
    },
    backToDashboard: {
        id: 'ngh.policy-renewal.backToDashboard',
        defaultMessage: 'Back to dashboard'
    },
    returnToAccount: {
        id: 'ngh.policy-renewal.returnToAccount',
        defaultMessage: 'Return to account'
    },
    renewal: {
        id: 'ngh.policy-renewal.renewal',
        defaultMessage: 'Renewal'
    },
    renewYourPolicy: {
        id: 'ngh.policy-renewal.renewYourPolicy',
        defaultMessage: 'Renew your policy'
    },
    payment: {
        id: 'ngh.policy-renewal.payment',
        defaultMessage: 'Payment'
    },
    paymentConfirmation: {
        id: 'ngh.policy-renewal.paymentConfirmation',
        defaultMessage: 'Payment confirmation'
    }
});
