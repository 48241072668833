import { defineMessages } from 'react-intl';

export default defineMessages({
    yes: {
        id: 'renewal.ngh.views.renewal-summary.animal.yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'renewal.ngh.views.renewal-summary.animal.no',
        defaultMessage: 'No'
    },
    notSelected: {
        id: 'renewal.ngh.views.renewal-summary.animal.notSelected',
        defaultMessage: 'Not Selected'
    },
    workingDog: {
        id: 'renewal.ngh.views.renewal-summary.animal.workingDog',
        defaultMessage: 'Working Dog'
    },
    breed: {
        id: 'renewal.ngh.views.renewal-summary.animal.breed',
        defaultMessage: 'Breed'
    },
    animalPHC: {
        id: 'renewal.ngh.views.renewal-summary.animal.animalPHC',
        defaultMessage: 'Permanent health cover'
    },
    pricePaid: {
        id: 'renewal.ngh.views.renewal-summary.animal.pricePaid',
        defaultMessage: 'Price Paid'
    },
    lossOfAnimalCover: {
        id: 'renewal.ngh.views.renewal-summary.animal.lossOfAnimalCover',
        defaultMessage: 'Loss of animal cover'
    },
    lossOfAnimalSumInsured: {
        id: 'renewal.ngh.views.renewal-summary.animal.lossOfAnimalSumInsured',
        defaultMessage: 'Loss of animal sum insured'
    },
    lossOfAnimalCoverType: {
        id: 'renewal.ngh.views.renewal-summary.animal.lossOfAnimalCoverType',
        defaultMessage: 'Loss of animal cover type'
    },
    lossOfUseCover: {
        id: 'renewal.ngh.views.renewal-summary.animal.lossOfUseCover',
        defaultMessage: 'Loss of use cover'
    },
    lossOfUseSumInsured: {
        id: 'renewal.ngh.views.renewal-summary.animal.lossOfUseSumInsured',
        defaultMessage: 'Loss of use sum insured'
    },
    vetFeesCover: {
        id: 'renewal.ngh.views.renewal-summary.animal.vetFeesCover',
        defaultMessage: 'Vets fees cover'
    },
    vetFeesLimit: {
        id: 'renewal.ngh.views.renewal-summary.animal.vetFeesLimit',
        defaultMessage: 'Vets fees limit'
    },
    vetFeesLimitsBasis: {
        id: 'renewal.ngh.views.renewal-summary.animal.vetFeesLimitsBasis',
        defaultMessage: 'Vets fees limits basis'
    },
    vetFeesCoverType: {
        id: 'renewal.ngh.views.renewal-summary.animal.vetFeesCoverType',
        defaultMessage: 'Vets fees cover type'
    },
    vetFeesExcess: {
        id: 'renewal.ngh.views.renewal-summary.animal.vetFeesExcess',
        defaultMessage: 'Vets fees excess'
    },
    ownersLiabilityCover: {
        id: 'renewal.ngh.views.renewal-summary.animal.ownersLiabilityCover',
        defaultMessage: 'Owners liability cover'
    },
});
