import React, { useCallback, useContext, useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useCurrency } from 'nfum-portals-utils-react';
import messages from './CostBreakdownBox.messages';
import metadata from './CostBreakdownBox.metadata.json5';
import styles from './CostBreakdownBox.module.scss';

function CostBreakdownBox(props) { /* NOSONAR: pure declarative usage  */
    const { submissionVM } = props;
    const translator = useContext(TranslatorContext);
    const isCash = _.get(submissionVM, 'baseData.value.paymentMethod_NFUM') === 'cash';
    const currencyFormatter = useCurrency();

    const getYearlyTransactionCost = useCallback(() => {
        let sum = _.get(submissionVM, 'transactionCost.value.amount');
        if (sum < 0) sum = -sum;
        return currencyFormatter.formatCurrency(`${sum}`, true);
    }, [currencyFormatter, submissionVM]);

    const getDate = (dateYMD) => {
        const { year, month, day } = dateYMD;
        const date = moment(new Date(year, month, day));
        return date;
    };

    const outstandingInstallments = useMemo(() => {
        const previousInstallments = _.get(submissionVM.value, 'previousInstallmentDetails_NFUM');
        const futureInstallments = _.get(submissionVM.value, 'installmentDetails_NFUM')
            .filter((installment) => getDate(installment.installmentDate).isAfter(moment()));

        const processedInstallments = [];
        futureInstallments.forEach((inst) => {
            const equivInstallment = previousInstallments
                .find((prevInst) => getDate(prevInst.installmentDate)
                    .isSame(getDate(inst.installmentDate)));
            processedInstallments.push({
                installmentAmount: inst.installmentAmount,
                installmentChangedAmount: {
                    amount: inst.installmentChangedAmount.amount
                        - (equivInstallment?.installmentPaid?.amount || 0),
                    currency: inst.installmentChangedAmount.currency
                },
                installmentDate: inst.installmentDate
            });
        });

        return processedInstallments.sort((a, b) => {
            return getDate(a.installmentDate).diff(getDate(b.installmentDate));
        });
    }, [submissionVM]);

    const getMonthlyTransactionCost = useCallback(() => {
        const transactionCosts = _.get(submissionVM, 'transactionCost.value.amount');
        const numberOfInstallments = outstandingInstallments.length || 1;

        // transaction cost divided by number of outstanding installments
        let sum = transactionCosts / numberOfInstallments;
        if (sum < 0) sum = -sum;
        return currencyFormatter.formatCurrency(`${sum}`, true);
    }, [currencyFormatter, submissionVM, outstandingInstallments]);

    const getYearlyTotalCost = useCallback(() => {
        let sum = _.get(submissionVM, 'totalCost.value.amount');
        if (sum < 0) sum = -sum;
        return currencyFormatter.formatCurrency(`${sum}`, true);
    }, [currencyFormatter, submissionVM]);

    const getMonthlyTotalCost = useCallback(() => {
        let sum = _.get(submissionVM, 'totalCostMonthly_NFUM.value.amount');
        if (sum < 0) sum = -sum;
        return currencyFormatter.formatCurrency(`${sum}`, true);
    }, [currencyFormatter, submissionVM]);

    const getAdditionalIncreaseDecreaseText = useCallback(() => {
        const transactionCost = _.get(submissionVM, 'transactionCost.value.amount');
        let amount = 0;
        let perTime = '';
        let noPremiumChange = '';
        let messageKey = '';

        if (isCash) {
            amount = getYearlyTransactionCost();
            perTime = translator(messages.perYear);
            noPremiumChange = translator(messages.noPremiumChange);
            if (transactionCost < 0) {
                messageKey = messages.decreaseOf;
            } else if (transactionCost > 0) {
                messageKey = messages.increaseOf;
            }
        } else {
            amount = getMonthlyTransactionCost();
            perTime = translator(messages.perMonth);
            noPremiumChange = translator(messages.noMonthlyPremiumChange);
            if (transactionCost < 0) {
                messageKey = messages.ddDecreaseOf;
            } else if (transactionCost > 0) {
                messageKey = messages.ddIncreaseOf;
            }
        }

        if (transactionCost === 0) {
            return noPremiumChange;
        }

        return translator(messageKey, { amount, perTime });
    }, [
        translator,
        getYearlyTransactionCost,
        getMonthlyTransactionCost,
        isCash,
        submissionVM
    ]);

    const overrideProps = {
        costInfoBox: {
            modelVM: submissionVM,
            additionalText: getAdditionalIncreaseDecreaseText(),
            isPerYear: isCash,
            titleText: translator(messages.yourNewPremium),
            premiumText: isCash ? getYearlyTotalCost() : getMonthlyTotalCost(),
            policyNumber: _.get(submissionVM.value, 'policyNumber'),
            policyStartDate: _.get(submissionVM.value, 'baseData.periodStartDate')
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
        />
    );
}

export default CostBreakdownBox;
