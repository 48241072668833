import { lib, SHA256, enc } from 'crypto-js';

function convertToBytesInt32(num) {
    const arr = new ArrayBuffer(4); // an Int32 takes 4 bytes
    const view = new DataView(arr);
    view.setUint32(0, num, false);
    return arr;
}

function generateRandomValues(size) {
    const randoms = lib.WordArray.random(size);
    const randoms1byte = [];

    randoms.words.forEach((word) => {
        const arr = convertToBytesInt32(word);
        const fourByteWord = new Uint8Array(arr);
        for (let i = 0; i < 4; i += 1) {
            randoms1byte.push(fourByteWord[i]);
        }
    });
    return randoms1byte;
}

function cryptoString(size) {
    const mask = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-._~';
    let result = '';
    const randomUints = generateRandomValues(size);
    for (let i = 0; i < size; i += 1) {
        const randomIndex = randomUints[i] % mask.length;
        result += mask[randomIndex];
    }
    return result;
}

function generateCodeChallenge(codeVerifier) {
    return SHA256(codeVerifier).toString(enc.Base64url);
}

export default function pkceChallenge(length = 43) {
    if (length < 43 || length > 128) {
        throw new Error(`Expected a length between 43 and 128. Received ${length}.`);
    }

    const verifier = cryptoString(length);
    const challenge = generateCodeChallenge(verifier);

    return { verifier, challenge };
}
