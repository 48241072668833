import React, { useContext, useCallback } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { useCurrency } from 'nfum-portals-utils-react';
import messages from './ContentsAwaySummary.messages';
import metadata from './ContentsAwaySummary.metadata.json5';
import styles from './ContentsAwaySummary.module.scss';

function ContentsAwaySummary(props) {
    const {
        data: unspecifiedBelongingsReplCostModel
    } = props;
    const currencyFormatter = useCurrency();
    const translator = useContext(TranslatorContext);

    const getItemReplacementCost = useCallback((replacementCost) => {
        if (!replacementCost) return null;
        return currencyFormatter.formatCurrency(replacementCost);
    }, [currencyFormatter]);

    const renderValue = (item, index, metadataProps) => {
        const { path } = metadataProps;
        return item[path];
    };

    const overrideProps = {
        contentsAwayTable: {
            title: translator(messages.unspecifiedItemsAway),
            data: [
                {
                    oldValue: getItemReplacementCost(unspecifiedBelongingsReplCostModel.oldValue)
                        || translator(messages.notSelected),
                    newValue: getItemReplacementCost(unspecifiedBelongingsReplCostModel.newValue)
                        || translator(messages.removed),
                }
            ]
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            renderValue
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            overrideProps={overrideProps}
        />
    );
}

export default ContentsAwaySummary;
