import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './UserDetailsComponent.metadata.json5';
import styles from './UserDetailsComponent.module.scss';

const isSubtype = (contactDetails, subtype) => {
    return contactDetails.subtype.value === subtype;
};

function UserDetailsComponent(props) {
    const breakpoint = useContext(BreakpointTrackerContext);
    const {
        id,
        value: account,
        path: elementPath,
        contactPath,
        onValueChange,
        onValidate,
        labelPosition,
        showOptional,
        readOnly,
        nameReadonly,
        phone,
        phoneWide
    } = props;
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);

    const contactVM = useMemo(() => _.get(account, contactPath, {}), [account, contactPath]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const onModelChange = useCallback(
        (model) => {
            onValueChange(model, `${elementPath}.${contactPath}`);
        },
        [contactPath, elementPath, onValueChange]
    );

    const overrideProps = {
        '@field': {
            labelPosition,
            showOptional,
            readOnly,
            phone,
            phoneWide,
            layout: breakpoint !== 'desktop' ? 'reversed' : ''
        },
        personFirstName: {
            readOnly: readOnly || nameReadonly
        },
        personLastName: {
            readOnly: readOnly || nameReadonly
        },
        companyName: {
            readOnly: readOnly || nameReadonly
        },
        personDetailsContainer: {
            visible: isSubtype(contactVM, 'Person')
        },
        companyDetailsContainer: {
            visible: isSubtype(contactVM, 'Company')
        },
        addressLine2: {
            visible:
                !readOnly || (readOnly && !_.isEmpty(contactVM.primaryAddress.addressLine2.value))
        },
        addressLine3: {
            visible:
                !readOnly || (readOnly && !_.isEmpty(contactVM.primaryAddress.addressLine3.value))
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={contactVM}
            overrideProps={overrideProps}
            onModelChange={onModelChange}
            onValidationChange={setComponentValidation}
            classNameMap={styles}
        />
    );
}

UserDetailsComponent.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.shape({}).isRequired,
    phoneWide: PropTypes.shape({}),
    phone: PropTypes.shape({}),
    path: PropTypes.string.isRequired,
    contactPath: PropTypes.string.isRequired,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    labelPosition: PropTypes.string.isRequired,
    showOptional: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool,
    nameReadonly: PropTypes.bool
};

UserDetailsComponent.defaultProps = {
    readOnly: false,
    nameReadonly: false,
    phoneWide: undefined,
    phone: undefined
};

export default UserDetailsComponent;
