import { defineMessages } from 'react-intl';

export default defineMessages({
    loadingQuote: {
        id: 'quoteLoadingView.loadingQuote',
        defaultMessage: 'Loading your quote',
    },
    updatingPrices: {
        id: 'quoteLoadingView.updatingPrices',
        defaultMessage: 'We are updating your prices.',
    },
});
