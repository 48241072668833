import { defineMessages } from 'react-intl';

export default defineMessages({
    paymentDate: {
        id: 'renewal.ngh.views.renewal-summary.paymentSchedule.paymentDate',
        defaultMessage: 'Payment date'
    },
    premium: {
        id: 'renewal.ngh.views.renewal-summary.paymentSchedule.premium',
        defaultMessage: 'Premium'
    },
});
