import { defineMessages } from 'react-intl';

export default defineMessages({
    weCanCallYou: {
        id: 'ceam.pages.serviceUnavailablePage.weCanCallYou',
        defaultMessage: 'We can call you at a time that\'s convinent for you'
    },
    bookACallBack: {
        id: 'ceam.pages.serviceUnavailablePage.bookACallBack',
        defaultMessage: 'Book a call back'
    },
    alternativelyYouCan: {
        id: 'ceam.pages.serviceUnavailablePage.alternativelyYouCan',
        defaultMessage: 'We\'ve saved your progress and will be able to pick up with you where you left off. Alternatively you can '
    },
    or: {
        id: 'ceam.pages.serviceUnavailablePage.or',
        defaultMessage: ' or '
    },
    sendUsAnEmail: {
        id: 'ceam.pages.serviceUnavailablePage.sendUsAnEmail',
        defaultMessage: 'send us an email.'
    },
    policyNumberReference: {
        id: 'ceam.pages.serviceUnavailablePage.policyNumberReference',
        defaultMessage: 'Policy number'
    },
    quoteNumberReference: {
        id: 'ceam.pages.serviceUnavailablePage.quoteNumberReference',
        defaultMessage: 'Quote number'
    },
    close: {
        id: 'ceam.pages.serviceUnavailablePage.close',
        defaultMessage: 'Close'
    },
    title: {
        id: 'ceam.pages.serviceUnavailablePage.title',
        defaultMessage: 'Sorry, we are experiencing some technical problems at the moment.'
    },
    description: {
        id: 'ceam.pages.serviceUnavailablePage.description',
        defaultMessage: 'We are aware of the issues and are working to solve it urgently so please try again a little later.'
    },
    description2: {
        id: 'ceam.pages.serviceUnavailablePage.description2',
        defaultMessage: "We've saved your progress and will be able to pick up with you where you left off."
    },
    descriptionExtended: {
        id: 'ceam.pages.serviceUnavailablePage.descriptionExtended',
        defaultMessage: 'You can also call our UK-based team, or arrange a call back for a time that suits you.'
    },
    agencyDescriptionExtended: {
        id: 'ceam.pages.serviceUnavailablePage.agencyDescriptionExtended',
        defaultMessage: 'You can also contact your local Agency at a time that suits you.'
    },
    pageTitle: {
        id: 'ceam.pages.serviceUnavailablePage.pageTitle',
        defaultMessage: 'Service currently unavailable'
    },
});
