import React, { useCallback } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import usePropertyTitle from '../../../hooks/usePropertyTitle';
import ValuablesPerLocation from './ValuablesPerLocation/ValuablesPerLocation';
import metadata from './ValuablesSummary.metadata.json5';
import styles from './ValuablesSummary.module.scss';

function ValuablesSummary(props) {
    const { homePropertiesData } = props;
    const { getPropertyTitle } = usePropertyTitle();

    const generateHomePropertiesData = useCallback(() => {
        if (!homePropertiesData) {
            return {};
        }
        const homePropertiesOverrides = homePropertiesData.map((homeProperty, index) => {
            return {
                [`valuablesHomePropertyTitle${index}`]: {
                    content: getPropertyTitle(homeProperty, false)
                },
                [`valuablesPerLocation${index}`]: {
                    scheduleItems: homeProperty.coverages.schedules[0].scheduleItems
                        .filter((item) => item
                            .itemData.ArticleGeographicLimit.typeCodeValue !== undefined)
                }
            };
        });
        return Object.assign({}, ...homePropertiesOverrides);
    }, [
        homePropertiesData,
        getPropertyTitle
    ]);

    const overrideProps = {
        valuablesHomeProperties: {
            data: homePropertiesData
        },
        ...generateHomePropertiesData()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentsMap: {
            valuablesPerLocation: ValuablesPerLocation
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentsMap}
        />
    );
}

export default ValuablesSummary;
