import { defineMessages } from 'react-intl';

export default defineMessages({
    perYearAsterisk: {
        id: 'endorsement.ngh.components.PaymentPremiumInfoBox.perYearAsterisk',
        defaultMessage: 'per year*'
    },
    perMonthAsterisk: {
        id: 'endorsement.ngh.components.PaymentPremiumInfoBox.perMonthAsterisk',
        defaultMessage: 'per month*'
    },
    perYearDot: {
        id: 'endorsement.ngh.components.PaymentPremiumInfoBox.perYearDot',
        defaultMessage: 'per year',
    },
    perMonthDot: {
        id: 'endorsement.ngh.components.PaymentPremiumInfoBox.perMonthDot',
        defaultMessage: 'per month',
    },
    insurancePremiumTax: {
        id: 'endorsement.ngh.components.PaymentPremiumInfoBox.insurancePremiumTax',
        defaultMessage: '*Includes Insurance Premium Tax (IPT) where appropriate',
    },
    onceTheInitialPayment: {
        id: 'endorsement.ngh.components.PaymentPremiumInfoBox.onceTheInitialPayment',
        defaultMessage: 'Once the initial payment has been made we can set up your Direct-Debit for the remaining 11 monthly payments of ',
    },
    noPremiumChangeText: {
        id: 'endorsement.ngh.components.PaymentPremiumInfoBox.noPremiumChangeText',
        defaultMessage: 'There is no change to your premium'
    }
});
