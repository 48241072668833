import React, { useCallback, useContext } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import useTagManager from '../../../../hooks/useTagManager';
import metadata from './FineArtsPerLocation.metadata.json5';
import styles from './FineArtsPerLocation.module.scss';
import messages from './FineArtsPerLocation.messages';

function FineArtsPerLocation(props) {
    const translator = useContext(TranslatorContext);
    const {
        pushLinkClickInfo
    } = useTagManager();
    const {
        propertyTitle,
        unSpecifiedFineArtData,
        othersData, specifiedData,
        stampdata,
        winesData,
        onClickSeeDocuments
    } = props;
    const {
        unspecSingleItemLimit,
        unspAntqCltFurnSI,
        unspecPicPntgsDetails,
        unspecBooksAndManucriptsSI,
        unspStaSclCrosArtSI,
        unspecPrclnRareGlsCrmicSI,
        unspecClckBrMtrMechArtSI,
        unspecPreciousMetalSI,
        unspecTepsAndRugsSI
    } = unSpecifiedFineArtData;

    const isUnspecHeader = unspAntqCltFurnSI
    || unspecPicPntgsDetails
    || unspecBooksAndManucriptsSI
    || unspStaSclCrosArtSI
    || unspecPrclnRareGlsCrmicSI
    || unspecClckBrMtrMechArtSI
    || unspecPreciousMetalSI
    || unspecTepsAndRugsSI;

    const isUnspecified = Object.keys(unSpecifiedFineArtData).length > 0;
    const isSPecified = specifiedData.length > 0;
    const isStampData = stampdata.length > 0;
    const isWinesData = winesData.length > 0;
    const isOthersData = othersData.length > 0;
    const isCollections = isStampData || isWinesData || isOthersData;

    const onFindAgencyClicked = useCallback(() => {
        const url = 'https://www.nfumutual.co.uk/agent-offices/';
        pushLinkClickInfo(translator(messages.fineArtsMessageContent212), url);
        window.open(url, '_blank');
    }, [pushLinkClickInfo, translator]);

    const isLessThanTwentyOne = () => {
        let count = 0;
        if (isSPecified) {
            count += specifiedData.length;
        }
        if (isStampData) {
            count += stampdata.length;
        }
        if (isWinesData) {
            count += winesData.length;
        }
        if (isOthersData) {
            count += othersData.length;
        }
        return count < 21;
    };
    const canFineArtsBeShown = isLessThanTwentyOne();
    const overrideProps = {
        '@field': {
            readOnly: true,
            labelPosition: 'left',
            className: 'riskObjectProperty',
            labelClassName: 'riskObjectPropertyLabel'
        },
        fineArtsPerLocationContainer1: {
            visible: !canFineArtsBeShown
        },
        fineArtsPerLocationDetailsContainer: {
            visible: canFineArtsBeShown
        },
        unspecifiedFineArtContainer: {
            visible: isUnspecified,
            className: 'yellowBorder'
        },
        fineArtsCollectionsProperties: {
            content: propertyTitle
        },
        unspecifiedSingleItemLimit: {
            value: unspecSingleItemLimit
        },
        unspecifiedFineArtPaintings: {
            visible: !!unspecPicPntgsDetails,
            value: unspecPicPntgsDetails
        },
        unspecifiedFineArtTapsteries: {
            visible: !!unspecTepsAndRugsSI,
            value: unspecTepsAndRugsSI
        },
        unspecifiedFineArtStatues: {
            visible: !!unspStaSclCrosArtSI,
            value: unspStaSclCrosArtSI,
        },
        unspecifiedFineArtClocks: {
            visible: !!unspecClckBrMtrMechArtSI,
            value: unspecClckBrMtrMechArtSI,
        },
        unspecifiedFineArtPreciousmetals: {
            visible: !!unspecPreciousMetalSI,
            value: unspecPreciousMetalSI,
        },
        unspecifiedFineArtBooks: {
            visible: !!unspecBooksAndManucriptsSI,
            value: unspecBooksAndManucriptsSI,

        },
        unspecifiedFineArtPorcelain: {
            visible: !!unspecPrclnRareGlsCrmicSI,
            value: unspecPrclnRareGlsCrmicSI,
        },
        unspecifiedFineArtAntique: {
            visible: !!unspAntqCltFurnSI,
            value: unspAntqCltFurnSI,
        },
        specifiedFineArtContainer: {
            visible: isSPecified,
            className: 'yellowBorder'
        },
        specifiedFineArtItemsContainer: {
            data: specifiedData
        },
        collectionContainer: {
            visible: isCollections
        },
        stampsMedalsCoinsContainer: {
            visible: isStampData,
            data: stampdata,
            className: 'tableHeader',
            columnsProportion: [2, 2, 1]
        },
        winesContainer: {
            visible: isWinesData,
            data: winesData,
            className: 'tableHeader',
            columnsProportion: [2, 2, 1]
        },
        othersContainer: {
            visible: isOthersData,
            data: othersData,
            className: 'tableHeader',
            columnsProportion: [2, 2, 1]
        },
        unspecifiedFineArtListsHeader: {
            className: 'unspecifiedFineArtListsHeader',
            labelClassName: 'unspecifiedFineArtListsHeaderLabel',
            visible: !!isUnspecHeader
        },
        fineArtsMessageContent112: {
            onClick: onClickSeeDocuments
        },
        fineArtsMessageContent212: {
            onClick: onFindAgencyClicked
        }
    };
    const cellFn = (tablevalue, index, metadataProps) => {
        const { path } = metadataProps;
        return tablevalue[path];
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            cellFn
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

export default React.memo(FineArtsPerLocation);
