import {
    useContext,
    useCallback
} from 'react';
import { TranslatorContext } from '@jutro/locale';

const usePropertyTitle = () => {
    const translator = useContext(TranslatorContext);

    const getPropertyTitle = useCallback((homeProperty, isPropertyTypeVisible = true) => {
        if (!homeProperty) {
            return undefined;
        }
        const { location, homhomePropertyDetails: { typeOfProperty } } = homeProperty;
        const titleParts = [
            location.addressLine1,
            location.addressLine2,
            location.addressLine3,
            location.city,
            location.postalCode
        ];
        if (isPropertyTypeVisible) {
            titleParts.push(translator({
                id: `typekey.TypeOfProperty_NFUM.${typeOfProperty}`,
                defaultMessage: typeOfProperty
            }));
        }
        return titleParts.filter((part) => part).join(', ');
    }, [translator]);

    return {
        getPropertyTitle
    };
};

export default usePropertyTitle;
