import React, { useCallback } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useCurrency } from 'nfum-portals-utils-react';
import './BicyclesPerLocation.messages';
import metadata from './BicyclesPerLocation.metadata.json5';
import styles from './BicyclesPerLocation.module.scss';

function BicyclesPerLocation(props) {
    const { scheduleItems } = props;
    const currencyFormatter = useCurrency();

    const generateBicyclesOverrides = useCallback(() => {
        const coverageItems = scheduleItems || [];
        const covOverrides = coverageItems.map((changedField, index) => {
            return {
                [`bicycleName${index}`]: {
                    content: changedField.itemData.ArticleType.typeCodeValue
                },
                [`articleLimit${index}`]: {
                    value: currencyFormatter
                        .formatCurrency(changedField.itemData?.ArticleLimit?.bigDecimal, true)
                }
            };
        });
        return Object.assign({}, ...covOverrides);
    }, [scheduleItems, currencyFormatter]);

    const overrideProps = {
        '@field': {
            readOnly: true,
            labelPosition: 'left',
            className: 'riskObjectProperty',
            labelClassName: 'riskObjectPropertyLabel'
        },
        bicyclesPerLocation: {
            data: scheduleItems
        },
        ...generateBicyclesOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
        />
    );
}

export default BicyclesPerLocation;
