import { defineMessages } from 'react-intl';

export default defineMessages({
    limitReachedInfo: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsModal.limitReachedInfo',
        defaultMessage: 'Only five items can be added as part of this request, if you need to add more items please discuss this with you agent.'
    },
    save: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsModal.save',
        defaultMessage: 'Save'
    },
    addItem: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsModal.addItem',
        defaultMessage: 'Add item'
    },
    saveAndAdd: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsModal.saveAndAdd',
        defaultMessage: 'Save and add another'
    },
    cancel: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsModal.cancel',
        defaultMessage: 'Cancel'
    },
    agreedValue: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsModal.agreedValue',
        defaultMessage: 'Agreed value'
    },
    collectionKept: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsModal.collectionKept',
        defaultMessage: 'Where is the collection kept?'
    },
    valuationDate: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsModal.valuationDate',
        defaultMessage: 'If you have a valuation when was it done?'
    },
    replacementCost: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsModal.replacementCost',
        defaultMessage: 'Please enter the revised replacement cost'
    },
    sumInsured: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsModal.sumInsured',
        defaultMessage: 'What is the sum insured of your collection?'
    },
    describeYourCollection: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsModal.describeYourCollection',
        defaultMessage: 'Describe your collection'
    },
    description: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsModal.description',
        defaultMessage: 'Description'
    },
    type: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsModal.type',
        defaultMessage: 'Type'
    },
    category: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsModal.category',
        defaultMessage: 'Category'
    },
    toAddSpecificValuable: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsModal.toAddSpecificValuable',
        defaultMessage: 'To add specific valuable items, please close this box and navigate to the Valuables section.'
    },
    selectOneBelow: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsModal.selectOneBelow',
        defaultMessage: 'To add Collections or Fine Art select one from below.'
    },
    fineArtAndCollections: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsModal.fineArtAndCollections',
        defaultMessage: 'Fine art & collections'
    },
    newlyAddedFineArts: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsModal.newlyAddedFineArts',
        defaultMessage: 'Newly added fine art and collections'
    },
    existingFineArts: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsModal.existingFineArts',
        defaultMessage: 'Fine art and collections already on your policy'
    },
    maxAllowedFieldLengthReached: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsModal.maxAllowedFieldLengthReached',
        defaultMessage: 'You have reached maximum allowed field length'
    },
    enterOnlyNumericalValues: {
        id: 'endorsement.ngh.views.fineArtsCard.fineArtsModal.enterOnlyNumericalValues',
        defaultMessage: 'Please enter only numerical values'
    }
});
