import { defineMessages } from 'react-intl';

export default defineMessages({
    original: {
        id: 'endorsement.ngh.views.policychange-summary.additionalCovers.original',
        defaultMessage: 'Original'
    },
    new: {
        id: 'endorsement.ngh.views.policychange-summary.additionalCovers.new',
        defaultMessage: 'New'
    },
    added: {
        id: 'endorsement.ngh.views.policychange-summary.additionalCovers.added',
        defaultMessage: 'Added'
    },
    notSelected: {
        id: 'endorsement.ngh.views.policychange-summary.additionalCovers.notSelected',
        defaultMessage: 'Not selected'
    },
    extra: {
        id: 'endorsement.ngh.views.policychange-summary.additionalCovers.extra',
        defaultMessage: 'Personal Legal Expenses Extra'
    },
    standard: {
        id: 'endorsement.ngh.views.policychange-summary.additionalCovers.standard',
        defaultMessage: 'Personal Legal Expenses'
    },
    cov_HOMHomeEmergencyCov: {
        id: 'endorsement.ngh.views.policychange-summary.additionalCovers.cov_HOMHomeEmergencyCov',
        defaultMessage: 'Home Emergency'
    },
    cov_LEXPersonalLegalExpensesCov: {
        id: 'endorsement.ngh.views.policychange-summary.additionalCovers.cov_LEXPersonalLegalExpensesCov',
        defaultMessage: 'Personal Legal Expenses'
    },
    cov_HOMContentsAwayCovPedalCycleExtension: {
        id: 'endorsement.ngh.views.policychange-summary.additionalCovers.cov_HOMContentsAwayCovPedalCycleExtension',
        defaultMessage: 'Cycling Protection'
    },
    cov_HOMBuildingsCovAccidentalDamageRequired: {
        id: 'endorsement.ngh.views.policychange-summary.additionalCovers.cov_HOMBuildingsCovAccidentalDamageRequired',
        defaultMessage: 'Buildings Accidental Damage'
    },
    cov_HOMContentsCovAccidentalDamageCover: {
        id: 'endorsement.ngh.views.policychange-summary.additionalCovers.cov_HOMContentsCovAccidentalDamageCover',
        defaultMessage: 'Contents Accidental Damage'
    },
    cov_HOMContentsCovPedalCycleExtension: {
        id: 'endorsement.ngh.views.policychange-summary.additionalCovers.cov_HOMContentsCovPedalCycleExtension',
        defaultMessage: 'Cycling Protection'
    }

});
