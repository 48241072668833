export default [
    {
        stepId: 0,
        title: {
            id: 'portal.wizardStep.policyChange.Make changes to your home insurance policy',
            defaultMessage: 'Make changes to your home insurance policy',
        },
        isTransactionNumberHidden: true
    },
    {
        stepId: 1,
        title: {
            id: 'portal.wizardStep.policyChange.Make changes to your home insurance policy',
            defaultMessage: 'Make changes to your home insurance policy',
        },
        isTransactionNumberHidden: true
    },
    {
        stepId: 2,
        title: {
            id: 'portal.wizardStep.policyChange.Review changes to your home insurance policy',
            defaultMessage: 'Review changes to your home insurance policy',
        },
        isTransactionNumberHidden: true,
        additionalInfo: {
            id: 'portal.wizardStep.policyChange.summaryPageAdditionalInfo',
            defaultMessage: 'Shown below is the cost of your new premium along with a summary of the changes you have requested. Please read through them carefully and confirm your changes at the bottom of the screen.'
        }
    },
    {
        stepId: 3,
        title: {
            id: 'portal.wizardStep.policyChange.Payment',
            defaultMessage: 'Payment',
        },
        isTransactionNumberHidden: true
    },
    {
        stepId: 4,
        title: {
            id: 'portal.wizardStep.policyChange.Your changes have been submitted',
            defaultMessage: 'Your changes have been submitted',
        },
        isTransactionNumberHidden: true,
        isPolicyNumberHidden: true
    }
];
