import { defineMessages } from 'react-intl';

export default defineMessages({
    highValueBicyclesModalTitle: {
        id: 'endorsement.ngh.views.highValueBicyclesModal.highValueBicyclesModalTitle',
        defaultMessage: 'High value bicycle'
    },
    highValueBicyclesModalTerms: {
        id: 'endorsement.ngh.views.highValueBicyclesModal.highValueBicyclesModalTerms',
        defaultMessage: 'High valued bicycles outside the home '
    },
    bicyclesDescription: {
        id: 'endorsement.ngh.views.highValueBicyclesModal.bicyclesDescription',
        defaultMessage: 'Description of the item'
    },
    tooltip: {
        id: 'endorsement.ngh.views.highValueBicyclesModal.tooltip',
        defaultMessage: 'This is an informational tooltip'
    },
    bicyclesReplacementCost: {
        id: 'endorsement.ngh.views.highValueBicyclesModal.bicyclesReplacementCost',
        defaultMessage: 'Replacement cost'
    },
    addItem: {
        id: 'endorsement.ngh.views.highValueBicyclesModal.addItem',
        defaultMessage: 'Add item'
    },
    cancel: {
        id: 'endorsement.ngh.views.highValueBicyclesModal.cancel',
        defaultMessage: 'Cancel'
    },
    saveAndAdd: {
        id: 'endorsement.ngh.views.highValueBicyclesModal.saveAndAdd',
        defaultMessage: 'Save and add another'
    },
    biyclesWorthDesription: {
        id: 'endorsement.ngh.views.highValueBicyclesModal.biyclesWorthDesription',
        defaultMessage: ' each. (Note: Bicycles worth up to £'
    },
    includedInPolicy: {
        id: 'endorsement.ngh.views.highValueBicyclesModal.includedInPolicy',
        defaultMessage: ' are included in your policy)'
    },
    moreThan: {
        id: 'endorsement.ngh.views.highValueBicyclesModal.moreThan',
        defaultMessage: 'more than £'
    },
    maxLength: {
        id: 'endorsement.ngh.views.highValueBicyclesModal.maxLength',
        defaultMessage: 'You have reached maximum allowed field length'
    },
    formatNotValid: {
        id: 'endorsement.ngh.views.highValueBicyclesModal.formatNotValid',
        defaultMessage: 'Please enter only numerical values'
    },
    descriptionTooltip: {
        id: 'endorsement.ngh.views.highValueBicyclesModal.descriptionTooltip',
        defaultMessage: 'Please provide as much detail as possible here including make and model of the item you wish to insure. An accurate and specific physical description helps us with claims promptly.'
    },
    replacementCostTooltip: {
        id: 'endorsement.ngh.views.highValueBicyclesModal.replacementCostTooltip',
        defaultMessage: 'Remember this is the cost to replace the item new rather than its current value.'
    },
    unspecifiedPedalCyclesLimitValidationMessage: {
        id: 'endorsement.ngh.views.highValueBicyclesModal.unspecifiedPedalCyclesLimitValidationMessage',
        defaultMessage: 'Only bicycles valued more than { limit } need to be declared'
    },
});
