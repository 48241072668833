/* eslint-disable */
import React, { useState, useContext } from 'react';
import { Button } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import classNames from 'classnames';

import messages from './NfumFieldLabel.messages';
import styles from './NfumFieldLabel.module.scss';

function NfumFieldLabel(props) { /* NOSONAR: pure declarative usage */
    const translator = useContext(TranslatorContext);

    const {
        id,
        showRequired,
        required,
        schemaRequired,
        readOnly,
        disabled,
        label,
        renderLabel,
        tooltip,
        rightTooltip,
        noMargin,
        rightMargin,
        children: tooltipContentChildren,
        showOptional,
        className,
        isMaxWidth
    } = props;
    const [tooltipHidden, updateTooltipHidden] = useState(true);
    const requiredIndicator = showRequired &&
            (required || schemaRequired) &&
            !readOnly &&
            !disabled && (
                <span aria-hidden="true" className={styles.requiredSymbol}>
                    *
                </span>
            );

    const optionalIndicator = showOptional &&
        (!required && !schemaRequired) &&
        !readOnly &&
        !disabled && (
            <span aria-hidden="true" className={styles.optionalSymbol}>
                {` (${translator(messages.optional)})`}
            </span>
        );

    const createLabel = () => {
        const _translatedLabel = label && translator(label);
        return (
            _translatedLabel && (
                <span className={styles.primaryLabel}>{_.trim(_translatedLabel)}</span>
            )
        );
    }

    const translatedLabel = createLabel();

    const toggleTooltip = (event) => {
        if (event.type === "keydown" && event.keyCode !== 13) {
            return;
        }
        updateTooltipHidden(!tooltipHidden);
    }

    const renderTooltipIcon = () => {
        return (
            <span>
                <button
                    type="button"
                    className={styles.tooltipIcon}
                    tabindex="-1"
                    role="tooltip"
                >
                    { tooltipHidden && 
                        <button
                            type="button"
                            className={styles.tooltipIcon}
                            style={{ backgroundImage: `url(${require(`../SectionComponentWithTitle/assets/svg/tooltip.svg`)})` }} // NOSONAR
                            onClick= {toggleTooltip} 
                            onKeyDown={toggleTooltip}
                            role="tooltip"
                            aria-label="Button to open a tooltip with more information about it"/>
                    }
                    { !tooltipHidden &&
                        <button
                            type="button"
                            className={styles.tooltipIcon}
                            style={{ backgroundImage: `url(${require(`../SectionComponentWithTitle/assets/svg/tooltip_selected.svg`)})` }} // NOSONAR
                            onClick= {toggleTooltip} 
                            onKeyDown={toggleTooltip} 
                            tabindex="0"/>
                    }
                </button>
            </span>
        );
    }

    const tooltipIcon = tooltip !== undefined && renderTooltipIcon();

    return (
        <div className={styles.nfumInputContainer}>
            <div className={classNames(styles.nfumFieldLabelContainer,
                    className,
                    {[styles.noMargin]: noMargin},
                    {[styles.rightMargin]: rightMargin})}>
                <label id={id} className={styles.nfumFieldLabel}>
                    {requiredIndicator}
                    {translatedLabel}
                    {renderLabel && renderLabel()}
                    {rightTooltip && <div className={styles.rightTooltip}></div>}
                    {tooltipIcon}
                    {optionalIndicator}
                </label>
            </div>

            { !isMaxWidth &&
                <div className={classNames(styles.tooltipContainer, {[styles.tooltipHidden]: tooltipHidden})} >
                <div className={styles.tooltipText}>
                    {tooltip !== undefined && tooltip.text ? translator(tooltip.text) : tooltipContentChildren}
                </div>
                <Button
                    type="text"
                    icon="close"
                    iconPosition="right"
                    onClick={toggleTooltip}
                    tabindex="-1"
                >
                    {translator(messages.close)}
                </Button>
            </div>
            }

            { isMaxWidth &&
                <div className={classNames(styles.fullTooltipContainer, {[styles.tooltipHidden]: tooltipHidden})} >
                <div className={styles.tooltipText}>
                    {tooltip !== undefined && tooltip.text ? translator(tooltip.text) : tooltipContentChildren}
                </div>
                <Button
                    type="text"
                    icon="close"
                    iconPosition="right"
                    onClick={toggleTooltip}
                    tabindex="-1"
                >
                    {translator(messages.close)}
                </Button>
            </div>
            }
        </div>
    );
}

export default NfumFieldLabel;
