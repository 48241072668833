import { TranslatorContext } from '@jutro/locale';
import React, { useContext } from 'react';
import styles from './CallUs.module.scss';
import messages from './CallUs.messages';

const CallUs = () => {
    const translator = useContext(TranslatorContext);
    return (
        <div className={styles.callUs}>
            <div className={styles.iconContainer}>
                <div className={styles.phoneIcon} />
            </div>
            <div className={styles.infoContainer}>
                <p className={styles.callUsInfo}>{ translator(messages.needYourHelp) }</p>
                <p className={styles.phoneInfo}>{ translator(messages.phoneNumber) }</p>
            </div>
        </div>
    );
};

export default CallUs;
