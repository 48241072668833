import { defineMessages } from 'react-intl';

export default defineMessages({
    paymentHeader: {
        id: 'portal.components.transactionWizardTitle.transactionWi1zardTitle.Policy Change - Homeowners',
        defaultMessage: 'Policy Change - Homeowners'
    },
    accountTitleNgh: {
        id: 'endorsement.ngh.directives.templates.bank-account-details-quote.Account:',
        defaultMessage: 'Account:'
    },
    nghPolicyChangeCoverages: {
        id: 'endorsement.ngh.directives.templates.ngh-coverages.Coverages',
        defaultMessage: 'Coverages'
    },
    nghPolicyChangeStart: {
        id: 'endorsement.ngh.directives.templates.ngh-policyChangeStart.Policy Start',
        defaultMessage: 'Policy Start'
    },
    nghPolicyChangeDetails: {
        id: 'endorsement.ngh.directives.templates.ngh-policyChangeDetails.Policy Change Details',
        defaultMessage: 'Policy Change Details'
    },
    nghPolicyChange: {
        id: 'endorsement.ngh.directives.templates.ngh-policy-changes.Policy Changes',
        defaultMessage: 'Policy Changes'
    },
    nghPolicySummary: {
        id: 'endorsement.ngh.directives.templates.ngh-policy-changes.Policy Summary',
        defaultMessage: 'Policy Summary'
    },
    nghPayment: {
        id: 'endorsement.ngh.directives.templates.ngh-policy-changes.Payment',
        defaultMessage: 'Payment'
    },
    nghDiretDebit: {
        id: 'endorsement.ngh.directives.templates.ngh-policy-changes.Direct Debit',
        defaultMessage: 'Direct Debit'
    },
    nghPaymentStatus: {
        id: 'endorsement.ngh.directives.templates.ngh-policy-changes.PaymentStatus',
        defaultMessage: 'Payment Status'
    },
    nghPolicyChangeQuotes: {
        id: 'endorsement.common.ngh.views.history.Quote',
        defaultMessage: 'Quote'
    },
    nghPolicyChangePayments: {
        id: 'endorsement.ngh.directives.templates.ngh-coverages.Payments',
        defaultMessage: 'Payments'
    },
    nghPolicyChangeSuccess: {
        id: 'endorsement.ngh.directives.templates.ngh-coverages.Policy Change Success',
        defaultMessage: 'Policy Change Success'
    },
    anErrorOccurred: {
        id: 'gw-portals-policytransaction-pc-ngh.wizard.error',
        defaultMessage: 'Something went wrong during this process.'
    },
    anErrorOccurredTitle: {
        id: 'gw-portals-policytransaction-pc-ngh.wizard.error.title',
        defaultMessage: 'Error'
    },
    saveQuoteError: {
        id: 'gw-portals-policytransaction-pc-ngh.Unable to quote',
        defaultMessage: 'Unable to quote'
    },
    saveQuoteErrorMessage: {
        id: 'gw-portals-policytransaction-pc-ngh.An error occurred while attempting to quote.',
        defaultMessage: 'An error occurred while attempting to quote.'
    },
    start: {
        id: 'gw-portals-policytransaction-pc-ngh.Start',
        defaultMessage: 'Start'
    },
    cancelAllChanges: {
        id: 'gw-portals-policytransaction-pc-ngh.cancelAllChanges',
        defaultMessage: 'Cancel changes'
    },
    reviewChanges: {
        id: 'gw-portals-policytransaction-pc-ngh.Review changes',
        defaultMessage: 'Continue'
    },
    confirmChanges: {
        id: 'gw-portals-policytransaction-pc-ngh.Confirm changes',
        defaultMessage: 'Confirm changes'
    },
    payChanges: {
        id: 'gw-portals-policytransaction-pc-ngh.Pay changes',
        defaultMessage: 'Pay changes'
    },
    returnToPortal: {
        id: 'gw-portals-policytransaction-pc-ngh.Return to Portal',
        defaultMessage: 'Return to portal'
    },
    completeChanges: {
        id: 'gw-portals-policytransaction-pc-ngh.Complete changes',
        defaultMessage: 'Complete changes'
    },
    cancelChanges: {
        id: 'gw-portals-policytransaction-pc-ngh.Cancel changes',
        defaultMessage: 'Cancel changes'
    },
    areYouSure: {
        id: 'gw-portals-policytransaction-pc-ngh.Are you sure?',
        defaultMessage: 'Are you sure?\nAll your changes will be lost if you continue.'
    },
    editChanges: {
        id: 'gw-portals-policytransaction-pc-ngh.Edit changes',
        defaultMessage: 'Edit changes'
    },
    yesCancel: {
        id: 'gw-portals-policytransaction-pc-ngh.Yes, cancel all changes',
        defaultMessage: 'Yes, cancel all changes'
    },
    returnToDashboard: {
        id: 'gw-portals-policytransaction-pc-ngh.returnToDashboard',
        defaultMessage: 'Return to dashboard'
    },
});
