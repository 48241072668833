import { defineMessages } from 'react-intl';

export default defineMessages({
    wineCollection: {
        id: 'endorsement.ngh.views.fineArtsSummary.wineCollection',
        defaultMessage: 'Wine collection'
    },
    replacementCost: {
        id: 'endorsement.ngh.views.fineArtsSummary.replacementCost',
        defaultMessage: 'Replacement cost'
    },
    wine: {
        id: 'endorsement.ngh.views.fineArtsSummary.wine',
        defaultMessage: 'Wine'
    },
    otherCollections: {
        id: 'endorsement.ngh.views.fineArtsSummary.otherCollections',
        defaultMessage: 'Other collections'
    },
    fineArts: {
        id: 'endorsement.ngh.views.fineArtsSummary.fineArts',
        defaultMessage: 'Fine arts'
    },
    stampsMedalsAndCoins: {
        id: 'endorsement.ngh.views.fineArtsSummary.stampsMedalsAndCoins',
        defaultMessage: 'Stamps, medals and coins'
    },
    itemsChanged: {
        id: 'endorsement.ngh.views.fineArtsSummary.itemsChanged',
        defaultMessage: 'Items changed'
    },
    original: {
        id: 'endorsement.ngh.views.fineArtsSummary.original',
        defaultMessage: 'Original'
    },
    new: {
        id: 'endorsement.ngh.views.fineArtsSummary.new',
        defaultMessage: 'New'
    },
    itemsRemoved: {
        id: 'endorsement.ngh.views.fineArtsSummary.itemsRemoved',
        defaultMessage: 'Items removed'
    },
    itemsAdded: {
        id: 'endorsement.ngh.views.fineArtsSummary.itemsAdded',
        defaultMessage: 'Items added'
    }
});
