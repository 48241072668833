import React, { useCallback } from 'react';
import {
    ViewModelForm
} from 'gw-portals-viewmodel-react';
import { wizardProps } from 'gw-portals-wizard-react';
import metadata from './BespokeValuableItems.metadata.json5';
import styles from './BespokeValuableItems.module.scss';
import ValuableSummaryItem from '../ValuableSummaryItem/ValuableSummaryItem';

function BespokeValuableItems(props) {
    const {
        scheduleItems
    } = props;

    const generateOverrides = useCallback(() => {
        if (!scheduleItems) return Object.assign({});

        const valuablesAddedOverrides = scheduleItems.valuables
            .map((item, ind) => {
                return {
                    [`valuableSummaryItem${ind}`]: {
                        scheduleItems: item,
                        index: ind
                    }
                };
            });

        return Object.assign({},
            ...valuablesAddedOverrides);
    }, [scheduleItems]);

    const overrideProps = {
        category: {
            content: scheduleItems.name
        },
        addedItems: {
            data: scheduleItems.valuables
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            valuableSummaryItem: ValuableSummaryItem
        }
    };

    return (
        <ViewModelForm
            model={scheduleItems}
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            overrideProps={overrideProps}
        />
    );
}

BespokeValuableItems.propTypes = wizardProps;
export default BespokeValuableItems;
