import { defineMessages } from 'react-intl';

export default defineMessages({
    yes: {
        id: 'quoteandbind.ngh.views.policy-summary.caravan.yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'quoteandbind.ngh.views.policy-summary.caravan.no',
        defaultMessage: 'No'
    },
    notSelected: {
        id: 'quoteandbind.ngh.views.policy-summary.caravan.notSelected',
        defaultMessage: 'Not Selected'
    },
    yesFixedSiteOnly: {
        id: 'quoteandbind.ngh.views.policy-summary.caravan.yesFixedSiteOnly',
        defaultMessage: 'Yes - Fixed site only'
    },
    fire: {
        id: 'quoteandbind.ngh.views.policy-summary.caravan.fire',
        defaultMessage: 'Fire'
    },
    flood: {
        id: 'quoteandbind.ngh.views.policy-summary.caravan.flood',
        defaultMessage: 'Flood'
    },
    storm: {
        id: 'quoteandbind.ngh.views.policy-summary.caravan.storm',
        defaultMessage: 'Storm'
    },
    theft: {
        id: 'quoteandbind.ngh.views.policy-summary.caravan.theft',
        defaultMessage: 'Theft'
    },
    caravanSerialNumber: {
        id: 'quoteandbind.ngh.views.policy-summary.caravan.caravanSerialNumber',
        defaultMessage: 'Serial Number'
    },
    caravanVinNumber: {
        id: 'quoteandbind.ngh.views.policy-summary.caravan.caravanVinNumber',
        defaultMessage: 'VIN'
    },
    cover: {
        id: 'quoteandbind.ngh.views.policy-summary.caravan.cover',
        defaultMessage: 'Cover'
    },
    caravanPersonalBelongingsCover: {
        id: 'quoteandbind.ngh.views.policy-summary.caravan.caravanPersonalBelongingsCover',
        defaultMessage: 'Personal belongings cover'
    },
    caravanLossOfUseExtension: {
        id: 'quoteandbind.ngh.views.policy-summary.caravan.caravanLossOfUseExtension',
        defaultMessage: 'Loss of use extension'
    },
    caravanEuropeanCoverExtension: {
        id: 'quoteandbind.ngh.views.policy-summary.caravan.caravanEuropeanCoverExtension',
        defaultMessage: 'European cover extension'
    },
    caravanExcludedPerils: {
        id: 'quoteandbind.ngh.views.policy-summary.caravan.caravanExcludedPerils',
        defaultMessage: 'Excluded perils'
    },
    excess: {
        id: 'quoteandbind.ngh.views.policy-summary.caravan.excesses',
        defaultMessage: 'Excesses'
    },
    caravanVoluntaryExcess: {
        id: 'quoteandbind.ngh.views.policy-summary.caravan.caravanVoluntaryExcess',
        defaultMessage: 'Voluntary excess'
    },
    caravanCompulsoryAllClaimsExcess: {
        id: 'quoteandbind.ngh.views.policy-summary.caravan.caravanCompulsoryAllClaimsExcess',
        defaultMessage: 'Compulsory all claims excess'
    },
    caravanFireExcess: {
        id: 'quoteandbind.ngh.views.policy-summary.caravan.caravanFireExcess',
        defaultMessage: 'Fire excess'
    },
    caravanFloodExcess: {
        id: 'quoteandbind.ngh.views.policy-summary.caravan.caravanFloodExcess',
        defaultMessage: 'Flood excess'
    },
    caravanStormExcess: {
        id: 'quoteandbind.ngh.views.policy-summary.caravan.caravanStormExcess',
        defaultMessage: 'Storm excess'
    },
    caravanTheftExcess: {
        id: 'quoteandbind.ngh.views.policy-summary.caravan.caravanTheftExcess',
        defaultMessage: 'Theft excess'
    },
    caravanAccidentalDamageExcess: {
        id: 'quoteandbind.ngh.views.policy-summary.caravan.caravanAccidentalDamageExcess',
        defaultMessage: 'Accidental damage excess'
    },
    caravanImpactExcess: {
        id: 'quoteandbind.ngh.views.policy-summary.caravan.caravanImpactExcess',
        defaultMessage: 'Impact excess'
    },
    caravanVandalismExcess: {
        id: 'quoteandbind.ngh.views.policy-summary.caravan.caravanVandalismExcess',
        defaultMessage: 'Vandalism excess'
    },
});
