import React, { useContext, useCallback } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { covKeys } from 'nfum-portals-utils-react';
import messages from './AdditionalCoversSummary.messages';
import metadata from './AdditionalCoversSummary.metadata.json5';
import styles from './AdditionalCoversSummary.module.scss';

function AdditionalCoversSummary(props) { /* NOSONAR: pure declarative usage  */
    const {
        data: additionalCoversModel
    } = props;
    const translator = useContext(TranslatorContext);

    const generateOverrides = useCallback(() => {
        if (!additionalCoversModel) return Object.assign({});
        const covOverrides = additionalCoversModel
            .filter((cov) => cov.newValue !== cov.oldValue)
            .map((cover, index) => {
                return {
                    [`coveragesTable${index}`]: {
                        data: [cover],
                        title: translator(messages[`cov_${cover.coverageId}`])
                    }
                };
            });
        return Object.assign({}, ...covOverrides);
    }, [additionalCoversModel, translator]);

    const renderCoverageValue = (coverageItem, index, coverageProps) => {
        const { path: oldOrNewValue } = coverageProps;
        if (coverageItem[oldOrNewValue] === true) {
            return translator(messages.added);
        }
        if (coverageItem[oldOrNewValue] === false) {
            return translator(messages.notSelected);
        }
        if (coverageItem[oldOrNewValue] === covKeys.STANDARD) {
            return translator(messages.standard);
        }
        if (coverageItem[oldOrNewValue] === covKeys.EXTRA) {
            return translator(messages.extra);
        }
        return coverageItem[oldOrNewValue];
    };

    const overrideProps = {
        additionalCovers: {
            data: additionalCoversModel
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            renderCoverageValue
        }
    };

    return (
        <ViewModelForm
            model={additionalCoversModel}
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            overrideProps={overrideProps}
        />
    );
}

export default AdditionalCoversSummary;
