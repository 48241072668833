import React, { useCallback, useMemo, useContext } from 'react';
import {
    ViewModelForm
} from 'gw-portals-viewmodel-react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { wizardProps } from 'gw-portals-wizard-react';
import { appendMetadataWithIndex } from 'gw-jutro-adapters-react';
import BespokeValuablesSummaryItem from '../BespokeValuableSummaryItem/BespokeValuableSummaryItem';
import metadata from './BespokeSummaryRemovedItems.metadata.json5';
import styles from './BespokeSummaryRemovedItems.module.scss';

function BespokeSummaryRemovedItems(props) {
    const {
        removedItemsModel,
        index
    } = props;
    const translator = useContext(TranslatorContext);

    const formattedMetadata = useMemo(() => {
        return appendMetadataWithIndex(metadata.componentContent, index);
    }, [index]);

    const truncateString = useCallback((str) => {
        if (str.length > 60) {
            return str.substring(0, 59);
        }
        return str;
    }, []);

    const getArticleValuableType = useCallback((articleSubType) => {
        return articleSubType !== undefined ? translator({
            id: `typekey.SpecifiedValuableType_NFUM.${articleSubType}`,
            defaultMessage: articleSubType
        }) : '';
    }, [translator]);

    const getArticleMakeType = useCallback((articleSubType) => {
        return articleSubType !== undefined ? translator({
            id: `typekey.SpecfdValuableMake_NFUM.${articleSubType}`,
            defaultMessage: articleSubType
        }) : '';
    }, [translator]);

    const getHeaderDescription = useCallback((value) => {
        const description = value.itemData.ArticleDescription.stringValue;
        if (value.itemData.ArticleType.typeCodeValue === 'Guns' || value.itemData.ArticleType.typeCodeValue === 'Watches') {
            return `${getArticleMakeType(value.itemData.ArticleMakeType.typeCodeValue)},${truncateString(description)}`;
        }
        if (value.itemData.ArticleType.typeCodeValue === 'JewelleryAndGemstones') {
            return `${getArticleValuableType(value.itemData.ArticleSubType.typeCodeValue)},${truncateString(description)}`;
        }
        return description;
    }, [getArticleMakeType,
        getArticleValuableType,
        truncateString
    ]);

    const generateOverrides = useCallback(() => {
        if (removedItemsModel.removed.length === 0) return Object.assign({});

        const fineArtsAddedItemsOverrides = removedItemsModel.removed.map((item, ind) => {
            return {
                [`headerRemovedItemDescription${ind}`]: {
                    content: getHeaderDescription(item)
                }
            };
        });

        return Object.assign({},
            ...fineArtsAddedItemsOverrides);
    }, [
        removedItemsModel,
        getHeaderDescription
    ]);

    const overrideProps = {
        [`category${index}`]: {
            content: removedItemsModel.name
        },
        [`removedItems${index}`]: {
            data: removedItemsModel.removed
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            valuableSummaryItem: BespokeValuablesSummaryItem
        }
    };

    return (
        <ViewModelForm
            model={removedItemsModel}
            uiProps={formattedMetadata}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

BespokeSummaryRemovedItems.propTypes = wizardProps;
export default BespokeSummaryRemovedItems;
