import {
    TagManagerBase
} from 'nfum-portals-utils-react';

function useCommonTagManager() {
    return {
        pushAccordionOpenInfo: TagManagerBase.pushAccordionOpenInfo,
        pushLinkClickInfo: TagManagerBase.pushLinkClickInfo,
        pushRelativeLinkClickInfo: TagManagerBase.pushRelativeLinkClickInfo,
        pushFileDownloadInfo: TagManagerBase.pushFileDownloadInfo
    };
}

export default useCommonTagManager;
