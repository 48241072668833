import React, { useCallback, useContext } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { useCurrency } from 'nfum-portals-utils-react';
import messages from './ValuablesPerLocation.messages';
import metadata from './ValuablesPerLocation.metadata.json5';
import styles from './ValuablesPerLocation.module.scss';

function ValuablesPerLocation(props) {
    const { scheduleItems } = props;
    const translator = useContext(TranslatorContext);
    const currencyFormatter = useCurrency();

    const generateValuablesOverrides = useCallback(() => {
        const coverageItems = scheduleItems || [];
        const covOverrides = coverageItems.map((changedField, index) => {
            const category = changedField.itemData.ArticleValuationMethod.typeCodeValue;
            return {
                [`valuableName${index}`]: {
                    content: changedField.itemData.ArticleType.typeCodeValue
                },
                [`articleValuationMethod${index}`]: {
                    value: translator({
                        id: `typekey.SpecifiedValuableType_NFUM.${category}`,
                        defaultMessage: category
                    })
                },
                [`articleLimit${index}`]: {
                    value: currencyFormatter
                        .formatCurrency(changedField.itemData.ArticleLimit.bigDecimal, true)
                },
                [`articleGeographicLimit${index}`]: {
                    value: changedField.itemData.ArticleGeographicLimit.typeCodeValue === 'AtHomeOnly'
                    ? translator(messages.insideHome)
                    : translator(messages.insideOutsideHome)
                },
                [`articleKeptInBank${index}`]: {
                    value: changedField.itemData.ArticleKeptinBank.booleanValue
                        ? translator(messages.yes)
                        : translator(messages.no)
                }
            };
        });
        return Object.assign({}, ...covOverrides);
    }, [scheduleItems, translator, currencyFormatter]);

    const overrideProps = {
        '@field': {
            readOnly: true,
            labelPosition: 'left',
            className: 'riskObjectProperty',
            labelClassName: 'riskObjectPropertyLabel'
        },
        valuablesNotSelected: {
            visible: scheduleItems.length === 0,
            value: translator(messages.notSelected)
        },
        valuablesPerLocation: {
            visible: scheduleItems.length > 0,
            data: scheduleItems
        },
        ...generateValuablesOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
        />
    );
}

export default ValuablesPerLocation;
