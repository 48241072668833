import { defineMessages } from 'react-intl';

export default defineMessages({
    contents: {
        id: 'endorsement.ngh.views.policychange-summary.contentsCov.contents',
        defaultMessage: 'Contents'
    },
    valuablesReplLimit: {
        id: 'endorsement.ngh.views.policychange-summary.contentsCov.valuablesReplLimit',
        defaultMessage: 'Valuables replacement limit'
    },
    originalAmount: {
        id: 'endorsement.ngh.views.policychange-summary.contentsCov.originalAmount',
        defaultMessage: 'Original amount'
    },
    newAmount: {
        id: 'endorsement.ngh.views.policychange-summary.contentsCov.newAmount',
        defaultMessage: 'New amount'
    }
});
