/* eslint-disable prefer-promise-reject-errors */
import _ from 'lodash';
import BaseTransportService from './BaseTransportService';

// eslint-disable-next-line max-len
// const httpTimeout = getConfigValue('httpSettings.timeout', DEFAULT_HTTP_TIMEOUT); /* NOSONAR: GW OOTB internal mechanism - high complexity and many dependencies */
const httpTimeout = 90000;

export default class TransportService {
    /**
     * Invoke the given method on the remote service endpoint.
     * @param {string} serviceEndpoint - The service url
     * @param {string|FormData} body - The body to pass
     * @param {Object} headers - The headers to pass
     * @param {Object} additionalParams - additionalParams object to pass
     * @returns {Promise} - The promise returned by the remote call
     */
    static send(serviceEndpoint, body, headers, additionalParams) {
        const callStart = new Date().getTime();
        // eslint-disable-next-line max-len
        const request = BaseTransportService.send(serviceEndpoint, headers, body, additionalParams) //nfum custom /* NOSONAR: GW OOTB internal mechanism - high complexity and many dependencies */
            .then((response) => {
                return response.result;
            })
            .catch(({ error = {}, message }) => { // nfum custom
                const callTime = new Date().getTime() - callStart;
                const errorCode = _.get(error, 'error.data.appError') || _.get(error, 'data.appError') || 'GW_ERROR';
                const appErrorCode = _.get(error, 'error.data.appErrorCode') || _.get(error, 'data.appErrorCode') || 101;
                const appData = _.get(error, 'error.data.appData') || _.get(error, 'data.appData') || {};
                const errorStatus = typeof message === 'string' && Number(message.substring(0, 3));

                return Promise.reject({
                    baseError: error.message,
                    errorCode: errorCode,
                    errorStatus: errorStatus,
                    appErrorCode: appErrorCode,
                    appData: appData,
                    isTimeout: (error.status === 0) && callTime < httpTimeout,
                    isUnauthenticated: (error.status === 401)
                });
            });
        return request;
    }

    static isSessionExpiredReason(reason) {
        return reason.isTimeout === true;
    }

    static isUserUnauthenticatedReason(reason) {
        return reason.isUnauthenticated === true;
    }
}
