import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NfumLoader } from 'nfum-components-platform-react';
import { withAuthenticationContext } from './AuthenticationContext';

class GrantedAuthorities extends Component {
    static propTypes = {
        //getGrantedAuthorities: PropTypes.func.isRequired,
        grantedAuthorities: PropTypes.shape({ // nfum custom // okta-patch
            then: PropTypes.func.isRequired
        }).isRequired,
        checkRenderBasedOnAuthorities: PropTypes.func.isRequired,
        children: PropTypes.node
    };

    static defaultProps = {
        children: undefined
    };

    state = {
        initialized: false,
        renderBasedOnAuthorities: null
    };

    // componentDidMount() {
    //     const { getGrantedAuthorities, checkRenderBasedOnAuthorities } = this.props;
    //     getGrantedAuthorities()
    //         .then((authorities) => {
    //             const renderBasedOnAuthorities = checkRenderBasedOnAuthorities(authorities);
    //             if (renderBasedOnAuthorities) {
    //                 this.setState({ renderBasedOnAuthorities });
    //             }
    //         })
    //         .finally(() => this.setState({ initialized: true }));
    // }

    // nfum custom // okta-patch
    componentDidMount() {
        const { grantedAuthorities, checkRenderBasedOnAuthorities } = this.props;
        const renderBasedOnAuthorities = checkRenderBasedOnAuthorities(grantedAuthorities);
        if (renderBasedOnAuthorities) {
            this.setState({ renderBasedOnAuthorities });
        }
        this.setState({ initialized: true });
    }

    render() {
        const { children } = this.props;
        const { initialized, renderBasedOnAuthorities } = this.state;

        if (!initialized) {
            return <NfumLoader />;
        }

        if (renderBasedOnAuthorities) {
            return renderBasedOnAuthorities;
        }
        return children;
    }
}

export default withAuthenticationContext(GrantedAuthorities);
