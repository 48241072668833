import { setComponentMapOverrides } from '@jutro/uiconfig';
import 'gw-capability-policychange-common-react';
import PolicyChangesStartConfirmationPage from './pages/PolicyChangesStartConfirmation/PolicyChangesStartConfirmationPage';
import PolicyChangeDetailsPage from './pages/PolicyChangeDetails/PolicyChangeDetailsPage';
import PolicyChangeCoveragesPage from './pages/PolicyChangeCoverages/PolicyChangeCoveragesPage';
import PolicyChangeSummaryPage from './pages/PolicyChangeSummary/PolicyChangeSummaryPage';
import PaymentPage from './pages/Payment/PaymentPage';
import SuccessPage from './pages/PolicyChangeSuccess/SuccessPage';
import NGHPolicyChangeWizardPageTemplate from './NGHPolicyChangeWizardPageTemplate';
import 'nfum-capability-policychange-common-react/NGHPolicyChange.messages';

setComponentMapOverrides(
    {
        PolicyChangesStartConfirmationPage: { component: 'PolicyChangesStartConfirmationPage' },
        PolicyChangeDetailsPage: { component: 'PolicyChangeDetailsPage' },
        PolicyChangeCoveragesPage: { component: 'PolicyChangeCoveragesPage' },
        PolicyChangeSummaryPage: { component: 'PolicyChangeSummaryPage' },
        PaymentPage: { component: 'PaymentPage' },
        SuccessPage: { component: 'SuccessPage' },
        NGHPolicyChangeWizardPageTemplate: { component: 'NGHPolicyChangeWizardPageTemplate' }
    },
    {
        PolicyChangesStartConfirmationPage,
        PolicyChangeDetailsPage,
        PolicyChangeCoveragesPage,
        PolicyChangeSummaryPage,
        PaymentPage,
        SuccessPage,
        NGHPolicyChangeWizardPageTemplate
    }
);

export { default as PolicyChangesStartConfirmationPage } from './pages/PolicyChangesStartConfirmation/PolicyChangesStartConfirmationPage';
export { default as NGHPolicyChangeWizard } from './NGHPolicyChangeWizard';
