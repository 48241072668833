import React, {
    useCallback
} from 'react';
import _ from 'lodash';
import { useModal } from '@jutro/components';
import { getConfigValue } from '@jutro/config';
import { useCommonTagManager } from 'nfum-portals-utils-react';
import { ReturnToDashboardModal } from 'nfum-components-platform-react';

const useWizardActions = () => {
    const {
        pushLinkClickInfo
    } = useCommonTagManager();
    const {
        showModal
    } = useModal();
    const returnToDashboard = useCallback((linkText) => {
        showModal(
            <ReturnToDashboardModal isOpen />
        ).then((choice) => {
            if (choice.type === 'EXIT') {
                const backToDashboardURL = getConfigValue('JUTRO_BACK_TO_DASHBOARD_PATH', '/');
                if (linkText) {
                    pushLinkClickInfo(linkText, backToDashboardURL);
                }
                window.location.replace(backToDashboardURL);
            }
        }, _.noop);
    }, []);

    return {
        returnToDashboard
    };
};

export default useWizardActions;
