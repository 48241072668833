import { defineMessages } from 'react-intl';

export default defineMessages({
    itemsAdded: {
        id: 'endorsement.ngh.views.policychange-summary.bicycles.itemsAdded',
        defaultMessage: 'Items added'
    },
    itemsChanged: {
        id: 'endorsement.ngh.views.policychange-summary.bicycles.itemsChanged',
        defaultMessage: 'Items changed'
    },
    itemsRemoved: {
        id: 'endorsement.ngh.views.policychange-summary.bicycles.itemsRemoved',
        defaultMessage: 'Items removed'
    },
    category: {
        id: 'endorsement.ngh.views.policychange-summary.bicycles.category',
        defaultMessage: 'Category'
    },
    replacementCost: {
        id: 'endorsement.ngh.views.policychange-summary.bicycles.replacementCost',
        defaultMessage: 'Replacement cost'
    },
    coverType: {
        id: 'endorsement.ngh.views.policychange-summary.bicycles.coverType',
        defaultMessage: 'Cover type'
    },
    keptInBank: {
        id: 'endorsement.ngh.views.policychange-summary.bicycles.keptInBank',
        defaultMessage: 'Kept in bank?'
    },
    yes: {
        id: 'endorsement.ngh.views.policychange-summary.bicycles.yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'endorsement.ngh.views.policychange-summary.bicycles.no',
        defaultMessage: 'No'
    },
    insideHomeOnly: {
        id: 'endorsement.ngh.views.policychange-summary.bicycles.insideHomeOnly',
        defaultMessage: 'Inside the home only'
    },
    insideOutsideHome: {
        id: 'endorsement.ngh.views.policychange-summary.bicycles.insideOutsideHome',
        defaultMessage: 'Both inside and outside the home (UK)'
    },
    originalAmount: {
        id: 'endorsement.ngh.views.policychange-summary.bicycles.originalAmount',
        defaultMessage: 'Original amount'
    },
    newAmount: {
        id: 'endorsement.ngh.views.policychange-summary.bicycles.newAmount',
        defaultMessage: 'New amount'
    }
});
