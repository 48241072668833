import React, {
    useState, useCallback, useEffect, useRef, useContext
} from 'react';
import _ from 'lodash';
import { useDependencies } from 'gw-portals-dependency-react';
import { useAuthentication, withAuthenticationContext } from 'gw-digital-auth-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { NfumAccordionHeader } from 'nfum-components-platform-react';
import {
    useErrorHandler,
    useCallRetry,
    MTA_STEPS,
    BESPOKE_MTA_STEPS
} from 'nfum-portals-utils-react';
import { TranslatorContext } from '@jutro/locale';
import { useWizardActions } from 'nfum-portals-wizard-react';
import { useTagManager, usePeriodsDiff } from 'nfum-capability-policychange-common-react';
import commonMessages from 'nfum-capability-policychange-common-react/NGHPolicyChange.messages';
import CostBreakdownBox from './CostBreakdownBox/CostBreakdownBox';
import PolicyDetailsSummary from './PolicyDetailsSummary/PolicyDetailsSummary';
import AdditionalCoversSummary from './AdditionalCoversSummary/AdditionalCoversSummary';
import BicyclesSummary from './BicyclesSummary/BicyclesSummary';
import ContentsAwaySummary from './ContentsAwaySummary/ContentsAwaySummary';
import ValuablesSummary from './ValuablesSummary/ValuablesSummary';
import metadata from './PolicyChangeSummaryPage.metadata.json5';
import styles from './PolicyChangeSummaryPage.module.scss';
import messages from './PolicyChangeSummaryPage.messages';
import ContentsMTASummary from './ContentsSummary/ContentsMTASummary';

function PolicyChangeSummaryPage(props) { /* NOSONAR: pure declarative usage  */
    const {
        wizardData: policySubmissionVM, updateWizardData, steps, jumpTo
    } = props;
    const { handleError } = useErrorHandler();
    const { returnToDashboard } = useWizardActions();
    const { PolicyService } = useDependencies('PolicyService');
    const { authHeader } = useAuthentication();
    const [isConditionsExclusionsAvailable, setIsConditionsExclusionsAvailable] = useState(false);
    const documentsSectionRef = useRef();
    const scrollToContentCardSection = useRef();
    const scrollToPolicyDetailsSection = useRef();
    const scrollToValuablesMTACardSection = useRef();
    const scrollToBicyclesMTACardSection = useRef();
    const scrollToAdditionalInsurancesSection = useRef();
    const scrollToContentsAwayMTACardSection = useRef();
    const DOCUMENT_TIMEOUT_DURATION_SECONDS = 180;
    const translator = useContext(TranslatorContext);
    const [addressValidationInd, setAddressValidationInd] = useState(null);
    const [disableNext, setDisableNext] = useState(null);
    const paymentMethod = _.get(policySubmissionVM.value, 'baseData.paymentMethod_NFUM');
    const policyDetailsOffset = -100;
    const cardsIds = Object.freeze({
        VALUABLES_CARD: 'valuablesCard',
        CONTENTS_AWAY_CARD: 'contentsAwayCard',
        POLICY_DETAILS_CARD: 'policyDetailsCard',
        CONTENTS_CARD: 'contentsCard',
        ADDITIONAL_INSURANCES_CARD: 'additionalInsurancesCard',
        BICYCLES_CARD: 'bicyclesCard'
    });

    const {
        pushFormStepInfo,
        pushFormStepErrorInfo,
        pushFileDownloadInfo,
        pushRelativeLinkClickInfo,
        pushAccordionOpenInfo
    } = useTagManager();
    const {
        getBicycles,
        getSpecificValuables,
        getAdditionalCovers,
        getUnspecifiedBelongingsReplCostValue,
        getContentsOverrideCostValue
    } = usePeriodsDiff(policySubmissionVM);
    const additionalCovers = getAdditionalCovers();
    const specificValuables = getSpecificValuables();
    const bicycles = getBicycles();
    const unspecifiedBelongingsReplCostValue = getUnspecifiedBelongingsReplCostValue();
    const contentsOverrideCostValue = getContentsOverrideCostValue();

    // #region GTM EVENTS
    useEffect(() => {
        const isBespokeData = _.get(policySubmissionVM.value, 'isBespokeHomeInsurance_NFUM');
        pushFormStepInfo(policySubmissionVM, isBespokeData
            ? BESPOKE_MTA_STEPS.MTA_SUMMARY
            : MTA_STEPS.MTA_SUMMARY);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const producerDesc = _.get(policySubmissionVM.value, 'baseData.producerDetails_NFUM.producerCodeDescription', '');
    const producerTele = _.get(policySubmissionVM.value, 'baseData.producerDetails_NFUM.producerCodeTelephone', '');
    const isBespoke = _.get(policySubmissionVM.value, 'baseData.producerDetails_NFUM.isBespoke', '');
    const showAgencyDetails = _.get(policySubmissionVM.value, 'baseData.producerDetails_NFUM.showProducerDetails', '');

    const handleContentsAwayAccordion = () => {
        if (scrollToContentCardSection?.current) {
            scrollToContentCardSection?.current?.scrollIntoView();
        } else {
            scrollToPolicyDetailsSection?.current?.scrollIntoView();
        }
    };

    const handleValuablesAccordion = () => {
        if (scrollToContentsAwayMTACardSection?.current) {
            scrollToContentsAwayMTACardSection?.current?.scrollIntoView();
        } else if (scrollToContentCardSection?.current) {
            scrollToContentCardSection?.current?.scrollIntoView();
        } else {
            scrollToPolicyDetailsSection?.current?.scrollIntoView();
        }
    };

    const handleAdditionalInsurancesAccordion = () => {
        if (scrollToBicyclesMTACardSection?.current) {
            scrollToBicyclesMTACardSection?.current?.scrollIntoView();
        } else if (scrollToValuablesMTACardSection?.current) {
            scrollToValuablesMTACardSection?.current?.scrollIntoView();
        } else if (scrollToContentsAwayMTACardSection?.current) {
            scrollToContentsAwayMTACardSection?.current?.scrollIntoView();
        } else if (scrollToContentCardSection?.current) {
            scrollToContentCardSection?.current?.scrollIntoView();
        } else {
            scrollToPolicyDetailsSection?.current?.scrollIntoView();
        }
    };

    const handleBicyclesAccordion = () => {
        if (scrollToValuablesMTACardSection?.current) {
            scrollToValuablesMTACardSection?.current?.scrollIntoView();
        } else if (scrollToContentsAwayMTACardSection?.current) {
            scrollToContentsAwayMTACardSection?.current?.scrollIntoView();
        } else if (scrollToContentCardSection?.current) {
            scrollToContentCardSection?.current?.scrollIntoView();
        } else {
            scrollToPolicyDetailsSection?.current?.scrollIntoView();
        }
    };

    const onUpdateOpenAccordion = useCallback((accordionCardsIds) => {
        const openCardId = accordionCardsIds && accordionCardsIds.length > 0
            ? accordionCardsIds[0] : null;
        switch (openCardId) {
            case cardsIds.POLICY_DETAILS_CARD:
                pushAccordionOpenInfo(translator(messages.policyDetails));
                scrollToPolicyDetailsSection?.current?.scrollIntoView();
                window.scrollBy(0, policyDetailsOffset);
                break;
            case cardsIds.CONTENTS_CARD:
                pushAccordionOpenInfo(translator(messages.contentsCard));
                scrollToPolicyDetailsSection?.current?.scrollIntoView();
                break;
            case cardsIds.CONTENTS_AWAY_CARD:
                pushAccordionOpenInfo(translator(messages.contentsAway));
                handleContentsAwayAccordion();
                break;
            case cardsIds.VALUABLES_CARD:
                pushAccordionOpenInfo(translator(messages.valuables));
                handleValuablesAccordion();
                break;
            case cardsIds.ADDITIONAL_INSURANCES_CARD:
                pushAccordionOpenInfo(translator(messages.additionalInsurances));
                handleAdditionalInsurancesAccordion();
                break;
            case cardsIds.BICYCLES_CARD:
                pushAccordionOpenInfo(translator(messages.bicycles));
                handleBicyclesAccordion();
                break;
            default:
                // do nothing
        }
    }, [cardsIds,
        pushAccordionOpenInfo,
        translator,
        policyDetailsOffset]);
    // #endregion GTM EVENTS

    useEffect(() => {
        const lobData = _.get(policySubmissionVM.value, 'lobData');
        const res = Object.entries(lobData)
            .reduce((initValue, [, value]) => initValue
                || value?.isConditionsExclusionsAvailable, false);
        setIsConditionsExclusionsAvailable(res);
    }, [policySubmissionVM]);

    const fetchDocuments = useCallback(async () => {
        const docs = await PolicyService.getDocuments(
            [policySubmissionVM.value.jobID],
            authHeader
        );
        return docs;
    }, [
        PolicyService,
        policySubmissionVM,
        authHeader
    ]);

    const {
        loading: isLoading,
        error: docError,
        data: documents
    } = useCallRetry(
        fetchDocuments,
        {
            retryTimes: 10,
            retryDelayInSeconds: 10,
            initialIsLoading: true,
            timeoutDurationSeconds: DOCUMENT_TIMEOUT_DURATION_SECONDS
        },
        []
    );

    const handlePageNavigation = useCallback((typeOfLink) => {
        const indexOfPolicyDetails = _.findIndex(
            steps,
            ({ path }) => path === typeOfLink
        );
        jumpTo(indexOfPolicyDetails);
    }, [jumpTo, steps]);

    const handleAddressValidationChange = useCallback((isConfirmed) => {
        if (isConfirmed) {
            setAddressValidationInd(true);
            setDisableNext(true);
        } else {
            setAddressValidationInd(false);
            setDisableNext(false);
        }
    }, [setAddressValidationInd]);

    const renderAccordionHeader = (isOpen, title) => {
        return (
            <NfumAccordionHeader
                isOpen={isOpen}
                title={title}
            />
        );
    };

    const handleNext = useCallback(() => {
        return policySubmissionVM;
    }, [policySubmissionVM]);

    const handlePrevious = useCallback(() => {
        _.set(policySubmissionVM, 'lobData.value.homeLine.coverables.homhomeProperty.homhomePropertyConstruction.contentsReplacementCostOverrid', contentsOverrideCostValue.oldValue);
        updateWizardData(policySubmissionVM);
    }, [contentsOverrideCostValue.oldValue, policySubmissionVM, updateWizardData]);

    const scrollToDocumentsSection = useCallback(() => {
        documentsSectionRef.current.scrollIntoView();
    }, []);

    const overrideProps = {
        policyDetailsList: {
            onUpdateAccordionStates: onUpdateOpenAccordion
        },
        policyDetailsCard: {
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen, messages.policyDetails,
                );
            }
        },
        contentsCard: {
            visible: (contentsOverrideCostValue.newValue
            !== contentsOverrideCostValue.oldValue),
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen, messages.contentsCard,
                );
            }

        },
        contentsAwayCard: {
            visible: unspecifiedBelongingsReplCostValue.newValue
                !== unspecifiedBelongingsReplCostValue.oldValue,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen, messages.contentsAway,
                );
            }
        },
        valuablesCard: {
            visible: specificValuables.added.length > 0
                || specificValuables.edited.length > 0
                || specificValuables.removed.length > 0,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen, messages.valuables,
                );
            }
        },
        bicyclesCard: {
            visible: bicycles.added.length > 0
                || bicycles.edited.length > 0
                || bicycles.removed.length > 0,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen, messages.bicycles,
                );
            }
        },
        additionalInsurancesCard: {
            visible: additionalCovers.filter((cov) => cov.newValue !== cov.oldValue).length > 0,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen, messages.additionalInsurances,
                );
            }
        },
        policyDetailsCardContainer: {
            ref: scrollToPolicyDetailsSection
        },
        contentsMTACardContainer: {
            visible: (contentsOverrideCostValue.newValue
                !== contentsOverrideCostValue.oldValue),
            ref: scrollToContentCardSection
        },
        contentsAwayMTACardContainer: {
            visible: unspecifiedBelongingsReplCostValue.newValue
                !== unspecifiedBelongingsReplCostValue.oldValue,
            ref: scrollToContentsAwayMTACardSection
        },
        valuablesMTACardContainer: {
            visible: specificValuables.added.length > 0
                || specificValuables.edited.length > 0
                || specificValuables.removed.length > 0,
            ref: scrollToValuablesMTACardSection
        },
        bicyclesMTACardContainer: {
            visible: bicycles.added.length > 0
                || bicycles.edited.length > 0
                || bicycles.removed.length > 0,
            ref: scrollToBicyclesMTACardSection
        },
        additionalInsurancesCardContainer: {
            visible: additionalCovers.filter((cov) => cov.newValue !== cov.oldValue).length > 0,
            ref: scrollToAdditionalInsurancesSection
        },
        costBreakdownBox: {
            submissionVM: policySubmissionVM
        },
        policyDetailsSummary: {
            submissionVM: policySubmissionVM
        },
        contentsSummary: {
            data: contentsOverrideCostValue
        },
        additionalCoversSummary: {
            data: additionalCovers
        },
        valuablesSummary: {
            data: specificValuables
        },
        bicyclesSummary: {
            data: bicycles
        },
        contentsAwaySummary: {
            data: unspecifiedBelongingsReplCostValue
        },
        editQuote: {
            onClick: () => {
                pushRelativeLinkClickInfo(translator(messages.editChanges), '/policychange-details');
                handlePageNavigation('/policychange-details');
            }
        },
        cancelQuote: {
            onClick: () => returnToDashboard(translator(messages.cancelChanges))
        },
        documentsList: {
            documents: documents,
            pushFileDownloadInfo
        },
        yourDocumentsSectionAnchor: {
            ref: documentsSectionRef
        },
        specialConditionsContainer: {
            visible: isConditionsExclusionsAvailable
        },
        policyScheduleLink: {
            onClick: () => scrollToDocumentsSection()
        },
        riskBillingAddValidationInd: {
            onValueChange: handleAddressValidationChange,
            value: addressValidationInd
        },
        referralMessage: {
            description: translator(messages.addValidationReferalMessage1),
            description1: translator(messages.addValidationReferalMessage2),
            producerDesc: producerDesc,
            producerTele: producerTele,
            isBespoke: isBespoke,
            showAgencyDetails: showAgencyDetails
        },
        referralMessageContainer: {
            visible: addressValidationInd === false
        },
        loader: {
            visible: isLoading,
            loaded: !isLoading
        },
        policyChangeSummaryContainer: {
            visible: !isLoading
        },
        billingvsRiskValidation: {
            visible: paymentMethod === 'cash'
        },
        confirmBillingAddressMessage: {
            value: _.get(policySubmissionVM.value, 'baseData.billingAddress_NFUM.displayName'),
            readOnly: true
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            costBreakdownBox: CostBreakdownBox,
            policyDetailsSummary: PolicyDetailsSummary,
            contentsMTASummary: ContentsMTASummary,
            additionalCoversSummary: AdditionalCoversSummary,
            valuablesSummary: ValuablesSummary,
            bicyclesSummary: BicyclesSummary,
            contentsAwaySummary: ContentsAwaySummary
        }
    };

    if (docError?.errorCode) {
        pushFormStepErrorInfo(policySubmissionVM, MTA_STEPS.MTA_SUMMARY, docError);
        handleError(docError, policySubmissionVM.value.quoteID);
        return false;
    }

    return (
        <WizardPage
            onNext={handleNext}
            showPrevious
            onPrevious={handlePrevious}
            showCancel={false}
            showNext={!isLoading}
            nextLabel={commonMessages.confirmChanges}
            disableNext={!disableNext && paymentMethod === 'cash'}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policySubmissionVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

PolicyChangeSummaryPage.propTypes = wizardProps;
export default withAuthenticationContext(PolicyChangeSummaryPage);
