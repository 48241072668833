import { defineMessages } from 'react-intl';

export default defineMessages({
    replacementCost: {
        id: 'renewal.ngh.views.renewal-summary.bicycles.replacementCost',
        defaultMessage: 'Replacement cost'
    },
    bicycles: {
        id: 'renewal.ngh.views.renewal-summary.bicycles.bicycles',
        defaultMessage: 'Bicycles'
    },
    notSelected: {
        id: 'renewal.ngh.views.renewal-summary.bicycles.notSelected',
        defaultMessage: 'Not selected'
    }
});
