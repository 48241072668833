/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextAreaField, Icon } from '@jutro/components';
import { NfumFieldLabel } from 'nfum-components-platform-react';
import classNames from 'classnames';

import styles from './NfumTextAreaField.module.scss';

function NfumTextAreaField(props) {
    const {
        value,
        success,
        error,
        onValidationChange,
    } = props;
    const [currentValidation, updateCurrentValidation] = useState(true);
    const [showState, updateShowState] = useState(false);

    const showError = value !== undefined && (error || !currentValidation);
    let icon;
    if (showError) {
    icon = "gw-close";
    } else if (success || value) {
    icon = "gw-check";
    } else {
    icon = undefined;
    }
    const iconClass = classNames(styles.nfumInputIcon, {
        [styles.errorIcon]: showError
    });

    const iconComponent = <Icon icon={icon} className={iconClass}/>;

    const onValidate = (isValid, path) => {
        updateCurrentValidation(isValid);
        onValidationChange(isValid, path);
    };

    const onBlur = () => {
        updateShowState(true);
    };

    return (
        <div className={styles.nfumInputContainer}>
            <NfumFieldLabel
                {...props}
            />
            <div className={styles.nfumInput}>
                <TextAreaField
                    {...props}
                    className={styles.nfumInputField}
                    onValidationChange={onValidate}
                    onBlur={onBlur}
                    hideLabel="true"
                />
                {(showState) && iconComponent}
            </div>
        </div>
    );
}

NfumTextAreaField.propTypes = {
    error: PropTypes.bool,
    success: PropTypes.bool
};

NfumTextAreaField.defaultProps = {
    error: false,
    success: false
};

export default NfumTextAreaField;
