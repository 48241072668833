import React, {
    useCallback, useContext
} from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useCommonTagManager } from 'nfum-portals-utils-react';
import { getConfigValue } from '@jutro/config';
import { TranslatorContext } from '@jutro/locale';
import styles from './SecurityCheckPage.module.scss';
import metadata from './SecurityCheckPage.metadata.json5';
import messages from './SecurityCheckPage.messages';

const SecurityCheckPage = () => {
    const translator = useContext(TranslatorContext);
    const {
        pushLinkClickInfo
    } = useCommonTagManager();

    const onClose = useCallback(() => {
        const backToDashboardURL = getConfigValue('JUTRO_BACK_TO_DASHBOARD_PATH', '/');
        pushLinkClickInfo(translator(messages.backToDashboard), backToDashboardURL);
        window.location.replace(backToDashboardURL);
    }, [pushLinkClickInfo, translator]);

    const overrideProps = {
        backToDashboardButton: {
            onClick: onClose
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
};

export default SecurityCheckPage;
