import React, { useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { FormattedMessage, useTranslator } from '@jutro/locale';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './PolicyChangeDetailsComponent.metadata.json5';
import policyChangeDetails from './PolicyChangeDetailsComponent.module.scss';
import messages from './PolicyChangeDetailsComponent.messages';

function PolicyChangeDetailsComponent(props) {
    const translator = useTranslator();
    const { policyDetails: policyDetailsData, showPolicyPeriodDateRange } = props;

    const getFormattedCurrency = useCallback((message, currencyValue) => {
        if (!currencyValue) {
            return null;
        }
        return (
            <FormattedMessage
                {...message}
                values={{
                    newPremium: (
                        <CurrencyField
                            id="currencyPremium"
                            value={currencyValue.newPremium}
                            readOnly
                            hideLabel
                            datatype="object"
                            className={policyChangeDetails.premiumValue}
                        />
                    ),
                    changeInCost: (
                        <CurrencyField
                            id="currencyPremium"
                            value={currencyValue.changeInCost}
                            readOnly
                            hideLabel
                            datatype="object"
                            className={policyChangeDetails.premiumValue}
                        />
                    )
                }}
            />
        );
    }, []);

    const getChangeInCostCurrency = useCallback(
        (message, currencyValue, changePercentage) => {
            if (!currencyValue) {
                return null;
            }
            const getCurrency = (
                <FormattedMessage
                    {...message}
                    values={{
                        val: (
                            <CurrencyField
                                id="currencyPremium"
                                value={currencyValue}
                                readOnly
                                hideLabel
                                datatype="object"
                                className={policyChangeDetails.premiumValue}
                            />
                        )
                    }}
                />
            );

            return (
                <>
                    {getCurrency}
                    {translator(messages.quoteValuePercentage, { value: changePercentage })}
                </>
            );
        },
        [translator]
    );

    const getTaxesPremiumCurrency = useCallback(() => {
        return (
            <FormattedMessage
                {...messages.quoteTotalPremiumTaxFees}
                values={{
                    newPremiumBeforeTaxes: (
                        <CurrencyField
                            id="currencyPremium"
                            value={policyDetailsData.newPremiumBeforeTaxes}
                            readOnly
                            hideLabel
                            datatype="object"
                            className={policyChangeDetails.premiumValue}
                        />
                    ),
                    taxes: (
                        <CurrencyField
                            id="currencyTaxes"
                            value={policyDetailsData.taxes}
                            readOnly
                            hideLabel
                            datatype="object"
                            className={policyChangeDetails.premiumValue}
                        />
                    )
                }}
            />
        );
    }, [policyDetailsData]);

    const getLabel = useCallback(() => {
        let label = translator(messages.effectiveDate);
        if (showPolicyPeriodDateRange) {
            label = translator(messages.quoteRenewalEffectiveDate);
        }
        return label;
    }, [translator, showPolicyPeriodDateRange]);

    const renderQuoteDetailsOverrides = useCallback(() => {
        const calculatePercentage = (premium, changeInCost) => { /* NOSONAR: GW OOTB internal mechanism */
            return Math.abs(Math.floor((changeInCost * 100) / premium));
        };
        const removeSign = (field) => {
            if (!field) {
                return null;
            }
            const copy = Object.assign({}, field);
            copy.amount = Math.abs(copy.amount);
            return copy;
        };

        if (_.isEmpty(policyDetailsData)) {
            return {};
        }

        const periodStartDate = _.get(policyDetailsData, 'policyPeriod.startDate');
        const periodEndDate = _.get(policyDetailsData, 'policyPeriod.endDate');
        const changeInCost = _.get(policyDetailsData, 'changeInCost.amount', 0);
        const increase = changeInCost > 0;
        const decrease = changeInCost < 0;
        const noChange = changeInCost === 0;
        const changeInCostNoSign = removeSign(_.get(policyDetailsData, 'changeInCost'));
        const percentageChange = calculatePercentage(
            _.get(policyDetailsData, 'previousPremium.amount'),
            _.get(policyDetailsData, 'changeInCost.amount')
        );

        const changeInCostIncreaseMessage = getChangeInCostCurrency(
            messages.quoteIncrease,
            policyDetailsData.changeInCost,
            percentageChange
        );

        const changeInCostDecreaseMessage = getChangeInCostCurrency(
            messages.quoteDecrease,
            policyDetailsData.changeInCost,
            percentageChange
        );

        const quoteTaxesCurrency = getTaxesPremiumCurrency();

        const overrides = { /* NOSONAR: GW OOTB internal mechanism */
            quotePremiumChange: {
                visible: !noChange
            },
            quotePremiumNoChange: {
                visible: noChange
            },
            changeInCost: {
                value: increase ? changeInCostIncreaseMessage : changeInCostDecreaseMessage
            },
            premiumValue: {
                content: quoteTaxesCurrency
            },
            messagePremiumNoChangeContainer: {
                visible: noChange
            },
            messagePremiumIncreaseContainer: {
                visible: increase
            },
            messagePremiumNewIncrease: {
                content: getFormattedCurrency(messages.quoteNewPremiumValueIncrease, {
                    newPremium: policyDetailsData.newPremium,
                    changeInCost: policyDetailsData.changeInCost
                })
            },
            messagePremiumDecreaseContainer: {
                visible: decrease
            },
            messagePremiumNewDecrease: {
                content: getFormattedCurrency(messages.quoteNewPremiumValueDecrease, {
                    newPremium: policyDetailsData.newPremium,
                    changeInCost: changeInCostNoSign
                })
            },
            policyPeriodDateRange: {
                label: getLabel()
            },
            policyPeriod: {
                value: {
                    startDate: periodStartDate,
                    endDate: periodEndDate
                }
            }
        };

        return overrides;
    }, [
        getChangeInCostCurrency,
        getFormattedCurrency,
        getLabel,
        getTaxesPremiumCurrency,
        policyDetailsData
    ]);

    const resolvers = {
        resolveClassNameMap: policyChangeDetails
    };
    const overrides = renderQuoteDetailsOverrides();

    const readValue = useCallback((id, path) => {
        return readViewModelValue(metadata.pageContent, policyDetailsData, id, path, overrides);
    }, [policyDetailsData, overrides]);

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={policyDetailsData}
            overrideProps={overrides}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

PolicyChangeDetailsComponent.propTypes = {
    policyDetails: PropTypes.arrayOf(PropTypes.shape({})),
    showPolicyPeriodDateRange: PropTypes.bool
};
PolicyChangeDetailsComponent.defaultProps = {
    policyDetails: undefined,
    showPolicyPeriodDateRange: false
};
export default PolicyChangeDetailsComponent;
