import React, { useCallback, useMemo } from 'react';
import {
    ViewModelForm
} from 'gw-portals-viewmodel-react';
import { wizardProps } from 'gw-portals-wizard-react';
import { appendMetadataWithIndex } from 'gw-jutro-adapters-react';
import FineArtsSummaryItem from '../FineArtsSummaryItem/FineArtsSummaryItem';
import metadata from './FineArtsSummaryAddedItems.metadata.json5';
import styles from './FineArtsSummaryAddedItems.module.scss';

function FineArtsSummaryAddedItems(props) {
    const {
        addedItemsModel,
        index
    } = props;

    const formattedMetadata = useMemo(() => {
        return appendMetadataWithIndex(metadata.componentContent, index);
    }, [index]);

    const generateOverrides = useCallback(() => {
        if (addedItemsModel.added.length === 0) return Object.assign({});

        const fineArtsAddedItemsOverrides = addedItemsModel.added.map((item, ind) => {
            return {
                [`fineArtsSummaryItem${ind}`]: {
                    fineArt: item,
                    index: ind
                }
            };
        });

        return Object.assign({},
            ...fineArtsAddedItemsOverrides);
    }, [
        addedItemsModel
    ]);

    const overrideProps = {
        [`category${index}`]: {
            content: addedItemsModel.name
        },
        [`addedItems${index}`]: {
            data: addedItemsModel.added
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            fineArtsSummaryItem: FineArtsSummaryItem
        }
    };

    return (
        <ViewModelForm
            model={addedItemsModel}
            uiProps={formattedMetadata}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

FineArtsSummaryAddedItems.propTypes = wizardProps;
export default FineArtsSummaryAddedItems;
