import { defineMessages } from 'react-intl';

export default defineMessages({
    policyNumberReference: {
        id: 'ceam.pages.contactUsPage.policyNumberReference',
        defaultMessage: 'Policy number'
    },
    quoteNumberReference: {
        id: 'ceam.pages.contactUsPage.quoteNumberReference',
        defaultMessage: 'Quote number'
    },
    close: {
        id: 'ceam.pages.contactUsPage.close',
        defaultMessage: 'Close'
    },
    title: {
        id: 'ceam.pages.contactUsPage.title',
        defaultMessage: 'We need you to get in touch'
    },
    description: {
        id: 'ceam.pages.contactUsPage.description',
        defaultMessage: 'Based on the information you have provided to us, we need you to contact us so we can support you with your policy.​'
    },
    pageTitle: {
        id: 'ceam.pages.contactUsPage.pageTitle',
        defaultMessage: 'Get in touch'
    },
    backToDashboard: {
        id: 'ceam.pages.contactUsPage.backToDashboard',
        defaultMessage: 'Back to dashboard'
    },
    renewalDescription: {
        id: 'ceam.pages.contactUsPage.renewalDescription',
        defaultMessage: 'Sorry, we are currently unable to process your renewal online. If you’d like to renew your cover, you’ll need to contact us. If we don’t hear from you, your policy won’t automatically renew and you’ll no longer be covered.'
    },
    renewalPageTitle: {
        id: 'ceam.pages.contactUsPage.renewalPageTitle',
        defaultMessage: 'Get in touch'
    },
    blockFutureMTADescription: {
        id: 'ceam.pages.contactUsPage.blockFutureMTADescription',
        defaultMessage: 'You can not start a new change until your previously completed change comes into effect.  If this change is urgent please contact us'
    },
    blockFutureMTA: {
        id: 'ceam.pages.contactUsPage.blockFutureMTA',
        defaultMessage: 'We can\'t process this change'
    }
});
