import React, {
    useState, useEffect, useCallback, useContext, useMemo
} from 'react';
import moment from 'moment';
import _ from 'lodash';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { TranslatorContext } from '@jutro/locale';
import { useDependencies } from 'gw-portals-dependency-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useWizardActions } from 'nfum-portals-wizard-react';
import {
    useErrorHandler,
    MTA_STEPS,
    BESPOKE_MTA_STEPS,
    ERROR_STEPS
} from 'nfum-portals-utils-react';
import { useTagManager } from 'nfum-capability-policychange-common-react';
import commonMessages from 'nfum-capability-policychange-common-react/NGHPolicyChange.messages';
import { getConfigValue } from '@jutro/config';
import metadata from './PaymentPage.metadata.json5';
import styles from './PaymentPage.module.scss';
import messages from './PaymentPage.messages';
import DirectDebitGuarantee from './DirectDebitGuarantee/DirectDebitGuarantee';

function PaymentPage(props) { /* NOSONAR: pure declarative usage  */
    const {
        wizardData: policyChangeVM, jumpTo, steps, goNext
    } = props;
    const POLICYCHANGE_JOB_TYPE_KEY = 'PolicyChange';
    const MIN_OUTSTANDING_INSTALLMENTS = 2;
    const CARD_PAYMENT_LIMIT = 25000;
    const translator = useContext(TranslatorContext);
    const { EndorsementService } = useDependencies('EndorsementService');
    const { authHeader } = useAuthentication();
    const [redirectUrl, setRedirectUrl] = useState('');
    const [isPaymentGatewayError, setIsPaymentGatewayError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isUnsuccessfulPaymentMessageVisible,
        setIsUnsuccessfulPaymentMessageVisible] = useState(false);
    const { handleError } = useErrorHandler();
    const {
        pushFormStepInfo,
        pushFormStepErrorInfo,
        pushLinkClickInfo,
        pushRelativeLinkClickInfo
    } = useTagManager();
    const { returnToDashboard } = useWizardActions();
    const producerDesc = _.get(policyChangeVM.value, 'baseData.producerDetails_NFUM.producerCodeDescription', '');
    const producerTele = _.get(policyChangeVM.value, 'baseData.producerDetails_NFUM.producerCodeTelephone', '');
    const isBespoke = _.get(policyChangeVM.value, 'baseData.producerDetails_NFUM.isBespoke', '');
    const showAgencyDetails = _.get(policyChangeVM.value, 'baseData.producerDetails_NFUM.showProducerDetails', '');
    const isPaymentMockEnabled = _.get(policyChangeVM.value, 'digitalPaymentMock_NFUM', false);

    // #region GTM EVENTS

    useEffect(() => {
        pushFormStepInfo(policyChangeVM, isBespoke
            ? BESPOKE_MTA_STEPS.PAYMENT
            : MTA_STEPS.PAYMENT);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // #endregion GTM EVENTS

    const returnToDCSDashboard = () => {
        const backToDashboardURL = getConfigValue('JUTRO_BACK_TO_DASHBOARD_PATH', '/');
        pushLinkClickInfo(translator(messages.returnToDashboard), backToDashboardURL);
        window.location.replace(backToDashboardURL);
    };

    const getDate = (dateYMD) => {
        const { year, month, day } = dateYMD;
        const date = moment(new Date(year, month, day));
        return date;
    };

    const outstandingInstallments = useMemo(() => {
        const previousInstallments = _.get(policyChangeVM.value, 'previousInstallmentDetails_NFUM');
        const futureInstallments = _.get(policyChangeVM.value, 'installmentDetails_NFUM')
            .filter((installment) => getDate(installment.installmentDate).isAfter(moment()));

        const processedInstallments = [];
        futureInstallments.forEach((inst) => {
            const equivInstallment = previousInstallments
                .find((prevInst) => getDate(prevInst.installmentDate)
                    .isSame(getDate(inst.installmentDate)));
            processedInstallments.push({
                installmentAmount: inst.installmentAmount,
                installmentChangedAmount: {
                    amount: inst.installmentChangedAmount.amount
                        - (equivInstallment?.installmentPaid?.amount || 0),
                    currency: inst.installmentChangedAmount.currency
                },
                installmentDate: inst.installmentDate
            });
        });

        return processedInstallments.sort((a, b) => {
            return getDate(a.installmentDate).diff(getDate(b.installmentDate));
        });
    }, [policyChangeVM]);

    const isDirectDebitPaymentMethod = _.get(policyChangeVM, 'baseData.value.paymentMethod_NFUM') === 'directdebit';
    const transactionCost = _.get(policyChangeVM.value, 'transactionCost.amount');
    const isIncrease = transactionCost > 0;
    const isDecrease = transactionCost < 0;

    const navigateTo = useCallback((pagePath) => {
        const pageIndex = _.findIndex(
            steps,
            ({ path }) => path === pagePath
        );
        jumpTo(pageIndex);
    }, [jumpTo, steps]);

    const bindPolicyChange = useCallback((shouldGoNext = false) => {
        const paymentMethod = _.get(policyChangeVM, 'baseData.value.paymentMethod_NFUM');
        setIsLoading(true);
        EndorsementService
            .bind([policyChangeVM.value.jobID, { paymentMethod }], authHeader)
            .then((result) => {
                policyChangeVM.value = result?.policyChange;
                if (shouldGoNext) {
                    goNext();
                }
                setIsLoading(false);
            })
            .catch((error) => {
                pushFormStepErrorInfo(
                    policyChangeVM,
                    MTA_STEPS.PAYMENT,
                    null,
                    ERROR_STEPS.PAYMENT_ERROR
                );
                setIsLoading(false);
                handleError(error, policyChangeVM.value.jobID);
            });
    }, [
        policyChangeVM,
        EndorsementService,
        goNext,
        pushFormStepErrorInfo,
        setIsLoading,
        handleError,
        authHeader
    ]);
    const navigateToSummary = () => {
        const url = '/policychange-summary';
        pushRelativeLinkClickInfo(translator(messages.cancelTransaction), url);
        navigateTo(url);
    };

    useEffect(() => {
        if (!isDecrease && !isIncrease) {
            bindPolicyChange();
        }
        if (!window.policyChangePaymentStatusListenerAttached) {
            window.addEventListener('message', async (event) => {
                if (process.env.REACT_APP_NFUM_PAYMENT_STATUS_ORIGIN
                    && event.origin !== process.env.REACT_APP_NFUM_PAYMENT_STATUS_ORIGIN) {
                    handleError(`Payment status origin different from configured host: ${event.origin}`);
                    return;
                }
                if (_.get(policyChangeVM, 'baseData.value.jobType') !== POLICYCHANGE_JOB_TYPE_KEY) return;
                const paymentStatus = await EndorsementService.checkPaymentStatus_NFUM(
                    policyChangeVM.value.jobID,
                    authHeader
                );
                if (paymentStatus === 'CAPTURED') {
                    bindPolicyChange(true);
                } else if (paymentStatus === 'CANCELLED') {
                    navigateToSummary();
                } else {
                    pushFormStepErrorInfo(
                        policyChangeVM,
                        MTA_STEPS.PAYMENT,
                        null,
                        ERROR_STEPS.PAYMENT_ERROR
                    );
                    setIsLoading(false);
                    setIsUnsuccessfulPaymentMessageVisible(true);
                }
            }, false);
            window.policyChangePaymentStatusListenerAttached = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchRedirectUrl = useCallback(async () => {
        try {
            const res = await EndorsementService.getRedirectURL(
                [policyChangeVM.value.jobID],
                authHeader
            );
            if (!_.isEmpty(res)) {
                setRedirectUrl(res);
                setIsPaymentGatewayError(false);
            } else {
                pushFormStepErrorInfo(
                    policyChangeVM,
                    MTA_STEPS.PAYMENT,
                    null,
                    ERROR_STEPS.PAYMENT_ERROR
                );
                setIsPaymentGatewayError(true);
            }
        } catch (error) {
            pushFormStepErrorInfo(
                policyChangeVM,
                MTA_STEPS.PAYMENT,
                null,
                ERROR_STEPS.PAYMENT_ERROR
            );
            setIsPaymentGatewayError(true);
        }
    }, [
        EndorsementService,
        authHeader,
        policyChangeVM,
        setIsPaymentGatewayError,
        pushFormStepErrorInfo
    ]);

    useEffect(() => {
        if (_.get(policyChangeVM.value, 'baseData.paymentMethod_NFUM') === 'cash'
            && _.get(policyChangeVM.value, 'transactionCost.amount') > 0) {
            // eslint-disable-next-line no-unused-expressions
            isPaymentMockEnabled ? setIsPaymentGatewayError(false) : fetchRedirectUrl();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNext = useCallback(async () => {
        let bindResponse;
        try {
            const { baseData } = policyChangeVM;
            const paymentData = _.get(baseData, 'paymentMethod_NFUM');
            const paymentDetails = {
                paymentMethod: paymentData.value.code,
            };
            bindResponse = await EndorsementService.bind(
                [policyChangeVM.value.jobID, paymentDetails],
                authHeader
            );
            policyChangeVM.value = bindResponse.policyChange;
            return policyChangeVM;
        } catch (error) {
            handleError(error, policyChangeVM.value.jobID);
        }
        return bindResponse.policyChange;
    }, [
        EndorsementService,
        authHeader,
        handleError,
        policyChangeVM
    ]);

    const renderAmount = (item, index, metadataProps) => {
        if (!item) return '';
        const { path } = metadataProps;
        const { amount } = item[path];
        return `£${(amount || 0).toFixed(2)}`;
    };

    const renderDate = (item, index, metadataProps) => {
        if (!item) return '';
        const { path } = metadataProps;
        const { year, month, day } = item[path];
        const date = moment(new Date(year, month, day)).format('DD/MM/YYYY');
        return date;
    };

    const renderInstallmentAmount = useCallback((installment) => {
        return renderAmount(installment, undefined, { path: 'installmentChangedAmount' });
    }, []);

    const getMonthlyTransactionCost = useCallback(() => {
        const transactionCosts = _.get(policyChangeVM, 'transactionCost.value.amount');
        const numberOfInstallments = outstandingInstallments.length || 1;

        // transaction cost divided by number of outstanding installments
        return transactionCosts / numberOfInstallments;
    }, [policyChangeVM, outstandingInstallments]);

    const getMonthlyTransactionCostText = () => {
        const diffAmount = getMonthlyTransactionCost();
        const absDiffAmount = Math.abs(diffAmount).toFixed(2);
        if (diffAmount < 0) {
            return `- £${absDiffAmount}`;
        }
        return `+ £${absDiffAmount}`;
    };

    const returnToPortal = () => {
        returnToDCSDashboard();
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            directDebitGuarantee: DirectDebitGuarantee
        },
        resolveCallbackMap: {
            renderAmount,
            renderDate,
            returnToPortal
        }
    };

    const getNewTotalCost = useCallback(() => {
        const yearlyPremium = _.get(policyChangeVM.value, 'totalCost.amount')?.toFixed(2);
        return `£${yearlyPremium}`;
    }, [policyChangeVM]);

    const getNewTransactionCost = useCallback(() => {
        const diff = _.get(policyChangeVM.value, 'transactionCost.amount');
        return `£${Math.abs(diff)?.toFixed(2)}`;
    }, [policyChangeVM]);

    const getYourNextPaymentText = useCallback(() => {
        if (outstandingInstallments.length < MIN_OUTSTANDING_INSTALLMENTS) {
            return '';
        }
        return translator(messages.yourNextPayment, {
            nextPaymentAmount: renderInstallmentAmount(outstandingInstallments[0]),
            numberOfPayments: outstandingInstallments.length - 1,
            followingPaymentsAmount: renderInstallmentAmount(outstandingInstallments[1])
        });
    }, [outstandingInstallments, translator, renderInstallmentAmount]);

    const onMockPay = async () => {
        if (!isDecrease && !isIncrease) {
            bindPolicyChange();
        } else {
            bindPolicyChange(true);
        }
    };

    const overrideProps = {
        directDebitValue: {
            content: getMonthlyTransactionCostText()
        },
        editQuote: {
            onClick: () => {
                const url = '/policychange-details';
                pushRelativeLinkClickInfo(translator(messages.editChanges), url);
                navigateTo(url);
            }
        },
        cancelQuote: {
            onClick: () => returnToDashboard(translator(commonMessages.cancelChanges))
        },
        cashPaymentContainer: {
            visible: !isDirectDebitPaymentMethod && isIncrease && isPaymentGatewayError === false
        },
        cashPremiumBox: {
            isPerYear: true,
            titleText: translator(messages.totalToPay),
            premiumText: getNewTransactionCost()
        },
        directDebitIncreaseTitle: {
            visible: getMonthlyTransactionCost() > 0
        },
        directDebitDecreaseTitle: {
            visible: getMonthlyTransactionCost() < 0
        },
        yourNextPaymentTextContainer: {
            visible: outstandingInstallments.length >= MIN_OUTSTANDING_INSTALLMENTS
        },
        yourNextPaymentText: {
            content: getYourNextPaymentText()
        },
        directDebitPremiumCostValue: {
            content: renderInstallmentAmount(_.first(outstandingInstallments))
        },
        paymentPagePayApp: {
            src: !isPaymentMockEnabled ? redirectUrl : '',
            visible: !isPaymentMockEnabled
        },
        paymentPagePayAppMock: {
            visible: isPaymentMockEnabled,
            onClick: onMockPay
        },
        extraPremiumContainer: {
            visible: _.first(outstandingInstallments) === undefined && isIncrease
        },
        extraRefundPremiumContainer: {
            visible: _.first(outstandingInstallments) === undefined && isDecrease
        },
        revisedPaymentScheduleContainer: {
            visible: !!_.first(outstandingInstallments)
        },
        revisedPaymentScheduleTable: {
            data: outstandingInstallments
        },
        guaranteeContainer: {
            visible: !!_.first(outstandingInstallments)
        },
        directDebitContainer: {
            visible: isDirectDebitPaymentMethod && (isIncrease || isDecrease)
        },
        paymentLimitContainer: {
            visible: transactionCost > CARD_PAYMENT_LIMIT
        },
        paymentLimitInfo: {
            errorMessage: translator(messages.cardLimit),
            showWorkingOnQuoteText: false,
            showSavedQuoteText: true,
            showContactUsTextWithAmendments: false,
            workingOnQuoteText: translator(messages.cardLimitMessage),
            savedProgressText: translator(messages.savedProgress),
            producerDesc: producerDesc,
            producerTele: producerTele,
            isBespoke: isBespoke,
            showAgencyDetails: showAgencyDetails
        },
        paymentLimitPremiumBox: {
            isIncreaseDecreaseMessage: false,
            isPerYear: true,
            titleText: translator(messages.totalToPay),
            premiumText: getNewTransactionCost()
        },
        noPremiumChangeContainer: {
            visible: !isDecrease && !isIncrease && !isLoading
        },
        noPremiumChangeBox: {
            isNoPremiumChange: true,
            isPerYear: !isDirectDebitPaymentMethod,
            titleText: translator(messages.newPremiumTitle),
            premiumText: getNewTotalCost()
        },
        returnToDashboardButton: {
            onClick: () => returnToDCSDashboard()
        },
        refundContainer: {
            visible: !isDirectDebitPaymentMethod && isDecrease
        },
        refundPremiumBox: {
            isIncreaseDecreaseMessage: true,
            increaseDecreaseText: translator(
                messages.decreaseOf,
                { amount: getNewTransactionCost() }
            ),
            isPerYear: true,
            titleText: translator(messages.newPremiumTitle),
            premiumText: getNewTotalCost()
        },
        extraRefundInfoTitle: {
            content: translator(messages.extraRefundInfoTitle, {
                amount: getNewTransactionCost()
            })
        },
        paymentGatewayErrorInfoContainer: {
            visible: isPaymentGatewayError === true
        },
        paymentGatewayErrorInfo: {
            errorMessage: translator(messages.paymentUnavailable),
            showWorkingOnQuoteText: false,
            showSavedQuoteText: true,
            showContactUsTextWithAmendments: false,
            workingOnQuoteText: translator(messages.paymentUnavailableMessageText),
            savedProgressText: translator(messages.savedProgress),
            producerDesc: producerDesc,
            producerTele: producerTele,
            isBespoke: isBespoke,
            showAgencyDetails: showAgencyDetails
        },
        returnToPortalButton: {
            visible: isPaymentGatewayError === true || transactionCost > CARD_PAYMENT_LIMIT
        },
        paymentsLoader: {
            loaded: !isLoading,
            text: translator(messages.paymentProcessing)
        },
        unsuccessfulPaymentMessage: {
            visible: isUnsuccessfulPaymentMessageVisible && !isLoading
        },
        paymentsContainer: {
            visible: !isUnsuccessfulPaymentMessageVisible && !isLoading
        }
    };

    const showNext = (((isDirectDebitPaymentMethod && isIncrease) || isDecrease)
        && isPaymentGatewayError !== true)
        || isUnsuccessfulPaymentMessageVisible;

    const nextLabel = isUnsuccessfulPaymentMessageVisible
        ? messages.updatePaymentInformation
        : messages.completeChanges;

    const nextAction = isUnsuccessfulPaymentMessageVisible
        ? () => {
            const url = '/policychange-summary';
            pushRelativeLinkClickInfo(translator(nextLabel), url);
            navigateTo(url);
        }
        : onNext;

    return (
        <WizardPage
            onNext={nextAction}
            showPrevious={false}
            showNext={showNext}
            nextLabel={nextLabel}
            showCancel={isUnsuccessfulPaymentMessageVisible}
            cancelLabel={messages.cancelTransaction}
            onCancel={() => returnToDashboard(translator(commonMessages.returnToDashboard))}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                classNameMap={resolvers.resolveClassNameMap}
                overrideProps={overrideProps}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

PaymentPage.propTypes = wizardProps;

export default PaymentPage;
