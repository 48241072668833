/* eslint-disable */
import React from 'react';
import { Grid } from '@jutro/layout';
import { RadioButtonField } from '@jutro/components';
import { NfumFieldLabel } from 'nfum-components-platform-react';
import classNames from 'classnames';
import styles from './NfumRadioButtonField.module.scss';

function NfumRadioButtonField(props) {
    const {
        className,
        label
    } = props;
    const hasTwoOptions = props.availableValues.length === 2;
    const radioButtonClassNames = classNames(className, { 
        ["horizonalView"]: hasTwoOptions && props.horizontalView !== false,
        ["verticalView"]: !hasTwoOptions || props.horizontalView === false
    });
    return (
        <div className={styles.nfumInputContainer}>
            { label && <NfumFieldLabel
                {...props}
            /> }
            <div className={styles.nfumInput}>
                <Grid
                    hgap="none"
                    className={radioButtonClassNames}
                >
                    <RadioButtonField
                        {...props}
                        tooltip=''
                        hideLabel="true"
                    />
                </Grid>
            </div>
        </div>
    );
}

export default NfumRadioButtonField;
