import { defineMessages } from 'react-intl';

export default defineMessages({
    yourNewPremium: {
        id: 'endorsement.ngh.views.policychange-summary.costBreakdownBox.yourNewPremium',
        defaultMessage: 'Your premium'
    },
    increaseOf: {
        id: 'endorsement.ngh.views.policychange-summary.costBreakdownBox.increaseOf',
        defaultMessage: 'This is an increase of {amount} {perTime}'
    },
    decreaseOf: {
        id: 'endorsement.ngh.views.policychange-summary.costBreakdownBox.decreaseOf',
        defaultMessage: 'This is a decrease of {amount} {perTime}'
    },
    ddIncreaseOf: {
        id: 'endorsement.ngh.views.policychange-summary.costBreakdownBox.ddIncreaseOf',
        defaultMessage: 'This is an increase of approximately {amount} {perTime}'
    },
    ddDecreaseOf: {
        id: 'endorsement.ngh.views.policychange-summary.costBreakdownBox.ddDecreaseOf',
        defaultMessage: 'This is a decrease of approximately {amount} {perTime}'
    },
    noPremiumChange: {
        id: 'endorsement.ngh.views.policychange-summary.costBreakdownBox.noPremiumChange',
        defaultMessage: 'There is no change to your premium'
    },
    noMonthlyPremiumChange: {
        id: 'endorsement.ngh.views.policychange-summary.costBreakdownBox.noMonthlyPremiumChange',
        defaultMessage: 'There is no change to your monthly premium'
    },
    perYear: {
        id: 'endorsement.ngh.views.policychange-summary.costBreakdownBox.perYear',
        defaultMessage: 'per year'
    },
    perMonth: {
        id: 'endorsement.ngh.views.policychange-summary.costBreakdownBox.perMonth',
        defaultMessage: 'per month'
    },
});
