import { defineMessages } from 'react-intl';

export default defineMessages({
    needYourHelp: {
        id: 'callus.needYourHelp',
        defaultMessage: 'Need help with your policy? Call us'
    },
    phoneNumber: {
        id: 'callus.phoneNumber',
        defaultMessage: '0808 159 7883'
    }
});
