import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './SectionComponent.module.scss';

const SectionComponent = ({ children, sectionType = 'basic', additionalClasses }) => (
    <section className={cx(styles.section, styles[sectionType], ...additionalClasses)}>
        { children }
    </section>
);

SectionComponent.defaultProps = {
    additionalClasses: [],
};

SectionComponent.propTypes = {
    children: PropTypes.element.isRequired,
    sectionType: PropTypes.oneOf('basic', 'highlighted', 'extended').isRequired,
    additionalClasses: PropTypes.arrayOf(PropTypes.string),
};
export default SectionComponent;
