import React from 'react';
import moment from 'moment';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import './PaymentScheduleSummary.messages';
import metadata from './PaymentScheduleSummary.metadata.json5';

function PaymentScheduleSummary(props) {
    const { installmentDetails } = props;

    const renderInstallmentDate = (installment) => {
        if (!installment) return null;
        const { installmentDate } = installment;
        const { year, month, day } = installmentDate;
        const date = moment(new Date(year, month, day)).format('DD/MM/YYYY');
        return date;
    };

    const renderInstallmentAmount = (installment) => {
        if (!installment) return null;
        const {
            installmentAmount: { amount }
        } = installment;
        const changedAmount = installment?.installmentChangedAmount?.amount || 0;
        const totalAmount = amount + changedAmount;
        return `£${totalAmount.toFixed(2)}`;
    };

    const overrideProps = {
        paymentScheduleTable: {
            data: installmentDetails
        },
        paymentDate: {
            renderCell: renderInstallmentDate
        },
        premium: {
            renderCell: renderInstallmentAmount
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
        />
    );
}

export default PaymentScheduleSummary;
