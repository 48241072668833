import React, { useCallback } from 'react';
import { pageTemplateProps } from 'gw-portals-wizard-react';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import { NfumWizardPageTemplate, HeaderBar } from 'nfum-portals-wizard-react';
import headerStepsConfig from './config/ngh-wizard-header-config';

function NGHPolicyChangeWizardPageTemplate(props) {
    const {
        children, acknowledgeError, errorsForStep, customMessageModal, ...otherProps
    } = props;

    const renderWizardHeader = useCallback(
        () => {
            return (
                <HeaderBar
                    stepsConfig={headerStepsConfig}
                />
            );
        },
        []
    );

    return (
        <NfumWizardPageTemplate
            renderContextComponent={renderWizardHeader}
            showContextComponent
            errorsForStep={errorsForStep}
            {...otherProps}
        >
            <WizardSingleErrorComponent
                acknowledgeError={acknowledgeError}
                issuesList={errorsForStep}
            />
            {children}
        </NfumWizardPageTemplate>
    );
}

NGHPolicyChangeWizardPageTemplate.propTypes = pageTemplateProps;
export default NGHPolicyChangeWizardPageTemplate;
