import React, { useCallback, useContext } from 'react';
import {
    ViewModelForm
} from 'gw-portals-viewmodel-react';
import _ from 'lodash';
import { wizardProps } from 'gw-portals-wizard-react';
import { useCurrency } from 'nfum-portals-utils-react';
import { TranslatorContext } from '@jutro/locale';
import metadata from './FineArtsSummaryItem.metadata.json5';
import messages from './FineArtsSummaryItem.messages';
import styles from './FineArtsSummaryItem.module.scss';

function FineArtsSummaryItem(props) {
    const {
        fineArt
    } = props;
    const translator = useContext(TranslatorContext);
    const currencyFormatter = useCurrency();
    const OTHER_KEY = 'Other';

    const getItemCollectionKept = useCallback((type) => {
        return translator({
            id: `typekey.CollectionKept_NFUM.${type}`,
            defaultMessage: type
        });
    }, [translator]);

    const category = Object.freeze({
        FINE_ART: 'FineArt',
        WINES: 'WineCollections',
        STAMPS_MEDALS_COINS: 'StampsMedalsCoins',
        OTHER_COLLECTIONS: 'OtherCollections'
    });

    const getItemFineArtType = useCallback((type) => {
        return translator({
            id: `typekey.FineArtType_NFUM.${type}`,
            defaultMessage: type
        });
    }, [translator]);

    const getHeaderDescription = useCallback(() => {
        let description = '';
        const itemType = _.get(fineArt, 'itemType');
        if (itemType === category.FINE_ART) {
            description = `${getItemFineArtType(fineArt.fineArtType)}, ${fineArt.description}`;
        } else if (itemType === category.WINES) {
            description = translator(messages.wineCollection);
        } else {
            description = `${fineArt?.collectionType}`;
        }

        return description;
    }, [fineArt, category, getItemFineArtType, translator]);

    const overrideProps = {
        headerDescription: {
            content: getHeaderDescription()
        },
        type: {
            visible: false
        },
        collectionType: {
            visible: false
        },
        sumInsured: {
            visible: [category.OTHER_COLLECTIONS, category.WINES, category.STAMPS_MEDALS_COINS].includes(_.get(fineArt, 'itemType')),
            value: currencyFormatter.formatCurrency(`${_.get(fineArt, 'sumInsured')}`, true),
        },
        collectionKept: {
            visible: [category.STAMPS_MEDALS_COINS].includes(_.get(fineArt, 'itemType')),
            value: getItemCollectionKept(_.get(fineArt, 'collectionKept'))
        },
        description: {
            visible: false
        },
        collectionDescription: {
            visible: _.get(fineArt, 'itemType') === category.OTHER_COLLECTIONS
                && _.get(fineArt, 'collectionType') === OTHER_KEY
        },
        valuationDate: {
            visible: [category.FINE_ART, category.WINES, category.STAMPS_MEDALS_COINS].includes(_.get(fineArt, 'itemType'))
                && _.get(fineArt, 'valuationDate') !== undefined,
            value: _.get(fineArt, 'valuationDate')
        },
        agreedValue: {
            visible: [category.FINE_ART].includes(_.get(fineArt, 'itemType')),
            value: currencyFormatter.formatCurrency(`${_.get(fineArt, 'agreedValue')}`, true),
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            model={fineArt}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

FineArtsSummaryItem.propTypes = wizardProps;
export default FineArtsSummaryItem;
