import React, {
    useCallback,
    useContext,
    useEffect,
    useState,
    useRef
} from 'react';
import PropTypes from 'prop-types';
import { MetadataContent } from '@jutro/uiconfig';
import _ from 'lodash';
import cx from 'classnames';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from 'gw-digital-auth-react';
import { ApplicationHeader, SubApplicationHeader } from '@jutro/router';
import { DropdownMenuLink, useModal } from '@jutro/components';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useCommonTagManager } from 'nfum-portals-utils-react';
import { useWizardActions } from 'nfum-portals-wizard-react';
import metadata from './PageHeaderComponent.metadata.json5';
import pageHeaderStyles from './PageHeaderComponent.module.scss';
import messages from './PageHeaderComponent.messages';
import CallUs from '../CallUs/CallUs';
import LogoutModal from '../LogoutModal/LogoutModal';

function PageHeaderComponent(props) {
    const {
        showModal
    } = useModal();
    const breakpoint = useContext(BreakpointTrackerContext); // nfum custom
    const isPhone = breakpoint === 'phone'; // nfum custom
    const isPhoneWide = breakpoint === 'phoneWide'; // nfum custom
    const [isSticky, setIsSticky] = useState(true); // nfum custom
    const topEdgeRef = useRef(); // nfum custom
    const auth = useAuthentication();
    const { routes, isPhoneNumberHidden } = props; // nfum custom
    const translator = useTranslator();
    const { env } = appConfig;
    const { returnToDashboard } = useWizardActions(); // nfum custom
    const {
        pushLinkClickInfo
    } = useCommonTagManager(); // nfum custom
    const CONTINUE = 'CONTINUE'; // nfum custom
    const LOGOUT = 'LOGOUT'; // nfum custom
    const getAvatarLinks = useCallback(() => {
        const links = [
            <DropdownMenuLink to="/userprofile">{translator(messages.account)}</DropdownMenuLink>,
            <DropdownMenuLink to="/faq">{translator(messages.faq)}</DropdownMenuLink>
        ];
        // when using jutroAuthContext, jutro application header add the logout link by default
        // avoiding custom logout for gwcp and follow jutro
        if (!auth.useJutroAuthContext) {
            links.push([
                <DropdownMenuLink onClick={auth.logout}>
                    {translator(messages.logout)}
                </DropdownMenuLink>
            ]);
        }
        return links;
    }, [translator, auth]);

    const getLogoSrc = useCallback(() => {
        const url = _.get(env, 'DEPLOYMENT_URL');
        return `${url}/branding/logo-header.svg`;
    }, [env]);

    /* nfum custom */
    const stickyHandler = (entries) => {
        if (!entries[0].isIntersecting) {
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
    };
    useEffect(() => {
        const observer = new window.IntersectionObserver(stickyHandler);
        if (topEdgeRef.current) {
            observer.observe(topEdgeRef.current);
        }
    }, []);

    const callLogout = useCallback(() => { // okta-patch
        // Setting logoutAM to true on click of signout to paas it to other open tab
        window.localStorage.setItem('logoutAM', true);
        auth.logout();
    }, [auth]);

    const handleLogoutClick = useCallback(() => { // okta-patch
        showModal(
            <LogoutModal isOpen />
        ).then((choice) => {
            if (choice.type === LOGOUT) {
                callLogout();
            } else if (choice.type === CONTINUE) {
                // return;
            }
        }, _.noop);
    }, [callLogout]);

    const redirectToNfum = useCallback(() => {
        const url = 'https://www.nfumutual.co.uk/insurance/home-insurance/';
        pushLinkClickInfo('nfum logo', url);
        window.open(url, '_blank');
    }, [pushLinkClickInfo]);

    const headerWrapperStyles = cx(pageHeaderStyles.headerWrapper, {
        [pageHeaderStyles.sticky]: isSticky
    });
    /* end: nfum custom */

    const overrides = {
        topEdge: { // nfum custom
            ref: topEdgeRef
        },
        callUsMobile: { // nfum custom
            visible: (isPhone || isPhoneWide) && !isPhoneNumberHidden
        },
        callUsDesktop: { // nfum custom
            visible: (!isPhone && !isPhoneWide) && !isPhoneNumberHidden
        },
        backToDashboardMobileContainer: { // nfum custom
            visible: isPhone || isPhoneWide,
            onClick: () => returnToDashboard(translator(messages.backToDashboard))
        },
        backToDashboardContainer: { // nfum custom
            visible: (!isPhone && !isPhoneWide),
            onClick: () => returnToDashboard(translator(messages.backToDashboard))
        },
        logoutMobileContainer: { // nfum custom
            visible: isPhone || isPhoneWide,
        },
        logoutContainer: { // nfum custom
            visible: (!isPhone && !isPhoneWide),
        },
        headerWrapper: { // nfum custom
            className: headerWrapperStyles
        },
        applicationheader: {
            avatarProps: {
                username: auth.user_name,
                title: auth.user_name,
                subtitle: auth.name
            },
            avatarChildren: getAvatarLinks(),
            logoSrc: getLogoSrc(),
        },
        applicationsubheader: {
            navigationRoutes: routes
        },
        nfuMutualLogoLink: { // nfum custom
            onClick: redirectToNfum
        }
    };

    const resolvers = {
        resolveClassNameMap: pageHeaderStyles,
        resolveComponentMap: {
            applicationheader: ApplicationHeader,
            subapplicationheader: SubApplicationHeader,
            callUs: CallUs // nfum custom
        },
        resolveCallbackMap: {
            onLogoutClick: handleLogoutClick // nfum custom // okta-patch
        }
    };

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrides}
            {...resolvers}
        />
    );
}

PageHeaderComponent.propTypes = {
    routes: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default PageHeaderComponent;
