import { defineMessages } from 'react-intl';

export default defineMessages({
    close: {
        id: 'nfum.platform.label.tooltip.Close',
        defaultMessage: 'Close'
    },
    optional: {
        id: 'nfum.platform.label.tooltip.Optional',
        defaultMessage: 'Optional'
    }
});
