import { defineMessages } from 'react-intl';

export default defineMessages({
    wineCollection: {
        id: 'endorsement.ngh.views.fineArtsSummaryItem.wineCollection',
        defaultMessage: 'Wine collection'
    },
    type: {
        id: 'endorsement.ngh.views.fineArtsSummaryItem.type',
        defaultMessage: 'Type'
    },
    collectionType: {
        id: 'endorsement.ngh.views.fineArtsSummaryItem.collectionType',
        defaultMessage: 'Collection type'
    },
    description: {
        id: 'endorsement.ngh.views.fineArtsSummaryItem.description',
        defaultMessage: 'Description'
    },
    collectionDescription: {
        id: 'endorsement.ngh.views.fineArtsSummaryItem.collectionDescription',
        defaultMessage: 'Describe your collection'
    },
    sumInsured: {
        id: 'endorsement.ngh.views.fineArtsSummaryItem.sumInsured',
        defaultMessage: 'Sum insured'
    },
    agreedValue: {
        id: 'endorsement.ngh.views.fineArtsSummaryItem.agreedValue',
        defaultMessage: 'Agreed value'
    },
    valuationDate: {
        id: 'endorsement.ngh.views.fineArtsSummaryItem.valuationDate',
        defaultMessage: 'When was the valuation done?'
    },
    collectionKept: {
        id: 'endorsement.ngh.views.fineArtsSummaryItem.collectionKept',
        defaultMessage: 'Where is the collection kept?'
    },
});
