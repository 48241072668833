import React from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import SectionComponent from '../SectionComponent/SectionComponent';
import metadata from './PaymentUnsuccessfulMessage.metadata.json5';
import './PaymentUnsuccessfulMessage.messages';

const PaymentUnsuccessfulMessage = () => {
    const resolvers = {
        resolveComponentMap: {
            sectionComponent: SectionComponent
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
};

export default PaymentUnsuccessfulMessage;
