import { defineMessages } from 'react-intl';

export default defineMessages({

    fineArtsMessageHeaderContent: {
        id: 'renewal.bespoke.views.renewal-summary.fineArtsPerLocation.fineArtsMessageHeader',
        defaultMessage: 'About your Fine Art and Collections'
    },
    fineArtsMessageContent111: {
        id: 'renewal.bespoke.views.renewal-summary.fineArtsPerLocation.fineArtsMessageContent111',
        defaultMessage: 'Full details of your Fine Art and Collection(s) items are included within your policy documentation.'
    },
    fineArtsMessageContent112: {
        id: 'renewal.bespoke.views.renewal-summary.fineArtsPerLocation.fineArtsMessageContent112',
        defaultMessage: 'Click here to view them'
    },
    fineArtsMessageContent211: {
        id: 'renewal.bespoke.views.renewal-summary.fineArtsPerLocation.fineArtsMessageContent211',
        defaultMessage: 'If you would like to discuss your fine art and collections with us please'
    },
    fineArtsMessageContent212: {
        id: 'renewal.bespoke.views.renewal-summary.fineArtsPerLocation.fineArtsMessageContent212',
        defaultMessage: ' contact your agency'
    },
    unspecifiedFineArt: {
        id: 'renewal.bespoke.views.renewal-summary.fineArtsPerLocation.unspecifiedFineArt',
        defaultMessage: 'Unspecified fine art'
    },
    unspecifiedSingleItemLimit: {
        id: 'renewal.bespoke.views.renewal-summary.fineArtsPerLocation.singleItemLimit',
        defaultMessage: 'Single item limit'
    },
    unspecifiedFineArtListsHeader: {
        id: 'renewal.bespoke.views.renewal-summary.fineArtsPerLocation.unspecifiedFineArtListsHeader',
        defaultMessage: 'Type'
    },
    unspecifiedFineArtPaintings: {
        id: 'renewal.bespoke.views.renewal-summary.fineArtsPerLocation.unspecifiedFineArtPaintings',
        defaultMessage: 'Pictures, paintings, drawings, etchings, prints and photographs'
    },
    unspecifiedFineArtTapsteries: {
        id: 'renewal.bespoke.views.renewal-summary.fineArtsPerLocation.unspecifiedFineArtTapsteries',
        defaultMessage: 'Tapsteries and rugs'
    },
    unspecifiedFineArtStatues: {
        id: 'renewal.bespoke.views.renewal-summary.fineArtsPerLocation.unspecifiedFineArtStatues',
        defaultMessage: 'Statues, sculptures, curios and objets d’art'
    },
    unspecifiedFineArtClocks: {
        id: 'renewal.bespoke.views.renewal-summary.fineArtsPerLocation.unspecifiedFineArtClocks',
        defaultMessage: 'Clocks, barometers, and mechanical art'
    },
    unspecifiedFineArtPreciousmetals: {
        id: 'renewal.bespoke.views.renewal-summary.fineArtsPerLocation.unspecifiedFineArtPreciousmetals',
        defaultMessage: 'Precious metals'
    },
    unspecifiedFineArtBooks: {
        id: 'renewal.bespoke.views.renewal-summary.fineArtsPerLocation.unspecifiedFineArtBooks',
        defaultMessage: 'Books and manuscripts'
    },
    unspecifiedFineArtPorcelain: {
        id: 'renewal.bespoke.views.renewal-summary.fineArtsPerLocation.unspecifiedFineArtPorcelain',
        defaultMessage: 'Porcelain, rare glass, and ceramics'
    },
    unspecifiedFineArtAntique: {
        id: 'renewal.bespoke.views.renewal-summary.fineArtsPerLocation.unspecifiedFineArtAntique',
        defaultMessage: 'Antique and collectable furniture'
    },
    specifiedFineArt: {
        id: 'renewal.bespoke.views.renewal-summary.fineArtsPerLocation.specifiedFineArt',
        defaultMessage: 'Specified fine art'
    },
    specifiedFineArtAgreedValue: {
        id: 'renewal.bespoke.views.renewal-summary.fineArtsPerLocation.specifiedFineArtAgreedValue',
        defaultMessage: 'Agreed value'
    },
    collection: {
        id: 'renewal.bespoke.views.renewal-summary.fineArtsPerLocation.collection',
        defaultMessage: 'Collection'
    },
    stampsMedalsCoinsType: {
        id: 'renewal.bespoke.views.renewal-summary.fineArtsPerLocation.stampsMedalsCoinsType',
        defaultMessage: 'Type'
    },
    stampsMedalsCoinsSingleItemLimit: {
        id: 'renewal.bespoke.views.renewal-summary.fineArtsPerLocation.stampsMedalsCoinsSingleItemLimit',
        defaultMessage: 'Single item limit'
    },
    stampsMedalsCoinsSumInsured: {
        id: 'renewal.bespoke.views.renewal-summary.fineArtsPerLocation.stampsMedalsCoinsSumInsured',
        defaultMessage: 'Sum insured'
    },
    stampsMedalsCoinsContainer: {
        id: 'renewal.bespoke.views.renewal-summary.fineArtsPerLocation.stampsMedalsCoinsTitle',
        defaultMessage: 'Stamps, Medals & Coin'
    },
    winesType: {
        id: 'renewal.bespoke.views.renewal-summary.fineArtsPerLocation.winesType',
        defaultMessage: 'Type'
    },
    winesSingleItemLimit: {
        id: 'renewal.bespoke.views.renewal-summary.fineArtsPerLocation.winesSingleItemLimit',
        defaultMessage: 'Single item limit'
    },
    winesSumInsured: {
        id: 'renewal.bespoke.views.renewal-summary.fineArtsPerLocation.winesSumInsured',
        defaultMessage: 'Sum insured'
    },
    winesContainer: {
        id: 'renewal.bespoke.views.renewal-summary.fineArtsPerLocation.winesTitle',
        defaultMessage: 'Wines'
    },
    othersType: {
        id: 'renewal.bespoke.views.renewal-summary.fineArtsPerLocation.othersType',
        defaultMessage: 'Type'
    },
    othersSingleItemLimit: {
        id: 'renewal.bespoke.views.renewal-summary.fineArtsPerLocation.othersSingleItemLimit',
        defaultMessage: 'Single item limit'
    },
    othersSumInsured: {
        id: 'renewal.bespoke.views.renewal-summary.fineArtsPerLocation.othersSumInsured',
        defaultMessage: 'Sum insured'
    },
    othersContainer: {
        id: 'renewal.bespoke.views.renewal-summary.fineArtsPerLocation.othersTitle',
        defaultMessage: 'Other'
    }
});
