import { defineMessages } from 'react-intl';

export default defineMessages({
    renewalReference: {
        id: 'headerBar.renewalReference',
        defaultMessage: 'Renewal reference: '
    },
    transactionReference: {
        id: 'headerBar.transactionReference',
        defaultMessage: 'Transaction reference: '
    },
    policyNumber: {
        id: 'headerBar.policyNumber',
        defaultMessage: 'Policy number: '
    }
});
