const fetchUserInfo = async (accessToken) => {
    const userInfo = await fetch(accessToken.userinfoUrl, {
        headers: {
            Authorization: `Bearer ${accessToken.accessToken}`,
        },
        method: 'GET',
    });
    return userInfo.json();
};

export default fetchUserInfo;
