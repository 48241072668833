import React, { useCallback, useContext } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import messages from './CaravanSummary.messages';
import metadata from './CaravanSummary.metadata.json5';
import styles from './CaravanSummary.module.scss';

function CaravanSummary(props) {
    const { value: caravans } = props;
    const translator = useContext(TranslatorContext);
    const caravanIds = Object.freeze({
        DAMAGE_COV: 'CVNCaravanDamageCov',
        VOLUNTARY: 'CVNCaravanDamageCovVoluntaryExcess',
        PERSONAL_BELONGINGS: 'CVNPersonalBelongingsCov',
        ALL_CLAIMS_XS_STANDARD: 'CVNCaravanDamageCovAllClaimsXSStandard',
        FIRE_XS_TOTAL: 'CVNCaravanDamageCovFireXSTotal',
        FLOOD_XS_TOTAL: 'CVNCaravanDamageCovFloodXSTotal',
        STORM_XS_TOTAL: 'CVNCaravanDamageCovStormXSTotal',
        THEFT_XS_TOTAL: 'CVNCaravanDamageCovTheftXSTotal',
        ACC_DAMAGE_XS_TOTAL: 'CVNCaravanDamageCovAccDamageXSTotal',
        IMPACT_XS_TOTAL: 'CVNCaravanDamageCovImpactXSTotal',
        VANDAL_XS_TOTAL: 'CVNCaravanDamageCovVandalXSTotal'
    });

    const resolveLossOfUseExtensionCoverValue = useCallback((caravan) => {
        switch (caravan.lossOfUseExtended) {
            case 'yes':
                return translator(messages.yes);
            case 'no':
                return translator(messages.notSelected);
            case 'yes_fixed_site_only':
                return translator(messages.yesFixedSiteOnly);
            default:
                return '';
        }
    }, [translator]);

    const resolveExcludedPerilsValue = useCallback((caravan) => {
        const perils = [];
        if (caravan.isFirePerilExcluded) {
            perils.push(translator(messages.fire));
        }
        if (caravan.isFloodPerilExcluded) {
            perils.push(translator(messages.flood));
        }
        if (caravan.isStormPerilExcluded) {
            perils.push(translator(messages.storm));
        }
        if (caravan.isTheftPerilExcluded) {
            perils.push(translator(messages.theft));
        }
        return perils.join(', ');
    }, [translator]);

    const getCaravanDamageTermValue = useCallback((caravan, termId) => {
        return caravan.coverages.coverages
            .find((cov) => cov.publicID === caravanIds.DAMAGE_COV)
            ?.terms?.find((term) => term.publicID === termId)?.chosenTermValue;
    }, [caravanIds]);

    const generateCaravanData = useCallback(() => {
        if (!caravans) {
            return {};
        }
        const caravanOverrides = caravans.map((caravan, index) => {
            const compulsoryAllClaimsExcessValue = getCaravanDamageTermValue(caravan,
                caravanIds.ALL_CLAIMS_XS_STANDARD);
            const fireExcessValue = getCaravanDamageTermValue(caravan, caravanIds.FIRE_XS_TOTAL);
            const floodExcessValue = getCaravanDamageTermValue(caravan, caravanIds.FLOOD_XS_TOTAL);
            const stormExcessValue = getCaravanDamageTermValue(caravan, caravanIds.STORM_XS_TOTAL);
            const theftExcessValue = getCaravanDamageTermValue(caravan, caravanIds.THEFT_XS_TOTAL);
            const accDamageExcessValue = getCaravanDamageTermValue(caravan,
                caravanIds.ACC_DAMAGE_XS_TOTAL);
            const impactExcessValue = getCaravanDamageTermValue(caravan,
                caravanIds.IMPACT_XS_TOTAL);
            const vandalExcessValue = getCaravanDamageTermValue(caravan,
                caravanIds.VANDAL_XS_TOTAL);
            return {
                [`caravanTitle${index}`]: {
                    content: `${caravan.make} ${caravan.model}`
                },
                [`caravanSerialNumber${index}`]: {
                    value: caravan.serialNumber,
                    visible: !!caravan.serialNumber
                },
                [`caravanVinNumber${index}`]: {
                    value: caravan.vinNumber,
                    visible: !!caravan.vinNumber
                },
                [`caravanPersonalBelongingsCover${index}`]: {
                    value: caravan.coverages.coverages
                        .find((cov) => cov.publicID === caravanIds.PERSONAL_BELONGINGS).selected
                        ? translator(messages.yes)
                        : translator(messages.notSelected)
                },
                [`caravanLossOfUseExtension${index}`]: {
                    value: resolveLossOfUseExtensionCoverValue(caravan)
                },
                [`caravanEuropeanCoverExtension${index}`]: {
                    value: caravan.isEuropeCoverExtended
                        ? translator(messages.yes)
                        : translator(messages.notSelected)
                },
                [`caravanExcludedPerils${index}`]: {
                    value: resolveExcludedPerilsValue(caravan),
                    visible: caravan.isFirePerilExcluded === true
                        || caravan.isFloodPerilExcluded === true
                        || caravan.isStormPerilExcluded === true
                        || caravan.isTheftPerilExcluded === true
                },
                [`caravanVoluntaryExcess${index}`]: {
                    value: `£${caravan.coverages.coverages
                        .find((cov) => cov.publicID === caravanIds.DAMAGE_COV)?.terms
                        ?.find((term) => term.publicID === caravanIds.VOLUNTARY)?.chosenTermValue}`
                },
                [`caravanCompulsoryAllClaimsExcess${index}`]: {
                    value: `£${compulsoryAllClaimsExcessValue}`
                },
                [`caravanFireExcess${index}`]: {
                    value: `£${fireExcessValue}`,
                    visible: compulsoryAllClaimsExcessValue !== fireExcessValue
                },
                [`caravanFloodExcess${index}`]: {
                    value: `£${floodExcessValue}`,
                    visible: compulsoryAllClaimsExcessValue !== floodExcessValue
                },
                [`caravanStormExcess${index}`]: {
                    value: `£${stormExcessValue}`,
                    visible: compulsoryAllClaimsExcessValue !== stormExcessValue
                },
                [`caravanTheftExcess${index}`]: {
                    value: `£${theftExcessValue}`,
                    visible: compulsoryAllClaimsExcessValue !== theftExcessValue
                },
                [`caravanAccidentalDamageExcess${index}`]: {
                    value: `£${accDamageExcessValue}`,
                    visible: compulsoryAllClaimsExcessValue !== accDamageExcessValue
                },
                [`caravanImpactExcess${index}`]: {
                    value: `£${impactExcessValue}`,
                    visible: compulsoryAllClaimsExcessValue !== impactExcessValue
                },
                [`caravanVandalismExcess${index}`]: {
                    value: `£${vandalExcessValue}`,
                    visible: compulsoryAllClaimsExcessValue !== vandalExcessValue
                }
            };
        });
        return Object.assign({}, ...caravanOverrides);
    }, [
        caravans,
        translator,
        resolveLossOfUseExtensionCoverValue,
        resolveExcludedPerilsValue,
        getCaravanDamageTermValue,
        caravanIds
    ]);

    const overrideProps = {
        '@field': {
            readOnly: true,
            labelPosition: 'left',
            className: 'riskObjectProperty',
            labelClassName: 'riskObjectPropertyLabel'
        },
        caravans: {
            data: caravans
        },
        ...generateCaravanData()
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
        />
    );
}

export default CaravanSummary;
