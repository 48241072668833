import React from 'react';
import _ from 'lodash';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import usePropertyTitle from '../../../hooks/usePropertyTitle';
import metadata from './FineArtsCollectionsSummary.metadata.json5';
import styles from './FineArtsCollectionsSummary.module.scss';
import FineArtsPerLocation from './FineArtsPerLocation/FineArtsPerLocation';

function FineArtsCollectionsSummary(props) {
    const {
        submissionVM,
        homePropertiesData,
        onClickSeeDocuments
    } = props;
    const fineArtTypes = {
        StampsMedalsCoins: 'StampsMedalsCoins',
        WineCollections: 'WineCollections',
        OtherCollections: 'OtherCollections',
        FineArt: 'FineArt'
    };
    const specFineArtType = {
        ObjectsDart: 'ObjectsDart',
        MechanicalArt: 'MechanicalArt',
        PreciousMetals: 'PreciousMetals',
    };
    const otherCollectionType = {
        CigaretteCards: 'CigaretteCards',
        BankNotes: 'BankNotes',
        PoliceInsignia: 'PoliceInsignia',
        TinSoldiers: 'TinSoldiers',
        VintageFountainPens: 'VintageFountainPens',
    };
    const fineArtsIds = {
        HOMFineArtCollectionsCov: 'HOMFineArtCollectionsCov',
        UnspecSingleItemLimit: 'HOMFineArtCollectionsCovUnspecSingleItemLimit',
        UnspAntqCltFurnSI: 'HOMFineArtCollectionsCovUnspAntqCltFurnSI',
        UnspecPicPntgsDetails: 'HOMFineArtCollectionsCovUnspecPicPntgsDetails',
        UnspecBooksManucriptsSI: 'HOMFineArtCollectionsCovUnspecBooksAndManucriptsSI',
        UnspStaSclCrosArtSI: 'HOMFineArtCollectionsCovUnspStaSclCrosArtSI',
        UnspecPrclnRareGlsCrmicSI: 'HOMFineArtCollectionsCovUnspecPrclnRareGlsCrmicSI',
        UnspecClckBrMtrMechArtSI: 'HOMFineArtCollectionsCovUnspecClckBrMtrMechArtSI',
        UnspecPreciousMetalSI: 'HOMFineArtCollectionsCovUnspecPreciousMetalSI',
        UnspecTepsAndRugsSI: 'HOMFineArtCollectionsCovUnspecTepsAndRugsSI',
        SpecifiedFineArtType: 'HOMFineArtCollectionsCovFineArtType',
        SpecItemIndvslExcess: 'HOMFineArtCollectionsCovSpecItemIndvslExcess',
    };
    const { getPropertyTitle } = usePropertyTitle();

    const fineArtsCovs = _.get(submissionVM, 'lobData.value.homeLine.coverables.homeProperties[0].coverages.fineArts', []);
    const coverages = _.get(submissionVM, 'lobData.value.homeLine.coverables.homeProperties[0].coverages.coverages');
    const FineArtCollCov = coverages.find((cov) => {
        return cov.publicID === fineArtsIds.HOMFineArtCollectionsCov;
    });
    const getFinArtTermLimitVal = (termPublicID, currSymbol = true) => {
        const chosenTermValueObj = FineArtCollCov?.terms
            ?.find((term) => term?.publicID === termPublicID);
        const chosenTermValue = chosenTermValueObj?.chosenTermValue;
        return currSymbol && chosenTermValue ? `£${chosenTermValue}` : undefined;
    };
    const genTypeDesc = (faCovsByType) => {
        if (faCovsByType.fineArtType === specFineArtType.ObjectsDart) {
            return `Objets d'art , ${faCovsByType?.description}`;
        }
        if (faCovsByType.fineArtType === specFineArtType.PreciousMetals) {
            return `Precious metals , ${faCovsByType?.description}`;
        }
        if (faCovsByType.fineArtType === specFineArtType.MechanicalArt) {
            return `Mechanical art , ${faCovsByType?.description}`;
        }
        return `${faCovsByType?.fineArtType}, ${faCovsByType?.description}`;
    };
    const genOtherCollType = (faCovsByType) => {
        if (faCovsByType.collectionType === otherCollectionType.CigaretteCards) {
            return 'Cigarette cards';
        }
        if (faCovsByType.collectionType === otherCollectionType.BankNotes) {
            return 'Bank notes';
        }
        if (faCovsByType.collectionType === otherCollectionType.PoliceInsignia) {
            return 'Police insignia';
        }
        if (faCovsByType.collectionType === otherCollectionType.TinSoldiers) {
            return 'Tin soldiers';
        }
        if (faCovsByType.collectionType === otherCollectionType.VintageFountainPens) {
            return 'Vintage fountain pens';
        }
        return faCovsByType.collectionType;
    };

    const getFineArtsCovsData = (fineArtType) => {
        const fineArtsCovsByType = fineArtsCovs.filter((fineArtCov) => {
            return fineArtCov.itemType === fineArtType;
        });
        if (fineArtType === fineArtTypes.FineArt && fineArtsCovsByType.length > 0) {
            const data = [];
            fineArtsCovsByType.forEach((faCovsByType) => {
                data.push({
                    typedesc: genTypeDesc(faCovsByType),
                    agreedvalue: faCovsByType.agreedValue ? `£${faCovsByType?.agreedValue}` : '£0'
                });
            });
            return data;
        }
        if (fineArtType === fineArtTypes.StampsMedalsCoins && fineArtsCovsByType.length > 0) {
            const data = [];
            fineArtsCovsByType.forEach((faCovsByType, index) => {
                data.push({
                    id: index,
                    stampsMedalsCoinsType: faCovsByType?.collectionType,
                    stampsMedalsCoinsSingleItemLimit: `£${faCovsByType?.singleItemLimit}`,
                    stampsMedalsCoinsSumInsured: `£${faCovsByType?.sumInsured}`
                });
            });
            return data;
        }
        if (fineArtType === fineArtTypes.WineCollections && fineArtsCovsByType.length > 0) {
            const data = [];
            fineArtsCovsByType.forEach((faCovsByType, index) => {
                data.push({
                    id: index,
                    winesType: 'Wine',
                    winesSingleItemLimit: `£${faCovsByType.singleItemLimit}`,
                    winesSumInsured: `£${faCovsByType.sumInsured}`,
                });
            });
            return data;
        }
        if (fineArtType === fineArtTypes.OtherCollections && fineArtsCovsByType.length > 0) {
            const data = [];
            fineArtsCovsByType.forEach((faCovsByType, index) => {
                data.push({
                    id: index,
                    othersType: genOtherCollType(faCovsByType),
                    othersSingleItemLimit: `£${faCovsByType.singleItemLimit}`,
                    othersSumInsured: `£${faCovsByType.sumInsured}`,
                });
            });
            return data;
        }
        return [];
    };
    const specifiedData = getFineArtsCovsData(fineArtTypes.FineArt);
    const stampdata = getFineArtsCovsData(fineArtTypes.StampsMedalsCoins);
    const winesData = getFineArtsCovsData(fineArtTypes.WineCollections);
    const othersData = getFineArtsCovsData(fineArtTypes.OtherCollections);

    const unspecSingleItemLimit = getFinArtTermLimitVal(fineArtsIds.UnspecSingleItemLimit);
    const unspAntqCltFurnSI = getFinArtTermLimitVal(fineArtsIds.UnspAntqCltFurnSI);
    const unspecPicPntgsDetails = getFinArtTermLimitVal(fineArtsIds.UnspecPicPntgsDetails);
    const unspecBooksAndManucriptsSI = getFinArtTermLimitVal(fineArtsIds.UnspecBooksManucriptsSI);
    const unspStaSclCrosArtSI = getFinArtTermLimitVal(fineArtsIds.UnspStaSclCrosArtSI);
    const unspecPrclnRareGlsCrmicSI = getFinArtTermLimitVal(fineArtsIds.UnspecPrclnRareGlsCrmicSI);
    const unspecClckBrMtrMechArtSI = getFinArtTermLimitVal(fineArtsIds.UnspecClckBrMtrMechArtSI);
    const unspecPreciousMetalSI = getFinArtTermLimitVal(fineArtsIds.UnspecPreciousMetalSI);
    const unspecTepsAndRugsSI = getFinArtTermLimitVal(fineArtsIds.UnspecTepsAndRugsSI);
    const unSpecifiedFineArtData = {
        unspecSingleItemLimit,
        unspAntqCltFurnSI,
        unspecPicPntgsDetails,
        unspecBooksAndManucriptsSI,
        unspStaSclCrosArtSI,
        unspecPrclnRareGlsCrmicSI,
        unspecClckBrMtrMechArtSI,
        unspecPreciousMetalSI,
        unspecTepsAndRugsSI,
    };
    const homeProperties = homePropertiesData.map((homeProperty) => {
        return getPropertyTitle(homeProperty, true);
    });
    const fineArtsHomePropertiesData = [
        {
            propertyTitle: homeProperties[0],
            unSpecifiedFineArtData,
            specifiedData,
            winesData,
            othersData,
            stampdata,
            onClickSeeDocuments
        },
    ];
    const overrideProps = {
        fineArtsHomeProperties: {
            data: fineArtsHomePropertiesData
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentsMap: {
            fineArtsPerLocation: FineArtsPerLocation
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentsMap}
        />
    );
}

export default React.memo(FineArtsCollectionsSummary);
