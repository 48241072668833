import React, { useCallback, useContext } from 'react';
import _ from 'lodash';
import {
    ViewModelForm,
    ViewModelServiceContext
} from 'gw-portals-viewmodel-react';
import { wizardProps } from 'gw-portals-wizard-react';
import { useModal } from '@jutro/components';
import { EntityUtil } from 'gw-portals-util-js';
import { useCurrency } from 'nfum-portals-utils-react';
import { TranslatorContext } from '@jutro/locale';
import { NfumConfirmModal } from 'nfum-components-platform-react';
import HighValueBicyclesModal from '../HighValueBicyclesModal/HighValueBicyclesModal';
import metadata from './HighValueBicycles.metadata.json5';
import styles from './HighValueBicycles.module.scss';
import messages from './HighValueBicycles.messages';

const mapHighValueBicycleToDTO = (itemData, { publicID, tempID, itemNumber } = {}) => {
    const dto = {
        '@deserialization-class': 'edge.capabilities.policycommon.coverage.schedule.dto.patterns.SimpleScheduledItemDTO',
        itemData: {
            ArticleExcessType: {
                typeCodeValue: 'All Claims'
            },
            ArticleKeptinBank: {},
            ArticleValuationMethod: {
                typeCodeValue: 'Bicycle'
            },
            ArticleGeographicLimit: {},
            ArticleDeductible: {
                bigDecimal: 0.0000
            },
            ArticleType: {
                typeCodeValue: itemData.description
            },
            ArticleLimit: {
                bigDecimal: Number(`${itemData.replacementCost}`.replaceAll(',', ''))
            }
        }
    };

    dto.tempID = tempID || `bike-${EntityUtil.nextId()}`;

    if (publicID) {
        dto.itemData.PublicID = {
            typeCodeValue: publicID
        };
    }

    if (itemNumber) {
        dto.itemNumber = itemNumber;
    }

    return dto;
};

function HighValueBicycles(props) {
    const translator = useContext(TranslatorContext);
    const { showModal } = useModal();
    const currencyFormatter = useCurrency();
    const {
        wizardData: submissionVM,
        updateWizardData,
        paths,
        hideLimitsReferral,
        isDisabled
    } = props;
    const {
        highValueItemsPath,
        replacementCostPath,
        highValueBicyclesPath
    } = paths;
    const viewModelService = useContext(ViewModelServiceContext);

    const mapHighValueBicycleFromDTO = useCallback((itemData) => ({
        type: itemData.ArticleValuationMethod.typeCodeValue,
        description: itemData.ArticleType.typeCodeValue,
        replacementCost: currencyFormatter
            .formatCurrency(`${itemData.ArticleLimit.bigDecimal}`.replaceAll(',', ''), true, false)
    }), [currencyFormatter]);

    const unspecifiedPedalCyclesLimit = currencyFormatter
        .formatCurrency(_.get(submissionVM, 'lobData.value.homeLine.lineCoverages.unspecifiedPedalCyclesLimit'), false, false);

    const isItemReplacementCostAboveTheLimit = useCallback((replacementCost) => {
        const limit = Number(_.get(submissionVM, `${replacementCostPath}.chosenTermValue`).replace(',', ''));
        return !!limit && Number(replacementCost) > limit;
    }, [submissionVM, replacementCostPath]);

    const openHighValueBicyclesModal = useCallback(async (items = []) => {
        const { type, data: formData } = await showModal(
            <HighValueBicyclesModal
                wizardData={submissionVM}
                isItemReplacementCostAboveTheLimit={isItemReplacementCostAboveTheLimit}
                unspecifiedPedalCyclesLimit={_.get(submissionVM, 'lobData.value.homeLine.lineCoverages.unspecifiedPedalCyclesLimit')}
            />
        );

        items.push(formData);

        if (type === 'CANCEL') {
            return [];
        }

        if (type === 'REOPEN') {
            await openHighValueBicyclesModal(items);
        }

        const newItems = items.filter((item) => !_.isUndefined(item));
        return newItems;
    }, [submissionVM, isItemReplacementCostAboveTheLimit, showModal]);

    const addBicycle = useCallback(async () => {
        hideLimitsReferral();
        const formData = await openHighValueBicyclesModal();
        const scheduleItems = _.get(submissionVM, `${highValueItemsPath}.scheduleItems`) || [];
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM, `${highValueItemsPath}.scheduleItems`,
            [...scheduleItems, ...formData.map((item) => mapHighValueBicycleToDTO(item))]);
        updateWizardData(newSubmissionVM);
    }, [
        submissionVM,
        updateWizardData,
        highValueItemsPath,
        openHighValueBicyclesModal,
        viewModelService,
        hideLimitsReferral
    ]);

    const editBicycle = useCallback(async (lobPath, bicycle) => {
        hideLimitsReferral();
        const initialFormData = mapHighValueBicycleFromDTO(bicycle.itemData);
        const { type, data: formData } = await showModal(
            <HighValueBicyclesModal
                isEditMode
                initialFormData={initialFormData}
                isItemReplacementCostAboveTheLimit={() => false}
                saveChangesButtonText={messages.saveChanges}
                unspecifiedPedalCyclesLimit={_.get(submissionVM, 'lobData.value.homeLine.lineCoverages.unspecifiedPedalCyclesLimit')}
            />
        );

        if (type === 'CANCEL') {
            return;
        }

        const scheduleItems = _.get(submissionVM, lobPath);
        const index = _.get(submissionVM, lobPath).findIndex((item) => {
            return item?.itemData?.PublicID?.typeCodeValue
                ? item?.itemData?.PublicID?.typeCodeValue
                    === bicycle?.itemData?.PublicID?.typeCodeValue
                : item?.tempID === bicycle?.tempID;
        });
        scheduleItems[index] = mapHighValueBicycleToDTO(
            _.merge(initialFormData, formData),
            {
                publicID: bicycle.itemData?.PublicID?.typeCodeValue,
                tempID: bicycle.tempID,
                itemNumber: bicycle.itemNumber
            }
        );
        _.set(submissionVM, lobPath, scheduleItems);
        updateWizardData(submissionVM);

        if (type === 'REOPEN') {
            addBicycle();
        }
    }, [
        submissionVM,
        addBicycle,
        updateWizardData,
        hideLimitsReferral,
        mapHighValueBicycleFromDTO,
        showModal
    ]);

    const removeBicycle = useCallback(async (lobPath, publicID, tempID) => {
        hideLimitsReferral();
        const { type } = await showModal(
            <NfumConfirmModal variant="variant3" />
        );
        if (type === 'CANCEL') {
            return;
        }
        const newScheduleItems = _.get(submissionVM, lobPath)
            .filter((item) => {
                return publicID
                    ? item?.itemData?.PublicID?.typeCodeValue !== publicID
                    : item?.tempID !== tempID;
            });
        _.set(submissionVM, lobPath, newScheduleItems);
        updateWizardData(submissionVM);
    }, [
        submissionVM,
        updateWizardData,
        hideLimitsReferral,
        showModal
    ]);

    const getBicycles = useCallback(() => {
        const lobScheduleItems = _.get(submissionVM, `${highValueBicyclesPath}.scheduleItems`);
        if (lobScheduleItems) {
            return lobScheduleItems
                .filter(({ itemData }) => _.isEmpty(itemData.ArticleGeographicLimit));
        }
        return [];
    }, [submissionVM, highValueBicyclesPath]);

    const generateBicyclesOverrides = useCallback(() => {
        const lobPath = `${highValueBicyclesPath}.scheduleItems`;
        const bicycles = getBicycles();
        const covOverrides = bicycles.map((changedField, index) => {
            return {
                [`valuableName${index}`]: {
                    content: changedField.itemData.ArticleType.typeCodeValue
                },
                [`bicycleCategory${index}`]: {
                    value: translator(messages.bicycle)
                },
                [`bicycleReplacementCost${index}`]: {
                    value: currencyFormatter
                        .formatCurrency(changedField.itemData.ArticleLimit.bigDecimal, true)
                },
                [`editButton${index}`]: {
                    disabled: isDisabled,
                    onClick: () => editBicycle(lobPath, changedField)
                },
                [`deleteButton${index}`]: {
                    disabled: isDisabled,
                    onClick: () => removeBicycle(lobPath,
                        changedField.itemData?.PublicID?.typeCodeValue,
                        changedField?.tempID)
                }
            };
        });
        return Object.assign({}, ...covOverrides);
    }, [
        highValueBicyclesPath,
        getBicycles,
        editBicycle,
        removeBicycle,
        currencyFormatter,
        translator,
        isDisabled
    ]);

    const overrideProps = {
        bicycles: {
            data: getBicycles(),
            visible: getBicycles().length > 0
        },
        highValueBicycleValue: {
            content: unspecifiedPedalCyclesLimit
        },
        bicycleWorthValue: {
            content: unspecifiedPedalCyclesLimit
        },
        addButton: {
            onClick: addBicycle,
            disabled: isDisabled
        },
        ...generateBicyclesOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            model={submissionVM}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

HighValueBicycles.propTypes = wizardProps;
export default HighValueBicycles;
