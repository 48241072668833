const tokenStorage = {};

/**
 * Returns the token of a given key
 *
 * @param {string} key - token key
 * @returns {string} - token
 */
export const getTokenByKey = (key) => tokenStorage[key];

/**
 * Store the token of a given key
 *
 * @param {string} key - token key
 * @param {string} token - token
 */
export const setToken = (key, token) => {
    tokenStorage[key] = token;
};

/**
 * Remove the expired token
 *
 * @param {string} key - token key
 */
export const removeTokenByKey = (key) => {
    delete tokenStorage[key];
};

/**
 * Return the current access token
 *
 * @returns {string} - the current access token
 */
export const getAccessToken = () => getTokenByKey('accessToken');

/**
 * Returns the current user idToken
 *
 * @returns {string} - current user idToken
 */
export const getIdToken = () => getTokenByKey('idToken');

export const clear = () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const propName of Object.getOwnPropertyNames(tokenStorage)) {
        delete tokenStorage[propName];
    }
};
