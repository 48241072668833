import { defineMessages } from 'react-intl';

export default defineMessages({
    singleArticleLimitValidationMessage: {
        id: 'endorsement.ngh.views.valuables.singleArticleLimitValidationMessage',
        defaultMessage: 'Only items valued more than { limit } need to be declared'
    },
    valuablesModalTitle: {
        id: 'endorsement.ngh.views.valuables.valuablesModalTitle',
        defaultMessage: 'Valuables (excluding bicycles)'
    },
    valuablesModalTerms: {
        id: 'endorsement.ngh.views.valuables.valuablesModalTerms',
        defaultMessage: "We use the term 'valuables' to cover jewellery, medals, coins and works of art as valuables * (Full list here). Think about what's in your drawers and cupboards then estimate how much it would to cost replace all these types of items. We're interested in items worth more than £x,000."
    },
    valuableType: {
        id: 'endorsement.ngh.views.valuables.valuableType',
        defaultMessage: 'Valuable type'
    },
    valuableTypeTooltip: {
        id: 'endorsement.ngh.views.valuables.valuableType.tooltip',
        defaultMessage: 'This is an informational tooltip'
    },
    valuableDescription: {
        id: 'endorsement.ngh.views.valuables.valuableDescription',
        defaultMessage: 'Description of the item'
    },
    valuableDescriptionTooltip: {
        id: 'endorsement.ngh.views.valuables.valuableDescription.tooltip',
        defaultMessage: 'This is an informational tooltip'
    },
    valuableReplacementCost: {
        id: 'endorsement.ngh.views.valuables.valuableReplacementCost',
        defaultMessage: 'Replacement cost'
    },
    valuableReplacementCostTootltip: {
        id: 'endorsement.ngh.views.valuables.valuableReplacementCost.tooltip',
        defaultMessage: 'This is an informational tooltip'
    },
    valuableCoverType: {
        id: 'endorsement.ngh.views.valuables.valuableCoverType',
        defaultMessage: 'Select cover type'
    },
    valuableCoverTypeTooltip: {
        id: 'endorsement.ngh.views.valuables.valuableCoverType.tooltip',
        defaultMessage: 'This is an informational tooltip'
    },
    insideOnly: {
        id: 'endorsement.ngh.views.valuables.valuableCoverType.insideOnly',
        defaultMessage: 'Inside the home only'
    },
    insideOutside: {
        id: 'endorsement.ngh.views.valuables.valuableCoverType.insideOutside',
        defaultMessage: 'Both inside and outside the home'
    },
    addItem: {
        id: 'endorsement.ngh.views.valuablesModal.addItem',
        defaultMessage: 'Add item'
    },
    cancel: {
        id: 'endorsement.ngh.views.valuablesModal.cancel',
        defaultMessage: 'Cancel'
    },
    saveAndAdd: {
        id: 'endorsement.ngh.views.valuablesModal.saveAndAdd',
        defaultMessage: 'Save and add another'
    },
    valuableStorageType: {
        id: 'endorsement.ngh.views.valuablesModal.valuableStorageType',
        defaultMessage: 'Is item kept in bank?'
    },
    yes: {
        id: 'endorsement.ngh.views.valuablesModal.yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'endorsement.ngh.views.valuablesModal.no',
        defaultMessage: 'No'
    },
    insideHomeOnly: {
        id: 'endorsement.ngh.views.valuablesModal.insideHomeOnly',
        defaultMessage: 'Inside the home only'
    },
    bothInsideOutsideHome: {
        id: 'endorsement.ngh.views.valuablesModal.bothInsideOutsideHome',
        defaultMessage: 'Both inside and outside the home'
    },
    maxLength: {
        id: 'endorsement.ngh.views.valuablesModal.maxLength',
        defaultMessage: 'You have reached maximum allowed field length'
    },
    formatNotValid: {
        id: 'endorsement.ngh.views.valuablesModal.formatNotValid',
        defaultMessage: 'Please enter only numerical values'
    },
    valuableDescTooltip: {
        id: 'endorsement.ngh.views.valuablesModal.valuableDescTooltip',
        defaultMessage: 'Please provide as much detail as possible here including make and model of the item you wish to insure. An accurate and specific physical description helps us with claims promptly.'
    },
    replaementCostTooltip: {
        id: 'endorsement.ngh.views.valuablesModal.replaementCostTooltip',
        defaultMessage: 'Remember this is the cost to replace the item new rather than its current value.'
    },
    storageTypeTooltip: {
        id: 'endorsement.ngh.views.valuablesModal.storageTypeTooltip',
        defaultMessage: 'We need to know if the valuable is kept in a bank'
    },
    fullListHere: {
        id: 'endorsement.ngh.views.valuablesModal.fullListHere',
        defaultMessage: 'Full list here'
    }
});
