const colorVars = {
    '--GW-FOCUS-COLOR-LIGHT': 'var(--NFUM-COLOR-GREY)',
    '--GW-BUTTON-OUTLINED-COLOR': 'var(--NFUM-COLOR-GREY)',
    '--GW-BUTTON-OUTLINED-BORDER-COLOR': '#FFCB05',
    '--GW-BUTTON-OUTLINED-COLOR-HOVER': '#ffffff',
    '--GW-BUTTON-OUTLINED-BACKGROUND-COLOR-HOVER': 'var(--NFUM-COLOR-GREY)',
    '--GW-BUTTON-FILLED-COLOR-HOVER': '#ffffff',
    '--GW-BUTTON-FILLED-BACKGROUND-COLOR-HOVER': 'var(--NFUM-COLOR-GREY)',
    '--GW-BUTTON-TEXT-COLOR': 'var(--NFUM-COLOR-GREY)',
    '--GW-DATA-TABLE-ROW-EVEN-BACKGROUND-COLOR': 'var(--NFUM-COLOR-WHITE)',

    // NFU Main
    '--NFUM-BODY-BACKGROUND-COLOR': '#EDEDED',
    '--NFUM-COLOR-YELLOW-MAIN': '#FFCB05',
    '--NFUM-COLOR-GREY': '#2d2c2c',

    '--NFUM-COLOR-WHITE': '#ffffff',
    '--NFUM-BORDER-BACKGROUND-COLOR': '#E1E1E1',
    '--NFUM-BORDER-LEFT-COLOR': '#FFCB05',
    '--NFUM-COLOR-PRIMARY': '#EDEDED',
    '--NFUM-FONT-COLOR-PRIMARY': '#606060',
    '--NFUM-FONT-COLOR-SECONDARY': '#AAAAAA',
    '--NFUM-BODY-BACKGROUND-COLOR-1': '#FFF5D1',
    '--NFUM-COLOR-ERROR': '#DE332C',
    '--NFUM-COLOR-FIELD-DISABLED': '#F1F1F1',
    '--NFUM-COLOR-LIGHT-GREY': '#DDDDDD',
    '--NFUM-TABLE-HEADER-BACKGROUND-COLOR': '#F1F1F1',
    '--NFUM-BORDER-BOTTOM-COLOR-GREY': '#757575',
    '--NFUM-COLOR-DARK': '#000000',
    '--NFUM-COLOR-TABLE-BORDERS-GREY': '#757575'
};

export default colorVars;
