import React, { useCallback, useContext } from 'react';
import {
    ViewModelForm
} from 'gw-portals-viewmodel-react';
import _ from 'lodash';
import { wizardProps } from 'gw-portals-wizard-react';
import { useCurrency } from 'nfum-portals-utils-react';
import { TranslatorContext } from '@jutro/locale';
import metadata from './FineArtsItem.metadata.json5';
import styles from './FineArtsItem.module.scss';
import messages from './FineArtsItem.messages';

function FineArtsItem(props) { /* NOSONAR: pure declarative usage  */
    const {
        fineArtVM,
        onRemoveItem,
        onEditItem,
        isExisting = false
    } = props;
    const currencyFormatter = useCurrency();
    const translator = useContext(TranslatorContext);
    const category = Object.freeze({
        FINE_ART: 'FineArt',
        WINES: 'WineCollections',
        STAMPS_MEDALS_COINS: 'StampsMedalsCoins',
        OTHER_COLLECTIONS: 'OtherCollections'
    });
    const OTHER_TYPE = 'Other';
    const isAgreedValue = [category.FINE_ART].includes(_.get(fineArtVM.value, 'itemType'));

    const getHeaderTitle = useCallback(() => {
        const itemType = _.get(fineArtVM.value, 'itemType');
        if (itemType === category.FINE_ART) {
            return translator(messages.fineArts);
        }
        if (itemType === category.WINES) {
            return translator(messages.wineCollection);
        }
        if (itemType === category.STAMPS_MEDALS_COINS) {
            return translator(messages.stampsMedalsAndCoins);
        }
        return translator(messages.otherCollections);
    }, [fineArtVM, category, translator]);

    const getItemFineArtType = useCallback((type) => {
        return translator({
            id: `typekey.FineArtType_NFUM.${type}`,
            defaultMessage: type
        });
    }, [translator]);

    const getItemTypeOfCollection = useCallback((type) => {
        return translator({
            id: `typekey.TypeOfCollection_NFUM.${type}`,
            defaultMessage: type
        });
    }, [translator]);

    const getHeaderDescription = useCallback(() => {
        const itemType = _.get(fineArtVM.value, 'itemType');
        if (itemType === category.FINE_ART) {
            return `${getItemFineArtType(fineArtVM.value.fineArtType)}, ${fineArtVM.value.description}`;
        }
        if (itemType === category.WINES) {
            return translator(messages.wineCollection);
        }
        return `${getItemTypeOfCollection(fineArtVM.value.collectionType)}`;
    }, [
        fineArtVM,
        category,
        getItemFineArtType,
        getItemTypeOfCollection,
        translator
    ]);

    const overrideProps = {
        title: {
            content: getHeaderTitle()
        },
        existingAgreedOrSumInsured: {
            path: isAgreedValue ? 'agreedValue' : 'sumInsured',
            value: isAgreedValue
                ? currencyFormatter.formatCurrency(`${_.get(fineArtVM.value, 'agreedValue')}`, true)
                : currencyFormatter.formatCurrency(`${_.get(fineArtVM.value, 'sumInsured')}`, true),
            visible: isExisting,
            label: isAgreedValue ?
                translator(messages.agreedValue) : translator(messages.sumInsured)
        },
        editButton: {
            onClick: () => onEditItem(fineArtVM)
        },
        deleteButton: {
            onClick: () => onRemoveItem(fineArtVM)
        },
        category: {
            visible: false
        },
        headerDescription: {
            content: getHeaderDescription()
        },
        type: {
            visible: false
        },
        collectionType: {
            visible: false
        },
        sumInsured: {
            visible: !isExisting && [category.OTHER_COLLECTIONS, category.WINES, category.STAMPS_MEDALS_COINS].includes(_.get(fineArtVM.value, 'itemType')),
            value: currencyFormatter.formatCurrency(`${_.get(fineArtVM.value, 'sumInsured')}`, true),
        },
        collectionKept: {
            visible: !isExisting && [category.STAMPS_MEDALS_COINS].includes(_.get(fineArtVM.value, 'itemType'))
        },
        collectionDescription: {
            visible: _.get(fineArtVM.value, 'itemType') === category.OTHER_COLLECTIONS
                && _.get(fineArtVM.value, 'collectionType') === OTHER_TYPE
        },
        valuationDate: {
            visible: !isExisting && [category.FINE_ART, category.WINES, category.STAMPS_MEDALS_COINS].includes(_.get(fineArtVM.value, 'itemType'))
                && _.get(fineArtVM.value, 'valuationDate') !== undefined
        },
        agreedValue: {
            visible: !isExisting && [category.FINE_ART].includes(_.get(fineArtVM.value, 'itemType'))
                && _.get(fineArtVM.value, 'agreedValue') !== undefined,
            value: currencyFormatter.formatCurrency(`${_.get(fineArtVM.value, 'agreedValue')}`, true),
        },
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            model={fineArtVM}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

FineArtsItem.propTypes = wizardProps;
export default FineArtsItem;
