import { defineMessages } from 'react-intl';

export default defineMessages({
    itemsAdded: {
        id: 'endorsement.ngh.views.policy-change-summary.valuables.itemsAdded',
        defaultMessage: 'Items added'
    },
    itemsChanged: {
        id: 'endorsement.ngh.views.policy-change-summary.valuables.itemsChanged',
        defaultMessage: 'Items changed'
    },
    itemsRemoved: {
        id: 'endorsement.ngh.views.policy-change-summary.valuables.itemsRemoved',
        defaultMessage: 'Items removed'
    },
    category: {
        id: 'endorsement.ngh.views.policy-change-summary.valuables.category',
        defaultMessage: 'Category'
    },
    replacementCost: {
        id: 'endorsement.ngh.views.policy-change-summary.valuables.replacementCost',
        defaultMessage: 'Replacement cost'
    },
    coverType: {
        id: 'endorsement.ngh.views.policy-change-summary.valuables.coverType',
        defaultMessage: 'Cover type'
    },
    keptInBank: {
        id: 'endorsement.ngh.views.policy-change-summary.valuables.keptInBank',
        defaultMessage: 'Kept in bank?'
    },
    yes: {
        id: 'endorsement.ngh.views.policy-change-summary.valuables.yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'endorsement.ngh.views.policy-change-summary.valuables.no',
        defaultMessage: 'No'
    },
    insideHomeOnly: {
        id: 'endorsement.ngh.views.policy-change-summary.valuables.insideHomeOnly',
        defaultMessage: 'Inside the home only'
    },
    insideOutsideHome: {
        id: 'endorsement.ngh.views.policy-change-summary.valuables.insideOutsideHome',
        defaultMessage: 'Both inside and outside the home (UK)'
    }
});
