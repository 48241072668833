/* eslint-disable no-param-reassign */
import _ from 'lodash';

const nameConfig = {
    formatMode: 'default',
    mode: 'default',
    visibleFields: ['Suffix', 'FirstName', 'Prefix', 'LastName', 'Middlename', 'Name']
};

function append(sb, fieldId, value) {
    if (!_.isEmpty(value) && _.includes(nameConfig.visibleFields, fieldId)) {
        sb.push(value);
    }
}

function format(person, delimiter) {
    const lastName = person.lastNameKanji || person.lastName;
    const firstName = person.firstNameKanji || person.firstName;
    const result = [];
    switch (nameConfig.formatMode) {
        case 'default':
            append(result, 'FirstName', firstName);
            append(result, 'LastName', lastName);

            break;
        case 'France':
            append(result, 'LastName', lastName);
            append(result, 'FirstName', firstName);
            break;
        case 'Japan':// NOSONAR: GW OOTB internal mechanism
            append(result, 'LastName', lastName);
            append(result, 'FirstName', firstName);
            break;
        case 'China':// NOSONAR: GW OOTB internal mechanism
            append(result, 'LastName', lastName);
            append(result, 'FirstName', firstName);
            break;
        default:
            break;
    }
    return result.join(delimiter);
}

function Driver(data) {
    if (data) {
        _.extend(this, data);
        if (!data.dateOfBirth) {
            data.dateOfBirth = null;
        }
    } else {
        this.person = {};
    }
}

Driver.prototype.getDisplayName = function (defaultName) {
    return format(this.person, ' ') || defaultName;
};

Driver.prototype.getDriverStatesForCountry = (states, countryCode) => {
    return _.filter(states, (state) => {
        const countryConstraint = _.find(state.categories, (constraint) => {
            return _.keys(constraint).indexOf('Country') !== -1;
        });
        if (countryConstraint) {
            return countryConstraint.Country === countryCode;
        }
        return false;
    });
};

export default Driver;
