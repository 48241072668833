import React, {
    useEffect, useState, useContext, useCallback
} from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import PropTypes from 'prop-types';
import { NfumLoader } from 'nfum-components-platform-react';
import { TranslatorContext } from '@jutro/locale';
import { useAuthentication } from 'gw-digital-auth-react';
import { PolicyService } from 'gw-capability-policy';
import { DocumentService } from 'gw-capability-policydocument';
import {
    PolicyCommonDetails,
    PolicyCommonDocuments,
    PolicyCommonContacts,
    PolicyCoverages
} from 'gw-capability-policy-common-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './PolicyDetails.metadata.json5';
import messages from './PolicyDetails.messages';
import styles from './PolicyDetails.module.scss';

const handlePrint = () => {
    window.print();
};

function PolicyDetails(props) {
    const { history, match } = props;
    const { authHeader } = useAuthentication();
    const [policyCommonDetails, updatePolicyCommonDetails] = useState({
        currentPeriodDetails: {},
        renewedPeriodDetails: {},
        hasRenewedPeriod: false,
        isPaymentStatusUnsuccessful: false
    });
    const [writableDocuments, updateWritableDocuments] = useState([]);
    const [loading, setLoading] = useState(true);
    const translator = useContext(TranslatorContext);
    const policyNumber = _.get(match, 'params.policyNumber');

    const getPolicyDetails = useCallback(async () => {
        const policyDetails = await PolicyService.getAccountPolicyDetails(policyNumber, authHeader);
        updatePolicyCommonDetails({
            currentPeriodDetails: policyDetails.currentPeriod,
            renewedPeriodDetails: policyDetails.renewingPeriod ? policyDetails.renewingPeriod : {},
            hasRenewedPeriod: !_.isEmpty(policyDetails.renewingPeriod),
            isPaymentStatusUnsuccessful: policyDetails.isPaymentStatusUnsuccessful
        });
        setLoading(false);
    }, [policyNumber, authHeader]);

    const redirectToRenewal = useCallback(
        (policyDetails, requestType) => {
            if (policyCommonDetails.isPaymentStatusUnsuccessful) {
                history.push(`/service-unavailable?policyNumber=${policyNumber}`);
            } else {
                history.push(`/renewal/${policyNumber}`, {
                    policyNumber, policyDetails, requestType
                });
            }
        },
        [history, policyNumber]
    );

    const getPolicyDownloadLink = useCallback(() => {
        return DocumentService.downloadPolicyDocument(
            _.get(policyCommonDetails.currentPeriodDetails, 'idCardPublicID'),
            _.get(policyCommonDetails.currentPeriodDetails, 'idCardSessionID')
        );
    }, [policyCommonDetails]);

    useEffect(
        () => {
            try {
                getPolicyDetails();
            } catch (e) {
                throw new Error(e);
            }
        },
        // Only need to run once when initial page render and when documents are added/removed
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [writableDocuments.length]
    );

    const overrideProps = {
        pageTitle: {
            content: translator(messages.pageTitle, {
                policyNumber: policyCommonDetails.currentPeriodDetails.policyNumber
            })
        },
        currentPeriod: {
            heading: translator(messages.currentTab)
        },
        renewalPeriod: {
            heading: policyCommonDetails.hasRenewedPeriod ? translator(messages.renewalTab) : '',
            disabled: !policyCommonDetails.hasRenewedPeriod
        },
        policyChangeButtonContainer: {
            visible: true
        },
        renewalPolicyChangeButtonContainer: {
            visible: true
        },
        idCardContainer: {
            visible: !_.isEmpty(_.get(policyCommonDetails.currentPeriodDetails, 'idCardPublicID'))
        },
        idCard: {
            href: getPolicyDownloadLink(),
            target: '_blank'
        },
        policyDocumentsSection: {
            updateWritableDocuments: updateWritableDocuments,
            writableDocuments: writableDocuments
        },
        changePolicyButtonId: {
            visible: policyCommonDetails.currentPeriodDetails.isBespokeHomeInsurance_NFUM !== true
        },
        bespokeChangePolicyButtonId: {
            visible: policyCommonDetails.currentPeriodDetails.isBespokeHomeInsurance_NFUM === true
        },
        bespokeCoveragesChangePolicyButtonId: {
            visible: policyCommonDetails.currentPeriodDetails.isBespokeHomeInsurance_NFUM === true
        }
    };

    const resolvers = {
        resolveComponentMap: {
            policycommondetailscomponent: PolicyCommonDetails,
            policycoveragescomponent: PolicyCoverages,
            policycommondocumentscomponent: PolicyCommonDocuments,
            policycommoncontactscomponent: PolicyCommonContacts
        },
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onPrint: handlePrint,
            onChangePolicy: () => {
                history.push('/endorsement-ngh', { policyNumber });
            },
            onChangeBespokePolicy: () => {
                history.push('/endorsement-bespoke-ngh', { policyNumber });
            },
            onChangeBespokePolicyCoverages: () => {
                history.push('/endorsement-coverages-bespoke-ngh', { policyNumber });
            },
            onChangePolicyRenewal: () => redirectToRenewal(
                policyCommonDetails.renewedPeriodDetails,
                'policyRenew'
            )
        }
    };

    return (
        <NfumLoader loaded={!loading}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyCommonDetails}
                overrideProps={overrideProps}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </NfumLoader>
    );
}
PolicyDetails.propTypes = {
    match: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    location: PropTypes.shape({
        productCode: PropTypes.string
    }).isRequired
};

export default withRouter(PolicyDetails);
