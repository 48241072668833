import React, {
    useCallback, useState, useMemo
} from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { UWBlockingPoint } from 'gw-portals-edge-validation-js';
import { useDependencies } from 'gw-portals-dependency-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './UnderwritingIssues.metadata.json5';
import styles from './UnderwritingIssues.module.scss';
import messages from './UnderwritingIssues.messages';

const BLOCKS_QUOTES = [UWBlockingPoint.BLOCKS_QUOTE, UWBlockingPoint.BLOCKS_QUOTE_RELEASE];

const DisplayUnderwritingIssues = (props) => { /* NOSONAR: pure declarative usage */
    const {
        underwritingIssues,
        blockQuote,
        history,
        quoteID,
        filterUWIssuesInCustomOffering
    } = props;
    const [referToUWForm, setReferToUWForm] = useState(false);
    const [underwriterButton, setUnderwriterButton] = useState(true);
    const [withdrawButton, setWithdrawButton] = useState(true);
    const [formData, updateFormData] = useState({});
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const { quoteUnderWritingService } = useDependencies('quoteUnderWritingService');

    const writeValue = useCallback(
        (value, path) => {
            const nextFormData = _.cloneDeep(formData);
            _.set(nextFormData, path, value);
            updateFormData(nextFormData);
        },
        [formData]
    );

    const getUwIssuesByGroup = useMemo(() => {
        const blockingUWIssues = underwritingIssues.filter(
            (issue) => issue.currentBlockingPoint !== UWBlockingPoint.NON_BLOCKING
        );
        return _.groupBy(blockingUWIssues, (issue) => issue.offering);
    }, [underwritingIssues]);

    const displayableIssues = useMemo(() => {
        let issues = _.keys(getUwIssuesByGroup);

        if (filterUWIssuesInCustomOffering) {
            issues = issues.filter((val) => val !== 'CUSTOM');
        }
        return issues.sort();
    }, [filterUWIssuesInCustomOffering, getUwIssuesByGroup]);

    const openUWForm = useCallback(() => {
        setReferToUWForm(true);
        setUnderwriterButton(false);
        setWithdrawButton(false);
    }, []);

    const referToUnderwriter = useCallback(() => {
        if (!quoteUnderWritingService) {
            return;
        }
        const { notes } = formData;
        quoteUnderWritingService.referToUnderwriter(quoteID, notes, authHeader).then(() => {
            history.push(`/quotes/${quoteID}/summary`);
        });
    }, [authHeader, quoteID, history, formData, quoteUnderWritingService]);

    const withdrawHandler = useCallback(() => {
        if (!quoteUnderWritingService) {
            return;
        }
        quoteUnderWritingService.withdrawJobByJobNumber(quoteID, authHeader).then(() => {
            history.push(`/quotes/${quoteID}/summary`);
        });
    }, [authHeader, quoteID, history, quoteUnderWritingService]);

    const cancelButton = useCallback(() => {
        setReferToUWForm(false);
        setUnderwriterButton(true);
        setWithdrawButton(true);
        updateFormData({});
    }, []);

    const getBlockingPointDisplayKey = useCallback(
        (blockingPoint) => {
            switch (blockingPoint) {
                case UWBlockingPoint.BLOCKS_BIND:
                    return translator(messages.blocksBind);
                case UWBlockingPoint.BLOCKS_QUOTE:
                    return translator(messages.blocksQuote);
                case UWBlockingPoint.BLOCKS_QUOTE_RELEASE:
                    return translator(messages.blocksQuoteRelease);
                case UWBlockingPoint.NON_BLOCKING:
                    return translator(messages.nonBlocking);
                default:
                    return blockingPoint;
            }
        },
        [translator]
    );

    const approveQuote = useCallback(
        (evt, DTO) => {
            evt.preventDefault();
            if (!quoteUnderWritingService) {
                return;
            }
            quoteUnderWritingService.approveUnderwritingIssue(quoteID, DTO, authHeader);
        },
        [quoteUnderWritingService, quoteID, authHeader]
    );

    const generateIssuesData = useCallback(
        (offering) => {
            const getUwIssueByBindOrBlock = !blockQuote
                ? offering.filter(
                    (issue) => issue.currentBlockingPoint === UWBlockingPoint.BLOCKS_BIND
                )
                : offering.filter((issue) => BLOCKS_QUOTES.includes(issue.currentBlockingPoint));
            return getUwIssueByBindOrBlock.map((issue) => {
                const currentBlockingPoint = getBlockingPointDisplayKey(issue.currentBlockingPoint);
                return (
                    <tr>
                        <td>{issue.description}</td>
                        <td>{currentBlockingPoint}</td>
                        {issue.canUserApprove ? (
                            <td>
                                <a
                                    href
                                    onClick={(evt) => approveQuote(evt, issue.originalDTO)}
                                >
                                    {translator(messages.approve)}
                                </a>
                            </td>
                        ) : null}
                    </tr>
                );
            });
        },
        [translator, approveQuote, blockQuote, getBlockingPointDisplayKey]
    );

    const underWritingIssuesTable = useCallback(() => {
        let renderByBlockingPoint = displayableIssues;

        if (filterUWIssuesInCustomOffering && blockQuote) {
            renderByBlockingPoint = ['CUSTOM'];
        }

        return (
            <table>
                {renderByBlockingPoint.map((offering) => {
                    return (
                        <>
                            <tr>
                                <td colSpan="3">
                                    <h4>{offering}</h4>
                                </td>
                            </tr>
                            {generateIssuesData(getUwIssuesByGroup[offering])}
                        </>
                    );
                })}
            </table>
        );
    }, [generateIssuesData,
        displayableIssues, getUwIssuesByGroup, blockQuote, filterUWIssuesInCustomOffering]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            openUWForm,
            referToUnderwriter,
            cancelButton,
            withdrawHandler
        }
    };
    const overrideProps = {
        gwAlert: {
            visible: blockQuote || displayableIssues.length > 0
        },
        gwAlertHeading: {
            content: translator(messages[blockQuote ? 'weCannotShow' : 'followingQuotes'])
        },
        alertAffectedQuotes: {
            visible: !blockQuote,
            content: displayableIssues.join(',')
        },
        referToUWForm: {
            visible: referToUWForm
        },
        underwriterButton: {
            visible: underwriterButton
        },
        cancelButton: {
            visible: cancelButton
        },
        withdrawButton: {
            visible: withdrawButton
        },
        issuesTable: {
            content: underWritingIssuesTable()
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={formData}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
};

DisplayUnderwritingIssues.propTypes = {
    underwritingIssues: PropTypes.arrayOf({}),
    blockQuote: PropTypes.bool,
    quoteID: PropTypes.string.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    filterUWIssuesInCustomOffering: PropTypes.bool
};
DisplayUnderwritingIssues.defaultProps = {
    underwritingIssues: [],
    blockQuote: false,
    filterUWIssuesInCustomOffering: true
};

const UnderwritingIssues = (props) => {
    const { underwritingIssues } = props;
    const hasUwIssues = underwritingIssues.length;
    const getOnOfferings = useMemo(() => {
        return underwritingIssues.every((uwIssue) => {
            return UWBlockingPoint.NON_BLOCKING.includes(uwIssue.currentBlockingPoint);
        });
    }, [underwritingIssues]);

    // render DisplayUnderwritingIssues only when underwritingIssues are available.
    return hasUwIssues && !getOnOfferings ? <DisplayUnderwritingIssues {...props} /> : null;
};

UnderwritingIssues.propTypes = {
    underwritingIssues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    quoteID: PropTypes.string.isRequired,
    filterUWIssuesInCustomOffering: PropTypes.bool
};

UnderwritingIssues.defaultProps = {
    filterUWIssuesInCustomOffering: true
};

export default withRouter(UnderwritingIssues);
