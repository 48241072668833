import { defineMessages } from 'react-intl';

export default defineMessages({
    account: {
        id: 'header.links.Account',
        defaultMessage: 'Account'
    },
    faq: {
        id: 'header.links.FAQ',
        defaultMessage: 'FAQ'
    },
    backToDashboard: {
        id: 'header.links.backToDashboard',
        defaultMessage: 'Back to dashboard'
    },
    logout: {
        id: 'header.links.Logout',
        defaultMessage: 'Sign out'
    },
    callUs: {
        id: 'nfum.portal.index.Need help with this form? Call us',
        defaultMessage: 'Need help with this form? Call us'
    },
    sessionTimeoutNFUM: {
        id: 'auth.components.logout-warning.Session timeout nfum',
        defaultMessage: 'Session Timeout'
    },
    warningAboutToExpire1: {
        id: 'auth.components.logout-warning.Warning, your session is about to expire1',
        defaultMessage: 'Warning, your session is about to expire'
    },
    continueSessionNFUM: {
        id: 'auth.components.logout-warning.Continue Session nfum',
        defaultMessage: 'Continue Session'
    },
    warningAboutToExpireNFUM1: {
        id: 'auth.components.session-warning.WarningNFUM1, your session is about to expire',
        defaultMessage: 'Your session is being timed out due to inactivity'
    },
    warningAboutToExpireNFUM2: {
        id: 'auth.components.session-warning.WarningNFUM2, please choose',
        defaultMessage: 'Please choose to either continue with your changes or quit without saving'
    },
    continueSession: {
        id: 'auth.components.session-warning.Continue Session',
        defaultMessage: 'Continue'
    },
    warningAboutLogout: {
        id: 'auth.components.logout-warning.Warning, your session is about to logout',
        defaultMessage: 'Are you sure you want to logout?'
    },
    continueLogout: {
        id: 'auth.components.logout-warning.Continue Logout',
        defaultMessage: 'Log out'
    },
    cancelLogout: {
        id: 'auth.components.logout-warning.Cancel Logout',
        defaultMessage: 'Continue session'
    }
});
