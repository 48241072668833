import React, { useContext, useCallback } from 'react';
import { WizardContext } from 'gw-portals-wizard-react';
import { TranslatorContext } from '@jutro/locale';
import styles from './HeaderBar.module.scss';
import messages from './HeaderBar.messages';

const HeaderBar = (props) => {
    const { stepsConfig } = props;
    const wizardContext = useContext(WizardContext);
    const translator = useContext(TranslatorContext);
    const {
        currentStepIndex,
        wizardData
    } = wizardContext;
    const stepFromConfig = stepsConfig.find((step) => step.stepId === currentStepIndex);
    return (
        <div className={styles.container}>
            <div className={styles.seperator} />
            <nav className={styles.headerBarContainer}>
                <div className={styles.headerBarHeader}>
                    <div className={styles.headerBarTitle}>
                        {translator(stepFromConfig.title)}
                    </div>
                    <div className={styles.reference}>
                        { wizardData.value.policyNumber && !stepFromConfig.isPolicyNumberHidden && (
                            <div>
                                <span>
                                    {translator(messages.policyNumber)}
                                    {wizardData.value.policyNumber}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
                { stepFromConfig.additionalInfo && (
                    <div className={styles.additionalInfoWrapper}>
                        <div className={styles.additionalInfoContainer}>
                            <p>{translator(stepFromConfig.additionalInfo)}</p>
                        </div>
                    </div>
                )}
            </nav>
        </div>
    );
};

export default HeaderBar;
