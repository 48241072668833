const spacingVars = {
    // Spacing scale
    '--GW-SPACING-1': '0.125rem', // 2px
    '--GW-SPACING-2': '0.25rem', //  4px
    '--GW-SPACING-3': '0.5rem', //   8px
    '--GW-SPACING-4': '0.75rem', // 12px
    '--GW-SPACING-5': '1rem', //    16px
    '--GW-SPACING-6': '1.25rem', // 20px
    '--GW-SPACING-8': '1.75rem', // 28px
    '--GW-SPACING-10': '2rem', //   32px

    // Layout scale
    '--GW-LAYOUT-1': '0.25rem', // 4px
    '--GW-LAYOUT-2': '0.5rem', // 8px
    '--GW-LAYOUT-3': '0.75rem', // 12px
    '--GW-LAYOUT-4': '1rem', // 16px
    '--GW-LAYOUT-5': '1.25rem', // 20px
    '--GW-LAYOUT-6': '1.5rem', // 24px
    '--GW-LAYOUT-8': '2rem', // 32px
    '--GW-LAYOUT-10': '2.5rem', // 40px
    '--GW-LAYOUT-12': '3rem', // 48px

};

export default spacingVars;
