import React, {
    useCallback, useRef, useEffect, useState, useContext
} from 'react';
import moment from 'moment';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { withAuthenticationContext, useAuthentication } from 'gw-digital-auth-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { NfumAccordionHeader } from 'nfum-components-platform-react';
import {
    useLobs,
    useErrorHandler,
    useCallRetry,
    RENEWAL_STEPS
} from 'nfum-portals-utils-react';
import { useWizardActions } from 'nfum-portals-wizard-react';
import usePropertyTitle from '../../hooks/usePropertyTitle';
import CaravanSummary from './CaravanSummary/CaravanSummary';
import BuildingsSummary from './BuildingsSummary/BuildingsSummary';
import metadata from './RenewalSummaryPage.metadata.json5';
import styles from './RenewalSummaryPage.module.scss';
import messages from './RenewalSummaryPage.messages';
import commonMessages from '../../NGHRenewal.messages';
import ContentsSummary from './ContentsSummary/ContentsSummary';
import AdditionalCoversSummary from './AdditionalCoversSummary/AdditionalCoversSummary';
import PaymentScheduleSummary from './PaymentScheduleSummary/PaymentScheduleSummary';
import PolicyDetailsSummary from './PolicyDetailsSummary/PolicyDetailsSummary';
import CostBreakdownBox from './CostBreakdownBox/CostBreakdownBox';
import AnimalSummary from './AnimalSummary/AnimalSummary';
import TravelSummary from './TravelSummary/TravelSummary';
import PersonalAccidentSummary from './PersonalAccidentSummary/PersonalAccidentSummary';
import ValuablesSummary from './ValuablesSummary/ValuablesSummary';
import BicyclesSummary from './BicyclesSummary/BicyclesSummary';
import BespokeValuables from '../BespokeValuables/BespokeValuables';
import useTagManager from '../../hooks/useTagManager';
import FineArtsCollectionsSummary from './FineArtsCollectionsSummary/FineArtsCollectionsSummary';

function RenewalSummaryPage(props) { /* NOSONAR: pure declarative usage  */
    const {
        wizardData: submissionVM,
        updateWizardData
    } = props;
    const { returnToDashboard } = useWizardActions();
    const {
        isPersonalAccidentLine,
        isAnimalLine,
        isTravelLine,
        isCaravanLine,
        isBuildingsCov,
        isContentsCov,
        isBespokeJourney, // to use in R1.2 to resolve if you're within Bespoke journey
        isFineArtAndCollectionsCov,
        isValuablesCov
    } = useLobs(submissionVM);
    const { handleError } = useErrorHandler();
    const { authHeader } = useAuthentication();
    const { PolicyService } = useDependencies('PolicyService');
    const DOCUMENT_TIMEOUT_DURATION_SECONDS = 180;
    const translator = useContext(TranslatorContext);
    const [addressValidationInd, setAddressValidationInd] = useState(null);
    const [disableNext, setDisableNext] = useState(null);
    const paymentMethod = _.get(submissionVM.value, 'baseData.paymentMethod_NFUM');
    const { history } = props;
    const {
        pushFormStepInfo,
        pushFormStepErrorInfo,
        pushAccordionOpenInfo,
        pushFileDownloadInfo,
        pushLinkClickInfo
    } = useTagManager();
    const cardsIds = Object.freeze({
        PAYMENT_SCHEDULE_CARD: 'paymentScheduleCard',
        POLICY_DETAILS_CARD: 'policyDetailsCard',
        ADDITIONAL_COVERS_CARD: 'additionalCoversCard',
        BUILDINGS_CARD: 'buildingsInsuranceCard',
        CONTENTS_CARD: 'contentsInsuranceCard',
        CARAVAN_CARD: 'caravanCard',
        CAT_DOG_CARD: 'dogAndCatCard',
        TRAVEL_CARD: 'travelCard',
        PERSONAL_ACCIDENT_CARD: 'personalAccidentCard',
        VALUABLES: 'valuablesCard',
        BESPOKE_VALUABLES_CARD: 'bespokeValuablesCard',
        BICYCLES: 'bicyclesCard',
        FINEARTS: 'fineArtsCollectionsCard',
    });
    const [isConditionsExclusionsAvailable, setIsConditionsExclusionsAvailable] = useState(false);
    const documentsSectionRef = useRef();
    const scrollToTopSection = useRef();
    const scrollTopPaymentSection = useRef();
    const scrollToBespokeValuablesSection = useRef();
    const isCash = _.get(submissionVM.value, 'baseData.paymentMethod_NFUM') === 'cash';
    const { getPropertyTitle } = usePropertyTitle();
    const homeScheduleItems = _.get(submissionVM, 'lobData.value.homeLine.coverables.homeProperties[0].coverages.schedules[0].scheduleItems');
    const producerDesc = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.producerCodeDescription', '');
    const producerTele = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.producerCodeTelephone', '');
    const isBespoke = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.isBespoke', '');
    const showAgencyDetails = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.showProducerDetails', '');
    // #region GTM EVENTS
    useEffect(() => {
        pushFormStepInfo(submissionVM, RENEWAL_STEPS.SUMMARY);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onUpdateOpenAccordion = useCallback((accordionCardsIds) => {
        const openCardId = accordionCardsIds && accordionCardsIds.length > 0
            ? accordionCardsIds[0] : null;
        switch (openCardId) {
            case cardsIds.PAYMENT_SCHEDULE_CARD:
                pushAccordionOpenInfo(translator(messages.paymentSchedule));
                // eslint-disable-next-line no-case-declarations
                const paymentAccordion = document.getElementById('paymentScheduleAnchor');
                if (paymentAccordion) {
                    // eslint-disable-next-line no-case-declarations
                    const header = document.getElementById('headerWrapper');
                    // eslint-disable-next-line no-case-declarations
                    const headerRect = header.getBoundingClientRect();
                    // eslint-disable-next-line no-case-declarations
                    const contentRect = paymentAccordion.getBoundingClientRect();
                    // eslint-disable-next-line no-case-declarations, max-len
                    const desiredScrollTop = contentRect.top - headerRect.height;
                    window.scrollBy({
                        top: desiredScrollTop,
                        behavior: 'smooth'
                    });
                }
                break;
            case cardsIds.POLICY_DETAILS_CARD:
                pushAccordionOpenInfo(translator(messages.policyDetails));
                // eslint-disable-next-line no-case-declarations
                const policyOpenAccordion = document.querySelector('.isOpen');
                if (policyOpenAccordion) {
                    const header = document.getElementById('headerWrapper');
                    const headerRect = header.getBoundingClientRect();
                    const contentRect = policyOpenAccordion.getBoundingClientRect();
                    const desiredScrollTop = contentRect.top - headerRect.height;
                    window.scrollBy({
                        top: desiredScrollTop,
                        behavior: 'smooth'
                    });
                } else {
                    const policyAccordion = document.getElementById('policyDetailsAnchor');
                    if (policyAccordion) {
                        const header = document.getElementById('headerWrapper');
                        const headerRect = header.getBoundingClientRect();
                        const contentRect = policyAccordion.getBoundingClientRect();
                        const desiredScrollTop = contentRect.top - headerRect.height;
                        window.scrollBy({
                            top: desiredScrollTop,
                            behavior: 'smooth'
                        });
                    }
                }
                break;
            case cardsIds.ADDITIONAL_COVERS_CARD:
                pushAccordionOpenInfo(translator(messages.additionalCovers));
                // eslint-disable-next-line no-case-declarations
                const additionalOpenAccordion = document.querySelector('.isOpen');
                if (additionalOpenAccordion) {
                    const header = document.getElementById('headerWrapper');
                    const headerRect = header.getBoundingClientRect();
                    const contentRect = additionalOpenAccordion.getBoundingClientRect();
                    const desiredScrollTop = contentRect.top - headerRect.height;
                    window.scrollBy({
                        top: desiredScrollTop,
                        behavior: 'smooth'
                    });
                } else {
                    const additionalAccordion = document.getElementById('additionalCoversCard');
                    if (additionalAccordion) {
                        const header = document.getElementById('headerWrapper');
                        const headerRect = header.getBoundingClientRect();
                        const contentRect = additionalAccordion.getBoundingClientRect();
                        const desiredScrollTop = contentRect.top - headerRect.height;
                        window.scrollBy({
                            top: desiredScrollTop,
                            behavior: 'smooth'
                        });
                    }
                }
                break;
            case cardsIds.BUILDINGS_CARD:
                pushAccordionOpenInfo(translator(messages.buildings));
                // eslint-disable-next-line no-case-declarations
                const buildingOpenAccordion = document.querySelector('.isOpen');
                if (buildingOpenAccordion) {
                    const header = document.getElementById('headerWrapper');
                    const headerRect = header.getBoundingClientRect();
                    const contentRect = buildingOpenAccordion.getBoundingClientRect();
                    const desiredScrollTop = contentRect.top - headerRect.height;
                    window.scrollBy({
                        top: desiredScrollTop,
                        behavior: 'smooth'
                    });
                } else {
                    const buildingAccordion = document.getElementById('buildingsInsuranceCard');
                    if (buildingAccordion) {
                        const header = document.getElementById('headerWrapper');
                        const headerRect = header.getBoundingClientRect();
                        const contentRect = buildingAccordion.getBoundingClientRect();
                        const desiredScrollTop = contentRect.top - headerRect.height;
                        window.scrollBy({
                            top: desiredScrollTop,
                            behavior: 'smooth'
                        });
                    }
                }
                break;
            case cardsIds.CONTENTS_CARD:
                pushAccordionOpenInfo(translator(messages.contents));
                // eslint-disable-next-line no-case-declarations
                const contentOpenAccordion = document.querySelector('.isOpen');
                if (contentOpenAccordion) {
                    const header = document.getElementById('headerWrapper');
                    const headerRect = header.getBoundingClientRect();
                    const contentRect = contentOpenAccordion.getBoundingClientRect();
                    const desiredScrollTop = contentRect.top - headerRect.height;
                    window.scrollBy({
                        top: desiredScrollTop,
                        behavior: 'smooth'
                    });
                } else {
                    const contentAccordion = document.getElementById('contentsInsuranceCard');
                    if (contentAccordion) {
                        const header = document.getElementById('headerWrapper');
                        const headerRect = header.getBoundingClientRect();
                        const contentRect = contentAccordion.getBoundingClientRect();
                        const desiredScrollTop = contentRect.top - headerRect.height;
                        window.scrollBy({
                            top: desiredScrollTop,
                            behavior: 'smooth'
                        });
                    }
                }
                break;
            case cardsIds.CARAVAN_CARD:
                pushAccordionOpenInfo(translator(messages.caravan));
                scrollToTopSection.current.scrollIntoView();
                break;
            case cardsIds.CAT_DOG_CARD:
                pushAccordionOpenInfo(translator(messages.catDog));
                scrollToTopSection.current.scrollIntoView();
                break;
            case cardsIds.TRAVEL_CARD:
                pushAccordionOpenInfo(translator(messages.travel));
                scrollToTopSection.current.scrollIntoView();
                break;
            case cardsIds.PERSONAL_ACCIDENT_CARD:
                pushAccordionOpenInfo(translator(messages.personalAccident));
                scrollToTopSection.current.scrollIntoView();
                break;
            case cardsIds.VALUABLES_CARD:
                pushAccordionOpenInfo(translator(messages.valuables));
                scrollToTopSection.current.scrollIntoView();
                break;
            case cardsIds.BESPOKE_VALUABLES_CARD:
                pushAccordionOpenInfo(translator(messages.valuables));
                // eslint-disable-next-line no-case-declarations
                const bespokeValuableOpenAccordion = document.querySelector('.isOpen');
                if (bespokeValuableOpenAccordion) {
                    const header = document.getElementById('headerWrapper');
                    const headerRect = header.getBoundingClientRect();
                    const contentRect = bespokeValuableOpenAccordion.getBoundingClientRect();
                    const desiredScrollTop = contentRect.top - headerRect.height;
                    window.scrollBy({
                        top: desiredScrollTop,
                        behavior: 'smooth'
                    });
                } else {
                    const bespokeValuablesAccordion = document.getElementById('bespokeValuablesCard');
                    if (bespokeValuablesAccordion) {
                        const header = document.getElementById('headerWrapper');
                        const headerRect = header.getBoundingClientRect();
                        const contentRect = bespokeValuablesAccordion.getBoundingClientRect();
                        const desiredScrollTop = contentRect.top - headerRect.height;
                        window.scrollBy({
                            top: desiredScrollTop,
                            behavior: 'smooth'
                        });
                    }
                }
                break;
            case cardsIds.BICYCLES:
                pushAccordionOpenInfo(translator(messages.bicycles));
                scrollToTopSection.current.scrollIntoView();
                break;
            case cardsIds.FINEARTS:
                pushAccordionOpenInfo(translator(messages.fineArts));
                // eslint-disable-next-line no-case-declarations
                const bespokeFineArtsOpenAccordion = document.querySelector('.isOpen');
                if (bespokeFineArtsOpenAccordion) {
                    const header = document.getElementById('headerWrapper');
                    const headerRect = header.getBoundingClientRect();
                    const contentRect = bespokeFineArtsOpenAccordion.getBoundingClientRect();
                    const desiredScrollTop = contentRect.top - headerRect.height;
                    window.scrollBy({
                        top: desiredScrollTop,
                        behavior: 'smooth'
                    });
                } else {
                    const bespokeFineArtsAccordion = document.getElementById('fineArtsCollectionsCard');
                    if (bespokeFineArtsAccordion) {
                        const header = document.getElementById('headerWrapper');
                        const headerRect = header.getBoundingClientRect();
                        const contentRect = bespokeFineArtsAccordion.getBoundingClientRect();
                        const desiredScrollTop = contentRect.top - headerRect.height;
                        window.scrollBy({
                            top: desiredScrollTop,
                            behavior: 'smooth'
                        });
                    }
                }
                break;
            default:
                // do nothing
        }
    }, [cardsIds, pushAccordionOpenInfo, translator]);
    // #endregion GTM EVENTS

    useEffect(() => {
        const lobData = _.get(submissionVM.value, 'lobData');
        const jobId = _.get(submissionVM.value, 'jobID');
        const policyNumber = _.get(submissionVM.value, 'policyNumber');
        const res = Object.entries(lobData)
            .reduce((initValue, [, value]) => initValue
                || value?.isConditionsExclusionsAvailable, false);
        setIsConditionsExclusionsAvailable(res);
        const baseData = _.get(submissionVM.value, 'baseData');
        if (baseData.automaticRenewalOoption_NFUM && baseData.paymentMethod_NFUM === 'directdebit') {
            history.push(`/contact-us?jobid=${jobId}&policyNumber=${policyNumber}&showRenewalDesc=true`);
        }
    }, [submissionVM, history]);

    const fetchDocuments = useCallback(async () => {
        const docs = await PolicyService.getDocuments(
            [submissionVM.value.jobID],
            authHeader
        );
        return docs;
    }, [PolicyService, submissionVM.value.jobID, authHeader]);

    const {
        loading: isLoading,
        error: docError,
        data: documents
    } = useCallRetry(
        fetchDocuments,
        {
            retryTimes: 10,
            retryDelayInSeconds: 10,
            initialIsLoading: true,
            timeoutDurationSeconds: DOCUMENT_TIMEOUT_DURATION_SECONDS
        },
        []
    );

    const handleNext = useCallback(() => {
        return submissionVM;
    }, [submissionVM]);

    const handleAddressValidationChange = useCallback((isConfirmed) => {
        if (isConfirmed) {
            setAddressValidationInd(true);
            setDisableNext(true);
        } else {
            setAddressValidationInd(false);
            setDisableNext(false);
        }
    }, [setAddressValidationInd]);

    const renderAccordionHeader = (isOpen, title, openMessage) => {
        return (
            <NfumAccordionHeader
                isOpen={isOpen}
                title={title}
                openMessage={openMessage}
            />
        );
    };

    const onFindAgencyClicked = useCallback(() => {
        const url = 'https://www.nfumutual.co.uk/agent-offices/';
        pushLinkClickInfo(translator(messages.customMessageDocumentation1), url);
        window.open(url, '_blank');
    }, [pushLinkClickInfo, translator]);

    const renderValuableHeader = (isOpen) => {
        return renderAccordionHeader(
            isOpen, messages.valuables, messages.reviewCover
        );
    };
    const overrideProps = {
        policyDetailsList: {
            onUpdateAccordionStates: onUpdateOpenAccordion
        },
        [cardsIds.PAYMENT_SCHEDULE_CARD]: {
            visible: !isCash,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen, messages.paymentSchedule, messages.reviewDetails
                );
            }
        },
        [cardsIds.POLICY_DETAILS_CARD]: {
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen, messages.policyDetails, messages.reviewDetails
                );
            }
        },
        [cardsIds.ADDITIONAL_COVERS_CARD]: {
            visible: isBuildingsCov || isContentsCov,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen, messages.additionalCovers, messages.reviewCover
                );
            }
        },
        [cardsIds.VALUABLES]: {
            visible: homeScheduleItems
                ?.filter((item) => !_.isEmpty(item.itemData.ArticleGeographicLimit))?.length > 0,
            renderHeader: renderValuableHeader
        },
        [cardsIds.BESPOKE_VALUABLES_CARD]: {
            visible: true,
            renderHeader: renderValuableHeader
        },
        [cardsIds.BICYCLES]: {
            visible: !isBespokeJourney && homeScheduleItems
                ?.filter((item) => _.isEmpty(item.itemData.ArticleGeographicLimit))?.length > 0,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen, messages.bicycles, messages.reviewCover
                );
            }
        },
        [cardsIds.BUILDINGS_CARD]: {
            visible: isBuildingsCov,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen, messages.buildings, messages.reviewCover
                );
            }
        },
        [cardsIds.CONTENTS_CARD]: {
            visible: isContentsCov,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen, messages.contents, messages.reviewCover
                );
            }
        },
        [cardsIds.TRAVEL_CARD]: {
            visible: isTravelLine,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen, messages.travel, messages.reviewCover
                );
            }
        },
        [cardsIds.CARAVAN_CARD]: {
            visible: isCaravanLine,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen, messages.caravan, messages.reviewCover
                );
            }
        },
        [cardsIds.CAT_DOG_CARD]: {
            visible: isAnimalLine,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen, messages.catDog, messages.reviewCover
                );
            }
        },
        [cardsIds.PERSONAL_ACCIDENT_CARD]: {
            visible: isPersonalAccidentLine,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen, messages.personalAccident, messages.reviewCover
                );
            }
        },
        [cardsIds.FINEARTS]: {
            visible: isBespokeJourney && isFineArtAndCollectionsCov,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen, messages.fineArts, messages.reviewCover
                );
            }
        },
        costBreakdownBox: {
            submissionVM
        },
        paymentScheduleSummary: {
            installmentDetails: _.get(submissionVM.value, 'installmentDetails_NFUM')
        },
        policyDetailsSummary: {
            submissionVM
        },
        buildingsSummary: {
            homePropertiesData: _.get(submissionVM, 'lobData.value.homeLine.coverables.homeProperties'),
            homeLine: _.get(submissionVM, 'lobData.value.homeLine'),
            onClickSeeDocuments: () => documentsSectionRef.current.scrollIntoView(),
            submissionVM
        },
        fineArtsCollectionsSummary: {
            homePropertiesData: _.get(submissionVM, 'lobData.value.homeLine.coverables.homeProperties'),
            submissionVM,
            onClickSeeDocuments: () => documentsSectionRef.current.scrollIntoView(),
        },
        contentsSummary: {
            homePropertiesData: _.get(submissionVM, 'lobData.value.homeLine.coverables.homeProperties'),
            submissionVM
        },
        valuablesSummary: {
            homePropertiesData: _.get(submissionVM, 'lobData.value.homeLine.coverables.homeProperties')
        },
        bicyclesSummary: {
            homePropertiesData: _.get(submissionVM, 'lobData.value.homeLine.coverables.homeProperties')
        },
        additionalCoversSummary: {
            homePropertiesData: _.get(submissionVM, 'lobData.value.homeLine.coverables.homeProperties'),
            lexLineCoverages: _.get(submissionVM, 'lobData.value.lexLine.lineCoverages.coverages'),
            homeLineCoverages: _.get(submissionVM, 'lobData.value.homeLine.lineCoverages.coverages'),
            submissionVM
        },
        caravanSummary: {
            value: _.get(submissionVM, 'lobData.value.caravanLine.coverables.caravans')
        },
        animalSummary: {
            value: _.get(submissionVM, 'lobData.value.animalLine.coverables.animals')
        },
        travelSummary: {
            value: _.get(submissionVM, 'lobData.value.travelLine.coverables.travellers')
        },
        personalAccidentSummary: {
            value: _.get(submissionVM, 'lobData.value.personalAccidentLine.coverables.individuals')
        },
        yourQuoteDocumentation: {
            onClick: () => documentsSectionRef.current.scrollIntoView()
        },
        needToMakeAChange: {
            producerDesc: producerDesc,
            producerTele: producerTele,
            isBespoke: isBespoke,
            showAgencyDetails: showAgencyDetails
        },
        needToMakeChangeRenewalDate: {
            content: moment(_.get(submissionVM.value, 'baseData.periodStartDate')).format('DD MMM YYYY')
        },
        needToMakeChangeInfoForDirectDebit: {
            visible: !isCash
        },
        needToMakeChangeInfoForCash: {
            visible: isCash
        },
        policyDetailsAnchor: {
            ref: scrollToTopSection
        },
        paymentScheduleAnchor: {
            ref: scrollTopPaymentSection
        },
        yourDocumentsSectionAnchor: {
            ref: documentsSectionRef
        },
        riskBillingAddValidationInd: {
            onValueChange: handleAddressValidationChange,
            value: addressValidationInd
        },
        referralMessage: {
            description: translator(messages.addValidationReferalMessage1),
            description1: translator(messages.addValidationReferalMessage2),
            producerDesc: producerDesc,
            producerTele: producerTele,
            isBespoke: isBespoke,
            showAgencyDetails: showAgencyDetails
        },
        documentsList: {
            documents: documents,
            pushFileDownloadInfo
        },
        inYourQuoteDocumentation: {
            onClick: () => documentsSectionRef.current.scrollIntoView()
        },
        specialConditionsContainer: {
            visible: isConditionsExclusionsAvailable
        },
        policyScheduleLink: {
            onClick: () => documentsSectionRef.current.scrollIntoView()
        },
        referralMessageContainer: {
            visible: addressValidationInd === false
        },
        loader: {
            visible: isLoading,
            loaded: !isLoading
        },
        summaryComponent: {
            visible: !isLoading
        },
        billingvsRiskValidation: {
            visible: paymentMethod === 'cash'
        },
        confirmBillingAddressMessage: {
            value: _.get(submissionVM.value, 'baseData.billingAddress_NFUM.displayName'),
            readOnly: true
        },
        bespokeValuablesCardContainer: {
            visible: isBespokeJourney && isValuablesCov === true,
            ref: scrollToBespokeValuablesSection
        },
        customMessageDiv: {
            visible: homeScheduleItems?.length > 20
        },
        valuablesInfoContainer: {
            visible: homeScheduleItems?.length < 21
        },
        valuablesHomePropertyTitle: {
            content: getPropertyTitle(_.get(submissionVM, 'lobData.value.homeLine.coverables.homeProperties[0]')),
            visible: homeScheduleItems?.length < 21
        },
        bespokeValuables: {
            scheduleItems: homeScheduleItems,
            submissionVM,
            visible: homeScheduleItems?.length < 21
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentsMap: {
            travelSummary: TravelSummary,
            personalAccidentSummary: PersonalAccidentSummary,
            animalSummary: AnimalSummary,
            caravanSummary: CaravanSummary,
            buildingsSummary: BuildingsSummary,
            contentsSummary: ContentsSummary,
            additionalCoversSummary: AdditionalCoversSummary,
            paymentScheduleSummary: PaymentScheduleSummary,
            policyDetailsSummary: PolicyDetailsSummary,
            costBreakdownBox: CostBreakdownBox,
            valuablesSummary: ValuablesSummary,
            bicyclesSummary: BicyclesSummary,
            bespokeValuables: BespokeValuables,
            fineArtsCollectionsSummary: FineArtsCollectionsSummary
        },
        resolveCallbackMap: {
            onScrollToDocumentsSection: () => documentsSectionRef.current.scrollIntoView(),
            onScrollToContactAgent: onFindAgencyClicked
        }
    };

    if (docError?.errorCode) {
        pushFormStepErrorInfo(submissionVM, RENEWAL_STEPS.SUMMARY, docError);
        handleError(docError, submissionVM.value.quoteID);
        return false;
    }

    return (
        <WizardPage
            onNext={handleNext}
            showNext={isCash && !isLoading}
            showCancel={false}
            showPrevious={false}
            showJumpBack={!isLoading}
            onJumpBack={() => returnToDashboard(translator(commonMessages.returnToDashboard))}
            nextLabel={commonMessages.renewPolicy}
            disableNext={!disableNext && paymentMethod === 'cash'}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentsMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

RenewalSummaryPage.propTypes = wizardProps;
export default withAuthenticationContext(RenewalSummaryPage);
