import React, { useState } from 'react';
import cx from 'classnames';
import { NfumLoader } from 'nfum-components-platform-react';
import { ImageComponent } from 'gw-components-platform-react';
import styles from './BridgeHeaderText.module.scss';

const BridgeHeaderText = (props) => {
    const {
        headerText,
        text,
        imageUrl,
        imageAlt
    } = props;
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const headerTextClass = cx({
        [styles.headerText]: true,
        [styles.headerTextPaddingLeft]: imageUrl
    });

    const onImageLoad = () => {
        setIsImageLoaded(true);
    };

    const hiddenIfLoading = cx({
        [styles.hidden]: !isImageLoaded
    });

    return (
        <div className={styles.container}>
            <div className={hiddenIfLoading}>
                <div className={styles.headerContainer}>
                    {imageUrl && <ImageComponent
                        className={styles.headerImage}
                        src={imageUrl}
                        alt={imageAlt}
                        onLoad={onImageLoad}
                    /> }
                    <h3 className={headerTextClass}>{headerText}</h3>
                </div>
                <div className={styles.contentContainer}>
                    <p className={styles.text}>{text}</p>
                </div>
            </div>
            { !isImageLoaded &&
                <div>
                    <NfumLoader />
                </div>
            }
        </div>
    );
};

export default BridgeHeaderText;
