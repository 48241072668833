import React, {
    useContext, useCallback, useMemo
} from 'react';
import _ from 'lodash';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import {
    useCurrency
} from 'nfum-portals-utils-react';
import useTagManager from '../../hooks/useTagManager';
import metadata from './PersonalLegalExpenses.metadata.json5';
import styles from './PersonalLegalExpenses.module.scss';
import messages from './PersonalLegalExpenses.messages';

function PersonalLegalExpenses(props) {
    const {
        wizardData: policyChangeVM, updateWizardData, paths
    } = props;
    const translator = useContext(TranslatorContext);
    const TIER_LEVEL_3_CUSTOMER = 'tierLevel3';
    const LEGAL_EXPENSES_STANDARD_KEY = 'Standard';
    const LEGAL_EXPENSES_EXTRA_KEY = 'Extra';
    const isTierLevel3Customer = _.get(policyChangeVM, 'lobData.value.homeLine.lineCoverages.contentsCovTier') === TIER_LEVEL_3_CUSTOMER;
    const { legalExpensesCoverPath } = paths;
    const legalExpensesCoverValue = _.get(policyChangeVM, `${legalExpensesCoverPath}.chosenTermValue`);
    const {
        pushLinkClickInfo
    } = useTagManager();
    const currencyFormatter = useCurrency();
    const dynamicPricing = useMemo(
        () => _.get(policyChangeVM.value, 'dynamicPricing_NFUM'),
        [policyChangeVM.value]
    );

    const seeAllCoverBenefits = useCallback(() => {
        const url = 'https://www.nfumutual.co.uk/home-insurance/personal-legal-expenses/';
        pushLinkClickInfo(translator(messages.seeAllCoverBenefits), url);
        window.open(url, '_blank');
    }, [pushLinkClickInfo, translator]);

    const learnMore = useCallback(() => {
        const url = 'https://www.nfumutual.co.uk/home-insurance/personal-legal-expenses-extra/';
        pushLinkClickInfo(translator(messages.learnMore), url);
        window.open(url, '_blank');
    }, [pushLinkClickInfo, translator]);

    const writeValue = useCallback(
        (value, path) => {
            _.set(policyChangeVM, path, value);
            updateWizardData(policyChangeVM);
        },
        [policyChangeVM, updateWizardData]
    );

    const getAddRemoveCoverToggleButtonLabel = (conditionForRemoveCoverLabel) => {
        return conditionForRemoveCoverLabel === LEGAL_EXPENSES_EXTRA_KEY
            ? translator(messages.removeCover)
            : translator(messages.addCover);
    };

    const handleEnableAllRisksLegalExpensesCover = useCallback((isAllRisksChecked) => {
        if (isAllRisksChecked) {
            _.set(policyChangeVM, 'lobData.homeLine.lexcoverLevel_NFUM', 'AllRisks');
            _.set(policyChangeVM, `${legalExpensesCoverPath}.chosenTermValue`, LEGAL_EXPENSES_EXTRA_KEY);
        } else if (!isAllRisksChecked || (isTierLevel3Customer && !isAllRisksChecked)) {
            _.set(policyChangeVM, 'lobData.homeLine.lexcoverLevel_NFUM', 'Standard');
            _.set(policyChangeVM, `${legalExpensesCoverPath}.chosenTermValue`, LEGAL_EXPENSES_STANDARD_KEY);
        }
        updateWizardData(policyChangeVM);
    }, [
        policyChangeVM,
        updateWizardData,
        isTierLevel3Customer,
        legalExpensesCoverPath
    ]);

    const getExtraDescription = useCallback(() => {
        const paymentMethod = _.get(policyChangeVM.value, 'baseData.paymentMethod_NFUM');
        if (paymentMethod === 'directdebit') {
            return messages.perMonth;
        }
        return messages.perYear;
    }, [policyChangeVM]);

    const getDynamicPrice = useCallback((amount) => {
        const paymentMethod = _.get(policyChangeVM.value, 'baseData.paymentMethod_NFUM');
        if (paymentMethod === 'directdebit') {
            return currencyFormatter.formatCurrency(amount / 12, true);
        }
        return currencyFormatter.formatCurrency(amount, true);
    }, [policyChangeVM, currencyFormatter]);

    const getDynamicPriceForLEXExtra = useCallback(() => {
        if (isTierLevel3Customer) {
            return getDynamicPrice(dynamicPricing.lexExtraPremium?.amount);
        }
        const allLexAmount = dynamicPricing.lexExtraPremium?.amount;
        return getDynamicPrice(allLexAmount);
    }, [getDynamicPrice, dynamicPricing, isTierLevel3Customer]);

    const overrideProps = {
        enableStandardLegalExpensesCoverSwitch: {
            path: legalExpensesCoverPath,
            value: true,
            disabled: true,
            label: translator(messages.includedAsStandard)
        },
        enableEnhancedLegalExpensesCoverSwitch: {
            path: legalExpensesCoverPath,
            value: legalExpensesCoverValue === LEGAL_EXPENSES_EXTRA_KEY,
            label: getAddRemoveCoverToggleButtonLabel(legalExpensesCoverValue),
            description: getDynamicPriceForLEXExtra(),
            extraDescription: getExtraDescription()
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallBackMap: {
            onAllRisksLexCoverChange: handleEnableAllRisksLegalExpensesCover,
            onSeeAllCoverBenefitsClick: seeAllCoverBenefits,
            onLearnMoreClicked: learnMore
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policyChangeVM}
            onModelChange={updateWizardData}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallBackMap}
        />
    );
}

export default PersonalLegalExpenses;
