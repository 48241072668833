import { defineMessages } from 'react-intl';

export default defineMessages({
    yes: {
        id: 'endorsement.bespoke.summary.valuablesCard.yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'endorsement.bespoke.summary.valuablesCard.no',
        defaultMessage: 'No'
    },
});
