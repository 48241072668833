const transformToDigitalAuthContext = (jutroAuthContext, token) => {
    return {
        user_id: jutroAuthContext.userInfo.sub,
        user_name: jutroAuthContext.userInfo.name,
        name: jutroAuthContext.userInfo.name,
        email: jutroAuthContext.userInfo.email,
        email_verified: jutroAuthContext.userInfo.email_verified,
        previous_logon_time: jutroAuthContext.userInfo.updated_at,
        sub: jutroAuthContext.userInfo.sub,
        accessToken: token,
        authHeader: {
            Authorization: `Bearer ${token}`,
        },
        isAuthInProgress: jutroAuthContext.isPending, // nfum custom // okta-patch
        isLoggedIn: jutroAuthContext.authenticated, // nfum custom // okta-patch
        logout: jutroAuthContext.logout,
        useJutroAuthContext: true
    };
};

const getDigitalAuthContext = async (jutroAuthContext) => {
    try {
        const token = await jutroAuthContext.getAccessToken();
        return transformToDigitalAuthContext(jutroAuthContext, token);
    } catch (e) { /* NOSONAR: GW OOTB internal mechanism - high complexity and many dependencies */
        throw e;
    }
};

export default {
    transformToDigitalAuthContext,
    getDigitalAuthContext
};
