const typographyVars = {
    '--GW-FONT-FAMILY': 'Meta Pro',
    '--GW-FONT-SANS-SERIF': 'Meta Pro',
    '--GW-FONT-SERIF': 'Archer SSm',
    // FONT WEIGHTS
    '--GW-FONT-WEIGHT-THIN': '100', // thin
    '--GW-FONT-WEIGHT-EXTRA-LIGHT': '200', // extra light
    '--GW-FONT-WEIGHT-LIGHT': '300', // light
    '--GW-FONT-WEIGHT-REGULAR': '400', // regular/normal
    '--GW-FONT-WEIGHT-MEDIUM': '500', // medium
    '--GW-FONT-WEIGHT-SEMI-BOLD': '600', // semi bold
    '--GW-FONT-WEIGHT-BOLD': '700', // bold
    '--GW-FONT-WEIGHT-EXTRA-BOLD': '800', // extra bold
    '--GW-FONT-WEIGHT-BLACK': '900', // black

    // FONT SIZE
    '--GW-FONT-SIZE': '1rem', // 16px  for regular texts
    '--GW-LINE-HEIGHT': 'regular', // for regular texts
    '--GW-FONT-SIZE-SM': '0.875rem', // 14px
    '--GW-LINE-HEIGHT-MD': 'semi bold',

    '--NFUM-FONT-SIZE-14': '0.875rem',
    '--NFUM-FONT-SIZE-16': '1rem',
    '--NFUM-FONT-SIZE-18': '1.125rem',
    '--NFUM-FONT-SIZE-20': '1.25rem',
    '--NFUM-FONT-SIZE-24': '1.5rem',

    // FOR HEADINGS
    '--GW-FONT-SIZE-MD': '1.25rem', // 20px
    '--GW-FONT-SIZE-LG': '1.5rem', // 24px
};

export default typographyVars;
