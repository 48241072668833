export default [
    {
        stepId: 0,
        title: {
            id: 'portal.wizardStep.renewal.Renew your policy',
            defaultMessage: 'Renew your policy',
        }
    },
    {
        stepId: 1,
        title: {
            id: 'portal.wizardStep.renewal.Payment',
            defaultMessage: 'Payment',
        }
    },
    {
        stepId: 2,
        title: {
            id: 'portal.wizardStep.renewal.Payment confirmation',
            defaultMessage: 'Payment confirmation',
        },
        isTransactionNumberHidden: true,
        isPolicyNumberHidden: true
    }
];
