import React, {
    useEffect, useState, useMemo, useCallback
} from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { MetadataContent } from '@jutro/uiconfig';
import {
    Icon, InfoLabel, Link, DateRangeField
} from '@jutro/components';
import { NfumLoader } from 'nfum-components-platform-react';
import { AccountBillingDetailsService } from 'gw-capability-billing';
import { UsersProfileDetailsService } from 'gw-capability-profileinfo';
import { PolicyService } from 'gw-capability-policy';
import { AMPSubmissionDraftService } from 'gw-capability-quoteandbind';
import { DocumentService } from 'gw-capability-policydocument';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { getNormalizedLOBName, isCapabilityEnabled } from 'gw-portals-config-js';
import { useAuthentication } from 'gw-digital-auth-react';
import { LocalDateUtil } from 'gw-portals-util-js';
import { useJobRetrieval } from 'nfum-portals-wizard-react'; // nfum custom
import { generateURL } from 'gw-portals-url-js';
import { useTranslator } from '@jutro/locale';

// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

import styles from './LandingPage.module.scss';
import metadata from './LandingPage.metadata.json5';
import messages from './LandingPage.messages';

const getCellType = (rowData) => {
    const activePeriod = _.first(rowData.periods);
    const lineOfBusiness = activePeriod.lines.length > 1
        ? activePeriod.lines.map((line) => line) : _.first(activePeriod.lines);
    const icon = lineOfBusiness.includes('HOMLine')
        ? (<Icon icon="gw-home" title={lineOfBusiness} key={lineOfBusiness} />)
        : (<Icon icon="gw-view-details" title={lineOfBusiness} key={lineOfBusiness} />);
    return icon;
};

const isPolicyActive = (periods) => {
    const currentRenewed = periods.length > 1;
    const activePeriod = _.first(periods);
    return new Date(activePeriod.effective).getTime() < new Date().getTime() && !currentRenewed;
};

const shouldRenewalNotificationShow = (accountPolicyData) => {
    return accountPolicyData.some((policy) => policy.periods.length > 1);
};

const getCellStatus = (rowData) => {
    if (isPolicyActive(rowData.periods)) {
        return <InfoLabel type="success" size="medium" message={messages.statusActive} />;
    }

    return <InfoLabel type="info" size="medium" message={messages.statusRenewed} />;
};

const getCellPolicy = (rowData) => {
    const activePeriod = _.first(rowData.periods);
    const periodWithIDCard = rowData.periods.find((policy) => policy.idCardPublicID);
    return (
        <Link
            className={styles.link}
            to={{
                pathname: `/account-policy-details/${activePeriod.policyId}`,
                productCode: activePeriod.lines[0],
                idCard: periodWithIDCard ? {
                    idCardSessionID: _.get(periodWithIDCard, 'idCardSessionID'),
                    idCardPublicID: _.get(periodWithIDCard, 'idCardPublicID')
                } : null
            }}
        >
            {activePeriod.policyId}
        </Link>
    );
};

const getCellEffective = (rowData, rowIndex) => {
    const activePeriod = _.first(rowData.periods);
    const effectiveDate = new Date(activePeriod.effective);
    const expirationDate = new Date(activePeriod.expiration);
    const periodValue = {
        startDate: {
            year: effectiveDate.getFullYear(),
            month: effectiveDate.getMonth(),
            day: effectiveDate.getDate()
        },
        endDate: {
            year: expirationDate.getFullYear(),
            month: expirationDate.getMonth(),
            day: expirationDate.getDate()
        },
    };
    return (
        <DateRangeField
            id={`effectiveDate_${rowIndex}`}
            value={periodValue}
            hideLabel
            readOnly
        />
    );
};

const getCellClaim = (rowData) => {
    const activePeriod = _.first(rowData.periods);

    const redirectPath = {
        pathname: '/fnol-select-policy',
        state: {
            policyNumber: activePeriod.policyId,
            redirectPath: '/home',
            claimData: { lossDate: new Date() }
        }
    };
    return (
        <Link to={redirectPath} className={styles.link}>
            {messages.fileClaim}
        </Link>
    );
};

const getCellPremium = (rowData, rowIndex) => {
    const activePeriod = _.first(rowData.periods);
    return (
        <CurrencyField
            id={`premium_${rowIndex}`}
            value={activePeriod.premium}
            dataType="object"
            readOnly
            hideLabel
        />
    );
};

function LandingPage(props) { /* NOSONAR: pure declarative usage  */
    const translator = useTranslator();
    const [accountBillingData, setAccountBillingData] = useState([]);
    const [accountPolicyData, setAccountPolicyData] = useState([]);
    const { authHeader } = useAuthentication();
    const auth = useAuthentication();
    const [accountData, setAccountData] = useState([]);
    const [isLoading, setLoadingState] = useState(false);
    const { capabilitiesConfig } = appConfig;
    const {
        jumpToRetrievedPolicyChange,
        jumpToRetrievedRenewal
    } = useJobRetrieval(); // nfum custom

    useEffect(() => {
        jumpToRetrievedPolicyChange(); // nfum custom
        jumpToRetrievedRenewal(); // nfum custom


        setLoadingState(true);
        let billingPromise = Promise.resolve([]);
        if (isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'billing' })) {
            billingPromise = AccountBillingDetailsService.getAccountBillingSummary(authHeader);
        }
        Promise.all([billingPromise.then(setAccountBillingData),
            PolicyService.getAccountPolicySummaries(authHeader).then(setAccountPolicyData),
            UsersProfileDetailsService.getAccountsContactSummaries(authHeader).then(setAccountData)
        ]).finally(() => {
            setLoadingState(false);
        });

        // disable re render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // nfum custom
    useEffect(() => {
        try {
            const buildNumber = process.env.REACT_APP_GW_VAR_BUILD_NUMBER;
            if (typeof buildNumber !== 'undefined' && buildNumber !== null) {
                console.log('Build Number : ', buildNumber);
            }
        } catch (error) {
            console.error('Error fetching build number:', error);
        }
    }, []);

    const aggregateBilling = useCallback((invoices) => {
        if (invoices.length === 0) {
            return null;
        }

        let total = 0;
        let due = new Date(invoices[0].dueDate);

        _.each(invoices, (invoice) => {
            total += invoice.amountDue.amount;
            const newDue = new Date(invoice.dueDate);
            if (newDue.getTime() < due.getTime()) {
                due = newDue;
            }
        });

        return {
            amountDue: {
                amount: total,
                currency: invoices[0].amountDue.currency
            },
            earliestDue: due
        };
    }, []);

    const getMyBalanceOverDueAndCurrentData = useCallback(
        (invoiceStatus) => {
            if (accountBillingData.activeInvoices) {
                const invoices = accountBillingData.activeInvoices;
                return aggregateBilling(
                    invoices.filter((invoice) => invoice.invoiceStatus === invoiceStatus)
                );
            }
            return null;
        },
        [accountBillingData.activeInvoices, aggregateBilling]
    );

    const getBalanceData = useCallback(() => {
        const overdue = getMyBalanceOverDueAndCurrentData('due');
        const current = getMyBalanceOverDueAndCurrentData('billed');
        if (overdue && current) {
            return {
                amount: current.amountDue.amount + overdue.amountDue.amount,
                currency: current.amountDue.currency
            };
        }
        if (overdue || current) {
            return (overdue || current).amountDue;
        }
        return null;
    }, [getMyBalanceOverDueAndCurrentData]);

    const readValue = useCallback(
        (id, path) => {
            if (path === 'balanceAmount') {
                return getBalanceData();
            }
            if (path === 'overDueAmount') {
                return _.get(getMyBalanceOverDueAndCurrentData('due'), 'amountDue');
            }
            if (path === 'currentAmount') {
                return _.get(getMyBalanceOverDueAndCurrentData('billed'), 'amountDue');
            }
            if (path === 'dueDate') {
                return _.get(getMyBalanceOverDueAndCurrentData('due'), 'earliestDue');
            }
            return _.get(accountBillingData, path);
        },
        [accountBillingData, getBalanceData, getMyBalanceOverDueAndCurrentData]
    );

    const policyWithIDCard = useMemo(() => {
        let periodWithIDCard;
        accountPolicyData.some((policyData) => {
            return policyData.periods.some((period) => {
                if (period.idCardPublicID) {
                    periodWithIDCard = period;
                    return true;
                }
                return false;
            });
        });
        return periodWithIDCard;
    }, [accountPolicyData]);

    const getIDCardQuickLink = useMemo(() => {
        if (!policyWithIDCard) {
            return null;
        }
        return DocumentService.downloadPolicyDocument(
            policyWithIDCard.idCardPublicID,
            policyWithIDCard.idCardSessionID
        );
    }, [policyWithIDCard]);

    const automatedPaymentURL = useMemo(() => {
        const billingID = _.get(accountBillingData, 'manualPayPaymentGroups[0]');

        if (!billingID) {
            return undefined;
        }
        return `billing-summary/${billingID}`;
    }, [accountBillingData]);

    const firstAccountSummaryDefined = useMemo(() => {
        return accountData.length <= 1 && !_.isEmpty(accountData);
    }, [accountData]);

    const mailToTrigger = useCallback((event, email) => {
        event.preventDefault();
        window.location = `mailto:${email}`;
    }, []);

    const claimCapabilityCheck = useMemo(() => {
        return isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'fnol' });
    }, []);

    const onQuoteQuickLinkClick = useCallback(
        (lob) => () => {
            const accountSummary = accountData[0];
            const submission = {
                baseData: {
                    productCode: lob,
                    accountHolder: accountSummary,
                    accountNumber: accountSummary.accountNumber,
                    policyAddress: accountSummary.accountContact.primaryAddress,
                    periodStartDate: LocalDateUtil.today()
                }
            };
            AMPSubmissionDraftService.createForAccount(submission, authHeader).then(
                (savedSubmission) => {
                    const { url } = appConfig.quoteAndBuy;
                    const location = `${url}/quote-${getNormalizedLOBName(lob)}`;
                    const params = {
                        quoteID: savedSubmission.quoteID,
                        postalCode: savedSubmission.baseData.policyAddress.postalCode
                    };

                    window.location = generateURL(location, params);
                }
            );
        },
        [accountData, authHeader]
    );

    const getCellAgent = useCallback(
        (rowData) => {
            return (
                <>
                    <Link className={styles.link} onClick={(e) => mailToTrigger(e, rowData.producerContact.email)} href="/">
                        {rowData.producerContact.displayName}
                    </Link>
                    <br />
                    {rowData.producerContact.phoneNumber ? (
                        <span>
                            {translator(messages.policyDetailsTel)}
                            {rowData.producerContact.phoneNumber}
                        </span>
                    ) : (
                        ''
                    )}
                </>
            );
        },
        [mailToTrigger, translator]
    );

    const getTableOverrides = useCallback(() => {
        return {
            typeColumn: { renderCell: getCellType },
            statusColumn: { renderCell: getCellStatus },
            policyColumn: { renderCell: getCellPolicy },
            effectiveColumn: { renderCell: getCellEffective },
            premiumColumn: { renderCell: getCellPremium },
            fileAClaimColumn: {
                visible: claimCapabilityCheck,
                renderCell: getCellClaim
            },
            agentColumn: {
                visible: accountData.length > 1,
                renderCell: getCellAgent
            }
        };
    }, [accountData, getCellAgent]);

    const onFileAClaimQuickLinkClick = useCallback(
        () => () => {
            const { history } = props;
            return history.push({
                pathname: '/fnol-select-policy',
                state: {
                    redirectPath: '/home',
                    claimData: { lossDate: new Date() }
                }
            });
        },
        [props]
    );

    const handleLinkClick = useCallback(
        (event) => {
            const { history } = props;
            return history.push(event.path);
        },
        [props]
    );

    const signOut = useCallback(
        () => {
            // Setting logoutAM to true on click of signout to paas it to other open tab
            window.localStorage.setItem('logoutAM', true);
            auth.logout();
        },
        [auth]
    );

    const showBillingSection = useMemo(() => {
        return isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'billing' });
    }, []);

    if (isLoading) {
        return <NfumLoader loaded={!isLoading} />; /* NOSONAR: pure declarative usage */
    }
    const override = {
        '@field': {
            phone: {
                labelPosition: 'left'
            }
        },
        myBalanceOverdueAmount: {
            visible: !_.isEmpty(getMyBalanceOverDueAndCurrentData('due'))
        },
        myBalanceCurrentAmount: {
            visible: !_.isEmpty(getMyBalanceOverDueAndCurrentData('billed'))
        },
        dueDateContainer: {
            visible: !_.isEmpty(getMyBalanceOverDueAndCurrentData('due'))
        },
        dueDateLabel: {
            visible: !_.isEmpty(getMyBalanceOverDueAndCurrentData('billed'))
        },
        payNowButton: {
            visible: firstAccountSummaryDefined
        },
        mediaSidebar: {
            showContact: accountData.length > 1
        },
        policiesTable: {
            data: accountPolicyData
        },
        printAnIDCardLink: {
            visible: !_.isNil(policyWithIDCard),
            href: getIDCardQuickLink,
            target: '_blank'
        },
        setupAutomatedPaymentsLink: {
            onClick: {
                callback: 'handleLinkClick',
                callbackProps: {
                    path: automatedPaymentURL,
                }
            },
            visible: !!automatedPaymentURL && showBillingSection
        },
        getAPAQuote: {
            onClick: onQuoteQuickLinkClick('PersonalAuto'),
            visible: appConfig.quoteAndBuy.ampQuote && firstAccountSummaryDefined
        },
        getAHOQuote: {
            onClick: onQuoteQuickLinkClick('Homeowners'),
            visible: appConfig.quoteAndBuy.ampQuote && firstAccountSummaryDefined
        },
        getANGHQuote: { // nfum custom
            onClick: onQuoteQuickLinkClick('NewGenerationHome'),
            visible: appConfig.quoteAndBuy.ampQuote && firstAccountSummaryDefined
        },
        renewalNotification: {
            visible: shouldRenewalNotificationShow(accountPolicyData)
        },
        fileAClaimLink: {
            onClick: onFileAClaimQuickLinkClick(),
            visible: claimCapabilityCheck
        },
        tileGroupContainer: {
            visible: showBillingSection
        },
        makeAPaymentLink: {
            visible: showBillingSection
        },
        signOut: {
            onClick: signOut
        },
        ...getTableOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveValue: readValue,
        resolveCallbackMap: {
            handleLinkClick
        }
    };

    // eslint-disable-next-line max-len
    return <MetadataContent uiProps={metadata.pageContent} overrideProps={override} {...resolvers} />;
}

export default withRouter(LandingPage);
