import React, { useCallback, useContext } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useCurrency } from 'nfum-portals-utils-react';
import messages from './CostBreakdownBox.messages';
import metadata from './CostBreakdownBox.metadata.json5';
import styles from './CostBreakdownBox.module.scss';

function CostBreakdownBox(props) { /* NOSONAR: pure declarative usage  */
    const { submissionVM } = props;
    const translator = useContext(TranslatorContext);
    const currencyFormatter = useCurrency();
    const isCash = _.get(submissionVM, 'baseData.value.paymentMethod_NFUM') === 'cash';
    const renewalDate = moment(_.get(submissionVM.value, 'baseData.periodStartDate')).format('DD MMMM YYYY');

    const getCurrentTotalCost = useCallback(() => {
        const yearlyPremium = _.get(submissionVM.value, 'previousTotalCost.amount')?.toFixed(2);
        const monthlyPremium = _.get(submissionVM.value, 'previousTotalCostMonthly_NFUM.amount')?.toFixed(2);
        return currencyFormatter.formatCurrency(`${isCash ? yearlyPremium : monthlyPremium}`, true);
    }, [submissionVM, isCash, currencyFormatter]);

    const getNewTotalCost = useCallback(() => {
        const yearlyPremium = _.get(submissionVM.value, 'totalCost.amount')?.toFixed(2);
        const monthlyPremium = _.get(submissionVM.value, 'totalCostMonthly_NFUM.amount')?.toFixed(2);
        return currencyFormatter.formatCurrency(`${isCash ? yearlyPremium : monthlyPremium}`, true);
    }, [submissionVM, isCash, currencyFormatter]);

    const overrideProps = {
        costInfoBox: {
            topText: translator(messages.yourCurrentPremium,
                {
                    currentPremium: getCurrentTotalCost(),
                    perTime: isCash
                        ? translator(messages.perYear)
                        : translator(messages.perMonth)
                }),
            modelVM: submissionVM,
            isPerYear: isCash,
            titleText: translator(messages.yourNewPremium, { renewalDate }),
            premiumText: getNewTotalCost(),
            policyNumber: _.get(submissionVM.value, 'policyNumber'),
            policyStartDate: _.get(submissionVM.value, 'baseData.periodStartDate')
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
        />
    );
}

export default CostBreakdownBox;
