import React, {
    useCallback, useEffect, useContext
} from 'react';
import _ from 'lodash';
import moment from 'moment';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { TranslatorContext } from '@jutro/locale';
import {
    RENEWAL_STEPS
} from 'nfum-portals-utils-react';
import { useWizardActions } from 'nfum-portals-wizard-react';
import metadata from './RenewalSuccessPage.metadata.json5';
import styles from './RenewalSuccessPage.module.scss';
import './RenewalSuccessPage.messages';
import commonMessages from '../../NGHRenewal.messages';
import useTagManager from '../../hooks/useTagManager';

function SuccessPage(props) {
    const { wizardData: renewalVM } = props;
    const { returnToDashboard } = useWizardActions();
    const translator = useContext(TranslatorContext);
    const {
        pushFormConfirmationInfo
    } = useTagManager();

    useEffect(() => {
        pushFormConfirmationInfo(renewalVM, RENEWAL_STEPS.SUCCESS);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getEffectiveDate = useCallback(() => {
        const periodStartDate = _.get(renewalVM.value, 'baseData.periodStartDate');
        if (periodStartDate) {
            const { year, month, day } = periodStartDate;
            return moment(new Date(year, month, day)).format('Do MMMM YYYY');
        }
        return '';
    }, [renewalVM]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        }
    };

    const overrideProps = {
        renewalSuccessTransactionPolicyNumber: {
            content: _.get(renewalVM.value, 'policyNumber') || ''
        },
        renewalSuccessTransactionEffectiveDate: {
            content: getEffectiveDate()
        }
    };

    return (
        <WizardPage
            onNext={() => returnToDashboard(translator(commonMessages.returnToDashboard))}
            showPrevious={false}
            showCancel={false}
            nextLabel={commonMessages.returnToDashboard}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

SuccessPage.propTypes = wizardProps;
export default withAuthenticationContext(SuccessPage);
